import type { IContextPageConfigTab } from "../../ProjectSettings";
import { IContextInformation } from "../matrixlib/MatrixLibInterfaces";

export type { IContextFramePlugin };
export { ContextFrameManager };
export { InitializeTrainingManager };

interface IContextFramePlugin {
    id: string; // an identifier for panel
    title: string; // name for tab and admin ui
    adminOption: string; // help for admin ui
    onUpdate: (ui: JQuery, config: IContextPageConfigTab, context: IContextInformation) => void; // function to render page, if context == null, it is the initial drawing
}

class ContextFrameManagerImpl {
    private pages: IContextFramePlugin[] = [];

    constructor() {}

    getPages() {
        return this.pages;
    }

    // register a plugin providing  a context page
    register(plugin: IContextFramePlugin) {
        this.pages.push(plugin);
    }

    // check if a context page is supplied by plugin
    implements(id: string): boolean {
        return !!this.getPlugin(id);
    }

    renderTab(panel: JQuery, id: string, config: IContextPageConfigTab, context: IContextInformation) {
        let p = this.getPlugin(id);
        p.onUpdate(panel, config, context);
    }

    // return a context page plugin of a given type
    private getPlugin(id: string): IContextFramePlugin {
        for (var idx = 0; idx < this.pages.length; idx++) {
            if (this.pages[idx].id == id) {
                return this.pages[idx];
            }
        }
        return null;
    }
}

var ContextFrameManager: ContextFrameManagerImpl;

function InitializeTrainingManager() {
    ContextFrameManager = new ContextFrameManagerImpl();
}
