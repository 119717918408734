import { GenericDocAbstractFieldHandler } from "./GenericDocAbstractFieldHandler";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";

export interface ITableOfContentOptions extends IDHFSectionOptions {
    format: string;
    render_toc?: boolean;
}

export class TableOfContentDocFieldHandler extends GenericDocAbstractFieldHandler {
    getDefaultConfig(): ITableOfContentOptions {
        return { format: '\\o "1-3" \\h \\z \\u \\f' };
    }

    getXmlValue(): string {
        let options: ITableOfContentOptions = { format: this.dhfFieldConfig.format };
        return JSON.stringify([options]);
    }
}
