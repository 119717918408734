import { IDB, IDBParent, DBCache } from "./DBCache";

import {
    XCPostAddProjectSetting,
    XCPostCompareHtml,
    XCGetProjectAudit,
    XCPostAddFolder,
    XCPostAddItem,
    XCPostSignItem,
    XCPostConvertWord,
    XCPostRestoreItem,
    XCPutEditItem,
    XCDeleteItem,
    XCGetNeedleMinimal,
    XCGetNeedle,
} from "./../../RestCalls";
import { RestConnector, IJcxhr, IFileParam, IFileUploadProgress } from "./RestConnector";
import { ml } from "./../matrixlib";
import {
    XRPostProject_RestoreItem_UndeleteAnswer,
    XRGetProject_ProjectSettingAll_GetSettingAck,
    XRTrimItem,
    XRGetProject_ItemDetail_TrimItem,
    XRGetProject_Needle_TrimNeedle,
    XRGetProject_AllJob_JobsWithUrl,
    XRPostProject_LaunchReport_CreateReportJobAck,
    XRGetProject_JobStatus_JobsStatusWithUrl,
    XRPostProject_CompareHtml_HtmlCompareResult,
    XRTrimNeedleItem,
    XRGetProject_NeedleMinimal_StringList,
    XRGetProject_CategoryList_GetProjectStructAck,
    XRGetProject_ProjectAudit_TrimAuditList,
    XRTrimAudit,
    XRGetProject_Reports_GetReportsAck,
    XRPostProject_AddFolder_AddItemAck,
    XRPostProject_SignItem_SignItemAck,
    XRGetProject_ProjectInfo_ProjectInfo,
    XRPostProject_AddItem_AddItemAck,
    XRPutProject_EditItem_TrimItem,
    XRProjectInfo,
} from "../../RestResult";
import { mDHF } from "./PluginManagerDocuments";
import { plugins } from "./PluginManager";
import { IPostCreateSignOrDocResult, IReportOptions } from "../matrixlib/MatrixLibInterfaces";
import {
    IItem,
    globalMatrix,
    matrixSession,
    restConnection,
    app,
    IItemGet,
    IGenericMap,
    IStringMap,
    IItemPut,
    IRestResult,
    IItemHistory,
    IRestParam,
} from "../../globals";
import { mTM } from "./TestManager";

export type { ISearchResult, ISearchResultField, IRestoreItemResult, IUpdateCache };
export { RestDB };

interface ISearchResult {
    itemId: string;
    version: number;
    title: string;
    fieldVal?: ISearchResultField[];
    downlinks: string[];
    uplinks: string[];
    labels: string[];
    creationDate?: string;
    // There is a pseudo-field called creationDate which may or may not be here.
    // We often construct ISearchResult objects from needle searches, and the needle
    // result has creationDate (sometimes).
}
interface ISearchResultField {
    id: number;
    value: string;
}

interface IRestoreItemResult {
    item: string;
    version: number;
    response: XRPostProject_RestoreItem_UndeleteAnswer;
}
interface IUpdateCache {
    item: IItem;
    parent: string;
}

class RestDB {
    // see for cache http://learn.jquery.com/code-organization/deferreds/examples/

    private restConnection: RestConnector;
    private _project: string;

    private dbCache: DBCache;

    constructor(init: RestConnector) {
        this.restConnection = init;
    }

    setProject(project: string): void {
        this._project = project;
        this.restConnection.setProject(project);
    }

    setSettingJSON(key: string, valueJSON: {}): JQueryDeferred<{}> {
        let that = this;
        var res = $.Deferred();
        var newSetting = JSON.stringify(valueJSON);
        try {
            var check = JSON.parse(newSetting);
            let setSetting: XCPostAddProjectSetting = {
                value: newSetting,
                key: key,
                reason: "setting change",
            };
            this.restConnection
                .postServer(this._project + "/setting", setSetting)
                .done(function () {
                    globalMatrix.ItemConfig.setSettingJSON(key, valueJSON);
                    res.resolve();
                })
                .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                    that.showRestError(jqxhr, textStatus, error);
                    res.reject();
                });
        } catch (err) {
            ml.UI.showError("Bad value of setting.", "Error was:" + err);
            res.reject();
        }
        return res;
    }

    readSettingJSONAsync(key: string, otherProject?: string, noRetry?: boolean): JQueryDeferred<any> {
        // settings stored as JSON, hence they can have any structure
        var res: JQueryDeferred<any> = $.Deferred();
        this.restConnection
            .getServer((otherProject ? otherProject : matrixSession.getProject()) + "/setting", noRetry)
            .done(function (result) {
                const settings = result as XRGetProject_ProjectSettingAll_GetSettingAck;
                var found = false;
                if (settings.settingList) {
                    $.each(settings.settingList, function (idx, setting) {
                        if (setting.key === key && setting.value && setting.value.indexOf("{") !== -1) {
                            // assume it a json
                            var val = ml.JSON.fromString(setting.value);
                            if (val.status === "ok") {
                                found = true;
                                res.resolve(val.value);
                            }
                        }
                    });
                }
                if (!found) {
                    res.resolve({});
                }
            })
            .fail(function () {
                res.reject();
            });
        return res;
    }

    setSettingCustomerJSON(settingId: string, valueJSON: {}) {
        let that = this;

        let res = $.Deferred();

        restConnection
            .postServer("all/setting", {
                key: settingId,
                value: JSON.stringify(valueJSON),
                reason: "setting change",
            })
            .done(function (result) {
                res.resolve();
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                that.showRestError(jqxhr, textStatus, error);
                res.reject();
            });

        return res;
    }

    readSettingCustomerJSONAsync(key: string) {
        // settings stored as JSON, hence they can have any structure
        var res: JQueryDeferred<any> = $.Deferred();
        this.restConnection.getServer("all/setting").done(function (result) {
            const settings = result as XRGetProject_ProjectSettingAll_GetSettingAck;
            var found = false;
            if (settings.settingList) {
                $.each(settings.settingList, function (idx, setting) {
                    if (setting.key === key && setting.value && setting.value.indexOf("{") !== -1) {
                        // assume it a json
                        var val = ml.JSON.fromString(setting.value);
                        if (val.status === "ok") {
                            found = true;
                            res.resolve(val.value);
                        }
                    }
                });
            }
            if (!found) {
                res.resolve({});
            }
        });
        return res;
    }

    // set or overwrite the default cache: used by jira addon to avoid caching
    setCache(externalCache: DBCache): void {
        this.dbCache = externalCache;
    }

    retrieveTreeAsync(): JQueryDeferred<{ tree: IDB[]; project: XRProjectInfo }> {
        let that = this;
        var res = $.Deferred<{ tree: IDB[]; project: XRProjectInfo }>();

        let date = ml.URL.getParameterByName(window.location.href, "atDate");
        let dp = date ? "&atDate=" + date : "";

        if (date) {
            globalMatrix.ItemConfig.activateTimewarp(date);
        }

        this.retrieveConfigAsync().done(function (project: XRProjectInfo) {
            that.restConnection.getProject("tree?fancy" + dp).done(async function (response) {
                const result: IDB[] = RestDB.filterLegacyReportCat(response as IDB[]);
                plugins.initProject(that._project);
                mTM.InitializeProject();

                that.dbCache = new DBCache();
                await that.dbCache.initMatrixTree(result, app.isMedical());
                res.resolve({ tree: result, project: project });
            });
        });
        return res;
    }
    retrieveTreeAndItem(itemId: string): JQueryDeferred<{ item: IItem; tree: IDB[]; project: XRProjectInfo }> {
        let that = this;
        var res = $.Deferred<{ item: IItem; tree: IDB[]; project: XRProjectInfo }>();

        let date = ml.URL.getParameterByName(window.location.href, "atDate");
        let dp = date ? "&atDate=" + date : "";

        if (date) {
            globalMatrix.ItemConfig.activateTimewarp(date);
        }

        this.retrieveConfigAsync().done(function (project: XRProjectInfo) {
            if (itemId) {
                // check whether to show an item / folder (and not a dashboard or something)
                let isItem = globalMatrix.ItemConfig.getCategories(true).indexOf(ml.Item.parseRef(itemId).type) != -1;
                let itemToGet = isItem ? itemId : "F-DOC-1";

                let history = "?history=true";

                that.restConnection
                    .getProject("item/" + itemToGet + history + "&withTree=1", false)
                    .done(async function (result) {
                        // init tree
                        let tree = RestDB.filterLegacyReportCat((result as XRTrimItem).contextTree.children);

                        for (let t of tree) {
                            if (t.id.startsWith("F-") && !t.children) {
                                t.children = [];
                            }
                        }

                        plugins.initProject(that._project);
                        mTM.InitializeProject();

                        ml.ContextFrames.init();

                        that.dbCache = new DBCache();
                        await that.dbCache.initMatrixTree(tree, app.isMedical());

                        let item = that.parseItemJSON(itemId, result as XRTrimItem);

                        if (that.dbCache.isFolder(itemId)) {
                            item["children"] = [];
                        }

                        res.resolve({ item: isItem ? item : null, tree: tree, project: project });
                    })
                    .fail(function (error) {
                        // item might have been deleted
                        that.loadTreeWithoutItem(dp).done((db) => {
                            res.resolve({ item: null, tree: db, project: project });
                            ml.ContextFrames.init();
                        });
                    });
            } else {
                that.loadTreeWithoutItem(dp).done((db) => {
                    res.resolve({ item: null, tree: db, project: project });
                    ml.ContextFrames.init();
                });
            }
        });
        return res;
    }

    protected loadTreeWithoutItem(dp: string): JQueryDeferred<IDB[]> {
        let that = this;

        let res = $.Deferred<IDB[]>();
        that.restConnection.getProject("tree?fancy" + dp).done(async function (response) {
            const result: IDB[] = RestDB.filterLegacyReportCat(response as IDB[]);
            plugins.initProject(that._project);
            mTM.InitializeProject();

            that.dbCache = new DBCache();
            await that.dbCache.initMatrixTree(result, app.isMedical());
            res.resolve(result);
        });
        return res;
    }
    static filterLegacyReportCat(result: any[]): any[] {
        let enableLegacyReport =
            globalMatrix.ItemConfig != undefined
                ? globalMatrix.ItemConfig.getExtrasConfig().enableLegacyReport
                : undefined;
        if (enableLegacyReport == undefined || enableLegacyReport == "0") {
            //Let remove legacy report cat from the tree
            let fReport1Index = result.findIndex((o) => {
                return o.id == "F-REPORT-1";
            });
            if (fReport1Index != -1) {
                result[fReport1Index].children = [];
            }
        }
        if (enableLegacyReport == "1") {
            result = result.filter((item) => {
                return item.id != "F-REPORT-1";
            });
        }
        return result;
    }

    doesExist(itemId: string): boolean {
        return this.dbCache.doesExist(itemId);
    }

    getChildrenIds(parentId: string): string[] {
        return this.dbCache.getChildrenIds(parentId);
    }

    getItemFromTree(itemId: string): IDB {
        return this.dbCache.getItemFromCache(itemId);
    }

    getItemAsync(itemId: string, ignoreErrors: boolean, includeHistory: boolean): JQueryDeferred<IItem> {
        let that = this;
        var res = $.Deferred();

        let history = includeHistory ? "?history=true" : "";
        if (this.dbCache.isFolder(itemId)) {
            this.restConnection
                .getProject("item/" + itemId + history, ignoreErrors)
                .done(function (result) {
                    var item = that.parseItemJSON(itemId, result as XRGetProject_ItemDetail_TrimItem);
                    item["children"] = [];
                    res.resolve(item);
                })
                .fail(function (error) {
                    res.reject(error);
                });
        } else {
            this.restConnection
                .getProject("item/" + itemId + history, ignoreErrors)
                .done(function (result) {
                    //TODO update tree (e.g if title changed or parent?)
                    var item = that.parseItemJSON(itemId, result as XRGetProject_ItemDetail_TrimItem);
                    res.resolve(item);
                })
                .fail(function (error) {
                    res.reject(error);
                });
        }
        return res;
    }

    getNeedlesAsync(
        searchExpr: string,
        up: boolean,
        down: boolean,
        fields: string,
        labels: boolean,
        ignoreFilters?: boolean,
    ): JQueryDeferred<IItem[]> {
        var res: JQueryDeferred<IItem[]> = $.Deferred();
        let params: IRestParam = {};
        if (up || down) params.links = up && down ? "down,up" : up ? "up" : "down";

        if (fields) params.fieldsOut = fields;
        if (labels) params.labels = 1;

        let items: IItem[] = [];
        params.search = "mrql:" + searchExpr;

        var labelFilter = ml.LabelTools.getFilter();
        if (!ignoreFilters && labelFilter && labelFilter.length > 0) {
            params.filter = labelFilter;
        }

        restConnection
            .postProject("needle", params, true)
            .done(function (results) {
                $.each((results as XRGetProject_Needle_TrimNeedle).needles, function (idx, needle) {
                    var item: IItemGet = {
                        id: ml.Item.parseRef(needle.itemOrFolderRef).id,
                        title: needle.title,
                        type: ml.Item.parseRef(needle.itemOrFolderRef).type,
                        downLinks: [],
                        upLinks: [],
                        modDate: needle.lastModDate,
                        creationDate: needle.creationDate,
                        isUnselected: 0,
                        labels: needle.labels
                            ? needle.labels.split(",").map(function (label) {
                                  return label.substr(1, label.length - 2);
                              })
                            : [],
                    };

                    if (needle.fieldVal) {
                        $.each(needle.fieldVal, function (jdx, fieldVal) {
                            (<IGenericMap>item)[fieldVal.id.toString()] = fieldVal.value;
                        });
                    }

                    for (var idx = 0; needle.downLinkList && idx < needle.downLinkList.length; idx++) {
                        var tol = needle.downLinkList[idx].itemRef;

                        item.downLinks.push({
                            to: ml.Item.parseRef(tol).id,
                            title: needle.downLinkList[idx].title,
                            modDate: needle.downLinkList[idx].modDate,
                        });
                    }

                    for (var idx = 0; needle.upLinkList && idx < needle.upLinkList.length; idx++) {
                        var tol = needle.upLinkList[idx].itemRef;

                        item.upLinks.push({
                            to: ml.Item.parseRef(tol).id,
                            title: needle.upLinkList[idx].title,
                            modDate: needle.upLinkList[idx].modDate,
                        });
                    }

                    items.push(item);
                });
                res.resolve(items);
            })
            .fail(function () {
                res.reject();
            });

        return res;
    }

    getItemProjectAsync(
        project: string,
        itemId: string,
        ignoreErrors: boolean,
        includeHistory: boolean,
    ): JQueryDeferred<IItem> {
        let that = this;
        var res = $.Deferred();

        let history = includeHistory ? "?history=true" : "";

        this.restConnection
            .getServer(project + "/item/" + itemId + history, ignoreErrors)
            .done(function (result) {
                var item = that.parseItemJSON(itemId, result as XRGetProject_ItemDetail_TrimItem);
                res.resolve(item);
            })
            .fail(function (error) {
                res.reject(error);
            });

        return res;
    }

    getProjectItemAsync(project: string, itemId: string, includeHistory: boolean): JQueryDeferred<IItem> {
        let that = this;
        var res = $.Deferred();

        let history = includeHistory ? "?history=true" : "";

        this.restConnection
            .getServer(project + "/item/" + itemId + history)
            .done(function (result) {
                var item = that.parseItemJSON(itemId, result as XRGetProject_ItemDetail_TrimItem);
                res.resolve(item);
            })
            .fail(function (error) {
                res.reject(error);
            });

        return res;
    }

    // typed based on a usage, please confirm
    startReportAsync(itemId: string, reportOptions: IReportOptions): JQueryDeferred<IPostCreateSignOrDocResult> {
        var res: JQueryDeferred<IPostCreateSignOrDocResult> = $.Deferred();
        reportOptions["url"] = globalMatrix.matrixBaseUrl;
        reportOptions["resturl"] = globalMatrix.matrixRestUrl;
        if (globalMatrix.ItemConfig.getTimeWarp()) {
            reportOptions["atDate"] = globalMatrix.ItemConfig.getTimeWarp();
        }
        let isSign = mDHF.isSignedType(ml.Item.parseRef(itemId).type);
        this.restConnection
            .postProject((reportOptions.inline && isSign ? "signedreport/" : "report/") + itemId, reportOptions)
            .done(function (result) {
                res.resolve(result as IPostCreateSignOrDocResult);
            });

        return res;
    }

    canLaunchReport(): JQueryDeferred<XRGetProject_AllJob_JobsWithUrl> {
        return <any>this.restConnection.getServer("all/job");
    }

    startCreateDocumentAsync(
        itemId: string,
        reportOptions: IReportOptions,
    ): JQueryDeferred<XRPostProject_LaunchReport_CreateReportJobAck> {
        var res = $.Deferred();
        reportOptions["url"] = globalMatrix.matrixBaseUrl;
        reportOptions["resturl"] = globalMatrix.matrixRestUrl;
        let isSign = mDHF.isSignedType(ml.Item.parseRef(itemId).type);
        this.restConnection
            .postProject((isSign ? "signedreport/" : "report/") + itemId, reportOptions)
            .done(function (result) {
                res.resolve(result as XRPostProject_LaunchReport_CreateReportJobAck);
            });

        return <any>res;
    }

    getReportDetails(jobId: number): JQueryDeferred<XRGetProject_JobStatus_JobsStatusWithUrl> {
        var res = $.Deferred();
        var fileparam = "";

        this.restConnection.getProject("job/" + jobId + fileparam).done(function (result) {
            res.resolve(result);
        });

        return <any>res;
    }

    compareHTML(compareParams: XCPostCompareHtml): JQueryDeferred<XRPostProject_CompareHtml_HtmlCompareResult> {
        return <any>this.restConnection.postServer("all/compareHtml", compareParams);
    }

    download(jobId: number, file: number, param: string[]): void {
        var fileparam = "/" + file;
        this.restConnection.download("job/" + jobId + fileparam, param);
    }

    downloadFromUrl(url: string, param?: IStringMap): void {
        this.restConnection.download(url, param);
    }
    downloadInMemory(jobId: number, file: string, dataType?: string): JQueryDeferred<string> {
        var res = $.Deferred();
        var fileparam = "/" + file;
        this.restConnection.getFile("job/" + jobId + fileparam, dataType).done(function (result) {
            res.resolve(result);
        });

        return <any>res;
    }
    downloadInMemoryFromUrl(url: string): JQueryDeferred<string> {
        var res = $.Deferred();
        this.restConnection.getFile(url).done(function (result) {
            res.resolve(result);
        });

        return <any>res;
    }
    getType(itemId: string): string {
        if (this.dbCache) {
            return this.dbCache.getType(itemId);
        }
        return "_";
    }

    touchAsync(itemId: string, depth: number, comment: string): JQueryDeferred<string> {
        return <any>this.restConnection.putProject("touch/" + itemId, { reason: comment, nbLayers: depth }, itemId);
    }

    getIcon(itemId: string): string {
        return this.dbCache.getIcon(itemId);
    }

    private parseSearchResult(needle: XRTrimNeedleItem, fieldList?: string): ISearchResult {
        let fullitem = ml.Item.parseRef(needle.itemOrFolderRef);
        let sr: ISearchResult = {
            itemId: fullitem.id,
            version: fullitem.version,
            title: needle.title,
            downlinks: [],
            uplinks: [],
            labels: [],
        };
        if (fieldList && fieldList.length > 0) {
            sr.fieldVal = needle.fieldVal;
        }
        if (needle.downLinkList) {
            $.each(needle.downLinkList, function (idx, link) {
                sr.downlinks.push(ml.Item.parseRef(link.itemRef).id);
            });
        }
        if (needle.upLinkList) {
            $.each(needle.upLinkList, function (idx, link) {
                sr.uplinks.push(ml.Item.parseRef(link.itemRef).id);
            });
        }
        if (needle.labels) {
            let labels = needle.labels.split(",");
            $.each(labels, function (idx, label) {
                sr.labels.push(label.substr(1, label.length - 2));
            });
        }
        return sr;
    }

    searchAsyncMinimalOutput(
        term: string,
        filter?: string,
        ignoreFilters?: boolean,
        crossProject?: string,
    ): JQueryDeferred<string[]> {
        var res = $.Deferred();
        // Add the minimal outputMode flag
        var params: XCGetNeedleMinimal = { search: term };

        if (!ignoreFilters && filter && filter.length > 0) {
            params.filter = filter;
        } else {
            var labelFilter = ml.LabelTools.getFilter();
            if (!ignoreFilters && labelFilter && labelFilter.length > 0) {
                params.filter = labelFilter;
            }
        }

        if (params.search.length > 0) {
            if (crossProject) {
                this.restConnection
                    .postServer(crossProject + "/needleminimal", params, true)
                    .done(function (result) {
                        res.resolve(result as XRGetProject_NeedleMinimal_StringList);
                    })
                    .fail(function (jqxhr, textStatus, error) {
                        res.reject(jqxhr, textStatus, error);
                    });
            } else {
                this.restConnection
                    .postProject("needleminimal", params, true)
                    .done(function (result) {
                        res.resolve(result as XRGetProject_NeedleMinimal_StringList);
                    })
                    .fail(function (jqxhr, textStatus, error) {
                        res.reject(jqxhr, textStatus, error);
                    });
            }
        } else {
            res.reject();
        }

        // TODO: investigate: XRGetProject_NeedleMinimal_StringList is not the same string[]
        return <JQueryDeferred<string[]>>res;
    }
    searchAsync(
        term: string,
        filter?: string,
        ignoreFilters?: boolean,
        fieldList?: string,
        crossProject?: string,
        labels?: boolean,
        down?: boolean,
        up?: boolean,
        treeOrder?: boolean,
    ): JQueryDeferred<ISearchResult[]> {
        let that = this;

        var res = $.Deferred();
        var params: XCGetNeedle = { search: term, id: "" };

        if (ignoreFilters && filter && filter.length > 0) {
            params.filter = filter;
        } else {
            var labelFilter = ml.LabelTools.getFilter();
            if (!ignoreFilters && labelFilter && labelFilter.length > 0) {
                params.filter = labelFilter;
            }
        }

        if (fieldList && fieldList.length > 0) {
            params.fieldsOut = fieldList;
        }
        if (treeOrder) {
            params.treeOrder = 1;
        }
        if (labels) {
            params.labels = 1;
        }
        if (down && up) {
            params.links = "up,down";
        } else if (down) {
            params.links = "down";
        } else if (up) {
            params.links = "up";
        }

        if (globalMatrix.ItemConfig.getTimeWarp()) {
            params.atDate = globalMatrix.ItemConfig.getTimeWarp();
        }

        if (params) {
            if (crossProject) {
                this.restConnection
                    .postServer(crossProject + "/needle", params, true)
                    .done(function (response) {
                        const result = response as XRGetProject_Needle_TrimNeedle;
                        var hoi: ISearchResult[] = [];
                        for (var idx = 0; idx < result.needles.length; idx++) {
                            hoi.push(that.parseSearchResult(result.needles[idx], fieldList));
                        }
                        res.resolve(hoi);
                    })
                    .fail(function (jqxhr, textStatus, error) {
                        res.reject(jqxhr, textStatus, error);
                    });
            } else {
                this.restConnection
                    .postProject("needle?", params, true)
                    .done(function (response) {
                        const result = response as XRGetProject_Needle_TrimNeedle;
                        var hoi: ISearchResult[] = [];
                        for (var idx = 0; idx < result.needles.length; idx++) {
                            hoi.push(that.parseSearchResult(result.needles[idx], fieldList));
                        }
                        res.resolve(hoi);
                    })
                    .fail(function (jqxhr, textStatus, error) {
                        res.reject(jqxhr, textStatus, error);
                    });
            }
        } else {
            res.reject();
        }
        return <any>res;
    }

    getVersionAsync(itemId: string, version: number, includeHistory?: boolean): JQueryDeferred<{}> {
        let that = this;

        var res = $.Deferred();
        if (this.dbCache.isFolder(itemId)) {
            res.resolve();
        } else {
            let history = includeHistory ? "?history=true" : "";
            this.restConnection
                .getProject("item/" + itemId + "-v" + version + history)
                .done(function (result) {
                    //TODO update tree (e.g if title changed or parent?)
                    var item = that.parseItemJSON(itemId, result as XRGetProject_ItemDetail_TrimItem);
                    res.resolve(item);
                })
                .fail(function (error) {
                    res.reject(error);
                });
        }
        return res;
    }
    // get an item from another project
    getProjectVersionAsync(
        project: string,
        itemId: string,
        version: number,
        includeHistory?: boolean,
    ): JQueryDeferred<{}> {
        let that = this;

        var res = $.Deferred();
        if (this.dbCache.isFolder(itemId)) {
            res.resolve();
        } else {
            let history = includeHistory ? "?history=true" : "";
            this.restConnection
                .getServer(project + "/item/" + itemId + "-v" + version + history)
                .done(function (result) {
                    var item = that.parseItemJSON(itemId, result as XRGetProject_ItemDetail_TrimItem);
                    res.resolve(item);
                })
                .fail(function (error) {
                    res.reject(error);
                });
        }
        return res;
    }
    // get /cat from another project
    getProjectCat(project: string): JQueryDeferred<XRGetProject_CategoryList_GetProjectStructAck> {
        var res = $.Deferred();
        this.restConnection
            .getServer(project + "/cat")
            .done(function (result) {
                res.resolve(result as XRGetProject_CategoryList_GetProjectStructAck);
            })
            .fail(function (error) {
                res.reject(error);
            });
        return <any>res;
    }

    getAuditLogAsync(
        startAt: number,
        maxResults: number,
        param: XCGetProjectAudit,
    ): JQueryDeferred<XRGetProject_ProjectAudit_TrimAuditList> {
        if (!param) {
            param = {};
        }
        param.startAt = startAt;
        param.maxResults = maxResults;

        return <any>this.restConnection.getProject("audit" + "?" + $.param(param, true));
    }
    getAuditDetailsAsync(auditId?: number): JQueryDeferred<XRTrimAudit> {
        var res = $.Deferred();

        let param = {
            auditIdMin: auditId,
            auditIdMax: auditId,
            tech: "yes",
            resolveRef: 1,
        };
        this.restConnection
            .getProject("audit" + "?" + $.param(param, true))
            .done(function (result) {
                const auditDetails = result as XRGetProject_ProjectAudit_TrimAuditList;
                if (auditDetails.audit && auditDetails.audit.length === 1) {
                    res.resolve(auditDetails.audit[0]);
                } else {
                    res.reject("no audit details with this number");
                }
            })
            .fail(function (error) {
                res.reject(error);
            });
        return <any>res;
    }

    getAvailableReportsAsync(): JQueryDeferred<XRGetProject_Reports_GetReportsAck> {
        return <any>this.restConnection.getServer("all/reports");
    }

    getTree(subtreeFilter: string[]): IDB[] {
        return this.dbCache.getTree(subtreeFilter);
    }

    getParentId(itemId: string): string {
        return this.dbCache.getParentId(itemId);
    }

    // return a list of parents of category root
    getCategoryBreadcrumbs(category: string): string[] {
        return this.dbCache.getCategoryBreadcrumbs(category);
    }

    // return a list of item, parent of item, parent of parent of item...
    getBreadcrumbs(itemId: string): string[] {
        return this.dbCache.getBreadcrumbs(itemId);
    }

    /* set style of item / folder
        computeFolder: 0=look (folder style from lookup)
        computeFolder: 1=all (all children in folder have style: folder has style)
        computeFolder: 2=any (any of the children has style: folder has style) */
    setStyle(itemIds: string[], style: string, computeFolder: number) {
        return this.dbCache.setStyle(itemIds, style, computeFolder);
    }

    getRootOfType(type: string): string {
        return this.dbCache.getRootOfType(type);
    }

    hasChildren(itemId: string): boolean {
        return this.dbCache.hasChildren(itemId);
    }

    isFolder(itemId: string): boolean {
        return this.dbCache.isFolder(itemId);
    }

    getItemTitle(itemId: string): string {
        return this.dbCache.getItemTitle(itemId);
    }

    isHiddenLink(itemId: string): boolean {
        return this.dbCache.isHiddenLink(itemId);
    }

    setHiddenLink(itemId: string, hidden: number): void {
        this.dbCache.setHiddenLink(itemId, hidden);
    }

    createItemAsync(
        itemJson: IItemPut,
        comment: string,
        actions: string,
        parentId: string,
        dontFailOnCleanup?: boolean,
    ): JQueryDeferred<IDBParent> {
        let that = this;
        var res2 = $.Deferred();
        if (itemJson.children) {
            let postItFolder: XCPostAddFolder = {
                label: itemJson.title,
                parent: parentId,
                reason: comment,
            };
            for (var par in itemJson) {
                if (!itemJson.hasOwnProperty(par)) continue;
                if (postItFolder.hasOwnProperty(par)) continue;
                if (par === "type") continue;
                if (par === "children") continue;
                if (par === "title") continue;
                if (par === "labels" || par === "linksUp" || par === "linksDown") {
                    continue;
                }
                let c: string = par;
                (<IGenericMap>postItFolder)["fx" + par] = (<IGenericMap>itemJson)[par];
            }
            if (dontFailOnCleanup) {
                postItFolder.failOnCleanup = 0;
            }
            this.restConnection.postProject("folder", postItFolder).done(function (result) {
                //TODO GET not serial BUT complete number like REQ-4 from rest
                itemJson.id = "F-" + itemJson.type + "-" + (result as XRPostProject_AddFolder_AddItemAck).serial;
                res2.resolve(that.dbCache.insertItem(itemJson, parentId));
            });
        } else {
            let postItItem: XCPostAddItem = {
                title: itemJson.title,
                folder: parentId,
                reason: comment,
                linksUp: "",
                linksDown: "",
            };
            for (var par in itemJson) {
                if (!itemJson.hasOwnProperty(par)) continue;
                if (postItItem.hasOwnProperty(par)) continue;
                if (par === "type") continue;
                if (par === "labels") {
                    postItItem.labels = itemJson.labels;
                    continue;
                }
                if (par === "linksUp") {
                    postItItem.linksUp = itemJson.linksUp;
                    continue;
                }
                if (par === "linksDown") {
                    postItItem.linksDown = itemJson.linksDown;
                    continue;
                }
                (<IGenericMap>postItItem)["fx" + par] = (<IGenericMap>itemJson)[par];
            }
            if (dontFailOnCleanup) {
                postItItem.failOnCleanup = 0;
            }

            this.restConnection.postProject("item", postItItem).done(function (result) {
                itemJson.id = itemJson.type + "-" + (result as XRPostProject_AddItem_AddItemAck).serial;
                var idbparent = that.dbCache.insertItem(itemJson, parentId);
                res2.resolve(idbparent);
            });
        }
        return <any>res2;
    }

    signItemAsync(
        itemId: string,
        password: string,
        comment: string,
        meaning?: string,
    ): JQueryDeferred<XRPostProject_SignItem_SignItemAck> {
        var res = $.Deferred();
        let signature: XCPostSignItem = {
            password: password,
        };
        if (meaning) {
            signature.acceptComments = meaning;
        }
        this.restConnection
            .postProject("sign/" + itemId, signature)
            .done(function (result) {
                res.resolve(result);
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                res.reject(error);
            });
        return <any>res;
    }

    checkPassword(password: string): JQueryDeferred<IRestResult> {
        return this.restConnection.postServer("/user/" + matrixSession.getUser() + "/check", { password: password });
    }

    convertDocAsync(
        fileNo: number,
        comment: string,
        targetDocumentFolder?: string,
        useOriginal?: boolean,
    ): JQueryDeferred<string> {
        let that = this;

        var res = $.Deferred();

        let convert: XCPostConvertWord = {
            fileNo: fileNo,
            reason: comment,
        };
        if (useOriginal) {
            convert.useAsField = 1;
        }
        if (targetDocumentFolder) {
            convert.targetDocumentFolder = targetDocumentFolder;
        }

        this.restConnection
            .postProject("wordconvert", convert)
            .done(function (result) {
                res.resolve(result);
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                res.reject(error);
            });

        return <any>res;
    }

    restoreItemAsync(
        itemId: string,
        title: string,
        version: number,
        comment: string,
    ): JQueryDeferred<IRestoreItemResult> {
        let that = this;
        var res = $.Deferred();
        var postIt: XCPostRestoreItem = {
            reason: comment,
        };

        var arg = itemId;
        if (version) {
            postIt.at = version;
        }

        this.restConnection.postProject("item/" + arg, postIt).done(function (result) {
            that.dbCache.deleteItem(itemId);
            var itemJson: IItem = { title: title, id: itemId, type: ml.Item.parseRef(itemId).type };
            that.dbCache.insertItem(itemJson, (result as XRPostProject_RestoreItem_UndeleteAnswer).newParent);
            res.resolve({ itemId: itemId, version: version, response: result });
        });

        return <any>res;
    }

    updateItemAsync(
        itemJson: IItemPut,
        comment: string,
        auditAction: string,
        requireVersion?: number,
    ): JQueryDeferred<IItemGet> {
        let that = this;

        var res = $.Deferred();
        var putIt: XCPutEditItem = { reason: comment };
        if (typeof itemJson.title !== "undefined") {
            putIt.title = itemJson.title;
        }
        if (auditAction) {
            putIt["auditAction"] = auditAction;
        }
        if (requireVersion) {
            putIt["currentVersion"] = requireVersion;
        }
        for (var par in itemJson) {
            if (!itemJson.hasOwnProperty(par)) continue;
            if (putIt.hasOwnProperty(par)) continue;
            if (par === "type") continue;
            if (par === "category") continue;
            if (par === "links") continue;
            if (par === "title") continue;
            if (par === "id") continue;

            if (isNaN(<any>par)) {
                // it's attribute other than a field
                (<IGenericMap>putIt)[par] = (<IGenericMap>itemJson)[par];
            } else {
                // it's a number so we assume it's a field
                (<IGenericMap>putIt)["fx" + par] = (<IGenericMap>itemJson)[par];
            }
        }
        this.restConnection
            .putProject("item/" + itemJson.id, putIt, itemJson.id)
            // @ts-ignore
            .done(function (response) {
                const result = response as XRPutProject_EditItem_TrimItem | string;

                if (result !== "putEditItem | You need to set a title argument") {
                    var item = <IItemGet>that.parseItemJSON(itemJson.id, <XRPutProject_EditItem_TrimItem>result);
                    if (that.dbCache.isFolder(itemJson.id)) {
                        item["children"] = [];
                    }

                    that.dbCache.updateItem(<IItem>itemJson);

                    res.resolve(item);
                } else {
                    ml.Logger.log(
                        "error",
                        "PUT of " + itemJson.id + " FAILED. Error was: putEditItem | You need to set a title argument",
                    );
                }
            })
            .fail(function () {
                // MATRIX-3815
                res.reject("saving failed");
            });

        return res;
    }

    moveItemsAsync(itemIds: string, newFolder: string, newPosition: number, comment: string): JQueryDeferred<{}> {
        let that = this;
        var res = $.Deferred();

        if (itemIds.indexOf(",") == -1) {
            var putIt = {
                reason: comment,
                newFolder: newFolder,
                newPosition: newPosition + 1,
            };
            this.restConnection
                .putProject("item/" + itemIds, putIt)
                .done(function () {
                    that.dbCache.moveItem(itemIds, newFolder, newPosition);
                    res.resolve();
                })
                .fail(function () {
                    res.reject();
                });
        } else {
            let moveIt = {
                items: itemIds,
                reason: comment,
            };
            this.restConnection
                .postProject("movein/" + newFolder, moveIt)
                .done(function () {
                    res.resolve();
                })
                .fail(function () {
                    res.reject();
                });
        }

        return res;
    }

    updateCache(newItem: IUpdateCache): void {
        this.dbCache.insertItem(newItem.item, newItem.parent);
    }

    // replaces the target, a child inside the target or creates a new child with source
    copyFrom(target: string, source: IDB) {
        return this.dbCache.copyFrom(target, source);
    }

    deleteItemAsync(itemId: string, comment: string, force: boolean): JQueryDeferred<string> {
        let that = this;

        var res = $.Deferred();
        var par: XCDeleteItem = {
            reason: comment,
            confirm: "no",
        };
        if (force) {
            par.confirm = "yes";
        }
        this.restConnection
            .deleteProjectAsync("item/" + itemId, par)
            .done(function () {
                var parentItem = that.dbCache.getParentId(itemId);
                that.dbCache.deleteItem(itemId);
                res.resolve(parentItem);
            })
            .fail(function () {
                res.reject();
            });

        return <any>res;
    }

    uploadFileProjectAsync(file: IFileParam, progress: (p: IFileUploadProgress) => void): JQueryDeferred<{}> {
        return this.restConnection.uploadFileProjectAsync(file, progress);
    }

    fetchFileAsync(url: string, progress: (p: IFileUploadProgress) => void): JQueryDeferred<{}> {
        return this.restConnection.fetchFileAsync(url, progress);
    }

    addDownLinkAsync(fromId: string, toId: string, comment: string): JQueryDeferred<{}> {
        var res = $.Deferred();
        this.restConnection
            .postProject("itemlink/" + fromId + "/" + toId, { reason: comment })
            .done(function () {
                res.resolve();
            })
            .fail(function (jqxhr, textStatus, error) {
                res.reject(jqxhr, textStatus, error);
            });
        return res;
    }

    removeDownLinkAsync(fromId: string, toId: string, comment: string): JQueryDeferred<{}> {
        var res = $.Deferred();
        this.restConnection
            .deleteProjectAsync("itemlink/" + fromId + "/" + toId, { reason: comment })
            .done(function () {
                res.resolve();
            })
            .fail(function () {
                res.reject();
            });
        return res;
    }

    private retrieveConfigAsync(): JQueryDeferred<XRProjectInfo> {
        var res = $.Deferred<XRProjectInfo>();
        this.restConnection.getProject("").done(function (result) {
            ml.UI.DateTime.initDateTimeSettings();
            globalMatrix.ItemConfig.init(result as XRGetProject_ProjectInfo_ProjectInfo);
            res.resolve(result as XRGetProject_ProjectInfo_ProjectInfo);
        });
        return res;
    }

    private showRestError(jqxhr: IJcxhr, textStatus: string, error: string): void {
        ml.UI.showError("Server connection error!", ml.UI.getDisplayError(jqxhr, textStatus, error));
    }

    private parseItemJSON(itemId: string, result: XRPutProject_EditItem_TrimItem): IItem {
        var item: IItemGet = {
            id: itemId,
            title: result.title,
            type: this.dbCache.getType(itemId),
            downLinks: [],
            upLinks: [],
            modDate: result.modDate,
            isUnselected: result.isUnselected,
            labels: result.labels ? result.labels : [],
            maxVersion: result.maxVersion,
        };

        if (result.docHasPackage) {
            item.docHasPackage = result.docHasPackage;
        }

        if (!result.maxVersion) {
            item.isDeleted = true;
        }

        if (result.fieldValList) {
            for (var fieldVal in result.fieldValList.fieldVal) {
                (<IGenericMap>item)[result.fieldValList.fieldVal[fieldVal].id.toString()] =
                    result.fieldValList.fieldVal[fieldVal].value;
            }
        }

        for (var idx = 0; result.downLinkList && idx < result.downLinkList.length; idx++) {
            var tol = result.downLinkList[idx].itemRef;

            item.downLinks.push({
                to: ml.Item.parseRef(tol).id,
                title: result.downLinkList[idx].title,
                modDate: result.downLinkList[idx].modDate,
            });
        }

        for (var idx = 0; result.upLinkList && idx < result.upLinkList.length; idx++) {
            var tol = result.upLinkList[idx].itemRef;

            item.upLinks.push({
                to: ml.Item.parseRef(tol).id,
                title: result.upLinkList[idx].title,
                modDate: result.upLinkList[idx].modDate,
            });
        }
        // copy original up list
        item.upLinkList = result.upLinkList;

        if (result.availableFormats) {
            item["availableFormats"] = result.availableFormats;
        }
        if (result.selectSubTree) {
            item["selectSubTree"] = result.selectSubTree;
        }
        if (result.requireSubTree) {
            item["requireSubTree"] = result.requireSubTree;
        }

        if (result.crossLinks) {
            item.crossLinks = result.crossLinks;
        }

        var hoi: IItemHistory[] = [];
        for (var idx = 0; result.itemHistoryList && idx < result.itemHistoryList.itemHistory.length; idx++) {
            var theAction = result.itemHistoryList.itemHistory[idx];
            var historyInfo: IItemHistory = {
                id: itemId,
                user: theAction.createdByUserLogin,
                action: theAction.auditAction,
                version: theAction.version,
                date: theAction.createdAt,
                dateUserFormat: theAction.createdAtUserFormat,
                title: theAction.title,
                comment: theAction.reason,
            };
            // now use the information that undeleted items have been deleted just before
            if (theAction.auditAction === "undelete") {
                if (result.itemHistoryList.itemHistory.length > idx + 1) {
                    var theDelete = result.itemHistoryList.itemHistory[idx + 1];
                    if (theDelete.auditAction !== "delete") {
                        historyInfo["deletedate"] = theDelete.deletedAtUserFormat;
                    }
                }
            }
            hoi.push(historyInfo);
        }
        item["history"] = hoi;
        return <IItem>item;
    }
}
