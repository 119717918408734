/// <reference types="matrixrequirements-type-declarations" />
import { ControlState, IStringMap } from "../../../globals";
import { ITableControlBaseParams } from "../../businesslogic";
import { IBaseControlOptions } from "./BaseControl";

export type { ITableControlOptions, ITableRow, ITableControlParams };

interface ITableControlParams extends ITableControlBaseParams {
    readonly?: boolean;
    onDblClick?: Function; // optional function to be called if cell is double clicked
    canBeModified?: boolean; // if true, rows can be added and moved,
    create?: boolean; // if canBeModified also can add lines (with little icon)
    showLineNumbers?: boolean; // whether to show line numbers in front of each row
    maxRows?: number; // if set to a value other than -1, the maximum number of rows which can be created
    fixRows?: number; // if set to a value other than -1, the table has the exact number of rows, no rows can be added or removed (maxRows is ignored)
    readonly_allowfocus?: boolean; // whether not editable cells can get focus (e.g. with tab),
    passFailEditorConfig?: any;
    readOnlyFields?: string[]; // limit editing to certain columns
    limitEditRow?: string; // limit editing to "first", "last" or "none" rows
    manualTableHeights?: boolean; // if set to true, the table heights are not automatically adjusted after rendering the table
    doNotRememberWidth?: boolean; // set to true not to store column width
    onColumnsResized?: Function; // called if column has been resized
    cellAskEdit?: string; // indicate that cell can be edited by showing string
    disableColumnReorder?: boolean; // set to true to disable reorder of columns
    inlineHelp?: string;
    autoUpdate?: string; // BETA: run a registered script after cell was modified
}

interface ITableControlOptions extends IBaseControlOptions {
    controlState?: ControlState;
    dummyData?: any;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: ITableControlParams;
}

interface ITableRow {
    idx: number;
    id: string;
    name: string;
    [key: string]: any;
}
