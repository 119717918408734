import { IDataStorage } from "../globals";

export { ServerStorageAdmin };

class ServerStorageAdmin implements IDataStorage {
    setItem(itemKey: string, itemVal: string): void {}

    getItem(itemKey: string): string {
        return "";
    }

    getItemDefault(itemKey: string, defaultValue: string): string {
        return "";
    }
}
