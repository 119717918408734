import { IDocFieldHandler } from "./IDocFieldHandler";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { IReference } from "../../../../globals";
import { ItemSelectionAbstractDocFieldHandler } from "./ItemSelectionAbstractDocFieldHandler";

export interface IItemTableOptions extends IDHFSectionOptions {
    includeInToc: boolean;
    sortedList: boolean;
    includeFolders: boolean;
    columns: string;
    search?: string;
}

export class ItemTableDocFieldHandler extends ItemSelectionAbstractDocFieldHandler implements IDocFieldHandler {
    getDefaultConfig(): IItemTableOptions {
        return {
            includeInToc: false,
            sortedList: false,
            includeFolders: false,
            columns: "item,labels,down",
        };
    }

    getXmlValue(): string {
        let reflist = <IReference[]>this.getItems();

        for (let idx = 0; idx < reflist.length; idx++) {
            (<IItemTableOptions>(<any>reflist[idx]))["includeInToc"] = this.dhfFieldConfig.includeInToc;
            (<IItemTableOptions>(<any>reflist[idx]))["includeFolders"] = this.dhfFieldConfig.includeFolders;
            (<IItemTableOptions>(<any>reflist[idx]))["sortedList"] = this.dhfFieldConfig.sortedList;
            (<IItemTableOptions>(<any>reflist[idx]))["columns"] = this.dhfFieldConfig.columns;
        }

        return JSON.stringify(reflist);
    }
}
