export type { IRefLinkOptions };
export { refLinkStyle, refLinkTooltip };

enum refLinkStyle {
    edit = 1, // user can change title
    link, // click opens another window
    show, // read only, can call callback if clicked
    select, // renders like the link, but select the item in the tree (changes selection)
    selectTree, // like link, but selects in tree unless ctrl-was clicked
}

enum refLinkTooltip {
    none = 1, // no tooltip
    html, // full html tooltip
}

interface IRefLinkOptions {
    style?: refLinkStyle;
    tooltip?: refLinkTooltip;
    callback?: Function; // function to be called (if syle is refLinkStyle.show) or after edit
    id?: string; // id of object
    title?: string; //  title of object
    folder?: boolean; //  (true if no id should be rendred)
    showVersion?: boolean; //
    validate?: boolean; //   default is true. valdiation requires non-empty string,
    isHidden?: boolean; //    default false, whether the item title should indicate that object is not in project filter
    placeholder?: string;
    hideTitle?: boolean; // option to hide the title
    crossProject?: string; // if the item id from another project
    css?: string; // possibility to add style information to class
    includeSourceLink?: string; // if set indicate that the reflink is an include, pointing to the source
}
