import { IDHFControlDefinition } from "../../../../common/businesslogic";
import { ml } from "../../../../common/matrixlib";
import { IBaseControlOptions } from "../../../../common/UI/Controls/BaseControl";
import { ISingleDefaultControllerConfig, SingleSelectBase } from "../../../../SingleSelectBase";
import { ILinkListOptions } from "../../../../common/businesslogic/FieldHandlers/Document/LinkListDocFieldHandler";
export { LinkList };

class LinkList extends SingleSelectBase<ILinkListOptions> {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        ctrl.control.itemSelection(
            ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    prefix: "Include references of these items",
                    buttonName: "Select Items",
                    showOnly: [],
                    showNot: ["REPORT"],
                },
            }),
        );
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        var controllerConfig = this.getConfig(ctrl);

        var external_links = $(
            '<select class="docOptionSelect p0 form-control" style="">' +
                '<option value="0">Show items without external links</option>' +
                '<option value="1">Show external links</option>' +
                '<option value="2">Show only done external links</option>' +
                '<option value="3">Show only external links which need to be done</option>' +
                "</select>",
        );

        let options =
            '<div class="checkbox" ><label><input type="checkbox" class="includeInToc" ' +
            (controllerConfig.includeInToc ? "checked" : "") +
            "> Add a reference to included items to table of content</label></div>";
        custom.append($(options));

        custom.append(external_links);

        $(custom.find(".p0")[0]).val(controllerConfig.showExternal);

        this.addSpecificSettings(controllerConfig, custom);
    }
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        var controllerConfig = this.getConfig(ctrl);

        var showExternal = $(custom.find(".p0")[0]).val();

        var changed = showExternal !== controllerConfig.showExternal;
        controllerConfig.showExternal = showExternal;

        changed = changed || controllerConfig.includeInToc !== $(custom.find(".includeInToc")[0]).prop("checked");
        controllerConfig.includeInToc = $(custom.find(".includeInToc")[0]).prop("checked");

        let specificChanged = this.setSpecificSettings(controllerConfig, custom);

        this.fieldHandler.setDHFConfig(controllerConfig);

        return changed || specificChanged;
    }

    protected config: ISingleDefaultControllerConfig;
}
