/**
 * (1) to use create a new category for the hazards: the category can have normal text / drop down and also a risk field
 * (2) in the category create a category setting "hazard"
 * (3) copy the risk configuration from the risk field in the risk category into the category setting "hazard"
 * (4) in that setting add "targetCategory":"<risk category>",
 * (5) create the drop down with hazards
 * find the first factor in the risk (note if it's not the first specify which using setting "factorIndex":number) which needs to be a dropdown, remove all but the first option and replace it with something like this
 *
 * "factors": [
      {
        "type": "hazard",
        "label": "Hazard",
        "inputType": "select",
        "options": [
          {
            "value": "",
            "label": "$id$ $title$",
            "changes": [
              { "changesFactor": "sequenceOfEvents", "value": "$risk.hazard$" },
              { "changesFactor": "hazardousSituation", "value": "HAZARDOUS SITUATION" },
              { "changesWeight": "p2", "value": "$risk.p1$" },
              { "changesWeight": "severity", "value": "$SEVERITY OF HARM$" }
            ]
          }
        ],.....

 *  the macros are $id$ to put the id of the HAZARD item, $title$ for the title
 *  $field$ for a normal field value
 *  $field.weight$ or $field.factor$ for values from a risk control
 *
 *
 * (6) optionally add a label which needs to be set on the hazard item to include the hazard "requireLabel":string
 * (7) optionally add a label which needs to be UNset on the hazard item to include the hazard "ignoreLabel":string
 *
 * (8) optionally: you can have multiple target categories, in that case just put the above configuration in an array called multi
 *  { multi:[ {targetCategory:"XXX", riskConfig:... }, {targetCategory:"YYY", riskConfig:... }]}
 */

import { ICategoryConfig, XRFieldTypeAnnotated } from "../../../common/businesslogic/index";
import { IPlugin, IProjectPageParam, IPluginPanelOptions, plugins } from "../../../common/businesslogic/index";
import { ISearchResult, ISearchResultField } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { IRiskParameter, IRiskValue } from "../../../common/UI/Controls/riskCtrl2";
import { IItem, globalMatrix, ControlState, app, restConnection, matrixSession } from "../../../globals";

import { IRiskConfig } from "../../../ProjectSettings";
import { XRGetProject_StartupInfo_ListProjectAndSettings } from "../../../RestResult";
import { FieldDescriptions } from "../../../common/businesslogic/FieldDescriptions";

export { initialize };

// that's for pre 2.3.6 / simple configurations, which can be just one publication target
interface IHazardMulti extends IHazard {
    multi: IHazard[];
}

interface IHazard {
    targetCategory: string; // the risk category
    factorIndex?: number; // default 0: first factor's option are the rules
    requireLabel?: string; // optionally add a label which needs to be set on the hazard item to include the hazard
    ignoreLabel?: string; // optionally add a label which needs to be UNset on the hazard item to include the hazard
    requireCheckbox?: string; // optionally add Checkbox field name which needs to be ticketd on the hazard item to include the hazard
    ignoreCheckbox?: string; // optionally add Checkbox field name which needs to be UNset on the hazard item to include the hazard

    riskConfig: IRiskConfig; // the tweaked risk config
}

class Hazards implements IPlugin {
    private item: IItem;
    private jui: JQuery;
    private hazConfig: IRiskConfig;
    private HAZ_CATEGORY = "HAZ";
    private hazCatConfig: ICategoryConfig;
    //
    // ****************************************
    // standard plugin interface
    // ****************************************

    public isDefault = true;
    constructor() {}
    initItem(_item: IItem, _jui: JQuery) {
        this.item = _item;
        this.jui = _jui;
    }

    initServerSettings(serverSettings: XRGetProject_StartupInfo_ListProjectAndSettings) {}

    updateMenu(ul: JQuery, hook: number) {
        return;
    }

    supportsControl(fieldType: string): boolean {
        return false;
    }

    createControl(ctrl: JQuery, options: IBaseControlOptions) {}

    initProject() {
        // this is for 2.2 and earlier only, config change after
        this.hazConfig = globalMatrix.ItemConfig.getSettingJSON("haz_config");
        if (this.hazConfig && this.hazConfig.hazard_category) {
            this.HAZ_CATEGORY = this.hazConfig.hazard_category;
        }
        this.hazCatConfig = globalMatrix.ItemConfig.getItemConfiguration(this.HAZ_CATEGORY);
    }

    // project pages show in the top in Projects, Reports and Documents

    getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            let that = this;
            let pages: IProjectPageParam[] = [];

            if (!this.hazConfig && this.getCategoriesWithConfigs().length == 0) {
                // no config no pages
                resolve([]);
                return;
            }
            pages.push({
                id: "HAZARDS",
                title: "Publish Hazards*",
                usesFilters: true,
                order: 500,
                render: (options: IPluginPanelOptions) => that.renderProjectPage(options),
            });

            resolve(pages);
        });
    }

    // ****************************************
    // Misc functions called by task control
    // to create / refresh UI components
    // ****************************************

    private getCategoriesWithConfigs() {
        return globalMatrix.ItemConfig.getCategories(true).filter(
            (cat) => !!globalMatrix.ItemConfig.getCategorySetting(cat, "hazard"),
        );
    }
    private renderProjectPage(options: IPluginPanelOptions) {
        let that = this;

        if (options.controlState === ControlState.Print) {
            return;
        }

        options.control.html("");

        for (let category of this.getCategoriesWithConfigs()) {
            let catSetting = <IHazardMulti>globalMatrix.ItemConfig.getCategorySetting(category, "hazard");
            // make configs an array of one or more targets

            let configs = catSetting.multi ? catSetting.multi : [catSetting];
            for (let config of configs) {
                let conf = <IHazard>ml.JSON.clone(config);
                let targetCategory = conf.targetCategory;
                if (!that.checkConfig(category, conf)) {
                    $("<p>").appendTo(options.control).html("Invalid configuration - see console log!");
                } else {
                    $(
                        `<button style='margin:12px' class='btn btn-success hidden-print'>Update risks in ${targetCategory} based on hazards in ${category}</button>`,
                    )
                        .appendTo($("<p>").appendTo(options.control))
                        .click(function () {
                            app.searchAsync("mrql:category=" + category, null, true, "*", null, true).done(
                                function (hazards) {
                                    that.cat2CatConfig(category, hazards, conf);
                                },
                            );
                        });
                }
            }
        }
        // add 2.2 and earlier
        if (this.hazConfig) {
            options.control.html("hazConfig does not exist!");

            options.control.append(ml.UI.getSpinningWait("retrieving hazards"));

            var transferable: string[] = [];
            $.each(that.hazCatConfig.fieldList, function (fieldIdx, field) {
                if (
                    field.fieldType === FieldDescriptions.Field_textline ||
                    field.fieldType === FieldDescriptions.Field_dropdown ||
                    field.fieldType === FieldDescriptions.Field_text
                ) {
                    transferable.push("" + field.id);
                }
            });
            app.searchAsync("mrql:category=" + this.HAZ_CATEGORY, null, true, transferable.join(","))
                .done(function (results) {
                    options.control.html("");
                    if (results.length > 0) {
                        $("<div>").appendTo(options.control).html("$0 for hazard id");
                        $("<div>").appendTo(options.control).html("$1 for hazard title");
                        let dix = 2;
                        $.each(results[0].fieldVal, function (ridx, r) {
                            $.each(that.hazCatConfig.fieldList, function (fieldIdx, field) {
                                if (field.id == r.id) {
                                    let temp = $("<div>").appendTo(options.control);
                                    temp.html("$" + dix + " for field " + field.label);
                                    dix++;
                                }
                            });
                        });

                        $("<button title class='btn btn-success hidden-print'>Update Risk Configuration</button>")
                            .appendTo(options.control)
                            .click(function () {
                                that.createRiskConfig(results);
                            });
                    } else {
                        ml.UI.showError("No hazards defined", "Hazard category " + that.HAZ_CATEGORY);
                    }
                })
                .fail(function () {
                    ml.UI.showError("Error retrieving Hazards", "");
                });
        }
    }

    private createRiskConfig(results: ISearchResult[]) {
        var rc = ml.JSON.clone(this.hazConfig);
        var def = JSON.stringify(rc.factors[0].options[0]);
        rc.factors[0].options = [];
        $.each(results, function (idx, result) {
            // create new hazard based on template
            var haz = def;

            let data: ISearchResultField[] = result.fieldVal ? ml.JSON.clone(result.fieldVal) : [];

            // create artificial fields for id and title
            data.splice(0, 0, { id: 1, value: result.title });
            data.splice(0, 0, { id: 0, value: result.itemId });
            // for all fields replace the placeholders

            var firstHaz = true;
            $.each(data, function (jdx, fac) {
                while (haz.indexOf("$" + jdx) != -1) {
                    // remove line breaks and other bad stuff. But put's quotes around the value ...
                    let str = JSON.stringify(fac.value);
                    if (!jdx && firstHaz) {
                        // hazard id
                        str = str.replace("-", "_");
                        firstHaz = false;
                    }
                    haz = haz.replace("$" + jdx + "", str.length > 0 ? str.substr(1, str.length - 2) : "");
                }
            });

            rc.factors[0].options.push(JSON.parse(haz));
        });
        app.setSettingJSON("risk_config", rc).done(function () {
            ml.UI.showAck(-1, "Risk configuration changed. Make sure all the risks are reviewed and updated!");
        });
    }

    /** verify that cofnig matches project configuration and that it makes +- sense in general */
    private checkConfig(category: string, conf: IHazard) {
        if (!category || !conf) {
            // that should never happen
            console.log(`Hazard.ts configuration error: category or configuration does not exist`);
            return false;
        }

        if (conf.requireCheckbox && !globalMatrix.ItemConfig.getFieldByName(category, conf.requireCheckbox)) {
            console.log(
                `Hazard.ts configuration error: category does not have a field "requireCheckbox" named ${conf.requireCheckbox}`,
            );
            return false;
        }
        if (conf.ignoreCheckbox && !globalMatrix.ItemConfig.getFieldByName(category, conf.ignoreCheckbox)) {
            console.log(
                `Hazard.ts configuration error: category does not have a field "ignoreCheckbox" named ${conf.ignoreCheckbox}`,
            );
            return false;
        }

        let factor = conf.factorIndex ? conf.factorIndex : 0; // default the first one
        if (!conf.riskConfig.factors || conf.riskConfig.factors.length < factor) {
            console.log(
                `Hazard.ts configuration error category: Template has none/not enough factors in the risk field.`,
            );
            return false;
        }

        if (conf.riskConfig.factors[factor].inputType != "select") {
            console.log(
                `Hazard.ts configuration error category: The specified risk factor with the dropdown options needs to be of type select.`,
            );
            return false;
        }

        if (!conf.riskConfig.factors[factor].options || conf.riskConfig.factors[factor].options.length != 1) {
            console.log(`Hazard.ts configuration error category: Template needs exactly one option as template.`);
            return false;
        }

        // all good
        return true;
    }
    private cat2CatConfig(category: string, hazards: ISearchResult[], conf: IHazard) {
        let factor = conf.factorIndex ? conf.factorIndex : 0; // default the first one

        // prepare the risk config, extract the template and reset options
        let optionTemplate = JSON.stringify(conf.riskConfig.factors[factor].options[0]);
        conf.riskConfig.factors[factor].options = [];

        let includedHazards = hazards.filter((hazard) => {
            if (conf.requireLabel) {
                if (hazard.labels.indexOf(conf.requireLabel) == -1) return false;
            }
            if (conf.ignoreLabel) {
                if (hazard.labels.indexOf(conf.ignoreLabel) != -1) return false;
            }
            if (conf.requireCheckbox) {
                let fv = hazard.fieldVal.filter(
                    (f) => f.id == globalMatrix.ItemConfig.getFieldByName(category, conf.requireCheckbox)?.id,
                );
                return fv.length && fv[0].value == "true";
            }
            if (conf.ignoreCheckbox) {
                let fv = hazard.fieldVal.filter(
                    (f) => f.id == globalMatrix.ItemConfig.getFieldByName(category, conf.ignoreCheckbox)?.id,
                );
                return fv.length == 0 || fv[0].value == "false";
            }
            return true;
        });
        for (let hazard of includedHazards) {
            // create a new option based on template
            let newOption = optionTemplate;

            let data: ISearchResultField[] = hazard.fieldVal ? ml.JSON.clone(hazard.fieldVal) : [];

            // create artificial fields for id and title
            newOption = newOption.replace(/\$id\$/g, hazard.itemId).replace(/\$title\$/g, hazard.title);

            let macroStart = newOption.indexOf("$");
            while (macroStart != -1) {
                let macroEnd = newOption.indexOf("$", macroStart + 1);
                if (macroEnd == -1) {
                    ml.UI.showError(
                        `Bad configuration`,
                        `macros for field values need to be $field[.factor[.weight]]$`,
                    );
                    return;
                }
                let macro = newOption.substring(macroStart + 1, macroEnd);
                let newValue = this.getFieldValue(hazard, category, macro);
                if (newValue === null) {
                    return;
                }
                newOption = newOption.replace(`$${macro}$`, "" + newValue);
                macroStart = newOption.indexOf("$");
            }
            // set the drop down option id and add it to the dropdown
            let parsed = JSON.parse(newOption);
            parsed.value = hazard.itemId.replace("-", "_"); // not sure why I need to do that
            conf.riskConfig.factors[factor].options.push(parsed);
        }

        // replace the configuration of the target's risk field

        // get the risk field in the target category
        let riskFields = globalMatrix.ItemConfig.getFieldsOfType("risk2", conf.targetCategory);
        if (!riskFields || riskFields.length == 0) {
            ml.UI.showError(`Bad configuration`, `Target category ${conf.targetCategory} has no risk field.`);
            return;
        }
        let riskField = riskFields[0];
        let newFieldParams = <IRiskParameter>(
            globalMatrix.ItemConfig.getFieldById(conf.targetCategory, riskField.field.id).parameterJson
        );
        newFieldParams.riskConfig = conf.riskConfig;
        let newField = {
            field: riskField.field.id,
            label: riskField.field.label,
            fieldParam: JSON.stringify(newFieldParams),
            reason: "scripted risk configuration update",
        };
        restConnection
            .putServer(matrixSession.getProject() + "/field", newField)
            .done(() => {
                ml.UI.showSuccess("Configuration updated - please review risks!");
                // reload after one second
                window.setTimeout(() => {
                    window.location.href = `${globalMatrix.matrixBaseUrl}/${matrixSession.getProject()}/F-${
                        conf.targetCategory
                    }-1`;
                }, 1000);
            })
            .fail(() => {
                // mhhh
            });
    }

    private getFieldValue(hazard: ISearchResult, category: string, macro: string) {
        let macroParts = macro.split(".");
        let field = globalMatrix.ItemConfig.getFieldByName(category, macroParts[0]);

        if (!field) {
            ml.UI.showError(`Bad configuration`, `Field ${macroParts[0]} does not exist in category ${category}.`);
            return null;
        }
        if (field.fieldType == FieldDescriptions.Field_risk2 && macroParts.length == 1) {
            ml.UI.showError(
                `Bad configuration`,
                `Field ${macroParts[0]} is a risk. You need to add ".weight" or ".factor" to specify the factor/weight to take`,
            );
            return null;
        }
        if (!hazard.fieldVal) {
            return "";
        }
        for (let fieldVal of hazard.fieldVal) {
            if (fieldVal.id == field.id) {
                if (field.fieldType == FieldDescriptions.Field_risk2) {
                    let riskVal = <IRiskValue>JSON.parse(fieldVal.value ? fieldVal.value : "{factors:[]}");
                    for (let factor of riskVal.factors) {
                        if (factor.type == macroParts[1]) {
                            return factor.value;
                        }
                        for (let weight of factor.weights) {
                            if (weight.type == macroParts[1]) {
                                return weight.value;
                            }
                        }
                    }
                    return "";
                }
                let tempJson = { a: fieldVal.value };
                let escapedJSON = JSON.stringify(tempJson);
                return escapedJSON.substring(6, escapedJSON.length - 2); // remove the {"a":" and "}"
            }
        }

        return "";
    }
}

// register the engine as plugin
function initialize() {
    let mHazards = new Hazards();
    plugins.register(mHazards);
}
