import { app, globalMatrix } from "../../../globals";
import { ml } from "../../matrixlib";
import { HTMLCleaner } from "../../matrixlib/index";
import { IRefLinkOptions, refLinkStyle, refLinkTooltip } from "./RefLinkDefines";

$.fn.refLink = function (this: JQuery, options: IRefLinkOptions) {
    let component = new RefLink(this, options);
    this.destroy = () => component.destroy();
    this.getValue = () => component.getValue();
    return this;
};

class RefLink {
    private settings: IRefLinkOptions;
    private newTitle: string;
    private lineEditor: any;
    private ttpart: JQuery;

    constructor(control: JQuery, options: IRefLinkOptions) {
        let that = this;

        this.settings = options;
        // defaults
        this.settings.style = typeof this.settings.style === "undefined" ? refLinkStyle.show : this.settings.style;
        this.settings.tooltip =
            typeof this.settings.tooltip === "undefined" ? refLinkTooltip.none : this.settings.tooltip;

        this.settings.validate = typeof this.settings.validate === "undefined" ? true : this.settings.validate;

        this.newTitle = this.settings.title;
        var title = this.settings.title;

        var isFolderLink = typeof this.settings.folder !== "undefined" && this.settings.folder;
        var key = this.settings.id; // key without revision
        if (localStorage.getItem("matixShowVersionInId") === "true") {
            var versionPos = key.indexOf("-v");
            if (versionPos !== -1) {
                key = key.substring(0, versionPos);
            }
        }
        let extraClass = "";
        if (key.startsWith("F-") && key.endsWith("-1")) extraClass = "key-top";

        var linkId =
            "<span class='" +
            extraClass +
            " " +
            (this.settings.isHidden ? "refId greyRefIdHyper" : "refId refIdHyper") +
            "'>" +
            (isFolderLink ? "" : key) +
            "</span>";
        let style = this.settings.css ? " style='" + this.settings.css + "'" : "";

        var linkTitle =
            "<span class='key-" +
            key +
            " " +
            (this.settings.isHidden ? "refTitle greyTitle" : "refTitle") +
            "'" +
            style +
            ">" +
            title +
            "</span> ";
        if (key && key[0] === "-") {
            // special node, e.g. Deleted Items
            linkId = "";
            linkTitle = "<span class='refTitleSpecial'>" + title + "</span> ";
        }
        var theTitle = $(linkTitle);
        if (this.settings.hideTitle) {
            theTitle.hide();
        }
        if (this.settings.includeSourceLink) {
            theTitle.append(
                `<span class="fal fa-external-link-alt" style="font-size: small;margin-left: 12px;" title="${this.settings.includeSourceLink}">`,
            );
        }
        var ttpart = $("");
        if (this.settings.style === refLinkStyle.link) {
            ttpart = $("<a>" + linkId + "</a>")
                .attr("href", app.createItemUrl(this.settings.id, this.settings.crossProject))
                .attr("target", "_blank");
            control.html("").append(ttpart).append(theTitle);
        }
        if (this.settings.style === refLinkStyle.selectTree) {
            ttpart = $("<a>" + linkId + "</a>").css("cursor", "pointer");
            ttpart.off("click").click(function (evt): any {
                if (evt.ctrlKey || evt.metaKey) {
                    var win = window.open(app.createItemUrl(that.settings.id), "_blank");
                    if (win) {
                        //Browser has allowed it to be opened
                        win.focus();
                    } else {
                        //Browser has blocked it
                        alert("Please allow popups for this site");
                    }
                    if (evt.preventDefault) evt.preventDefault();
                    if (evt.stopPropagation) evt.stopPropagation();
                } else {
                    return app.treeSelectionChangeAsync(that.settings.id);
                }
            });
            control.html("").append(ttpart).append(theTitle);
        } else if (this.settings.style === refLinkStyle.edit) {
            this.lineEditor = <any>theTitle.editable({
                mode: "inline",
                onblur: "submit",
                placeholder: that.settings.placeholder,
                clear: false,
                showbuttons: false,
                validate: function (value: string): any {
                    if ($.trim(value) == "" && that.settings.validate && $(this).css("display") != "none") {
                        if (that.settings.callback) {
                            that.settings.callback.apply(null, [that.settings.id, ""]);
                        }
                        return { msg: "title required!", newValue: "", activate: false };
                    }
                },
                success: function (response: any, newValue: string) {
                    if (that.settings.callback) {
                        let text = new HTMLCleaner(newValue, true).getClean(HTMLCleaner.CleanLevel.Basic);
                        that.settings.callback.apply(null, [that.settings.id, text]);
                    }
                },
            });
            ttpart = $(linkId);
            (<JQuery>this.lineEditor).on("shown", function (event: JQueryEventObject) {
                if (that.settings.callback && that.settings.validate) {
                    $(event.delegateTarget)
                        .data("editable")
                        .input.$input.on("keyup", function (e: JQueryEventObject) {
                            that.newTitle = $(e.delegateTarget).val();
                            that.settings.callback.apply(null, [that.settings.id, that.newTitle]);
                        });
                }
            });
            control.html("").append(ttpart).append(this.lineEditor);
        } else if (this.settings.style === refLinkStyle.show) {
            ttpart = $(linkId);
            control.html("").append(ttpart).append(theTitle);
            if (this.settings.callback) {
                control.off("click").click(function (evt) {
                    if (evt.ctrlKey || evt.metaKey) {
                        var win = window.open(
                            app.createItemUrl(that.settings.id, that.settings.crossProject),
                            "_blank",
                        );
                        if (win) {
                            //Browser has allowed it to be opened
                            win.focus();
                        } else {
                            //Browser has blocked it
                            alert("Please allow popups for this site");
                        }
                        if (evt.preventDefault) evt.preventDefault();
                        if (evt.stopPropagation) evt.stopPropagation();
                    } else {
                        return that.settings.callback.apply(null, [that.settings.id]);
                    }
                });
            }
        } else if (this.settings.style === refLinkStyle.select) {
            ttpart = $(
                "<span class='" +
                    (this.settings.isHidden ? "refId greyRefIdHyper" : "refId refIdHyper") +
                    "'>" +
                    key +
                    "</span>",
            );
            control.html("").append(ttpart).append(theTitle);
            if (this.settings.callback) {
                ttpart.off("click").click(function (evt) {
                    if (evt.ctrlKey || evt.metaKey) {
                        var win = window.open(app.createItemUrl(that.settings.id), "_blank");
                        if (win) {
                            //Browser has allowed it to be opened
                            win.focus();
                        } else {
                            //Browser has blocked it
                            alert("Please allow popups for this site");
                        }
                    } else {
                        return that.settings.callback.apply(null, [that.settings.id]);
                    }
                });
            }
        }

        if (
            this.settings.tooltip === refLinkTooltip.html &&
            that.settings.id.indexOf("-") != 0 &&
            app.getType(that.settings.id)[0] != "_" &&
            !app.isFolder(that.settings.id) &&
            app.getCurrentItemId() != that.settings.id
        ) {
            ttpart.data("tooltip", "popover").data("placement", "bottom");

            ttpart.tooltip({ title: "hold shift to see a preview", trigger: "", container: "body" });
            ttpart.on("mouseover", function (event) {
                if (!globalThis.globalMouseDown) {
                    ml.UI.showTooltip(that.settings.id, $(event.delegateTarget), event, that.settings.crossProject);

                    ttpart.tooltip("show");
                    // and hide after a second
                    setTimeout(function () {
                        // the hiding on leave / click is really not working well
                        ttpart.tooltip("hide");
                        $(".tooltip.fade").remove(); // the hiding is really not working well
                    }, 1000);
                } else {
                    ttpart.tooltip("hide");
                }
            });
            ttpart.on("mouseleave", function () {
                ml.UI.hideTooltip();
                ttpart.tooltip("hide");
            });
            ttpart.on("mousedown", function () {
                ml.UI.hideTooltip(true);
                ttpart.tooltip("hide");
            });
        }
    }

    getValue(): string {
        return this.newTitle;
    }

    destroy() {
        if (this.lineEditor) {
            this.lineEditor.off();
            this.lineEditor.editable("destroy");
        }
        if (this.ttpart) {
            this.ttpart.off();
        }
    }
}

globalThis.globalMouseDown = false;

$(document)
    .mousedown(function () {
        globalThis.globalMouseDown = true;
    })
    .mouseup(function () {
        globalThis.globalMouseDown = false;
    });
