import { DateFieldHandler } from "../DateFieldHandler";
import { IAnyMap } from "../../../../globals";
import { IDocFieldHandler } from "./IDocFieldHandler";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { ml } from "../../../matrixlib";
import { DateTimeBL } from "../../DateTimeBL";

export type { IDueDateOptions, IDHFDueDateConfig };

interface IDueDateOptions extends IDHFSectionOptions {}

interface IDHFDueDateConfig {
    default: IDueDateOptions;
}

export class DateDocHandler extends DateFieldHandler implements IDocFieldHandler {
    private type: string;

    constructor(type: string, config: IAnyMap, fieldValue: string | undefined) {
        if (config === undefined) {
            config = DateDocHandler.default_config;
        }
        super(config);
        this.type = type;
        this.dhfFieldConfig = config;

        this.initData(fieldValue);
    }

    static default_config: IDHFDueDateConfig = {
        default: {},
    };
    getFieldType(): string {
        return this.type;
    }
    setDHFConfig(config: IAnyMap) {
        this.dhfFieldConfig = config;
    }

    getXmlValue(): string {
        let xmlval: any[] = [];
        let yyyymmdd = this.getDate();
        // new Date take mm-1
        let dateStr = DateTimeBL.getInstance().renderCustomerHumanDate(yyyymmdd, true);
        xmlval.push({ date: dateStr });

        return JSON.stringify(xmlval);
    }
    addSignatures(signatures: string[], includeAll: boolean) {
        //Nothing to do here
    }
    dhfFieldConfig: IAnyMap;

    getDefaultConfig(): any {
        return {};
    }
    protected name = "";
    getFieldName(): string {
        return this.name;
    }

    setFieldName(value: string): void {
        this.name = value;
    }
}
