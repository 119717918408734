import { IDocFieldHandler } from "./IDocFieldHandler";
import { IGenericMap } from "../../../../globals";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { ICustomSection } from "../../../../printinterface/PrintProcessorInterfaces";
import { ItemSelectionFromToAbstractDocFieldHandler } from "./ItemSelectionFromToAbstractDocFieldHandler";

export interface ICustomSectionOptions extends IDHFSectionOptions {
    includeInToc: boolean;
    options: ICustomSection;
    landscape: boolean;
}

export class CustomDocFieldHandler extends ItemSelectionFromToAbstractDocFieldHandler implements IDocFieldHandler {
    getDefaultConfig(): ICustomSectionOptions {
        return {
            includeInToc: false,
            landscape: false,
            options: {
                description: "",
                descriptionContent: "",
                descriptionNoContent: "<p>not applicable</p>",
                formatter: "",
            },
        };
    }

    public getXmlValue(): string {
        let controllerConfig = this.dhfFieldConfig;

        // get lists
        let reflist = this.getSelectedItems();

        let td: IGenericMap[] = [];
        let r0: IGenericMap = {};
        for (let idx = 0; idx < reflist.from.length; idx++) {
            r0["from" + idx] = reflist.from[idx].to;
        }
        td.push(r0);

        let r1: IGenericMap = {};
        for (let idx = 0; idx < reflist.to.length; idx++) {
            r1["to" + idx] = reflist.to[idx].to;
        }
        td.push(r1);

        // copy parameters

        let r2: IGenericMap = {};
        r2["SETTINGS"] = JSON.stringify(controllerConfig.options);

        td.push(r2);

        // return the xml dump
        return JSON.stringify(td);
    }
}
