import { ControlState, globalMatrix } from "../../../globals";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { LabelSwitches } from "../Parts/LabelSwitches";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export type { IDocFilterOptions };
export { DocFilterImpl };

interface IDocFilterOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: {};
}

$.fn.docFilter = function (this: JQuery, options: IDocFilterOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_docFilter,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new DocFilterImpl(this, options.fieldHandler as GenericFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};
class DocFilterImpl extends BaseControl<GenericFieldHandler> {
    private settings: IDocFilterOptions;

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IDocFilterOptions) {
        let that = this;
        var defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                // item the item containing the rest of the information
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        var currentLabelsOn: string[] = [];

        if (!this.settings.isItem) {
            return;
        }

        var ctrlContainer = $("<div class='baseControl'>");

        if (this.settings.fieldValue) {
            var onList = ml.JSON.fromString(this.settings.fieldValue);
            currentLabelsOn = ml.JSON.clone(onList.value);
        }
        // do not pass type: otherwise it will be removed. Also tooltips should be like for filter
        var lt = new LabelSwitches(
            ctrlContainer,
            this.settings.canEdit,
            null,
            currentLabelsOn,
            "doc_filter",
            function (newSelection: {}) {
                that._root.data("new", JSON.stringify(newSelection));
                if (that.settings.valueChanged) {
                    that.settings.valueChanged.apply(null);
                }
            },
        );
        if (lt.labelsExist()) {
            this._root.append(super.createHelp(this.settings));
            this._root.append(ctrlContainer);
        }

        this._root.data("original", JSON.stringify(currentLabelsOn));
        this._root.data("new", JSON.stringify(currentLabelsOn));
    }

    // public interface
    async hasChangedAsync() {
        // hack the download button

        $("#filterWarning").remove();
        if (this._root.data("new") && this._root.data("new") != "[]") {
            let applied_filters = "";
            try {
                const filterData = JSON.parse(this._root.data("new"));
                if (filterData.join) {
                    let existingLabels = ml.LabelTools.getLabelNames();
                    applied_filters = filterData
                        .map((f) => f.replace("!", ""))
                        .filter(function (filter: string) {
                            return existingLabels.indexOf(filter) != -1;
                        })
                        .join(", ");
                }
            } catch (e) {
                // Ignore parsing errors
            }
            if (applied_filters) {
                $("#btnDownload").prepend(
                    '<span id="filterWarning" title="Filter enabled: ' +
                        applied_filters +
                        '" class="fal fa-exclamation-triangle" style="padding-right:12px;color: red;"></span>',
                );
            }
        }

        return this._root.data("original") !== this._root.data("new");
    }
    async getValueAsync() {
        return this._root.data("new");
    }
    setValue() {}
    destroy() {}
    resizeItem() {}
}
