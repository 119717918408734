import { IDHFControlDefinition, IDHFSection, IDHFSectionOptions } from "./common/businesslogic/index";
import { IBaseControlOptions } from "./common/UI/Controls/BaseControl";
import { app, IReference } from "./globals";
import { ml } from "./common/matrixlib";
import { SelectSearchQueue } from "./SelectSearchQueue";
import { BaseDHFSection } from "./client/plugins/DocumentSections/BaseDHFSection";

export type {
    ISingleSelectOptions,
    IDoubleSelectOptions,
    ISingleDefaultControllerConfig,
    IDoubleDefaultControllerConfig,
};
export { SingleSelectBase };

interface ISingleSelectOptions extends IDHFSectionOptions {
    search?: string;
}
interface IDoubleSelectOptions extends IDHFSectionOptions {
    searchFrom?: string;
    searchTo?: string;
}

interface ISingleDefaultControllerConfig {
    default: ISingleSelectOptions;
}
interface IDoubleDefaultControllerConfig {
    default: ISingleSelectOptions;
}

abstract class SingleSelectBase<T extends ISingleSelectOptions> extends BaseDHFSection<T> implements IDHFSection {
    abstract renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions): void;

    addSignatures(signatures: string[], currentValue: IDHFControlDefinition) {}
    abstract showSpecificSettings(
        ctrl: IDHFControlDefinition,
        ctrlParameter: IBaseControlOptions,
        custom: JQuery,
    ): void;

    protected addSpecificSettings(controllerConfig: ISingleSelectOptions, custom: JQuery) {
        let search = $(
            '<input autocomplete="off" style="height: 20px;width: 420px;float: right;" class="form-control p_search" type="text" name="search" value="" /> ',
        );
        var sp = $("<div>Find items by search: </div>").append(search);
        custom.append(sp);
        search.val(controllerConfig.search ? controllerConfig.search : "");
    }

    abstract saveSpecificSettingsAsync(
        ctrl: IDHFControlDefinition,
        ctrlParameter: IBaseControlOptions,
        custom: JQuery,
    ): Promise<boolean>;

    protected setSpecificSettings(controllerConfig: ISingleSelectOptions, custom: JQuery) {
        let changed = controllerConfig.search != $(custom.find(".p_search")[0]).val();
        controllerConfig.search = $(custom.find(".p_search")[0]).val();

        return changed;
    }

    hasSearch(ctrl: IDHFControlDefinition) {
        var controllerConfig = this.getConfig(ctrl);
        return !!controllerConfig.search;
    }

    executeSearch(ctrl: IDHFControlDefinition) {
        var controllerConfig = this.getConfig(ctrl);

        app.searchAsyncMinimalOutput(controllerConfig.search, null, true).done(function (itemIds) {
            if (itemIds.length != 0) {
                $(".refreshNeeded", ctrl.control).removeClass("refreshNeeded");
                $(".contentNeeded", ctrl.control).removeClass("contentNeeded");
            }
            ctrl.control.getController().setValue(itemIds);
        });
    }

    async verifySearchAsync(ctrl: IDHFControlDefinition) {
        let controllerConfig = this.getConfig(ctrl);

        if (controllerConfig.search) {
            $(".fa-sync-alt", ctrl.control).addClass("icon-refresh-animate");
            SelectSearchQueue.addToSearchQueue(() => {
                return app.searchAsyncMinimalOutput(controllerConfig.search, null, true).done(async function (current) {
                    $(".fa-sync-alt", ctrl.control).removeClass("icon-refresh-animate");
                    let lastVal = await ctrl.control.getController().getValueAsync();
                    let last: IReference[] = <IReference[]>lastVal ? JSON.parse(lastVal) : [];
                    if (current.length == 0) {
                        return;
                    } else if (last.length != current.length) {
                        return;
                    } else {
                        let lastItems = last
                            .map(function (lastItem) {
                                return lastItem.to;
                            })
                            .sort(function (a, b) {
                                return ml.Item.sort(a, b);
                            });
                        let currentItems = current.sort(function (a, b) {
                            return ml.Item.sort(a, b);
                        });
                        for (var idx = 0; idx < lastItems.length; idx++) {
                            if (lastItems[idx] != currentItems[idx]) {
                                return;
                            }
                        }
                    }
                    $(".refreshNeeded", ctrl.control).removeClass("refreshNeeded");
                });
            });
        }
    }
    async verifyContentAsync(ctrl: IDHFControlDefinition) {
        let lastVal = await ctrl.control.getController().getValueAsync();
        let last: IReference[] = <IReference[]>lastVal ? JSON.parse(lastVal) : [];
        if (last.length == 0) {
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").addClass("contentNeeded");
        } else {
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").removeClass("contentNeeded");
        }
    }

    protected removeSpaces(str: string) {
        if (!str) return "";
        return str
            .split("|")
            .map(function (strp) {
                return strp.trim();
            })
            .join("|")
            .split(",")
            .map(function (strp) {
                return strp.trim();
            })
            .join(",");
    }
}
