import { DropdownAbstractDocFieldHandler } from "./DropdownAbstractDocFieldHandler";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";

export interface ITracesOptions extends IDHFSectionOptions {
    strict?: boolean;
    dateoptions?: string;
    includeInToc?: boolean;
    numericalOrder?: boolean;
}

export interface ITracesValue extends ITracesOptions {
    from?: string;
    to?: string;
}

export class TraceDocFieldHandler extends DropdownAbstractDocFieldHandler {
    getDefaultConfig(): ITracesOptions {
        return { strict: true, dateoptions: "", includeInToc: false, numericalOrder: false };
    }

    getXmlValue(): string {
        let data = this.getData();
        let traces: ITracesValue = {};
        if (data) {
            let sel = data.split("|");
            traces.from = sel[0];
            traces.to = sel[1].split("+").join(",");
        }
        traces.strict = this.dhfFieldConfig.strict;
        traces.dateoptions = this.dhfFieldConfig.dateoptions;
        traces.includeInToc = this.dhfFieldConfig.includeInToc;
        traces.numericalOrder = this.dhfFieldConfig.numericalOrder;

        return JSON.stringify([traces]);
    }
}
