import { ControlState, globalMatrix } from "../../../globals";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export type { IHiddenOptions };
export { HiddenImpl };

interface IHiddenOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: {};
}

$.fn.hidden = function (this: JQuery, options: IHiddenOptions) {
    if (!options.fieldHandler) {
        //No need for a field handler here, so let's create a dummy one.
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_hidden,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new HiddenImpl(this, options.fieldHandler as GenericFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);

    return this;
};

class HiddenImpl extends BaseControl<GenericFieldHandler> {
    private curValue: any;

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IHiddenOptions) {
        this.curValue = options.fieldValue;
    }
    async getValueAsync() {
        return this.curValue;
    }
    setValue(newValue: any) {
        this.curValue = newValue;
    }
    async hasChangedAsync() {
        return false;
    }
    destroy() {}
    resizeItem() {}
}
