import { IDataStorage } from "../globals";

export { ProjectStorageAdmin };

class ProjectStorageAdmin implements IDataStorage {
    setItem(itemKey: string, itemVal: string) {}

    getItem(itemKey: string): string {
        return "";
    }
    getItemDefault(itemKey: string, defaultValue: string): string {
        return "";
    }
}
