import { ITestConfig, ITestConfigTablesColumn, ITestConfigTablesColumns, ITestRuleStep } from "../../ProjectSettings";
import { ItemConfiguration, XRFieldTypeAnnotatedParamJson } from "./ItemConfiguration";

export type { ITestFieldParam, ITestStepsResultOption, ITestConfig15, ITestStepsResultsConfig };
export { TestManagerConfiguration };

interface ITestFieldParam extends XRFieldTypeAnnotatedParamJson {
    fieldMeaning: string;
}
interface ITestStepsResultOption {
    id: string;
    label: string;
}

interface ITestConfig15 {
    columnsStepsResult: ITestConfigTablesColumn[];
    columnsSteps: ITestConfigTablesColumn[];
}

interface ITestStepsResultsConfig {
    canBeModified: boolean;
    columns: ITestConfigTablesColumn[];
    passFailEditorConfig: ITestRuleStep[];
}

class TestManagerConfiguration {
    // configuration for per project
    public XTCconfig: ITestConfig;

    constructor() {
        this.XTCconfig = <ITestConfig>(<any>{});
    }

    initialize(itemConfig: ItemConfiguration) {
        this.XTCconfig = itemConfig.getTestConfig();

        if (!this.XTCconfig) {
            // default until 1.3
            this.XTCconfig = <any>{
                xtcType: "XTC",
                cloneSources: ["TC", "UC"],
                columnsSteps: [
                    { name: "Action", field: "action", editor: "text" },
                    { name: "Expected Result", field: "expected", editor: "text" },
                ],
                columnsStepsResult: [
                    { name: "Action", field: "action", editor: "none" },
                    { name: "Expected Result", field: "expected", editor: "none" },
                    { name: "Passed/Failed", field: "result", editor: "result" },
                    { name: "Comment", field: "comment", editor: "text" },
                ],
                automatic: [],
                manual: [
                    { human: "passed", command: "passed", render: "ok", code: "p" },
                    { human: "failed", command: "failed", render: "error", code: "f" },
                    { human: "", command: "not executed", render: "warning", code: "" },
                ],
                perStep: [
                    { human: "passed", command: "passed", render: "ok", code: "p", key: "p", image: "success.png" },
                    { human: "failed", command: "failed", render: "error", code: "f", key: "f", image: "fail.png" },
                    { human: "", command: "not executed", render: "warning", code: "", key: "r", image: "" },
                ],
            };
        }
    }

    // returns possible values for the drop down of the completed test case
    public getTestRunResultOptions(): ITestStepsResultOption[] {
        var opts: ITestStepsResultOption[] = [];
        if (this.XTCconfig.automatic && this.XTCconfig.automatic.length > 0) {
            opts.push({ id: "a", label: "automatic" });
        }
        for (var idx = 0; idx < this.XTCconfig.manual.length; idx++) {
            opts.push({ id: this.XTCconfig.manual[idx].code, label: this.XTCconfig.manual[idx].command });
        }
        return opts;
    }

    // get the configuration for a test definition table (e.g. test actions and expected results)
    public getTestStepsConfig(category: string): ITestConfigTablesColumns {
        if (this.XTCconfig.render && this.XTCconfig.render[category]) {
            return this.XTCconfig.render[category];
        }
        // this is actually a fallback for project created with version 1.5 or before
        // these project can be upgraded by just replacing the xtc_config with the latest version
        // UNLESS column names have been changed (which never happend)....
        return {
            columns:
                category === this.XTCconfig.xtcType
                    ? (<ITestConfig15>(<any>this.XTCconfig)).columnsStepsResult
                    : (<ITestConfig15>(<any>this.XTCconfig)).columnsSteps,
        };
    }

    // get definition for a test execution table (e.g. including columns with results/comments)
    public getTestStepsResultsConfig(): ITestStepsResultsConfig {
        return {
            canBeModified: false,
            columns: this.getTestStepsConfig(this.XTCconfig.xtcType).columns,
            passFailEditorConfig: this.XTCconfig.perStep,
        };
    }

    // check if the category is an executed test case (usually this will mean category type XTC)
    isXTC(type: string): boolean {
        return this.XTCconfig.xtcType && this.XTCconfig.xtcType.toLowerCase() === type.toLowerCase();
    }

    // check if this is a test case, use case, or something else which can be converted to a TC
    isTC(type: string): boolean {
        return this.isCloneSource(type);
    }

    // return the executed test case category type, (usually this will mean type XTC)
    getXTCType() {
        return this.XTCconfig.xtcType;
    }
    // return a simple array of all categories which can be converted to XTC
    getCloneSources() {
        return this.XTCconfig.cloneSources;
    }

    // returns place holder text if no value is selected
    public getTestRunResultPlaceholder(value: string): string {
        var code = value ? value.split("|")[0] : "";
        if (this.XTCconfig.automatic && this.XTCconfig.automatic.length > 0) {
            for (var idx = 0; idx < this.XTCconfig.automatic.length; idx++) {
                if (this.XTCconfig.automatic[idx].code === code) {
                    return "automatic - was '" + this.XTCconfig.automatic[idx].human + "' when last saved";
                }
            }
        }
        return "select test result";
    }

    public isCloneSource(category: string): boolean {
        if (!this.XTCconfig.cloneSources) {
            return false;
        }
        for (var csi = 0; csi < this.XTCconfig.cloneSources.length; csi++) {
            if (this.XTCconfig.cloneSources[csi] === category) {
                return true;
            }
        }
        return false;
    }
}
