import {
    IDHFSectionOptions,
    IDHFSection,
    IDHFControlDefinition,
    DocumentSectionType,
    ColumnTypesInfo,
    mDHF,
} from "../../../common/businesslogic/index";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { app, globalMatrix, matrixSession } from "../../../globals";
import { IDHFConfig } from "../../../ProjectSettings";
import {
    DocumentOptionsFieldHandler,
    IDocumentOptionsOptions,
} from "../../../common/businesslogic/FieldHandlers/Document/DocumentOptionsFieldHandler";
import { IDocFieldHandler } from "../../../common/businesslogic/FieldHandlers/Document/IDocFieldHandler";
import { BaseDHFSection } from "./BaseDHFSection";
export { DocumentOptions };

class DocumentOptions extends BaseDHFSection<IDocumentOptionsOptions> implements IDHFSection {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        ctrl.control.hidden(ctrlParameter);

        if (!matrixSession.isEditor() || !app.canEditItem(ctrlParameter.item) || ctrlParameter.locked) {
            $("#documentConfig").hide();
            return;
        }

        var button = $(
            "<button id='buttonDocOptions' title='Document Options' class='btn btn-item hidden-print'> <span class='fal fa-cog'></span></button>",
        );

        var controller = this;
        var ctrlobj = ctrl;
        var ctrlParam = ctrlParameter;
        button.click(function () {
            mDHF.showConfigDialog("", controller, ctrlobj, ctrlParam, "Document Options", true);
        });

        $("#documentConfig").replaceWith(button);
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = <IDocumentOptionsOptions>this.getConfig(ctrl);

        let auto_number = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p_auto_number" ' +
                (controllerConfig.auto_number ? "checked" : "") +
                "> Enforce section numbering (instead of relying on Word)</label></div>",
        );
        let omit_title = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p_omit_title" ' +
                (controllerConfig.omit_title ? "checked" : "") +
                "> Do not use document title as heading</label></div>",
        );
        let internal_links = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p_internal_links" ' +
                (controllerConfig.internal_links ? "checked" : "") +
                "> Generate links into document instead of links to Matrix</label></div>",
        );
        let hide_links = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p_hide_links" ' +
                (controllerConfig.hide_links ? "checked" : "") +
                "> Hide box with attached files / cross project links</label></div>",
        );
        let no_folder_ids = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p_no_folder_ids" ' +
                (controllerConfig.no_folder_ids ? "checked" : "") +
                "> Hide id of folders (show only names)</label></div>",
        );

        custom.append($("<p>Table of content and header numbering:</p>"));
        custom.append(auto_number);
        custom.append(omit_title);
        custom.append(internal_links);
        custom.append(hide_links);
        custom.append(no_folder_ids);
    }
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        var controllerConfig = <IDocumentOptionsOptions>this.getConfig(ctrl);

        var auto_number = $(custom.find(".p_auto_number")[0]).prop("checked");
        let no_folder_ids = $(custom.find(".p_no_folder_ids")[0]).prop("checked");
        var omit_title = $(custom.find(".p_omit_title")[0]).prop("checked");
        let internal_links = $(custom.find(".p_internal_links")[0]).prop("checked");
        let hide_links = $(custom.find(".p_hide_links")[0]).prop("checked");
        var changed =
            controllerConfig.auto_number !== auto_number ||
            controllerConfig.no_folder_ids !== no_folder_ids ||
            omit_title !== controllerConfig.omit_title ||
            internal_links !== controllerConfig.internal_links ||
            hide_links !== controllerConfig.hide_links;

        controllerConfig.auto_number = auto_number;
        controllerConfig.no_folder_ids = no_folder_ids;
        controllerConfig.omit_title = omit_title;
        controllerConfig.internal_links = internal_links;
        controllerConfig.hide_links = hide_links;

        this.fieldHandler.setDHFConfig(controllerConfig);
        return changed;
    }
    async verifyContentAsync(ctrl: IDHFControlDefinition) {}
}
