import { MR1, IItemChangeEvent } from "../../common/businesslogic/index";
import { IPlugin, IProjectPageParam, plugins } from "../../common/businesslogic/index";
import { ml } from "../../common/matrixlib";
import { IBaseControlOptions, BaseControl } from "../../common/UI/Controls/BaseControl";
import { IRiskParameter } from "../../common/UI/Controls/riskCtrl2";
import {
    ControlState,
    IReference,
    IBooleanMap,
    IStringJQueryMap,
    matrixSession,
    globalMatrix,
    restConnection,
    app,
    IItemGet,
    IGenericMap,
    IItem,
} from "../../globals";

import { IRiskConfig, IColDef, IFieldDescription } from "../../ProjectSettings";
import { XCPutEditItem } from "../../RestCalls";
import { XRGetProject_Needle_TrimNeedle, XRTrimLink } from "../../RestResult";
import { FieldDescriptions } from "../../common/businesslogic/FieldDescriptions";
import { GenericFieldHandler } from "../../common/businesslogic/FieldHandlers/GenericFieldHandler";
import { EmptyFieldHandler } from "../../common/businesslogic/FieldHandlers/EmptyFieldHandler";

export type { IRiskTableControlOptions, IRiskTableParams };
export { initialize };

interface IRiskTableControlOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: IRiskTableParams;
    links?: IReference[];
}

interface IRiskTableParams {
    tableOptions?: {
        showFullRisk?: boolean; // if true the risk ID will be shown with the title
        hideReadonly?: boolean; // if true readonly values are hidden from the table
        cloneButtonName?: string; // name of button to clone the last row
    };
}

class RiskControlFolderControl extends BaseControl<EmptyFieldHandler> {
    protected config: IRiskConfig;
    private settings: IRiskTableControlOptions;

    private changes: IBooleanMap = {};
    private riskIDs: string[] = [];
    private riskControlsRows: IStringJQueryMap = {};
    private forwardChangeEvents: boolean = false;

    constructor(control: JQuery) {
        super(control, new EmptyFieldHandler(FieldDescriptions.Field_dummy, {}));
    }

    init(options: IRiskTableControlOptions) {
        let that = this;

        if (!matrixSession.hasRisks()) {
            this._root.append("risks not licensed");
            return;
        }
        if (!options || options.controlState != ControlState.FormEdit) {
            return;
        }

        var defaultOptions: IRiskTableControlOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dummy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: function () {},
            parameter: {
                tableOptions: {
                    showFullRisk: false,
                    hideReadonly: false,
                },
            },
        };
        this.settings = <IRiskTableControlOptions>ml.JSON.mergeOptions(defaultOptions, options);
        // get risk config
        this.config = (<IRiskParameter>this.settings.parameter).riskConfig
            ? (<IRiskParameter>this.settings.parameter).riskConfig
            : globalMatrix.ItemConfig.getRiskConfig();

        this._root.append(super.createHelp(this.settings));

        let riskAsTable = $('<table class="table">');
        this._root.append(riskAsTable);

        // get and show risks
        app.getNeedlesAsync("folder=" + options.id, false, true, "*", false).done((resultsNeedles) => {
            if (!resultsNeedles.length) {
                // nothing to do - for now
                return;
            }

            // the needle search returns an unordered list of children, the tree has the ordered info
            let orderedChildren = app.getChildrenIds(that.settings.id);
            let needles = resultsNeedles.sort(function (a, b) {
                return orderedChildren.indexOf(a.id) - orderedChildren.indexOf(b.id);
            });

            // otherwise lots of not needed events
            that.disableChangeEvents();

            riskAsTable.append($("<thead>").append(that.renderTableHeaderRow()));
            let tbody = $("<tbody class='riskTableBody'>").appendTo(riskAsTable);

            // there's gonna be a risk field in that type -> otherwise the constructor would not have been called
            let riskFields = globalMatrix.ItemConfig.getFieldsOfType("risk2", that.settings.type);
            let riskFieldId = riskFields[0].field.id;
            $.each(needles, function (riskIdx, risk) {
                let riskValue = risk[riskFieldId];

                that.renderRiskRow(
                    tbody,
                    risk.id,
                    riskFieldId,
                    riskValue,
                    risk.downLinks,
                    that.settings.parameter.tableOptions && that.settings.parameter.tableOptions.hideReadonly,
                );
            });

            tbody.sortable({
                stop: function (event, ui) {
                    that.moveRow();
                },
            });
            // add extra row
            that.renderTableFooterRow(riskAsTable, resultsNeedles[resultsNeedles.length - 1].id, riskFieldId);

            // now all is done,
            that.enableChangeEvents();
        });
    }
    async hasChangedAsync() {
        let that = this;
        return new Promise<boolean>((resolve, reject) => {
            let changed = false;
            $.each(this.changes, function (itemId, itemChanged) {
                changed = changed || itemChanged;
            });
            $.each($(".riskDetails", this._root), function (rowIdx, row) {
                if (that.riskIDs[rowIdx] != $(row).data("itemId")) {
                    changed = true;
                }
            });
            resolve(changed);
        });
    }
    async getValueAsync() {
        let that = this;

        return "";
    }
    destroy() {}
    resizeItem() {}

    async saveChanges(): Promise<unknown> {
        let that = this;
        let hasChanged = await this.hasChangedAsync();
        return new Promise<unknown>((resolve, reject) => {
            if (!hasChanged) {
                resolve({});
                return;
            }
            matrixSession
                .getCommentAsync()
                .done(function (comment: string) {
                    that.saveChangesRec(0, comment)
                        .then(function () {
                            resolve({});
                        })
                        .catch(function () {
                            reject();
                        });
                })
                .fail(function () {
                    // user cancelled comment/action
                    reject();
                });
        });
    }

    private reorderRec(row: number): JQueryDeferred<{}> {
        let that = this;

        let res = $.Deferred();
        if (row >= this.riskIDs.length) {
            res.resolve();
        } else {
            app.moveItemsAsync($($(".riskDetails", this._root)[row]).data("itemId"), this.settings.id, 10000 + row)
                .done(function () {
                    that.reorderRec(row + 1)
                        .done(function () {
                            res.resolve();
                        })
                        .fail(function () {
                            res.reject();
                        });
                })
                .fail(function () {
                    res.reject();
                });
        }
        return res;
    }
    // save changes of all risk which changed
    private async saveChangesRec(row: number, comment: string): Promise<{}> {
        let that = this;
        return new Promise<unknown>(async (resolve, reject) => {
            if (row >= this.riskIDs.length) {
                this.changes = {};
                // done saving the content changes, check if something was moved

                let reorderFrom = -1;
                let orderedRows = $(".riskDetails", this._root);
                let rowIdx = 0;
                while (reorderFrom == -1 && rowIdx < orderedRows.length) {
                    if (that.riskIDs[rowIdx] != $(orderedRows[rowIdx]).data("itemId")) {
                        reorderFrom = rowIdx;
                    } else {
                        rowIdx++;
                    }
                }
                if (reorderFrom == -1) {
                    resolve({});
                } else {
                    this.reorderRec(reorderFrom)
                        .done(function () {
                            resolve({});
                        })
                        .fail(function () {
                            reject();
                        });
                }
            } else {
                if (this.changes[this.riskIDs[row]]) {
                    // this row needs saving
                    let riskId = this.riskIDs[row];
                    // get new value of the field
                    let riskFields = globalMatrix.ItemConfig.getFieldsOfType("risk2", ml.Item.parseRef(riskId).type);
                    let fieldId = riskFields[0].field.id;
                    // get new value
                    let riskValue = await that.riskControlsRows[this.riskIDs[row]].getController().getValueAsync();

                    // put it on server
                    that.putRisk(riskId, fieldId, riskValue, comment)
                        .done(function (updatedItem) {
                            that.saveChangesRec(row + 1, comment)
                                .then(function () {
                                    resolve({});
                                })
                                .catch(function () {
                                    reject();
                                });
                        })
                        .fail(function () {
                            reject();
                        });
                } else {
                    // check next row
                    return this.saveChangesRec(row + 1, comment);
                }
            }
        });
    }

    // callback when a value in a cell has changed
    private rowChange(itemId: string, row: JQuery) {
        this.changes[itemId] = row.getController().hasChangedAsync();
        if (this.forwardChangeEvents) {
            this.settings.valueChanged();
        }
    }
    // prevent change events being sent to app
    private disableChangeEvents() {
        this.forwardChangeEvents = false;
    }
    // enable events and actually do one check
    private enableChangeEvents() {
        this.forwardChangeEvents = true;
        this.settings.valueChanged();
    }
    // callback after drop of row
    private moveRow() {
        this.settings.valueChanged();
    }

    // set the width of a column
    private setWidth(th: JQuery, colDef: IColDef) {
        if (!colDef) return;
        if (colDef.maxWidth) th.css("max-width", colDef.maxWidth);
        if (colDef.minWidth) th.css("min-width", colDef.minWidth);
        if (colDef.width) th.css("width", colDef.width);
    }

    // check whether a cell should be the same for risks
    private isRowSpan(colDef: IColDef) {
        if (!colDef) return false;
        if (colDef.rowSpan) return true;
        return false;
    }

    // render a row showing a risk
    private renderRiskRow(
        tbody: JQuery,
        itemId: string,
        riskFieldId: number,
        riskValue: string,
        downLinkList: IReference[],
        hideReadonly: boolean,
    ) {
        let that = this;
        // create a new fake risk item
        let item: IItemGet = {
            id: itemId,
            title: "",
            type: this.settings.type,
            downLinks: [],
            upLinks: [],
            modDate: null,
            labels: [],
        };
        // copy the risk field data inside
        (<IGenericMap>item)[riskFieldId.toString()] = riskValue;
        // copy the downlinks (risk controls) inside
        $.each(downLinkList, function (dlIdx, dl) {
            item.downLinks.push(dl);
        });

        let tr = $("<tr class='riskDetails'>").appendTo(tbody);

        tr.data("itemId", itemId);

        let rowSettings = ml.JSON.clone(this.settings);
        rowSettings.item = item;
        rowSettings.id = item.id;
        rowSettings.isItem = true;
        rowSettings.links = item.downLinks;
        rowSettings.fieldValue = (<IGenericMap>item)[riskFieldId.toString()];
        rowSettings.hideReadonlyColumns = hideReadonly;
        rowSettings.valueChanged = () => {
            that.rowChange(item.id, tr);
        };
        tr.riskCtrl2(rowSettings);

        that.riskControlsRows[item.id] = tr;
        // set item to not changed
        // this would overwrite value from onchange that.changes[item.id] = false;
        that.riskIDs.push(item.id);
        this.highlightRow(tr);
    }

    // render a row with risk column names
    private renderTableHeaderRow() {
        let that = this;

        let tr = $("<tr class='rotateHead'>");

        $("<th class='rotate'>").appendTo(tr); // column for risk id

        // add columns for text inputs (weights)
        $.each(this.config.factors, function (factorIdx, factor) {
            let th = $("<th class='rotate'>")
                .appendTo(tr)
                .html("<div>" + factor.label + "</div>"); // column for factor (text input);
            that.setWidth(th, factor.colDef);
        });
        // add columns values inputs (factors)
        $.each(this.config.factors, function (factorIdx, factor) {
            $.each(factor.weights, function (weightIdx, weight) {
                let th = $("<th class='rotate'>")
                    .appendTo(tr)
                    .html("<div>" + weight.label + "</div>"); // column for weight (drop down input);
                that.setWidth(th, weight.colDef);
            });
        });
        // add column for RBM // RPN before controls
        let rbm = $("<th class='rotate'>")
            .appendTo(tr)
            .html("<div>" + (this.config.rbm && this.config.rbm.short ? this.config.rbm.short : "RBM") + "</div>");
        if (this.config.rbm) {
            that.setWidth(rbm, this.config.rbm.colDef);
        }

        // add column for risk controls
        let thc = $("<th class='rotate'>")
            .appendTo(tr)
            .html("<div>" + (this.config.controls ? this.config.controls : "Risk Controls") + "</div>");
        that.setWidth(thc, this.config.mitigationColDef);

        // add columns for values (factors) after risk controls
        $.each(this.config.factors, function (factorIdx, factor) {
            $.each(factor.weights, function (weightIdx, weight) {
                let th = $("<th class='rotate'>")
                    .appendTo(tr)
                    .html("<div>" + weight.label + "</div>"); // column for weight (drop down input)
                that.setWidth(th, weight.colDef);
                if (
                    that.settings.parameter.tableOptions &&
                    that.settings.parameter.tableOptions.hideReadonly &&
                    (ml.JSON.isTrue(weight.readonly) || !that.config.postReduction)
                ) {
                    th.hide();
                }
                if (that.config.postReduction) {
                    let visibleColumns = that.config.postReduction.weights.map(function (weight) {
                        return weight.type;
                    });
                    if (visibleColumns.indexOf(weight.type) == -1) {
                        th.remove();
                    }
                }
            });
        });

        // column for RAM // RPN after  controls
        let ram = $("<th class='rotate'>")
            .appendTo(tr)
            .html("<div>" + (this.config.ram && this.config.ram.short ? this.config.ram.short : "RAM") + "</div>");
        if (this.config.ram) {
            that.setWidth(ram, this.config.ram.colDef);
        }

        return tr;
    }

    // render a row with an add button to clone last row
    private renderTableFooterRow(table: JQuery, lastItemId: string, riskFieldId: number) {
        let that = this;

        if (!this.settings.parameter.tableOptions || !this.settings.parameter.tableOptions.cloneButtonName) {
            // no extra function(s)
            return;
        }
        let tfoot = $("<tfoot>").appendTo(table);
        let tr = $("<tr class='riskFooter'>").appendTo(tfoot);

        // compute colspan
        let colspan = 1;
        // add columns for text inputs (weights)
        colspan += this.config.factors.length;

        // add columns values inputs (factors)
        $.each(this.config.factors, function (factorIdx, factor) {
            colspan += factor.weights.length;
        });
        // add column for RBM // RPN before controls
        colspan++;
        // add column for risk controls
        colspan++;

        // add columns for values (factors) after risk controls
        $.each(this.config.factors, function (factorIdx, factor) {
            colspan += factor.weights.length;
        });

        // column for RAM // RPN after  controls
        colspan++;

        let firstCol = $("<td colspan='" + colspan + "'>").appendTo(tr); // column for risk id
        let addButtonName = this.settings.parameter.tableOptions.cloneButtonName;
        $("<button class='btn btn-default btn-xs' style='margin:6px'>" + addButtonName + "</button>")
            .click(function () {
                matrixSession.getCommentAsync().done(function (comment: string) {
                    let params = {
                        targetFolder: that.settings.id,
                        targetProject: matrixSession.getProject(),
                        reason: comment,
                    };
                    restConnection
                        .postServer(matrixSession.getProject() + "/copy/" + lastItemId, params)
                        .done(async function (newItems: any) {
                            app.insertInTree({
                                parent: that.settings.id,
                                position: 10000,
                                item: {
                                    type: that.settings.type,
                                    id: newItems.itemsAndFoldersCreated[0],
                                    title: app.getItemTitle(lastItemId),
                                },
                            });
                            tr.remove();
                            that.renderRiskRow(
                                table,
                                newItems.itemsAndFoldersCreated[0],
                                riskFieldId,
                                await that.riskControlsRows[lastItemId].getController().getValueAsync(),
                                [],
                                that.settings.parameter.tableOptions.hideReadonly,
                            );

                            that.renderTableFooterRow(table, lastItemId, riskFieldId);
                        });
                });
            })
            .appendTo(firstCol);
    }

    // update a risk in the database (only change the risk field)
    private putRisk(itemId: string, riskFieldId: number, riskValue: string, comment: string) {
        var putIt: XCPutEditItem = <any>{ reason: comment, onlyThoseFields: 1, onlyThoseLabels: 1 };

        (<IGenericMap>putIt)["fx" + riskFieldId] = riskValue;

        return restConnection.putProject("item/" + itemId, putIt);
    }

    refreshLinks() {
        Object.values(this.riskControlsRows).forEach((row) => {
            this.highlightRow(row);
        });
    }

    private highlightRow(row: JQuery) {
        $(".riskIdCell", row).highlightReferences();
    }
}

$.fn.riskCtrl2Folder = function (this: JQuery, options: IRiskTableControlOptions) {
    let baseControl = new RiskControlFolderControl(this);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class RiskControlFolderPlugin implements IPlugin {
    static fieldType = FieldDescriptions.Field_riskFolder;

    control: JQuery;

    constructor() {}

    private _item: IItem;

    public isDefault = true;

    initItem(item: IItem, jui: JQuery) {
        this._item = item;
    }

    initServerSettings() {
        MR1.onBeforeSaveAsync().subscribe(this, function (event: IItemChangeEvent) {
            let ctrls = event.view.getControls(RiskControlFolderPlugin.fieldType);
            let res = $.Deferred();
            if (ctrls.length == 1) {
                let controller: RiskControlFolderControl = <RiskControlFolderControl>ctrls[0].getController();
                controller.saveChanges().then(() => {
                    res.resolve();
                });
            } else {
                res.resolve();
            }
            return res;
        });
    }

    initProject() {}

    async getProjectPagesAsync() {
        return [];
    }

    updateMenu(ul: JQuery) {}

    supportsControl(fieldType: string): boolean {
        return fieldType === RiskControlFolderPlugin.fieldType;
    }
    createControl(ctrl: JQuery, options: IBaseControlOptions) {
        this.control = ctrl;
        if (!options.isForm || options.isItem) {
            ctrl.riskCtrl2Folder(null);
            return;
        }
        let riskFields = globalMatrix.ItemConfig.getFieldsOfType("risk2", options.type);
        if (riskFields.length == 1) {
            let riskConfig = (<IRiskParameter>riskFields[0].field.parameterJson).riskConfig;
            if (riskConfig) {
                (<any>options.parameter).riskConfig = riskConfig;
            }

            ctrl.riskCtrl2Folder(options);
        } else {
            // create 'empty' control
            ctrl.riskCtrl2Folder(null);
        }
    }

    // @ts-ignore I see no issue there, because IFieldParam have `[key: string]: any;`
    addFieldSettings(
        configApp: any,
        project: string,
        pageId: string,
        fieldType: string,
        fieldParams: IRiskTableParams,
        ui: JQuery,
        paramChanged: () => void,
    ) {
        let that = this;

        if (fieldType != RiskControlFolderPlugin.fieldType) {
            return;
        }

        if (!fieldParams.tableOptions) {
            fieldParams.tableOptions = {};
        }
        ml.UI.addCheckbox(ui, "show full risk title", fieldParams.tableOptions, "showFullRisk", paramChanged);
        ml.UI.addCheckbox(
            ui,
            "hide readonly post reduction values",
            fieldParams.tableOptions,
            "hideReadonly",
            paramChanged,
        );
        ml.UI.addTextInput(
            ui,
            "add button to clone last risk (empty for none)",
            fieldParams.tableOptions,
            "cloneButtonName",
            paramChanged,
        );
    }

    getFieldConfigOptions(): IFieldDescription[] {
        return [
            {
                id: RiskControlFolderPlugin.fieldType,
                label: "Risk Table [" + RiskControlFolderPlugin.fieldType + "]",
                capabilities: {
                    canBeXtcPreset: false,
                    canBePublished: false,
                    canBeReadonly: true,
                    canHideInDoc: true,
                    canRequireContent: false,
                },
                class: "folder",
                help: "shows risks in a folder in tabular form",
            },
        ];
    }
}

function initialize() {
    let folderRiskControl = new RiskControlFolderPlugin();
    // @ts-ignore I see no issue there, because IFieldParam have `[key: string]: any;`
    plugins.register(folderRiskControl);
}
