import { SelectMode } from "../UI/Components/ProjectViewDefines";
import { ItemSelectionTools } from "../UI/Tools/ItemSelectionView";
import {
    IRestParam,
    IReference,
    IItem,
    IItemGet,
    globalMatrix,
    app,
    matrixSession,
    matrixApplicationUI,
} from "./../../globals";
import { MR1 } from "./../businesslogic/index";
import { ml } from "./../matrixlib";
import {
    IReportGeneratorTools,
    IJobList,
    IReportOptions,
    IPostCreateSignOrDocResult,
    IGetReportProgressResult,
    IReportTransferField,
    IGetReportProgressJobFileResult,
    IReportInput,
} from "./MatrixLibInterfaces";

export { ReportGeneratorTools };

class ReportGeneratorTools implements IReportGeneratorTools {
    private reportJoblist: IJobList = { allGood: true, tasks: [], doneBatch: 0 };
    private reportProc: number;
    lastReportXMLs: string[] = [];

    public SaveAndCreate(
        itemId: string,
        reportOptions: IReportOptions,
        progressInfo: string,
        postCreateCallback: Function,
        postFailCallback?: Function,
        postProgressCallback?: Function,
    ): void {
        let that = this;

        if (app.getNeedsSave()) {
            app.saveAsync(false).done(function () {
                window.setTimeout(function () {
                    that.StartReportEngine(
                        itemId,
                        reportOptions,
                        progressInfo,
                        postCreateCallback,
                        postFailCallback,
                        postProgressCallback,
                    );
                }, 1000);
            });
        } else {
            this.StartReportEngine(
                itemId,
                reportOptions,
                progressInfo,
                postCreateCallback,
                postFailCallback,
                postProgressCallback,
            );
        }
    }

    private StartReportEngine(
        reportId: string,
        reportOptions: IReportOptions,
        progressInfo: string,
        postCreateCallback: Function,
        postFailCallback?: Function,
        postProgressCallback?: Function,
    ): void {
        let that = this;
        app.canLaunchReport().done(function (canLaunch: boolean) {
            if (canLaunch) {
                that.StartReportEngineLaunch(
                    reportId,
                    reportOptions,
                    progressInfo,
                    postCreateCallback,
                    postFailCallback,
                    postProgressCallback,
                );
            } else {
                ml.UI.showConfirm(
                    1,
                    { title: "Report engine busy. Please wait a while and try again...", ok: "Try Again" },
                    function () {
                        window.setTimeout(function () {
                            that.StartReportEngine(
                                reportId,
                                reportOptions,
                                progressInfo,
                                postCreateCallback,
                                postFailCallback,
                                postProgressCallback,
                            );
                        }, 400);
                    },
                    function () {},
                );
            }
        });
    }

    private StartReportEngineLaunch(
        reportId: string,
        reportOptions: IReportOptions,
        progressInfo: string,
        postCreateCallback: Function,
        postFailCallback?: Function,
        postProgressCallback?: Function,
    ): void {
        let that = this;
        ml.Logger.log("debug", "starting report: " + progressInfo);
        ml.Logger.log("lastReportJobId", "");
        ml.Logger.log("lastReportFileId", "");
        ml.Logger.log("lastReportJobXML", "");

        app.startReportAsync(reportId, reportOptions).done(function (jobId: IPostCreateSignOrDocResult) {
            ml.Logger.log("lastReportJobId", jobId.jobId.toString());
            that.reportJoblist.tasks.push({
                jobId: jobId.jobId,
                progress: 0,
                reportId: reportId,
                reportOptions: reportOptions,
                postCreateCallback: postCreateCallback,
                postFailCallback: postFailCallback,
                postProgressCallback: postProgressCallback,
            });

            let labels = reportOptions.filter ? " (" + reportOptions.filter + ")" : "";
            if (that.reportJoblist.tasks.length === 1) {
                ml.UI.Progress.Init(progressInfo + labels, !!reportOptions.filter);
                that.reportJoblist.allGood = true;
                that.reportJoblist.doneBatch = 0;
                clearTimeout(that.reportProc);
                that.reportProc = window.setTimeout(function () {
                    that.waitForReports(0);
                }, 500);
            }
        });
    }

    private waitForReports(rid: number): void {
        let that = this;
        if (this.reportJoblist.tasks.length <= rid) {
            ml.UI.Progress.ErrorHide("Error retrieving report status", 2000);
            return;
        }
        app.getReportDetails(this.reportJoblist.tasks[rid].jobId)
            .done(function (progress: IGetReportProgressResult) {
                let status = that.getProgressStatus(progress.status);

                if (status === "Error" || progress.progress > 100) {
                    let details = status && status != "Error" ? that.getError(status) : "";

                    ml.UI.showError(
                        "Error",
                        "Failed to create " +
                            that.reportJoblist.tasks[rid].reportId +
                            (details ? "<br>Error was: " + details : ""),
                    );
                    if (that.reportJoblist.tasks[rid].postFailCallback) {
                        that.reportJoblist.tasks[rid].postFailCallback(
                            "Failed to create " +
                                that.reportJoblist.tasks[rid].reportId +
                                (details ? "<br>Error was: " + details : ""),
                        );
                    }
                    that.reportJoblist.tasks.splice(rid, 1);
                    that.reportJoblist.allGood = false;
                    that.reportJoblist.doneBatch++;

                    rid -= 1;
                } else if (progress.progress < 100) {
                    if (status && status.itemsProgress) {
                        console.log(JSON.stringify(status.itemsProgress, null, 2));
                    }

                    if (that.reportJoblist.tasks[rid].postProgressCallback) {
                        that.reportJoblist.tasks[rid].postProgressCallback(progress.progress);
                    }

                    that.reportJoblist.tasks[rid].progress = progress.progress;
                    var progressTotal = 100 * that.reportJoblist.doneBatch;
                    for (var idx = 0; idx < that.reportJoblist.tasks.length; idx++) {
                        progressTotal += that.reportJoblist.tasks[idx].progress;
                    }
                    ml.UI.Progress.Update(
                        progressTotal / (that.reportJoblist.tasks.length + that.reportJoblist.doneBatch),
                    );
                } else {
                    // done with at least one

                    var lastCreatedObject = progress.jobFile[progress.jobFile.length - 1];
                    ml.Logger.log("lastReportFileId", lastCreatedObject.jobFileId.toString());
                    that.lastReportXMLs.push(
                        "/job/" + that.reportJoblist.tasks[rid].jobId + "/" + progress.jobFile[0].jobFileId,
                    );
                    ml.Logger.log(
                        "lastReportJobXML",
                        globalMatrix.matrixRestUrl +
                            "/" +
                            matrixSession.getProject() +
                            "/job/" +
                            that.reportJoblist.tasks[rid].jobId +
                            "/" +
                            progress.jobFile[0].jobFileId,
                    );

                    if (that.reportJoblist.tasks[rid].postCreateCallback) {
                        that.reportJoblist.tasks[rid].postCreateCallback(
                            lastCreatedObject,
                            globalMatrix.matrixRestUrl +
                                "/" +
                                matrixSession.getProject() +
                                "/job/" +
                                that.reportJoblist.tasks[rid].jobId +
                                "/" +
                                lastCreatedObject.jobFileId,
                        );
                    } else {
                        app.download(that.reportJoblist.tasks[rid].jobId, lastCreatedObject.jobFileId);
                        that.updateAfterCreatingCache(that.reportJoblist.tasks[rid].reportId);
                    }

                    that.reportJoblist.doneBatch++;
                    that.reportJoblist.tasks.splice(rid, 1);
                    rid -= 1;
                }

                if (that.reportJoblist.tasks.length > 0) {
                    that.reportProc = window.setTimeout(function () {
                        that.waitForReports((rid + 1) % that.reportJoblist.tasks.length);
                    }, 500);
                    return;
                } else if (that.reportJoblist.allGood) {
                    ml.UI.Progress.SuccessHide("Finished creating report/document", 2000);
                } else {
                    ml.UI.Progress.ErrorHide("Error creating report/document", 2000);
                }
            })
            .fail(function () {
                ml.UI.Progress.ErrorHide("Error retrieving report status", 2000);
            });
    }

    // this method checks if a signCache has been created during download of an item if so it updates it (without asking questions...)
    private updateAfterCreatingCache(itemId: string) {
        // MATRIX-3721 if a signed has been downloaded, a cache has been created
        // if the same SIGN is being displayed, we need to load and display the new item, we do this as a second protection by cancelling the edits

        if (
            itemId &&
            ml.Item.parseRef(itemId).type == "SIGN" &&
            matrixApplicationUI.lastMainItemForm &&
            app.getCurrentItemId() == itemId
        ) {
            app.cancel();
            // and we force a get the latest
            matrixSession.pushMessages.watchItem(itemId);
        }
    }
    private getProgressStatus(text: string): any {
        if (!text || text[0] != "{") {
            return text;
        }
        let isJson = ml.JSON.fromString(text);
        if (isJson.status == "ok") {
            return isJson.value;
        }
        return "";
    }

    private getError(error: any) {
        if (!error.itemsProgress || error.itemsProgress.length == 0) {
            return error.errorMessage ? error.errorMessage : "";
        }
        for (let idx = 0; idx < error.itemsProgress.length; idx++) {
            if (error.itemsProgress[idx].progress == 200) {
                // that's an error
                return error.itemsProgress[idx].documentRef + ": " + error.itemsProgress[idx].message;
            }
        }
        return "unknown error";
    }

    CreateSignedDoc(
        docId: string,
        signatures: string[],
        signedDocumentsControl: JQuery,
        labelFilter: string,
        signName: string,
        transferFields: IReportTransferField[],
        defaultLabels: string[],
        docUpdateCb: (createdDocumentId: string) => void,
    ): void {
        let that = this;
        if (app.getNeedsSave()) {
            ml.UI.showError("You must save document", "Save document before archiving it!");
            return;
        }
        let useDefaultFolder = globalMatrix.globalShiftDown;

        matrixSession.getCommentAsync().done(function (comment: string) {
            // get the last used folders
            let signTarget = globalMatrix.projectStorage.getItem("SignTarget")
                ? globalMatrix.projectStorage.getItem("SignTarget")
                : "F-SIGN-1";

            // shift pressed - use default folder
            if (useDefaultFolder) {
                that.createSIGN(
                    signTarget,
                    comment,
                    docId,
                    signatures,
                    signedDocumentsControl,
                    labelFilter,
                    signName,
                    transferFields,
                    defaultLabels,
                    docUpdateCb,
                );
            } else {
                var st = new ItemSelectionTools();
                st.showDialog({
                    selectMode: SelectMode.singleFolder,
                    linkTypes: [{ type: "SIGN" }],
                    selectionChange: function (newSelection: IReference[]) {
                        if (!newSelection.length) {
                            newSelection = [{ to: "F-SIGN-1", title: "" }];
                        }
                        signTarget = newSelection[0].to;
                        globalMatrix.projectStorage.setItem("SignTarget", signTarget);

                        // finally
                        that.createSIGN(
                            signTarget,
                            comment,
                            docId,
                            signatures,
                            signedDocumentsControl,
                            labelFilter,
                            signName,
                            transferFields,
                            defaultLabels,
                            docUpdateCb,
                        );
                    },
                    getSelectedItems: async function () {
                        return [{ to: signTarget, title: "" }];
                    },
                    dialogTitle: "Select target folder",
                    focusOn: signTarget,
                });
            }
        });
    }

    createSIGN(
        target: string,
        comment: string,
        docId: string,
        signatures: string[],
        signedDocumentsControl: JQuery,
        labelFilter: string,
        signName: string,
        transferFields: IReportTransferField[],
        defaultLabels: string[],
        docUpdateCb: (createdDocumentId: string) => void,
    ): void {
        let that = this;

        var reportOptions: IReportOptions = { reason: comment };

        if (labelFilter && labelFilter.length > 0) {
            reportOptions.filter = labelFilter;
        }

        if (!signatures || signatures.length === 0) {
            reportOptions["isSignedReport"] = true;
            reportOptions["includeSignatures"] = "";
        } else {
            reportOptions["isSignedReport"] = true;
            reportOptions["includeSignatures"] = signatures.join(",");
        }
        reportOptions["newTitle"] = signName;
        if (transferFields) {
            var trv = "";
            $.each(transferFields, function (tfIdx, tfv) {
                if (trv !== "") {
                    trv += ";";
                }
                trv += "(" + tfv.fromId + "," + tfv.toId + ")";
            });
            if (trv !== "") {
                reportOptions["copyFields"] = trv;
            }
        }

        that.StartReportEngine(
            docId,
            reportOptions,
            "Creating SIGNed Document",
            function (lastCreatedObject: IGetReportProgressJobFileResult) {
                var newId = lastCreatedObject.visibleName;
                docUpdateCb(newId);
                // the new SIGN item id (newId) was created on the server, create the link and udpate the client
                app.getItemAsync(newId)
                    .done(function (itemdetails: IItem) {
                        MR1.triggerAfterCreateSign(itemdetails);
                        if (defaultLabels.length > 0) {
                            app.updateItemInDBAsync(
                                {
                                    id: itemdetails.id,
                                    onlyThoseFields: 1,
                                    onlyThoseLabels: 1,
                                    labels: defaultLabels.join(","),
                                },
                                "edit",
                            ).done(function (updatedItem) {
                                that.addSignToTree(target, comment, updatedItem, signedDocumentsControl);
                            });
                        } else {
                            that.addSignToTree(target, comment, itemdetails, signedDocumentsControl);
                        }
                    })
                    .fail(function () {
                        ml.UI.showError("Could not create SIGN document!", "");
                    });
            },
        );
    }

    protected async addSignToTree(
        target: string,
        comment: string,
        itemdetails: IItemGet,
        signedDocumentsControl: JQuery,
    ) {
        if (target != "F-SIGN-1") {
            // move it to target on server
            app.moveItemsAsync(itemdetails.id, target, 10000, comment);
        }

        // in the meantime update the tree
        var itemJson = {
            id: itemdetails.id,
            title: itemdetails.title,
            type: itemdetails.type,
        };
        var newItem = {
            parent: target,
            position: 100000,
            item: itemJson,
        };

        app.insertInTree(newItem);
        app.updateCache(newItem);

        // and add it to the list of downlinks
        if (signedDocumentsControl) {
            var refs = <IReference[]>await signedDocumentsControl.getController().getValueAsync();
            if (!refs) {
                refs = [];
            }
            refs.push({
                to: itemdetails.id,
                title: itemdetails.title,
            });
            signedDocumentsControl.getController().setValue(refs);

            ml.UI.showSuccess("Signed document " + itemdetails.id + " was created");
        }
    }

    CreateDoc(docId: string, format: IReportOptions, labelFilter: string): void {
        var reportOptions: IReportOptions = format ? format : <IReportOptions>{};
        if (labelFilter && labelFilter.length > 0) {
            reportOptions.filter = labelFilter;
        }
        this.SaveAndCreate(docId, reportOptions, "Downloading Document", null);
    }

    CreateReport(
        reportId: string,
        format: IReportOptions,
        inputItems?: IReportInput[],
        requiredItems?: IReportInput[],
    ): void {
        var reportOptions: IReportOptions = format ? format : <IReportOptions>{};

        var labelFilter = ml.LabelTools.getFilter();

        if (labelFilter && labelFilter.length > 0) {
            reportOptions.filter = labelFilter;
        }

        // only use the selected / required input
        var list: string[] = [];
        if (inputItems) {
            for (var idx = 0; idx < inputItems.length; idx++) {
                list.push(inputItems[idx].to);
            }
        }

        if (requiredItems) {
            for (var idx = 0; idx < requiredItems.length; idx++) {
                list.push(requiredItems[idx].to);
            }
        }
        if (list.length > 0) {
            reportOptions["itemList"] = list.join(",");
        }

        this.SaveAndCreate(reportId, reportOptions, "Downloading Report", null);
    }

    DownloadSignedDoc(signedId: string, format: IReportOptions) {
        let that = this;
        ml.Logger.log("debug", "starting signed report");
        ml.Logger.log("lastReportJobId", "");
        ml.Logger.log("lastReportFileId", "");
        ml.Logger.log("lastReportJobXML", "");

        app.startCreateDocumentAsync(signedId, format).done(function (jobId: IPostCreateSignOrDocResult) {
            var reportOptions: IReportOptions = format ? format : <IReportOptions>{};
            ml.Logger.log("lastReportJobId", jobId.jobId.toString());

            that.reportJoblist.tasks.push({
                jobId: jobId.jobId,
                progress: 0,
                reportId: signedId,
                reportOptions: reportOptions,
                postCreateCallback: null,
            });

            if (that.reportJoblist.tasks.length === 1) {
                ml.UI.Progress.Init("Downloading Document");
                that.reportJoblist.allGood = true;
                that.reportJoblist.doneBatch = 0;
                clearTimeout(that.reportProc);
                that.reportProc = window.setTimeout(function () {
                    that.waitForReports(0);
                }, 500);
            }
        });
    }
}
