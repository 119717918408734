import { XCPostExecuteXtc } from "../../RestCalls";
import {
    IItemControlOptions,
    ItemControl,
    IUIMap,
    IProjectPanelControlOptions,
    SelectMode,
} from "../UI/Components/index";
import { IDB, DBCache } from "./DBCache";
import { XRFieldTypeAnnotated } from "./ItemConfiguration";
import { RestDB } from "./RestDB";
import { ml } from "./../matrixlib";
import { refLinkStyle, refLinkTooltip } from "../UI/Parts/RefLinkDefines";
import { ItemCreationTools } from "../UI/Tools/ItemCreationView";
import { ITestRule, ITestConfigTablesColumns } from "../../ProjectSettings";
import { XRGetProject_Needle_TrimNeedle, XRPostProject_ExecuteXtc_FolderAnswer } from "../../RestResult";
import {
    IItem,
    IReference,
    IGenericMap,
    matrixSession,
    app,
    INumberStringMap,
    globalMatrix,
    IControlDefinition,
    ControlState,
    restConnection,
} from "../../globals";
import { FieldDescriptions } from "./FieldDescriptions";
import {
    ITestStepsResultsConfig,
    ITestStepsResultOption,
    TestManagerConfiguration,
    ITestFieldParam,
} from "./TestManagerConfiguration";
import { NavigationPanel } from "../UI/MainTree/MainTree";

export type {
    ITestWizardParams,
    ITestWizardParamsPreset,
    IFieldMapping,
    ITestStepsResult,
    ITestResultInfo,
    ITestRuleResult,
};
export { TestManager, mTM };
export { InitializeTestManager };

interface ITestWizardParams {
    single?: number;
    input?: string[];
    output?: string;
    parentFolder?: string;
    filter?: string[];
    itemPresets?: ITestWizardParamsPreset[];
    itemFieldMapping?: IFieldMapping[];
    reason?: string;
}
interface ITestWizardParamsPreset {
    field: number;
    value: string;
}
interface IFieldMapping {
    fromId: number;
    toId: number;
}
interface ITestStepsResult {
    result: string;
}
interface ITestResultInfo {
    automatic: boolean;
    label: string;
}

interface ITestRuleResult extends ITestRule {
    result: string; // the actial result  coce
}

class TestManager {
    // configuration for per project
    private testConfig: TestManagerConfiguration;

    private lookup: IGenericMap;

    public isDefault = true;

    constructor() {
        this.testConfig = new TestManagerConfiguration();
    }

    getConfiguration(): TestManagerConfiguration {
        return this.testConfig;
    }

    public UpdateFolderMenu(ul: JQuery, item: IItem) {
        let that = this;

        if (!matrixSession.isEditor()) {
            return;
        }

        if (!item || app.isFolder(item.id)) {
            if (this.testConfig.XTCconfig && this.testConfig.XTCconfig.reExecute && this.isXTC(item.type)) {
                var miReExecute = $('<li><a href="javascript:void(0)" id="toolXtcRedo">Redo failed tests</a></li>')
                    .click(function () {
                        that.redoFailed(item);
                    })
                    .appendTo(ul);
            }
            return;
        }

        if (this.testConfig.isCloneSource(item.type)) {
            $('<li><a href="javascript:void(0)" id="toolXtc">Prepare for test run</a></li>')
                .click(function () {
                    app.canNavigateAwayAsync()
                        .done(function () {
                            that.ConvertAll(null, item.id);
                        })
                        .fail(function () {});
                })
                .appendTo(ul);
        }
    }

    public InitializeProject() {
        this.testConfig.initialize(globalMatrix.ItemConfig);
    }

    // this function is executed before an executed test case is saved.
    // it allows set the test result ("test_result" field) from test steps results
    // in the test table ("test_steps_result" field) if it is set to automatic
    public async PreSaveHook(isItem: boolean, item: IItem, type: string, controls: IControlDefinition[]) {
        if (
            isItem &&
            type === this.testConfig.XTCconfig.xtcType &&
            this.testConfig.XTCconfig.automatic &&
            this.testConfig.XTCconfig.automatic.length > 0
        ) {
            await this.computeOverallResult(controls);
        }
        this.createHumanValues(item, controls);
    }

    public RenderActionButtons(options: IItemControlOptions, body: JQuery) {
        let that = this;

        if (!this.isXTC(options.type)) {
            return false;
        }

        if (!options.isItem && options.item && options.controlState === ControlState.FormEdit) {
            // render controls for a folder

            body.append($("<span class='baseControlHelp'>Tools</span>"));
            var folderEdit = $("<div class='hidden-print baseControl'></div>");
            body.append(folderEdit);
            var createTools = new ItemCreationTools();
            createTools.renderButtons({
                parent: options.item.id,
                dontOpenNewItem: false,
                control: folderEdit,
                linkTypes: [{ type: options.item.type, name: "Test Run Folder", folder: true }],
            });

            var testCreateUI = $("<div  class='hidden-print'></div>");
            body.append(testCreateUI);
            testCreateUI.append("<hr/>");
            testCreateUI.append('<span class="baseControlHelp">Select Test and Use Cases to Run</span>');
            testCreateUI.append("<br/>");
            testCreateUI.append("<br/>");
            const _createTestRun = $(
                '<button class="buttonCreateSelect btn btn-success sel_create_TestForms">Create <b>Test Forms</b></button>',
            ).click(() => {
                this.ConvertAll(options.item.id);
            });
            testCreateUI.append(_createTestRun);
        }

        return true;
    }

    // **********************
    // public functions
    // **********************

    // check if the category is an executed test case (usually this will mean category type XTC)
    isXTC(type: string): boolean {
        return this.testConfig.isXTC(type);
    }

    // check if this is a test case, use case, or something else which can be converted to a TC
    isTC(type: string): boolean {
        return this.testConfig.isTC(type);
    }

    // return the executed test case category type, (usually this will mean type XTC)
    getXTCType() {
        return this.testConfig.getXTCType();
    }
    // return a simple array of all categories which can be converted to XTC
    getCloneSources() {
        return this.testConfig.getCloneSources();
    }

    private redoFailed(folderItem: IItem) {
        let that = this;
        const fromFolder = folderItem.id;
        // get all failed tests
        app.getNeedlesAsync(that.testConfig.XTCconfig.reExecute, true, false, "", false)
            .done((needles) => {
                let tcs: string[] = [];

                // get all tests in selected folder
                let xtcs = app.getChildrenIdsRec(fromFolder);

                $.each(needles, function (idx, needle) {
                    // for all failed tests in selected folder
                    let xtcId = needle.id;
                    if (xtcs.indexOf(xtcId) != -1) {
                        $.each(needle.upLinkList, function (uidx, ul) {
                            // get all uplinks which are tests - if there are more than one only use the one in the title
                            // this can happen if a TC includes others
                            let ulItem = ml.Item.parseRef(ul.itemRef);
                            if (
                                that.testConfig.isCloneSource(ulItem.type) &&
                                (needle.upLinkList.length == 1 || needle.title.indexOf("(" + ulItem.id + ")") != -1) &&
                                tcs.indexOf(ulItem.id) == -1
                            ) {
                                tcs.push(ulItem.id);
                            }
                        });
                    }
                });

                if (tcs.length == 0) {
                    ml.UI.showSuccess("All tests passed in this folder");
                } else {
                    that.ConvertAll("F-" + folderItem.type + "-1", null, tcs);
                }
            })
            .fail(function () {});
    }
    // target folder is a folder or an item (TC) which should be converted to XTC
    // if sourceTC is set to a TC it is executed without letting the user choose a TC in the dialog and no parent folder is created

    private ConvertAll(targetFolderId: string, sourceTC?: string, preselectTC?: string[]) {
        let that = this;

        let okButton: JQuery;
        let exeParams: ITestWizardParams = {};
        let tree: JQuery;
        let wizardStep = 0;
        let niceSize = ml.UI.getNiceDialogSize(730, 480);

        // decide which fields to map from use cases and test cases to XTC
        this.prepareMapping();

        // prepare dialog (wizard step 1)
        ml.Search.searchInDialog();

        // reading values from step one and preparation of step 2
        async function convertAllNext() {
            // change wizard button, start phase 2

            var hasUserChoice = false;
            wizardStep++;
            okButton.html('<span class="ui-button-text">Finish</span>');

            // prepare parameter
            exeParams = {};

            if (sourceTC) {
                exeParams.single = 1;
                exeParams.input = [sourceTC];
                exeParams.output = that.testConfig.XTCconfig.xtcType;
                exeParams.parentFolder = "F-" + that.testConfig.XTCconfig.xtcType + "-1"; // root folder of category
            } else {
                // get values step 1, clean ui
                var newSelection = <IReference[]>await tree.getController().getValueAsync();
                var ul = $("<ul>");
                var inputItems: string[] = [];
                for (var idx = 0; idx < newSelection.length; idx++) {
                    inputItems.push(newSelection[idx].to);
                    let link = $("<div>").refLink({
                        folder: false,
                        id: newSelection[idx].to,
                        title: newSelection[idx].title,
                        style: refLinkStyle.link,
                        tooltip: refLinkTooltip.html,
                        callback: function () {},
                    });

                    ul.append($("<li>").append(link));
                }
                $(".fancytree-icontext", ul).remove();
                exeParams.input = inputItems;
                exeParams.output = that.testConfig.XTCconfig.xtcType;
                exeParams.parentFolder =
                    preselectTC && preselectTC.length > 0
                        ? "F-" + that.testConfig.XTCconfig.xtcType + "-1"
                        : targetFolderId;
                tree.hide();
            }

            // prepare step 2 UI
            app.dlgForm.addClass("dlg-v-scroll").removeClass("dlg-no-scroll");
            var scrollPanel = $('<div class="layoutContainerScroll"></div>');
            app.dlgForm.append(scrollPanel);
            // selected items
            if (!sourceTC) {
                let ulContainer = $(
                    '<div class="controlContainer" ><span class="baseControlHelp">Items to convert</span></div>',
                );
                ulContainer.append(ul);

                scrollPanel.append(ulContainer);

                // filter options
                var relevant_labels = ml.LabelTools.getLabelDefinitions(that.testConfig.XTCconfig.cloneSources); // labels of UC, TC, ...

                if (relevant_labels.length > 0) {
                    hasUserChoice = true;
                    // filtering execution by label
                    var filters = ml.LabelTools.getFilter().split(","); // currently selected filter
                    exeParams.filter = [];
                    let labelContainer = $(
                        '<div class="controlContainer" ><span class="baseControlHelp">Convert only items with all of these labels</span></div>',
                    );

                    scrollPanel.append(labelContainer);
                    var useFilters: IUIMap = {};
                    $.each(relevant_labels, function (index, rl) {
                        var set = filters.indexOf(rl.label) > -1;
                        if (set) {
                            exeParams.filter.push(rl.label);
                        }
                        var useFilter = $("<div>");
                        var name = rl.reportName;
                        if (rl.style && rl.style.filter && rl.style.filter.on && rl.style.filter.on.displayName) {
                            name = rl.style.filter.on.displayName;
                        }
                        useFilter.checkBox({
                            controlState: ControlState.FormEdit,
                            canEdit: true,
                            fieldValue: set ? "true" : "false",
                            help: name,
                            valueChanged: async () => {
                                exeParams.filter = [];
                                for (let ufid of Object.keys(useFilters)) {
                                    let uf = useFilters[ufid];
                                    if ((await uf.getController().getValueAsync()) === true) {
                                        exeParams.filter.push(ufid);
                                    }
                                }
                            },
                        });

                        // make list more compact, but skipping the last element
                        if (index !== relevant_labels.length - 1) {
                            useFilter.css("margin-bottom", "-8px");
                        }

                        useFilters[rl.label] = useFilter;
                        labelContainer.append(useFilter);
                    });
                }
            }

            var fieldList = globalMatrix.ItemConfig.getItemConfiguration(that.testConfig.XTCconfig.xtcType).fieldList;
            // preset field options
            exeParams.itemPresets = [];
            var defaultTestResult = that.testConfig.XTCconfig.defaultTestResultResult
                ? that.testConfig.XTCconfig.defaultTestResultResult
                : "";
            var testResultFieldId = 0;

            for (var tfi = 0; tfi < fieldList.length; tfi++) {
                if (fieldList[tfi].fieldType === FieldDescriptions.Field_test_result) {
                    testResultFieldId = fieldList[tfi].id;
                }
            }
            if (testResultFieldId && defaultTestResult) {
                exeParams.itemPresets.push({ field: testResultFieldId, value: defaultTestResult });
            }

            var presetFields: number[] = [];
            if (that.testConfig.XTCconfig.presetFields) {
                var inputCtrls = $("<div>");
                var ctrl: ItemControl;
                ctrl = new ItemControl({
                    control: inputCtrls,
                    controlState: ControlState.DialogCreate,
                    parent: "",
                    type: that.testConfig.XTCconfig.xtcType,
                    isItem: true,
                    changed: async function () {
                        if (ctrl) {
                            var val = await ctrl.getValues({});
                            // reset defaults
                            exeParams.itemPresets = [];
                            // set default result
                            if (testResultFieldId && defaultTestResult) {
                                exeParams.itemPresets.push({ field: testResultFieldId, value: defaultTestResult });
                            }
                            // set defaults of entered values
                            $.each(presetFields, function (pfidx, pf) {
                                exeParams.itemPresets.push({ field: pf, value: (<IGenericMap>val)[pf] });
                            });
                        }
                    },
                });
                await ctrl.load();
                scrollPanel.append(inputCtrls);
                $(".panel-body-v-scroll", inputCtrls).removeClass("panel-body-v-scroll");

                // hide all ctrls by default
                hasUserChoice = showOnlyWantedFields(ctrl, inputCtrls, fieldList, presetFields);
            }
            return hasUserChoice;
        }

        function showOnlyWantedFields(
            ctrl: ItemControl,
            inputCtrls: JQuery,
            fieldList: XRFieldTypeAnnotated[],
            presetFields: number[],
        ) {
            let hasUserChoice = false;

            $(".itemTitleBarNoTools", ctrl).hide();
            $(".baseControlHelp", inputCtrls).parent().hide();

            $.each(ctrl.getControls(), function (cidx, c) {
                c.getController().disableDelayedShow = true;
            });

            $.each(fieldList, function (tfi, tf) {
                $.each(that.testConfig.XTCconfig.presetFields, function (csi, preset) {
                    var preset = that.testConfig.XTCconfig.presetFields[csi];

                    if (
                        (preset.field.toLowerCase() === "name" &&
                            tf.label.toLowerCase() === preset.value.toLowerCase()) ||
                        (preset.field.toLowerCase() === "meaning" &&
                            tf.parameterJson &&
                            (<ITestFieldParam>tf.parameterJson).fieldMeaning &&
                            (<ITestFieldParam>tf.parameterJson).fieldMeaning.toLowerCase() ===
                                preset.value.toLowerCase())
                    ) {
                        $.each($(".baseControlHelp", inputCtrls), function (bchidx, bch) {
                            if (
                                $(bch).html().toLowerCase() === tf.label.toLowerCase() ||
                                (bch.firstChild &&
                                    bch.firstChild.textContent &&
                                    bch.firstChild.textContent.toLowerCase() == tf.label.toLowerCase())
                            ) {
                                $(bch).parent().show();
                                presetFields.push(tf.id);
                                hasUserChoice = true;
                            }
                        });
                    }
                });
            });
            return hasUserChoice;
        }
        function convertFinish() {
            exeParams.itemFieldMapping = that.getMappingItems();

            matrixSession.getCommentAsync().done(function (comment) {
                exeParams.reason = comment;

                ml.UI.setEnabled($("button", app.dlgForm.parent()), false);

                restConnection
                    .postProject("execute", <XCPostExecuteXtc>(<any>exeParams), true)
                    .done(function (newXTCResponse) {
                        const newXTC = newXTCResponse as XRPostProject_ExecuteXtc_FolderAnswer;

                        if (newXTC.xtcInError && newXTC.xtcInError.length > 0) {
                            let issues = "";
                            $.each(newXTC.xtcInError, function (eidx, err) {
                                issues += `<b>${err.key}</b><br><ul style="text-align:left">`;
                                $.each(err.errors, function (edidx, errd) {
                                    issues += `<li>${errd}</li>`;
                                });
                                issues += "</ul>";
                            });
                            if (newXTC.folder != " -- ")
                                ml.UI.showAck(-1, issues, "Warning: issues during XTC generation");
                        }
                        if (newXTC.folder == " -- ") {
                            // after removing all the ugly things nothing left to create
                            ml.UI.showError(
                                "No XTCs were created",
                                "None of the selected tests had all the selected labels, therefore no XTCs have been created",
                            );

                            app.dlgForm.html("");
                            app.dlgForm.dialog("close");
                            return;
                        }
                        restConnection.getProject("tree?fancy").done(async function (response) {
                            var pdb = new DBCache();
                            const result: IDB[] = RestDB.filterLegacyReportCat(response as IDB[]);
                            await pdb.initMatrixTree(result, false);
                            if (exeParams.single) {
                                // find new items in folder and copy them into F-XTC-1
                                var current = app.getChildrenIds(newXTC.folder);
                                var newItem = "";
                                $.each(pdb.getChildrenIds(newXTC.folder), function (cidx, cid) {
                                    if (current.indexOf(cid) === -1) {
                                        app.copyFrom(exeParams.parentFolder, pdb.getItemFromCache(cid));
                                        newItem = cid;
                                    }
                                });
                                app.canNavigateAwayAsync()
                                    .done(function () {
                                        app.treeSelectionChangeAsync(newItem);
                                    })
                                    .fail(function () {});
                            } else {
                                // copy the new tree in the folder
                                var newFolder = pdb.getItemFromCache(newXTC.folder);
                                // insert stuff in folder
                                app.copyFrom(exeParams.parentFolder, newFolder);
                                // show folder if you can navigate away...
                                app.canNavigateAwayAsync()
                                    .done(function () {
                                        app.treeSelectionChangeAsync(newXTC.folder);
                                    })
                                    .fail(function () {});
                            }
                        });
                        ml.UI.showSuccess("Test " + (sourceTC ? "form was" : "forms were") + " generated");

                        ml.UI.setEnabled($("button", app.dlgForm.parent()), true);
                        app.dlgForm.html("");
                        app.dlgForm.dialog("close");
                    })
                    .fail(function (jqxhr, textStatus, error) {
                        ml.UI.showError("Error", "Status:" + textStatus + "<br/>Error was:" + error);
                        ml.UI.setEnabled($("button", app.dlgForm.parent()), true);
                        app.dlgForm.html("");
                        app.dlgForm.dialog("close");
                    });
            });
        }
        // show dialog
        function updateOkButton(enabled: boolean) {
            if (okButton) {
                okButton.prop("disabled", !enabled);
                if (enabled) {
                    okButton.removeClass("ui-state-disabled");
                } else {
                    okButton.addClass("ui-state-disabled");
                }
            }
        }

        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-v-scroll");
        app.dlgForm.addClass("dlg-no-scroll");

        if (!sourceTC) {
            let treeConfig = <IProjectPanelControlOptions>{
                tree: app.getTree(this.testConfig.XTCconfig.cloneSources),
                controlState: ControlState.DialogCreate,
                canSelectItems: true,
                selectMode: SelectMode.auto,
                collectionChanged: function (count) {
                    updateOkButton(count > 0);
                },
            };
            if (preselectTC) {
                treeConfig.selectedItems = preselectTC.map(function (tc) {
                    return { to: tc, title: "" };
                });
            }
            tree = $("<div style='display: flex;flex-direction: column;max-height: 100%;'>").projectView(treeConfig);

            app.dlgForm.append(tree);
        }

        app.dlgForm
            .dialog({
                autoOpen: true,
                title: "Create Test Form" + (sourceTC ? "" : "s"),
                height: niceSize.height,
                width: niceSize.width,
                modal: true,
                close: function () {
                    app.dlgForm.parent().html("");
                    app.dlgForm.css("display", "none");
                    // dlg is gone, remove highlights and back to global highlighting
                    ml.Search.endSearchInDialog();
                    NavigationPanel.focusTree();
                },
                resizeStop: function () {
                    app.dlgForm.resizeDlgContent([tree]);
                },
                open: function () {
                    okButton = $(".ui-dialog-buttonpane button:contains('Next')", app.dlgForm.parent());

                    if (sourceTC) {
                        // skip step 0;
                        var hasUserInput = convertAllNext();
                        updateOkButton(true);
                        if (!hasUserInput) {
                            // no need to wait  / ask the user for more
                            convertFinish();
                        }
                    } else {
                        // wait for user to select item (unless something was from pre-selection)
                        updateOkButton(preselectTC && preselectTC.length > 0);
                    }
                },
                buttons: [
                    {
                        text: "Next",
                        class: "btnDoIt",
                        click: function () {
                            if (wizardStep === 0) {
                                var hasUserInput = convertAllNext();
                                updateOkButton(true);
                                if (!hasUserInput) {
                                    // no need to wait / ask the user for more
                                    convertFinish();
                                }
                            } else {
                                convertFinish();
                            }
                        },
                    },
                    {
                        text: "Cancel",
                        class: "btnCancelIt",
                        click: function () {
                            matrixSession.stopCommitTransaction();
                            app.dlgForm.dialog("close");
                        },
                    },
                ],
            })
            .resizeDlgContent(tree ? [tree] : [], false);
    }

    // get the configuration for a test definition table (e.g. test actions and expected results)
    public getTestStepsConfig(category: string): ITestConfigTablesColumns {
        return this.testConfig.getTestStepsConfig(category);
    }

    // get definition for a test execution table (e.g. including columns with results/comments)
    public getTestStepsResultsConfig(): ITestStepsResultsConfig {
        return this.testConfig.getTestStepsResultsConfig();
    }

    // returns possible values for the drop down of the completed test case
    public getTestRunResultOptions(): ITestStepsResultOption[] {
        return this.testConfig.getTestRunResultOptions();
    }

    // returns place holder text if no value is selected
    public getTestRunResultPlaceholder(value: string): string {
        return this.testConfig.getTestRunResultPlaceholder(value);
    }

    // creates a search expression needed to find XTCs with state new,progress,ok,error,warning
    getSearchExpression(resultType: string, notEqual: boolean): string {
        let fields = globalMatrix.ItemConfig.getFieldsOfType("test_result");
        let compare = notEqual ? "!=" : "=";

        if (fields.length != 1) {
            return ""; // there needs to be exactly one XTC type with on test result field
        }
        // test result field with quotes around
        let fieldName = '"' + fields[0].field.label + '"';

        // all searches are "fieldname"="expression" or "fieldname"!="expression" -> sb + expression + sa
        let sb = fieldName + compare + '"';
        let sa = '"';

        let opts: string[] = [];
        // collect different possible results

        if (resultType == "new") {
            opts.push(sb + this.testConfig.XTCconfig.defaultTestResultResult + sa);
            opts.push(sb + "r" + sa); // r = special code: reset -> not executed not started
        } else if (resultType == "progress") {
            if (this.testConfig.XTCconfig.automatic.length) {
                // by default the last means automatic  means, undecided = in progress
                let autoProgress = this.testConfig.XTCconfig.automatic[this.testConfig.XTCconfig.automatic.length - 1];
                opts.push(sb + autoProgress.code + "|" + autoProgress.render + "|" + autoProgress.human + sa);
            }
            opts.push(sb + "i" + sa); // i = special code: in progress
        } else {
            for (let result of this.testConfig.XTCconfig.automatic) {
                if (result.render == resultType) {
                    opts.push(sb + result.code + "|" + result.render + "|" + result.human + sa);
                }
            }
            for (let result of this.testConfig.XTCconfig.manual) {
                if (result.render == resultType) {
                    opts.push(sb + result.code + sa);
                }
            }
        }
        // combine with and or or
        return " (" + (notEqual ? opts.join(" and ") : opts.join(" or ")) + ") ";
    }
    // **********************
    // helper functions to convert test case to executed test cases
    // this could happen on the server

    // create an automatic mapping of properties to be copied
    // the mapping is done
    // - if the items fields have the same label (e.g. Description to Description)
    // - from field_type "test_steps" to "test_steps_result"
    // - NOT from JIRA and maybe other items

    private prepareMapping(): IGenericMap {
        var fieldList = globalMatrix.ItemConfig.getItemConfiguration(this.testConfig.XTCconfig.xtcType).fieldList;
        this.lookup = {};

        for (var csi = 0; csi < this.testConfig.XTCconfig.cloneSources.length; csi++) {
            var cs = globalMatrix.ItemConfig.getItemConfiguration(
                this.testConfig.XTCconfig.cloneSources[csi],
            ).fieldList;
            for (var csfi = 0; csfi < cs.length; csfi++) {
                var csf = cs[csfi];
                for (var tfi = 0; tfi < fieldList.length; tfi++) {
                    var tf = fieldList[tfi];
                    if (tf.label.toLowerCase() !== "jira") {
                        if (
                            (tf.fieldType === FieldDescriptions.Field_test_steps_result &&
                                csf.fieldType === FieldDescriptions.Field_test_steps) ||
                            tf.label === csf.label
                        ) {
                            this.lookup[csf.id] = tf.id;
                        }
                    }
                }
            }
        }
        return this.lookup;
    }
    public getMappingItems(): IFieldMapping[] {
        this.prepareMapping();
        var mapping: IFieldMapping[] = [];
        $.each(this.lookup, function (lidx, l) {
            if (l) {
                mapping.push({ fromId: lidx, toId: l });
            }
        });
        return mapping;
    }

    // **********************
    // helper functions to compute the values of the overall "test_result" field from
    // in the test table ("test_steps_result" field)
    // **********************

    private getResultInfo(val: string): ITestResultInfo {
        if (val === "a") {
            return { automatic: true, label: "automatic" }; // automatic never changed
        }
        for (var idx = 0; idx < this.testConfig.XTCconfig.automatic.length; idx++) {
            if (this.testConfig.XTCconfig.automatic[idx].code === val) {
                return { automatic: true, label: this.testConfig.XTCconfig.automatic[idx].human };
            }
        }
        for (var idx = 0; idx < this.testConfig.XTCconfig.manual.length; idx++) {
            if (this.testConfig.XTCconfig.manual[idx].code === val) {
                return { automatic: false, label: this.testConfig.XTCconfig.manual[idx].human };
            }
        }
        return { automatic: false, label: "please select result" };
    }
    private async allTestSteps(controls: IControlDefinition[], code: string) {
        for (var idx = 0; idx < controls.length; idx++) {
            if (controls[idx].fieldType === FieldDescriptions.Field_test_steps_result) {
                var val = await (controls[idx].control as any).getController().getValueAsync();
                var v: ITestStepsResult[] = JSON.parse(val);
                for (var step = 0; step < v.length; step++) {
                    if (v[step].result !== code) {
                        return false;
                    }
                }
            }
        }
        return true;
    }
    private async oneTestStep(controls: IControlDefinition[], code: string) {
        for (var idx = 0; idx < controls.length; idx++) {
            if (controls[idx].fieldType === FieldDescriptions.Field_test_steps_result) {
                var val = await (controls[idx].control as any).getController().getValueAsync();
                var v: ITestStepsResult[] = JSON.parse(val);
                for (var step = 0; step < v.length; step++) {
                    if (v[step].result === code) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private async computeOverallResult(controls: IControlDefinition[]) {
        // this should only happen if the value is not set to a manual
        for (var cidx = 0; cidx < controls.length; cidx++) {
            if (controls[cidx].fieldType === FieldDescriptions.Field_test_result) {
                var resultInfo = this.getResultInfo((<any>controls[cidx].control).getController().getValueRaw());
                if (resultInfo.automatic) {
                    // apply rules to find a value
                    for (var r = 0; r < this.testConfig.XTCconfig.automatic.length; r++) {
                        var rule = this.testConfig.XTCconfig.automatic[r];
                        // rule: {human:"not started", code:"an", render:"warning", rule:"all", param:""},
                        // no specifc rule... default
                        if (rule.rule === "") {
                            (<any>controls[cidx].control).getController().setValue(rule.code, true);
                            return;
                        }
                        // all: check if it applies: all elements need to be set to a value
                        if (rule.rule === "all" && (await this.allTestSteps(controls, rule.param))) {
                            (<any>controls[cidx].control).getController().setValue(rule.code, true);
                            return;
                        }
                        // one: check if it applies: at least one need to be set to a value
                        if (rule.rule === "one" && (await this.oneTestStep(controls, rule.param))) {
                            (<any>controls[cidx].control).getController().setValue(rule.code, true);
                            return;
                        }
                    }
                }
            }
        }
    }

    // create for each test step a column human which has the test result as human readable string
    private async createHumanValues(item: IItem, controls: IControlDefinition[]) {
        for (var ci = 0; ci < controls.length; ci++) {
            if (controls[ci].fieldType === FieldDescriptions.Field_test_steps_result) {
                var val = await (<any>controls[ci].control).getController().getValueAsync();
                var tsr: ITestRuleResult[] = JSON.parse(val);
                for (var step = 0; step < tsr.length; step++) {
                    for (var idx = 0; idx < this.testConfig.XTCconfig.perStep.length; idx++) {
                        if (this.testConfig.XTCconfig.perStep[idx].code === tsr[step].result) {
                            tsr[step].human = this.testConfig.XTCconfig.perStep[idx].human;
                            tsr[step].render = this.testConfig.XTCconfig.perStep[idx].render;
                        }
                    }
                }
                (<any>controls[ci].control).getController().setValue(JSON.stringify(tsr));
            } else if (controls[ci].fieldType === FieldDescriptions.Field_test_result) {
                var tr: string = (<any>await controls[ci].control).getController().getValueAsync();
                for (var idx = 0; idx < this.testConfig.XTCconfig.automatic.length; idx++) {
                    if (this.testConfig.XTCconfig.automatic[idx].code === tr) {
                        (<any>controls[ci].control)
                            .getController()
                            .setValue(
                                tr +
                                    "|" +
                                    this.testConfig.XTCconfig.automatic[idx].render +
                                    "|" +
                                    this.testConfig.XTCconfig.automatic[idx].human,
                                true,
                            );
                    }
                }
                for (var idx = 0; idx < this.testConfig.XTCconfig.manual.length; idx++) {
                    if (this.testConfig.XTCconfig.manual[idx].code === tr) {
                        (<any>controls[ci].control)
                            .getController()
                            .setValue(
                                tr +
                                    "|" +
                                    this.testConfig.XTCconfig.manual[idx].render +
                                    "|" +
                                    this.testConfig.XTCconfig.manual[idx].human,
                            );
                    }
                }
            } else if (
                this.testConfig.XTCconfig.autoFillTester &&
                controls[ci].fieldType === FieldDescriptions.Field_user
            ) {
                // set the test to the first user saving it
                let tester: string = await (<any>controls[ci].control).getController().getValueAsync();
                let config = globalMatrix.ItemConfig.getFieldById("XTC", controls[ci].fieldId);
                if (config && config.parameterJson && config.parameterJson.fieldMeaning == "tester") {
                    if (
                        (this.testConfig.XTCconfig.autoFillTester == "first" &&
                            item.history.length == 1 && // first version
                            (tester == (<INumberStringMap>item)[config.id] || //  and the field is still the preset (from the creation)
                                !tester)) || // or the field is still empty
                        this.testConfig.XTCconfig.autoFillTester == "last"
                    ) {
                        // last
                        controls[ci].control.getController().setValue(matrixSession.getUser());
                    }
                }
            }
        }
    }
}

var mTM: TestManager;

function InitializeTestManager() {
    mTM = new TestManager();
}
