import { app, globalMatrix, matrixSession } from "../../../globals";
import { ml } from "../../matrixlib";
import { IPanel } from "../Application";
import { HistoryTools } from "../Tools/ItemHistoryView";

export { DeletedItemPanel };

class DeletedItemPanel implements IPanel {
    title = "Deleted Items";

    private delayedFilter: number;
    private filterDeletedItems() {
        window.clearTimeout(this.delayedFilter);
        this.delayedFilter = window.setTimeout(() => {
            $(".panel:has(.history-panel)", $(".panel-body-v-scroll")).show();
            $("#hiddenItemsCount").remove();
            let value = $("#calendarFilter").val().toLowerCase();
            if (!value || value.length == 1) {
                ml.Search.hideHighlight();
                return;
            }
            // there are at least 2 letter in filter box
            ml.Search.highlight(value);
            let count = 0;
            $(".panel:has(.history-panel)", $(".panel-body-v-scroll")).each((i, p) => {
                let elem = $(p);
                if (elem.text().toLowerCase().indexOf(value) == -1) {
                    elem.hide();
                    count++;
                }
            });
            if (count > 0) {
                $(
                    `<div id="hiddenItemsCount" style="margin-right:10px;display:block;text-align:right"><small>${count} hidden deleted items</small></div>`,
                ).insertBefore(".history-panel-group");
            }
        }, 200);
    }

    private addFilter() {
        let that = this;
        var filter = $(
            `<input type="text" id="calendarFilter" style="width:20vw; float:right" placeholder="filter..." class="doNotCopy  form-control"/>`,
        );
        $(".toolbarButtons").append($("<div/>").append(filter));
        filter.keyup(() => {
            that.filterDeletedItems();
        });
    }
    constructor() {
        document.title = this.title + " - " + matrixSession.getProject();
        var ht = new HistoryTools();
        ht.showDeletedItems(app.itemForm);
        this.addFilter();
    }

    destroy() {}
}
