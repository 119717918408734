import { XRMergeAction, XRMergeItem } from "../../RestResult";
import { ILink } from "../../globals";
import { ml } from "../matrixlib";

export type {
    IBranchInfo,
    IFieldMergeMapping,
    IMergeResults,
    IMergeDetails,
    IMergeLookup,
    ILastMerges,
    ILastMerge,
    IMove,
    IMergeCommand,
    IMergeCommandTarget,
    IImportMergeItem,
};

export { EMergeChangeStatus, EItemChangeState, EMergeActionChoice, EMergeType, BranchingConstants, BranchingHelper };

class BranchingConstants {
    static BRANCH_INFO_SETTING = "branch_info_02";
}

/**this is the information about a branch (including the merge history) */
interface IBranchInfo {
    /** tag set in mainline and branch just after the creation of the branch */
    branchTag: string;
    /** UTC date / time when the branch was created */
    branchDate: string;
    /** if of the mainline project */
    sourceProject: string;
    /** mapping of fields between mainline and branch */
    fieldMapping: IFieldMergeMapping[];
    /** allows you to not merge some categories, if not set it defaults to ["REPORT", FOLDER"] */
    dontMerge?: string[];
    /** these labels are set in new /updated items in mainline (if they exist in the given category) */
    setLabels?: string[];
    /** these labels are reset in new /updated items in mainline (if they exist in the given category) */
    resetLabels?: string[];
    /**  these labels are not copied in case they changed in the branch */
    ignoreLabels?: string[];
    /**  these labels are set (next to set labels) if there was a conflict and the user decided to mark the conflicts */
    conflictLabels?: string[];
    /** user ids of user who can merge branch back into main */
    branchMasters?: string[];
}

// this indicated how items changed between the last sync point and now
enum EMergeChangeStatus {
    deleted = 0, // item was deleted since last merge/branching off
    created = 1, // item was created (or restored) since last merge/branching off
    changed = 2, // item was changed since last merge/branching off
    unchanged = 3, // item was not changed since last merge/branching off
    notExist = 4, // item did not and is not existing nor and at last merge/branching off
}
// mapping between fields ids of identical field - will be created during creation of branch
interface IFieldMergeMapping {
    /** field id in mainline project */
    mainline: number;
    /** field id of same field in branch project */
    branch: number;
}

// this is the result of one merge activity
interface IMergeResults {
    // meta info
    utcDate: string; // when the merge was performed
    user: string; // by whom
    comment: string; // optional comment
    tag: string; // auto? created tag

    // merge actions which related to two items are documented in here
    results: IMergeDetails[];
}

enum EItemChangeState {
    never = 0, // did not exist in past not now
    notNow = 1, // existed in past but not now
    now = 2, // exists now
}
// that's is the user decision on how to handle a situation
enum EMergeActionChoice {
    noActionNeeded = -1, // if there's no action needed
    undecided = 0, // if there's no default suggested action (this cannot be the final choice though!)
    ignore = 1, // don't merge a difference from the branch to the mainline
    add_restore = 2, // add an item from the branch to the mainline. there it might be a deleted (to be restored) or a new item (to be created with) with the same id
    replace = 3, // update the mainline item to match the branch
    delete = 4, // delete the mainline item to match the delete in the branch
}

// that is the result from a merge decision take by the user
interface IMergeDetails {
    id: string; // id of the item or folder
    mV: number; // revision mainline after merge (or 0 if it was deleted)
    bV: number; // revision branch  after merge (or 0 if it was deleted)
    a: EMergeActionChoice;
    np: string; // new parent id if an item was moved in the mainline, or "" otherwise
    l: string[]; // new links created
    u: string; // links removed
}

// lookup table to quickly get items by id
interface IMergeLookup {
    [key: string]: XRMergeItem;
}

// this is a setting of the mainline, used to record timing of merge actions from multiple branches
/*
{
  "WHEELY_LABELS_BRANCH":{
    "timeGetChanges":3,
    "timeGetLinks":1
  }
}
*/
interface ILastMerges {
    [key: string]: ILastMerge;
}
interface ILastMerge {
    [key: string]: number;
}
interface IMove {
    id: string;
    parent: string;
}

interface IMergeCommand {
    copy: string[]; // items to copy, restore, or create in mainline to match the branch -> if a specific version needs to exist it should be REQ-5-v4 instead of REQ-5 (meaning that REQ-5 does not exist)
    conflicted: string[]; // subset from copy, to indicate that there was a conflict. This allows to set additional labels
    delete: string[]; // items to delete
    add_links: ILink[]; // links to add
    remove_links: ILink[]; // links to remove
    move: IMove[]; // items to move
    push?: number; // if set to 1 it copes
}

interface IMergeCommandTarget {
    id: string;
    m: number; // mainline revision: 0 if in item in mainline is deleted or does not yet exist
}

interface IImportMergeItem {
    instance?: string; // in case it's in another instance
    project?: string; // in case it's in another project
    id: string;
    revision: number;
    description: string; // explain what the item is
}

enum EMergeType {
    undefined = 0,
    merge = 1,
    push = 2,
}

// Helper functions used by UI that expose branching.
class BranchingHelper {
    static addMergeDetail(ul: JQuery, detail: XRMergeAction, targetProject: string, isFromBranch: boolean) {
        if (detail.error) {
            if (detail.error.indexOf("doesn't exist") != -1) {
                $("<li>").appendTo(ul).html(`Not updated (strict mode) request was: ${detail.request}`);
            } else {
                $("<li>").appendTo(ul).html(`Error ${detail.action}: ${detail.error}`);
            }
            return;
        }

        let fromWhere = isFromBranch ? "branch" : "mainline";

        try {
            switch (detail.action) {
                case "created":
                    ul.append(
                        $("<li>").html(
                            `${detail.action} ${BranchingHelper.renderItemLink(
                                detail.mainlineItem,
                                targetProject,
                            )} based on ${fromWhere} revision ${ml.Item.parseRef(detail.branchItem).version}`,
                        ),
                    );
                    break;
                case "deleted":
                    ul.append(
                        $("<li>").html(
                            `${detail.action} ${BranchingHelper.renderItemLink(
                                detail.mainlineItem,
                                targetProject,
                            )} revision ${ml.Item.parseRef(detail.mainlineItem).version}`,
                        ),
                    );
                    break;
                case "moved":
                    ul.append(
                        $("<li>").html(
                            `${detail.action} ${BranchingHelper.renderItemLink(
                                detail.mainlineItem,
                                targetProject,
                            )} to ${BranchingHelper.renderItemLink(detail.mainlineFolder, targetProject)}`,
                        ),
                    );
                    break;
                case "merge_restore":
                    ul.append(
                        $("<li>").html(
                            `restored ${BranchingHelper.renderItemLink(detail.mainlineItem, targetProject)} revision ${
                                ml.Item.parseRef(detail.mainlineItem).version
                            } based on ${fromWhere} revision ${ml.Item.parseRef(detail.branchItem).version}`,
                        ),
                    );
                    break;
                case "merge_update":
                    ul.append(
                        $("<li>").html(
                            `updated ${BranchingHelper.renderItemLink(
                                detail.mainlineItem,
                                targetProject,
                            )} to revision ${
                                ml.Item.parseRef(detail.mainlineItem).version
                            } based on ${fromWhere} revision ${ml.Item.parseRef(detail.branchItem).version}`,
                        ),
                    );
                    break;
                case "link_added":
                    ul.append(
                        $("<li>").html(
                            `added link from ${BranchingHelper.renderItemLink(
                                detail.mainlineItem,
                                targetProject,
                            )} to  ${BranchingHelper.renderItemLink(
                                ml.Item.parseRef(detail.mainlineItem2).id,
                                targetProject,
                            )}`,
                        ),
                    );
                    break;
                case "link_removed":
                    ul.append(
                        $("<li>").html(
                            `removed link from ${BranchingHelper.renderItemLink(
                                detail.mainlineItem,
                                targetProject,
                            )} to  ${BranchingHelper.renderItemLink(
                                ml.Item.parseRef(detail.mainlineItem2).id,
                                targetProject,
                            )}`,
                        ),
                    );
                    break;
                default:
                    ul.append(
                        $("<li>").html(
                            `${detail.action} ${BranchingHelper.renderItemLink(
                                detail.mainlineItem,
                                targetProject,
                            )} revision ${
                                ml.Item.parseRef(detail.mainlineItem).version
                            } based on ${fromWhere} revision ${ml.Item.parseRef(detail.branchItem).version}`,
                        ),
                    );
                    break;
            }
        } catch (e) {
            console.log("Merge/Push Error");
            console.log(detail);
            $("<li>")
                .appendTo(ul)
                .html(`Request ${detail.request ? detail.request : ""}`);
        }
    }

    static renderItemLink(fullItem: string, otherProject?: string) {
        // this item is in the project the user is looking at
        if (!otherProject) return ml.Item.parseRef(fullItem).id;
        // or in the other project
        return "#" + otherProject + "/" + ml.Item.parseRef(fullItem).id + "#";
    }
}
