import { FieldDescriptions } from "../FieldDescriptions";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { IFieldHandler } from "./IFieldHandler";
export { CheckboxFieldHandler };

class CheckboxFieldHandler implements IFieldHandler {
    private data: string | undefined;
    private config: XRFieldTypeAnnotatedParamJson;

    constructor(configIn: XRFieldTypeAnnotatedParamJson) {
        this.config = configIn ?? {};
        this.data = undefined;
    }

    getFieldType(): string {
        return FieldDescriptions.Field_checkbox;
    }

    initData(serializedFieldData: string | undefined) {
        this.data = serializedFieldData;
        if (serializedFieldData === undefined) {
            if (this.config.initialContent !== undefined) {
                this.data = this.config.initialContent;
            }
        }
    }

    getData(): string | undefined {
        return this.data;
    }

    setData(value: string, doValidation?: boolean) {
        this.data = value;
    }

    getValue(): boolean | undefined {
        if (this.data) {
            if (this.data == "1" || this.data == "true") {
                return true;
            } else if (this.data == "0" || this.data == "false") {
                return false;
            }
        }
        return undefined;
    }

    setValue(value: boolean) {
        if (value !== undefined) {
            this.data = value ? "true" : "false";
        } else {
            this.data = undefined;
        }
    }
}
