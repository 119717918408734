// A field handler base implementation for field types that ultimately are displayed
// by the item selection control

import { IReference } from "../../../globals";
import { FieldDescriptions } from "../FieldDescriptions";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { IFieldHandler } from "./IFieldHandler";

export { ItemSelectionFieldHandler };

class ItemSelectionFieldHandler implements IFieldHandler {
    protected fieldType: string;
    protected data: string;
    protected config: XRFieldTypeAnnotatedParamJson;

    constructor(configIn: XRFieldTypeAnnotatedParamJson, fieldTypeIn?: string) {
        this.config = configIn;
        this.data = undefined;

        if (fieldTypeIn) {
            this.fieldType = fieldTypeIn;
        } else {
            // Field_links is a kind of default. This field handler is often used
            // in cases that don't map to an item field, just to a control used
            // to display a UI. In those cases, no one cares about the field type.
            this.fieldType = FieldDescriptions.Field_links;
        }
    }

    addSignatures(signatures: string[], includeAll: boolean) {
        //Nothing to do here
    }

    getFieldType(): string {
        return this.fieldType;
    }

    initData(serializedFieldData: string | undefined) {
        this.data = serializedFieldData;
    }

    getData(): string | undefined {
        return this.data;
    }

    setData(value: string, doValidation?: boolean) {
        this.data = value;
    }

    getItems(): IReference[] {
        if (this.data) {
            const array: IReference[] = JSON.parse(this.data);
            return array;
        }
        return [];
    }

    getItemCount(): number {
        return this.getItems().length;
    }

    hasItems(): boolean {
        return this.getItemCount() > 0;
    }

    hasItem(itemId: string, projectShortLabel?: string): boolean {
        for (let item of this.getItems()) {
            if (item.to == itemId && item.projectShortLabel == projectShortLabel) {
                return true;
            }
        }
        return false;
    }

    insertItem(position: number, item: IReference): ItemSelectionFieldHandler {
        if (this.hasItem(item.to, item.projectShortLabel)) {
            const label = item.projectShortLabel ? item.projectShortLabel + "/" : "";
            throw new Error(`Item ${label}${item.to} is already included`);
        }

        let items = this.getItems();
        items.splice(position, 0, item);
        this.data = JSON.stringify(items);
        return this;
    }

    appendItem(item: IReference): ItemSelectionFieldHandler {
        const position = this.getItemCount();
        return this.insertItem(position, item);
    }

    removeItem(position: number): ItemSelectionFieldHandler {
        let items = this.getItems();
        if (position < items.length) {
            items.splice(position, 1);
            this.data = JSON.stringify(items);
        }
        return this;
    }

    clear(): ItemSelectionFieldHandler {
        // Clear is actually a write operation. From unset/undefined we move to defined and empty.
        const items: IReference[] = [];
        this.data = JSON.stringify(items);
        return this;
    }

    setItems(selectedItems: IReference[]) {
        if (!selectedItems) {
            selectedItems = [];
        }
        this.data = selectedItems ? JSON.stringify(selectedItems) : undefined;
    }

    protected name = "";
    getFieldName(): string {
        return this.name;
    }

    setFieldName(value: string): void {
        this.name = value;
    }
}
