import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { GenericDocAbstractFieldHandler } from "./GenericDocAbstractFieldHandler";

export interface ISmartTextOptions extends IDHFSectionOptions {
    plaintext?: boolean;
    richtext?: boolean;
    abbreviations?: boolean;
    terms?: boolean;
    customerTags?: boolean;
    projectTags?: boolean;
    includeAll?: boolean;
    firstColumnName: string;
    secondColumnName: string;
}

export class SmartTextDocFieldHandler extends GenericDocAbstractFieldHandler {
    getDefaultConfig(): ISmartTextOptions {
        return {
            plaintext: false,
            richtext: false,
            abbreviations: true,
            terms: true,
            customerTags: true,
            projectTags: true,
            includeAll: false,
            firstColumnName: "",
            secondColumnName: "",
        };
    }

    getXmlValue(): string {
        let options: ISmartTextOptions = {
            abbreviations: this.dhfFieldConfig.abbreviations,
            terms: this.dhfFieldConfig.terms,
            richtext: this.dhfFieldConfig.richtext,
            plaintext: this.dhfFieldConfig.plaintext,
            includeAll: this.dhfFieldConfig.includeAll,
            customerTags: true,
            projectTags: true,
            firstColumnName: this.dhfFieldConfig.firstColumnName ? this.dhfFieldConfig.firstColumnName : "",
            secondColumnName: this.dhfFieldConfig.secondColumnName ? this.dhfFieldConfig.secondColumnName : "",
        };

        return JSON.stringify([options]);
    }
}
