/// <reference types="matrixrequirements-type-declarations" />;
import { IItem, ControlState, app, matrixSession, matrixApplicationUI, globalMatrix } from "../../../globals";
import { FieldHandlerFactory, IItemWatched, MR1 } from "../../businesslogic/index";

import { UIToolsConstants } from "../../matrixlib/MatrixLibInterfaces";
import { LabelTools } from "../../matrixlib/index";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { MarkAsTemplateImpl } from "./markAsTemplate";
import { ml } from "./../../matrixlib";
import { refLinkStyle, refLinkTooltip } from "../Parts/RefLinkDefines";
import { HistoryTools } from "../Tools/ItemHistoryView";
import { ReferenceTools } from "../Tools/ItemReferenceView";
import { mTM } from "../../businesslogic/index";
import { mDHF } from "../../businesslogic/index";
import { pluginHooks, plugins } from "../../businesslogic/index";
import { IPanel } from "../Application";
import { EImportMode } from "../../businesslogic/ComponentImport";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";

export type { ITitleToolbarOptions };
export { TitleToolbarImpl };

interface ITitleToolbarOptions extends IBaseControlOptions {
    parameter?: {
        readonly?: boolean; // can be set to overwrite the default readonly status
        placeholder?: string;
    };
    id?: string;
    title?: string;
    item?: IItem;
    canEditTitle?: boolean;
    canDelete?: boolean;
    locked?: string; // set to name of label, if item sis locked by used of label
    unlockers?: string; //  hint who can unlock it
    type?: string;
    validate?: boolean;
    noAutoActivation?: boolean;
}

$.fn.titleToolbar = function (this: JQuery, options: ITitleToolbarOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_dummy,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new TitleToolbarImpl(this, options.fieldHandler as EmptyFieldHandler);
    this.getController = () => {
        return baseControl;
    };

    baseControl.init(options);
    return this;
};

class TitleToolbarImpl extends BaseControl<EmptyFieldHandler> {
    private settings: ITitleToolbarOptions;
    private isInHiddenMode: boolean;
    private link: JQuery;
    private warnedAboutOutOfDate: boolean;
    private _refDlgIcon: JQuery;

    constructor(control: JQuery, fieldHandler: EmptyFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: ITitleToolbarOptions) {
        let that = this;

        this._refDlgIcon = null;
        var defaultOptions: ITitleToolbarOptions = {
            id: "", // identifier of item for which this control is shown
            title: "",
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dummy text (for form design...)
            valueChanged: function () {}, // callback to call if value changes
            canEdit: false, // whether data can be edited
            canEditTitle: false, // whether data can be edited (sometimes the item might 'wrongly marked as' readonly because it is a signed doc with signatures)
            canDelete: false, // whether item can be deleted
            validate: true, // default is true. validation requires non-empty string
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
                placeholder: "enter title",
            },
        };
        this.isInHiddenMode = false;
        this.settings = <ITitleToolbarOptions>ml.JSON.mergeOptions(defaultOptions, options);

        this.warnedAboutOutOfDate = false;

        var showTools =
            this.settings.controlState === ControlState.FormEdit ||
            this.settings.controlState === ControlState.FormView;

        var title = this.settings.title;
        if (this.settings.controlState === ControlState.DialogCreate) {
            this._root.append(
                $("<span class='pull-left baseControlHelp itemTitleDemand'>" + this.settings.help + "</span>"),
            );
        }

        this._root.data("original", this.settings.title);
        this._root.data("new", this.settings.title);
        // create title bar
        if (showTools) {
            this._root.addClass("itemTitleBar");
        } else if (this.settings.canEdit) {
            this._root.addClass("itemTitleBarNoTools");
        } else {
            this._root.addClass("itemTitleBarNoToolsNoEdit");
        }

        let importSource = matrixSession.isConfigClient() ? null : app.getImportSource(this.settings.item);

        this.link = $("<div>").refLink({
            folder: false, // show id if it exists
            placeholder: this.settings.parameter.placeholder,
            id: this.settings.id,
            title: title,
            style: this.settings.canEdit && this.settings.canEditTitle ? refLinkStyle.edit : refLinkStyle.link,
            tooltip: refLinkTooltip.none,
            callback: function (id: string, newText: string) {
                if (!that.isInHiddenMode) {
                    if (that.settings.type == "DOC" && newText.indexOf("\\") != -1) {
                        ml.UI.showError("Invalid character in DOC title", "backslashes are not allowed!");
                    }
                    that._root.data("new", newText);
                    if (that.settings.valueChanged) {
                        that.settings.valueChanged.apply(null);
                    }
                }
            },
            validate: this.settings.validate,
            includeSourceLink:
                !showTools && importSource && importSource.relation == EImportMode.Include
                    ? `included from${importSource.downItem.projectShort}/${importSource.downItem.itemRefWithVersion}`
                    : "",
        });

        $(".refId", this.link).click(function () {
            if (
                that.settings.controlState != ControlState.FormEdit &&
                that.settings.controlState != ControlState.FormView
            ) {
                // nothing to show
                return;
            } else if (ml.Item.parseRef(that.settings.id).isFolder) {
                return;
            } else {
                let currentPanel: IPanel = matrixApplicationUI.currentPanel;
                if (currentPanel.toggleZen) {
                    currentPanel.toggleZen();
                }
            }
        });

        this.showOutOfDateIcon();

        this.link.addClass("itemTitle rowFlex");
        if (this.settings.locked) {
            let labelTools = ml.CreateNewLabelTools();
            let dn = labelTools.getDisplayName(this.settings.locked);
            let lockedBy = $("<span class='lockedItem'>").html("<i class='fa fa-lock-alt'></i> (locked)");

            // tooltip with info why it is locked and how it can be unlocked
            $(".refId", this.link).append(lockedBy);

            let tooltip = "Locked by label '" + this.settings.locked + (dn ? ": " + dn : "") + "'";
            tooltip += this.settings.unlockers
                ? ". Can be unlocked by: " + this.settings.unlockers + "."
                : ". Cannot be unlocked in UI.";

            lockedBy.tooltip({ title: tooltip, placement: "bottom" });
        }
        this._root.append(this.link);
        if (this.settings.controlState === ControlState.DialogCreate && !this.settings.noAutoActivation) {
            setTimeout(function () {
                $(".refTitle", that.link).click();
            }, 500);
        }

        if (this.settings.controlState !== ControlState.DialogCreate) {
            this.link.find(".refId").append($("<div class='pull-right itemViewers'>"));
        }
        $(this._root).on("focusout.editable", function (e) {
            $(".editable-open").parent().find("form").trigger("submit");
            console.log("title submitted");
        });

        if (showTools) {
            // add titlebar tools
            var itemTools = $("<div class=' hidden-print toolsBarButtonsContainer'></div>");
            this._root.append(itemTools);

            var miTouch = $(
                '<li title="Create new version of item"><a href="javascript:void(0)" id="toolTouch">Touch</a></li>',
            ).click(function () {
                app.touchAsync(that.settings.id, 1);
            });

            var bShare = $(
                '<div class="btn-group">' +
                    ' <button id="shareButton"  tabindex="-1" type="button" title data-original-title="Tools" data-toggle="dropdown" class="btn btn-item btn-sm dropdown-toggle">' +
                    '<span class="fal fa-ellipsis-v-alt"></span></button>' +
                    ' <ul class="sharemenu dropdown-menu dropdown-menu-sub pull-right" role="menu">' +
                    " </ul>" +
                    "</div>",
            );
            var bShareMenu = $("ul", bShare);

            var extras = globalMatrix.ItemConfig.getExtrasConfig();

            if (
                !this.settings.locked &&
                !this.settings.isFolder &&
                app.touchToolAvailable(this.settings.item) &&
                (!extras || !ml.JSON.isTrue(extras.noTouch))
            ) {
                bShareMenu.append(miTouch);
            }

            if (!mDHF.isDocumentType(this.settings.type)) {
                if (matrixSession.getUISettings().legacyPrint) {
                    var miPrint = $('<li title="prepare to print"><a href="javascript:void(0)">Print</a></li>').click(
                        function () {
                            app.print();
                        },
                    );
                    bShareMenu.append(miPrint);
                }
            }

            if (app.mailToolAvailable()) {
                var miMail = $(
                    '<li class="mailMenu"><a id="toolSendTo" href="javascript:void(0)">Send link by mail</a></li>',
                );

                miMail.click(function (event: JQueryEventObject) {
                    var itemId = app.getCurrentItemId();
                    var message = ml.Mail.getCannedMessage("link", "", itemId);

                    ml.Mail.sendMailDlg("", null, itemId, message, "");
                });
                bShareMenu.append(miMail);
            } else {
                var miMail = $(
                    '<li class="mailMenu"><a href="mailto:?subject=' +
                        this.settings.id +
                        "&amp;body=" +
                        location +
                        '">Email</a></li>',
                );
                bShareMenu.append(miMail);
            }

            var labelTools = $('<div class="labelTools">');

            if (globalMatrix.ItemConfig.getLabelsConfig() && globalMatrix.ItemConfig.getLabelsConfig().useLabelBar) {
                let labelBar = $("<div class='labelBar'>");
                this._root.after(labelBar);
                labelBar.append(labelTools);
            } else {
                itemTools.after(labelTools);
            }

            var workflowTools = $('<div class="btn-group workflowTools">');
            itemTools.append(workflowTools);

            if (
                !this.settings.isFolder &&
                (app.hasLinks(this.settings.item) ||
                    (mTM.getCloneSources() && mTM.getCloneSources().indexOf(this.settings.type) != -1))
            ) {
                this._refDlgIcon = $(`<i class="fal fa-sitemap" aria-hidden="true" />`);
                var refDlgButton = $(
                    "<button id='referenceToolBtn' tabindex='-1' title data-original-title='References' class='btn btn-item'>",
                ).append(this._refDlgIcon);
                var refDlgGroup = $("<div class='btn-group'>")
                    .append(refDlgButton)
                    .click(function () {
                        var rt = new ReferenceTools();
                        rt.showReferenceDialog({ item: that.settings.item, canEdit: that.settings.canEdit });
                    });
                itemTools.append(refDlgGroup);
                this.updateItem();
            }

            if (!this.settings.isFolder) {
                // there is no easy way to get history of folder
                var historyTools = new HistoryTools();
                historyTools.renderButtons({
                    control: itemTools,
                    id: this.settings.id,
                    isFolder: this.settings.isFolder,
                    item: this.settings.item,
                    readOnly: !this.settings.canEdit,
                });

                if (importSource) {
                    const startText = importSource.relation == EImportMode.Include ? "included from " : "copied from ";
                    let sourceInfo =
                        startText + importSource.downItem.projectShort + "/" + importSource.downItem.itemRefWithVersion;
                    let sourceLink = $(
                        `<div  class='btn-group'><button id='originalItemBtn' tabindex='-1' title data-original-title='${sourceInfo}' class='btn btn-item'> <span class='fal fa-external-link-alt'></span></button></div>`,
                    );

                    sourceLink.click(() =>
                        window.open(
                            globalMatrix.matrixBaseUrl +
                                "/" +
                                importSource.downItem.projectShort +
                                "/" +
                                importSource.downItem.itemRefWithVersion.split("-v")[0],
                        ),
                    );

                    itemTools.append(sourceLink);
                }

                let usedBy = app.getUsedBy(this.settings.item);

                if (usedBy) {
                    let linkList = $("<ul>");

                    for (let useByLink of usedBy) {
                        let targetType = useByLink.relation == EImportMode.Include ? "included as" : "copied as";
                        let targetInfo = useByLink.upItem.projectShort + "/" + useByLink.upItem.itemRefWithVersion;
                        $(
                            `<li>${targetType} <span data-target='${
                                targetInfo.split("-v")[0]
                            }' class='refIdHyper includedLink'>${targetInfo}</span></li>`,
                        ).appendTo(linkList);
                    }
                    let targetLink = $(
                        `<div  class='btn-group'><button id='originalItemBtn' tabindex='-1' title data-original-title='used by' class='btn btn-item'> <span class='fal fa-external-link-square-alt'></span></button></div>`,
                    );
                    itemTools.append(targetLink);
                    targetLink.click(() => {
                        let usedByDlg = $("<div>").appendTo($("body"));

                        ml.UI.showDialog(
                            usedByDlg,
                            "Item used by",
                            linkList,
                            300,
                            200,
                            [
                                {
                                    text: "Close",
                                    class: "btnDoIt",
                                    click: function () {
                                        usedByDlg.dialog("close");
                                    },
                                },
                            ],
                            UIToolsConstants.Scroll.Vertical,
                            true,
                            true,
                            () => {
                                usedByDlg.remove();
                            },
                            () => {
                                $(".includedLink", linkList).click((e: JQueryEventObject) => {
                                    window.open(globalMatrix.matrixBaseUrl + "/" + $(e.delegateTarget).data("target"));
                                });
                            },
                            () => {},
                        );
                    });
                }
            }

            // The TestManager customizes folder menus in some cases.
            mTM.UpdateFolderMenu(bShareMenu, this.settings.item);

            let itemParsed = ml.Item.parseRef(this.settings.item.id);
            let root = itemParsed.number == 1 && itemParsed.isFolder;

            if (this.settings.canEdit && app.canDeleteItem(this.settings.item) && !root) {
                var bDelete = $('<li class="deleteMenu">Delete</li>');

                bShareMenu.prepend("<li class='divider'></li>");
                bShareMenu.prepend(bDelete);

                bDelete.click(function () {
                    var whatToDelete = that.settings.id;
                    MR1.triggerBeforeDeleteAsync(that.settings.item)
                        .done(function () {
                            let isTemplate = mDHF.isUsedAsTemplate(that.settings.item.id);
                            let message = isTemplate
                                ? that.settings.item.id +
                                  " is used as template. Deleting will also remove the templates!"
                                : "Delete '" + that.settings.item.id + " " + title + " '?";
                            let ok = isTemplate ? "Delete and remove templates" : "Delete";

                            ml.UI.showConfirm(
                                1,
                                { title: message, ok: ok },
                                function () {
                                    let deletedItems = app.getChildrenIdsRec(whatToDelete);
                                    app.deleteItem(whatToDelete).done(function () {
                                        MarkAsTemplateImpl.removeFromTemplates(deletedItems);
                                        MR1.triggerAfterDelete(that.settings.item);
                                        if (isTemplate) {
                                            mDHF.removeAsTemplate(that.settings.item.id);
                                        }
                                    });
                                },
                                function () {},
                            );
                        })
                        .fail(function () {
                            ml.Logger.log("warn", "delete cancelled using MR1");
                        });
                });
            }
            itemTools.append(bShare);
            itemTools.append(ml.ContextFrames.getExpender());
            $("button", itemTools).tooltip({ placement: "bottom" });

            // moved after adding history (for jobs plugin to be able to remove it;-)
            plugins.updateMenu(pluginHooks.shares, bShareMenu);
        }
    }
    // public interface
    async hasChangedAsync() {
        return this._root.data("original") !== this._root.data("new");
    }
    async getValueAsync() {
        return this._root.data("new");
    }
    setValue(newTitle: string, fireUpdate: boolean) {
        this._root.data("new", newTitle);
        this._root.find("input").val(newTitle);
        this._root.find(".refTitle").html(newTitle);

        if (fireUpdate && this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }
    getText() {}

    getValueRaw() {}

    titleCreationMode() {
        // special mode where control is hidden and used by report generator to set the value
        this._root.hide();
        this.isInHiddenMode = true;
    }

    destroy() {
        if (this.link) {
            (<XEditable>(<any>this.link)).destroy();
        }
    }

    resizeItem() {}

    setViewers(viewers: IItemWatched) {
        let that = this;

        if (viewers.item !== this.settings.item.id) {
            // that can happen
            // e.g. if use changes item and does not type for a while, the old item will be pinged
            return;
        }
        $(".itemViewers", this._root).html("");
        let ignoredFirstMe = false;
        let otherEditor = viewers.editor && !viewers.editor.thisSocket ? viewers.editor.user : "";

        if (viewers.editor && this.settings.type != "XTC" && this.settings.type != "SIGN") {
            $("#thisItemIsDated").hide();
        } else {
            $("#thisItemIsDated").show();
        }

        $.each(viewers.users, function (vidx, viewer) {
            if (viewer == matrixSession.getUser() && !ignoredFirstMe) {
                // I myself appear at most once in an item
                ignoredFirstMe = true;
                return;
            }

            let user = globalMatrix.ItemConfig.getUserInfo(viewer);
            if (!user) {
                // that's a spying super user;-)
                return;
            }
            let name = (user.firstName ? user.firstName : "") + " " + (user.lastName ? user.lastName : "");
            if (name == " ") name = viewer;
            let itemAccess = "itemViewer";
            if (otherEditor == viewer) {
                otherEditor = ""; // there can only be one
                itemAccess = "itemEditor";
                name += " - is editing this item";
            }
            let spanItemViewer = $("<div class='" + itemAccess + "' title='" + name + "'></div>");
            spanItemViewer.append(ml.UI.getAvatar(user.login, 32));

            $(".itemViewers", that._root).append(spanItemViewer);
        });
    }

    updateItem(newItem?: IItem) {
        let that = this;
        if (newItem) {
            if (newItem.id !== this.settings.item.id) {
                // that can happen
                // e.g. if use changes item and does not type for a while, the old item will be pinged
                // console.write("newItem.id !== settings.item.id");
                return;
            }
            if (
                newItem.history.length &&
                newItem.history.length !== this.settings.item.history.length &&
                !(
                    newItem.history.length === this.settings.item.history.length + 1 &&
                    newItem.history[0].action === "move" &&
                    newItem.history[0].user === matrixSession.getUser()
                ) // no warning after simple move by this user! MATRIX-1416
            ) {
                if (!this.warnedAboutOutOfDate) {
                    var message =
                        "User " +
                        newItem.history[0].user +
                        " changed item after you loaded it <br><br> Last change at " +
                        newItem.history[0].dateUserFormat;

                    ml.UI.showConfirm(
                        -12,
                        { title: message, ok: "Update to new", nok: "Keep displaying old" },
                        function () {
                            app.cancel();
                        },
                        function () {
                            that.warnedAboutOutOfDate = true;
                            that._root.addClass("staleItem");
                            $(".itemTitle", that._root).tooltip({
                                title: message + " ",
                                placement: "right",
                                html: true,
                                template:
                                    '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner" style="max-width:800px"></div></div>',
                            });
                        },
                    );
                }
            }
            this.settings.item.downLinks = newItem.downLinks;
            this.settings.item.upLinks = newItem.upLinks;
        }

        if (this._refDlgIcon) {
            var downMissing = app.isDownLinkMissing(this.settings.item);
            var upMissing = app.isUpLinkMissing(this.settings.item);
            this._refDlgIcon.removeClass("refGood").removeClass("refOutdated").removeClass("refMissing");
            if (upMissing || downMissing) {
                this._refDlgIcon.addClass("refMissing");
            } else if (app.isAnyLinkOutdated(this.settings.item)) {
                this._refDlgIcon.addClass("refOutdated");
            } else {
                this._refDlgIcon.addClass("refGood");
            }
        }
    }

    private toggleOutOfDateIcon() {}
    private showOutOfDateIcon() {
        let that = this;

        if (
            this.settings.controlState == ControlState.FormEdit &&
            this.settings.item &&
            this.settings.item.history &&
            this.settings.item.upLinks &&
            this.settings.item.upLinks.length
        ) {
            // check if item is potentially out of date
            let isXTCorSIGN = this.settings.type == "XTC" || this.settings.type == "SIGN";
            // for XTC take time of creation of XTC, for other the last modification time
            let thisTime = this.settings.item.history[isXTCorSIGN ? this.settings.item.history.length - 1 : 0].date;
            // get the last modification date of all parents
            let parentLastChange = new Date(this.settings.item.upLinks[0].modDate);
            for (let parent of this.settings.item.upLinks) {
                if (new Date(parent.modDate) > parentLastChange) {
                    parentLastChange = new Date(parent.modDate);
                }
            }
            if (new Date(thisTime) < parentLastChange) {
                let clickToTouch = this.settings.canEdit ? " Click to touch" : "";
                let message = isXTCorSIGN
                    ? "the underlying " +
                      (this.settings.type == "XTC" ? "test" : "DOC") +
                      " was changed after the " +
                      this.settings.type +
                      " was created"
                    : "at least one uplinked item is newer than this item." + clickToTouch;
                let icon = $(`<span id="thisItemIsDated" class="fal fa-history datedLink" title="${message}"></span>`);
                if (this.settings.canEdit && !isXTCorSIGN) {
                    icon.css("cursor", "pointer").click(() => {
                        app.touchAsync(that.settings.id, 1);
                    });
                }
                this.link.find(".refId").append(icon);
            }
        }
    }
}
