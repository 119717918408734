import { ControlState, globalMatrix, IGenericMap } from "../../../globals";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";

export type { IHtmlFormOptions, IHTMLFormValue, IFormValue };
export { HtmlFormImpl };

interface IHtmlFormOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: {
        readonly?: boolean;
        htmlSetting?: string; // pointer to project settings with html code
    };
}
interface IHTMLFormValue {
    name: string;
    value: string;
    pos?: number;
}
interface IFormValue {
    data: IHTMLFormValue[];
    html: string;
}

$.fn.htmlform = function (this: JQuery, options: IHtmlFormOptions) {
    if (!options.fieldHandler) {
        //No need for a field handler here, so let's create a dummy one.
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_dummy,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }

    let baseControl = new HtmlFormImpl(this, options.fieldHandler as EmptyFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);

    return this;
};

class HtmlFormImpl extends BaseControl<EmptyFieldHandler> {
    private settings: IHtmlFormOptions;
    private timer: number;
    private form: JQuery;

    constructor(control: JQuery, fieldHandler: EmptyFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IHtmlFormOptions) {
        let that = this;

        // initialize options
        var defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dumy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
                htmlSetting: "", // set to a project setting name to retrive html from project setting
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);
        // public interface

        // private functions

        // get html from fieldSettings
        if (this.settings.parameter.htmlSetting) {
            this.form = $(globalMatrix.ItemConfig.getSetting(this.settings.parameter.htmlSetting));
        }

        // initialize object
        var values: IHTMLFormValue[] = [];
        if (typeof this.settings.fieldValue !== "undefined" && this.settings.fieldValue) {
            values = (<{ data: IHTMLFormValue[] }>JSON.parse(this.settings.fieldValue)).data;
        }

        this._root.append(super.createHelp(this.settings));

        var ctrlContainer = $("<div>").addClass("baseControl");
        this._root.append(ctrlContainer);
        ctrlContainer.append(this.form);

        // implement this.timer to check for changes
        if (
            this.settings.controlState === ControlState.FormEdit ||
            this.settings.controlState === ControlState.DialogEdit
        ) {
            this.timer = window.setInterval(function () {
                var json = that.readData();

                var newVal = JSON.stringify(json);
                if (newVal !== that._root.data("new")) {
                    that._root.data("new", newVal);

                    if (that.settings.valueChanged) {
                        that.settings.valueChanged.apply(null);
                    }
                }
            }, 500);
        } else {
            this.form.find("textarea").each(function (index: number, elem: Element) {
                $(elem).attr("readonly", "readonly");
            });
            this.form.find("input, select").each(function (index: number, elem: Element) {
                $(elem).prop("disabled", true);
            });
        }
        // save values after loading
        this._root.data("original", JSON.stringify(values));
        this._root.data("new", JSON.stringify(values));

        this.writeData(values);
    }

    async hasChangedAsync() {
        return this._root.data("original") !== this._root.data("new");
    }

    async getValueAsync() {
        var html = this.getHtml();
        var json = this.readData();

        return JSON.stringify({ data: json, html: html });
    }
    setValue(newValue: string) {
        let values = (<{ data: IHTMLFormValue[] }>JSON.parse(newValue)).data;
        this._root.data("new", JSON.stringify(values));
        this.writeData(values);
    }
    destroy() {
        window.clearInterval(this.timer);
    }

    resizeItem() {}

    // read data from form
    private readData(): IHTMLFormValue[] {
        var data: IHTMLFormValue[] = [];
        var radios = {};
        this.form.find("input, select, textarea").each(function (index: number, elem: Element) {
            var inp = $(elem);
            var val: any;
            switch (inp.attr("type")) {
                case "radio":
                    if (!(<IGenericMap>radios)[inp.attr("name")]) {
                        (<IGenericMap>radios)[inp.attr("name")] = 0;
                    }
                    (<IGenericMap>radios)[inp.attr("name")]++;
                case "checkbox":
                    val = inp.is(":checked");
                    break;
                default:
                    val = inp.val();
            }
            var field: IHTMLFormValue = { name: inp.attr("name"), value: val };
            if ((<IGenericMap>radios)[inp.attr("name")]) {
                field.pos = (<IGenericMap>radios)[inp.attr("name")];
            }
            data.push(field);
        });

        return data;
    }
    // write data back into form
    private writeData(data: IHTMLFormValue[]): void {
        var radios = {};
        this.form.find("input, select, textarea").each(function (idx: number, element: Element) {
            var inp = $(element);
            var val: any;

            if (inp.attr("type") === "radio") {
                if (!(<IGenericMap>radios)[inp.attr("name")]) {
                    (<IGenericMap>radios)[inp.attr("name")] = 0;
                }
                (<IGenericMap>radios)[inp.attr("name")]++;
            }

            // get saved value
            $.each(data, function (dp, d) {
                if (
                    d.name === inp.attr("name") &&
                    (!(<IGenericMap>radios)[inp.attr("name")] || (<IGenericMap>radios)[inp.attr("name")] == d.pos)
                ) {
                    val = d.value;
                }
            });
            // set in html
            switch (inp.attr("type")) {
                case "radio":
                    inp.prop("checked", val);
                    break;
                case "checkbox":
                    inp.prop("checked", val);
                    break;
                default:
                    inp.val(val);
            }
        });
        if (this.form.data("postload")) {
            console.log(" this won't work with https://content-security-policy.com 'unsafe-eval' enabled");
            eval(this.form.data("postload"));
        }
    }
    // make printable html
    private getHtml() {
        this.form.find("input, select, textarea").each(function (index: number, elem: Element) {
            var $this = $(elem);

            if ($this.is("[type='radio']") || $this.is("[type='checkbox']")) {
                if ($this.prop("checked")) {
                    $this.attr("checked", "checked");
                } else {
                    $this.removeAttr("checked");
                }
            } else if ($this.is("select")) {
                $this.find(":selected").attr("selected", "selected");
            } else if ($this.is("textarea")) {
                $this.html($this.val());
            } else {
                $this.attr("value", $this.val());
            }
        });
        this.form.find("script").each(function (index: number, elem: Element) {
            var $this = $(elem);

            $this.replaceWith("<span>");
        });

        return this.form.html();
    }
}
