import { GenericDocAbstractFieldHandler } from "./GenericDocAbstractFieldHandler";

export class GenericDocFieldHandler extends GenericDocAbstractFieldHandler {
    getDefaultConfig(): any {
        return {};
    }

    getXmlValue(): string {
        return JSON.stringify([this.rawValue ?? ""]);
    }

    addSignatures(signatures: string[], includeAll?: boolean) {}
}
