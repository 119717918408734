import { IDocFieldHandler } from "./IDocFieldHandler";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { IFromToSelection } from "../../../UI/Controls/itemSelectionFromTo";
import { IGenericMap } from "../../../../globals";
import { ItemSelectionFromToAbstractDocFieldHandler } from "./ItemSelectionFromToAbstractDocFieldHandler";

export interface ITestResultsOptions extends IDHFSectionOptions {
    lastOnly: boolean;
    lastCreatedOnly: boolean;
    completeTree: boolean;
    failedOnly: boolean;
    dateoptions: string;
    includeInToc: boolean;
    numericalOrder: boolean;
    searchFrom?: string;
    searchTo?: string;
}

export interface IDHFTestResultsConfig {
    default: ITestResultsOptions;
}

export class TestResultsDocFieldHandler extends ItemSelectionFromToAbstractDocFieldHandler implements IDocFieldHandler {
    getDefaultConfig(): ITestResultsOptions {
        return {
            lastOnly: true,
            lastCreatedOnly: false,
            completeTree: false,
            failedOnly: false,
            dateoptions: "",
            includeInToc: false,
            numericalOrder: false,
        };
    }

    getXmlValue(): string {
        let controllerConfig = this.dhfFieldConfig;

        // get lists
        let reflist = <IFromToSelection>this.getSelectedItems();
        let td: IGenericMap[] = [];
        let r0: IGenericMap = {};
        for (let idx = 0; idx < reflist.from.length; idx++) {
            r0["from" + idx] = reflist.from[idx].to;
        }
        td.push(r0);

        let r1: IGenericMap = {};
        for (let idx = 0; idx < reflist.to.length; idx++) {
            r1["to" + idx] = reflist.to[idx].to;
        }
        td.push(r1);

        td.push({ lastOnly: controllerConfig.lastOnly });
        td.push({ lastCreatedOnly: controllerConfig.lastCreatedOnly });
        td.push({ completeTree: controllerConfig.completeTree });
        td.push({ failedOnly: controllerConfig.failedOnly });
        td.push({ includeInToc: controllerConfig.includeInToc });
        td.push({ numericalOrder: controllerConfig.numericalOrder });

        td.push({ dateoptions: controllerConfig.dateoptions });
        return JSON.stringify(td);
    }
}
