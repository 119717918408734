import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { GenericDocAbstractFieldHandler } from "./GenericDocAbstractFieldHandler";

export interface IDocumentOptionsOptions extends IDHFSectionOptions {
    auto_number?: boolean;
    omit_title?: boolean;
    internal_links?: boolean;
    no_folder_ids?: boolean;
    hide_links?: boolean; // if true does not show boxes with cross project and file links
}

export class DocumentOptionsFieldHandler extends GenericDocAbstractFieldHandler {
    getDefaultConfig(): IDocumentOptionsOptions {
        return {
            auto_number: true,
            omit_title: true,
            internal_links: false,
        };
    }

    setDHFConfig(config: IDocumentOptionsOptions): void {
        this.dhfFieldConfig = config;
    }

    getXmlValue(): string {
        let controllerConfig = this.dhfFieldConfig;

        let options: IDocumentOptionsOptions = {};
        options.auto_number = controllerConfig.auto_number || false;
        options.omit_title = controllerConfig.omit_title || false;
        options.internal_links = controllerConfig.internal_links || false;
        options.no_folder_ids = controllerConfig.no_folder_ids || false;
        options.hide_links = controllerConfig.hide_links || false;

        return JSON.stringify([options]);
    }
}
