import { IDHFSection, IDHFControlDefinition } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import {
    IItemIndexOptions,
    ITEM_INDEX_FORMAT,
} from "../../../common/businesslogic/FieldHandlers/Document/ItemIndexDocFieldHander";
import { BaseDHFSection } from "./BaseDHFSection";

export class ItemIndex extends BaseDHFSection<IItemIndexOptions> implements IDHFSection {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        ctrlParameter.fieldValue = "includes an index with all referenced items";
        ctrl.control.plainText(
            ml.JSON.setOptions(ml.JSON.clone(ctrlParameter), {
                parameter: { readonly: true, rows: 1, allowResize: false },
            }),
        );
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        var controllerConfig = this.getConfig(ctrl);

        var format = $(
            '<input autocomplete="off" style="height: 20px;width: 420px;float: right;" class="form-control p0" type="text" name="format" value="" /> ',
        );

        custom.append($("<br/>"));
        custom.append($("<span>Word formatting: </span>").append(format));

        format.val(controllerConfig.format ? controllerConfig.format : ITEM_INDEX_FORMAT);
    }
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        var controllerConfig = this.getConfig(ctrl);

        var newval = $(custom.find(".p0")[0]).val();
        var changed = controllerConfig.format !== newval;
        controllerConfig.format = newval;

        this.fieldHandler.setDHFConfig(controllerConfig);
        return changed;
    }
    async verifyContentAsync(ctrl: IDHFControlDefinition) {}
}
