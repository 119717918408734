import { IDocFieldHandler } from "./IDocFieldHandler";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { IReference } from "../../../../globals";
import { IItemListOptions } from "./ItemListDocFieldHandler";
import { ItemSelectionAbstractDocFieldHandler } from "./ItemSelectionAbstractDocFieldHandler";

export interface ILinkListOptions extends IDHFSectionOptions {
    showExternal: number;
    includeInToc: boolean;
    search?: string;
}

export class LinkListDocFieldHandler extends ItemSelectionAbstractDocFieldHandler implements IDocFieldHandler {
    getDefaultConfig(): ILinkListOptions {
        return { showExternal: 1, includeInToc: false };
    }

    getXmlValue(): string {
        let reflist = <IReference[]>this.getItems();

        for (let idx = 0; idx < reflist.length; idx++) {
            (<ILinkListOptions>(<any>reflist[idx]))["showExternal"] = this.dhfFieldConfig.showExternal;
            (<IItemListOptions>(<any>reflist[idx]))["includeInToc"] = this.dhfFieldConfig.includeInToc;
        }

        return JSON.stringify(reflist);
    }
}
