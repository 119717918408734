import { globalMatrix, IStringMap } from "./../../globals";
import { ml } from "./../matrixlib";
import { IURLTools, IMatrixUrlParts } from "./MatrixLibInterfaces";

export { URLTools };

class URLTools implements IURLTools {
    getParameterByName(url: string, name: string): string | null {
        var match = RegExp("[?&]" + name + "=([^&]*)").exec(url);
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    }
    parseUrl(url: String): IMatrixUrlParts {
        let result: IMatrixUrlParts = {
            project: "",
            item: "",
            params: {},
        };

        // remove https://server.matrixreq.com/
        let noserver = url
            .replace(globalMatrix.matrixBaseUrl + "/", "")
            .replace(globalMatrix.matrixBaseUrl, "")
            .split("?");
        if (noserver[0]) {
            let pi = noserver[0].split("/");
            result.project = pi[0];
            result.item = pi.length > 1 ? pi[1] : "";
        }
        if (noserver.length > 1) {
            let params = noserver[1].split("&");
            $.each(params, function (pidx, param) {
                let firstE = param.indexOf("=");
                if (firstE == -1) {
                    ml.Logger.log("warning", "invalid url parameter '" + param + "'");
                    return;
                }
                result.params[param.substr(0, firstE)] = decodeURIComponent(
                    param.substr(firstE + 1).replace(/\+/g, " "),
                );
            });
        }

        return result;
    }
}
