import { globalMatrix, IReference } from "../../../globals";
import { ml } from "../../matrixlib";
import { HTMLCleaner } from "../../matrixlib/index";
import { SelectMode } from "../Components/ProjectViewDefines";
import { ItemSelectionTools } from "../Tools/ItemSelectionView";

interface IEditorSmartTextOptions {
    docMode: boolean;
    tableMode: boolean;
}

((factory: Function) => {
    factory($);
})(($: Summernote.summernote) => {
    // template, editor
    var tmpl = $.summernote.renderer.getTemplate();
    var editor = <Summernote.editor>$.summernote.eventHandler.getEditor();
    var renderer = $.summernote.renderer;

    $.summernote.addPlugin(<Summernote.plugin>{
        /** @property {String} name name of plugin */
        name: "smarttext",
        buttons: {
            // buttons
            smarttext: function (lang?: any, opt?: IEditorSmartTextOptions) {
                var dropdown = '<ul class="smarttaglist dropdown-menu"></ul>';
                window.setTimeout(function () {
                    jQuery(".smarttaglist")
                        .parent()
                        .on("show.bs.dropdown", function () {
                            ml.SmartText.createMenu(opt.docMode, opt.tableMode);
                        });
                }, 200);
                return tmpl.iconButton("fal fa-copy", {
                    title: "Smart Text",
                    dropdown: dropdown,
                });
            },
        },
        init: function (layoutInfo: any) {},
        dialogs: {},
        /**
         * @property {Object} events
         * @property {Function} events.hello  run function when button that has a 'hello' event name  fires click
         * @property {Function} events.helloDropdown run function when button that has a 'helloDropdown' event name  fires click
         * @property {Function} events.helloImage run function when button that has a 'helloImage' event name  fires click
         */
        events: {
            // events
            selectSmartLink: function (layoutInfo: Summernote.layoutInfo, value: any, target: JQuery, event: any) {
                let st = new ItemSelectionTools();
                // find out what to link to
                let linkStyle = localStorage.getItem("linkStyle");
                if (!linkStyle) {
                    linkStyle = "noTitle";
                }
                // make sure it is inserted at correct place

                let range: Range;
                let selection = window.getSelection();
                if (selection && selection.rangeCount > 0) {
                    range = selection.getRangeAt(0);
                }
                if (
                    !range ||
                    !range.startContainer ||
                    jQuery(range.startContainer).closest(".note-editable").length === 0
                ) {
                    range = layoutInfo.editable().data("lastRange");
                }

                let selectOptions = jQuery(`<div class='selectReferenceOption'>
                    <form action="">
                        <input type="radio" name="title" value="noTitle" ${
                            linkStyle == "noTitle" ? 'checked="checked"' : ""
                        }> No title<br>
                        <input type="radio" name="title" value="title" ${
                            linkStyle == "title" ? 'checked="checked"' : ""
                        }> Title behind link<br>
                        <input type="radio" name="title" value="linktitle" ${
                            linkStyle == "linktitle" ? 'checked="checked"' : ""
                        }> Title as part of link
                    </form>
                    </div>`);

                // let user select the items
                st.showDialog({
                    selectMode: SelectMode.independent,
                    linkTypes: globalMatrix.ItemConfig.getCategories(true).map(function (cat) {
                        return { type: cat };
                    }),
                    selectionChange: function (newSelection: IReference[]) {
                        let linkStyle = jQuery("input[name=title]:checked", selectOptions).val();
                        localStorage.setItem("linkStyle", linkStyle);
                        if (newSelection.length > 0) {
                            let refTexts: string[] = [];
                            for (var idx = 0; idx < newSelection.length; idx++) {
                                refTexts.push(
                                    newSelection[idx].to +
                                        (linkStyle == "title" ? "!" : "") +
                                        (linkStyle == "linktitle" ? "+" : ""),
                                );
                            }

                            editor.insertHTML(layoutInfo.editable(), target, selection, range, refTexts.join(", "));
                        }
                    },
                    getSelectedItems: async function () {
                        return [];
                    },
                    selectOptions: selectOptions,
                });
            },
            selectCrossLink: function (layoutInfo: Summernote.layoutInfo, value: any, target: any, event: any) {
                let st = new ItemSelectionTools();
                let projectShortLabel: string;
                let range: Range;
                let selection = window.getSelection();
                if (selection && selection.rangeCount > 0) {
                    range = selection.getRangeAt(0);
                }
                if (
                    !range ||
                    !range.startContainer ||
                    jQuery(range.startContainer).closest(".note-editable").length === 0
                ) {
                    range = layoutInfo.editable().data("lastRange");
                }

                let selectOptions = jQuery(`<div class='selectCrossReferenceOption'>
                    <label for="refPrefix">Link display name (optional) - do not use spaces</label><input autocomplete="off" type="text" class="form-control" id="refPrefix" pattern="[a-zA-Z0-9-_]+" />
                    </div>`);

                // let user select the items
                st.showCrossProjectDialog({
                    selectMode: SelectMode.independent,
                    linkTypes: [],
                    selectionChange: function (newSelection: IReference[]) {
                        let prefix = jQuery("#refPrefix", selectOptions).val();
                        if (prefix) {
                            prefix = prefix.replace(/[^a-zA-Z0-9-_]/g, "_") + "|";
                        }
                        if (newSelection.length > 0) {
                            let refTexts: string[] = [];
                            for (var idx = 0; idx < newSelection.length; idx++) {
                                refTexts.push("#" + prefix + projectShortLabel + "/" + newSelection[idx].to + "#");
                            }

                            editor.insertHTML(layoutInfo.editable(), target, selection, range, refTexts.join(", "));
                        }
                    },
                    crossProjectInit: function (psl: string) {
                        projectShortLabel = psl;
                    },
                    getSelectedItems: async function () {
                        return [];
                    },
                    selectOptions: selectOptions,
                });
            },
            createFigureCaption: function (layoutInfo: Summernote.layoutInfo, value: any, target: any, event: any) {
                ml.SmartText.createCaption(false, editor, layoutInfo.editable());
            },
            createTableCaption: function (layoutInfo: Summernote.layoutInfo, value: any, target: any, event: any) {
                ml.SmartText.createCaption(true, editor, layoutInfo.editable());
            },
            insertFigReference: function (layoutInfo: Summernote.layoutInfo, value: any, target: any, event: any) {
                ml.SmartText.insertFigReference(value, editor, layoutInfo.editable());
            },
            insertTabReference: function (layoutInfo: Summernote.layoutInfo, value: any, target: any, event: any) {
                ml.SmartText.insertTabReference(value, editor, layoutInfo.editable());
            },
            pasteBuffer: function (layoutInfo: Summernote.layoutInfo, value: any, target: any, event: any) {
                ml.SmartText.pasteBuffer(editor, layoutInfo.editable());
            },
            createAbbreviationText: function (layoutInfo) {
                ml.SmartText.createEditTag(4, null);
            },
            createTermText: function (layoutInfo) {
                ml.SmartText.createEditTag(3, null);
            },
            createSmartText: function (layoutInfo) {
                ml.SmartText.createEditTag(2, null);
            },

            createPlainText: function (layoutInfo) {
                ml.SmartText.createEditTag(1, null);
            },
            selectSmartText: function (layoutInfo: Summernote.layoutInfo, value: any, target: any, event: Event) {
                if ((<Element>event.target).className.indexOf("editSmartText") !== -1) {
                    ml.SmartText.createEditTag(0, value);
                } else if ((<Element>event.target).className.indexOf("deleteSmartText") !== -1) {
                    ml.SmartText.deleteTag(value);
                } else {
                    var $editable = layoutInfo.editable();

                    let range: Range;
                    let selection = window.getSelection();
                    if (selection && selection.rangeCount > 0) {
                        range = selection.getRangeAt(0);
                    }
                    if (
                        !range ||
                        !range.startContainer ||
                        jQuery(range.startContainer).closest(".note-editable").length === 0
                    ) {
                        range = layoutInfo.editable().data("lastRange");
                    }
                    editor.insertHTML(layoutInfo.editable(), target, selection, range, "_" + value + "_");
                }
            },
        },
    });

    $.summernote.addPlugin(<Summernote.plugin>{
        /** @property {String} name name of plugin */
        name: "extraclean",
        buttons: {
            // buttons
            extraclean: function (lang?: any, opt?: any) {
                return tmpl.iconButton("far fa-square", {
                    title: "Clean Text",
                    event: "cleanText",
                });
            },
        },
        init: function (layoutInfo: any) {},
        dialogs: {},
        /**
         * @property {Object} events
         * @property {Function} events.hello  run function when button that has a 'hello' event name  fires click
         * @property {Function} events.helloDropdown run function when button that has a 'helloDropdown' event name  fires click
         * @property {Function} events.helloImage run function when button that has a 'helloImage' event name  fires click
         */
        events: {
            // events
            cleanText: function (
                layoutInfo: Summernote.layoutInfo,
                value: any,
                target: any,
                event: JQueryMouseEventObject,
            ) {
                //
                if (globalMatrix.globalShiftDown) {
                    let range: Range;
                    let selection = window.getSelection();
                    if (selection && selection.rangeCount > 0) {
                        range = selection.getRangeAt(0);
                    }
                    if (
                        selection.isCollapsed ||
                        !range ||
                        !range.startContainer ||
                        jQuery(range.startContainer).closest(".note-editable").length === 0
                    ) {
                        let input = layoutInfo.editable().html();

                        let cleaner = new HTMLCleaner(input, false);

                        layoutInfo.editable().html(cleaner.getClean(HTMLCleaner.CleanLevel.Strict));
                    } else {
                        // clean selection
                        var container = jQuery("<div>");
                        for (var i = 0, len = selection.rangeCount; i < len; ++i) {
                            container.append(selection.getRangeAt(i).cloneContents());
                        }
                        let cleaner = new HTMLCleaner(container.html(), false);
                        range.deleteContents();
                        var el = document.createElement("span");
                        range.insertNode(el);
                        el.innerHTML = cleaner.getClean(HTMLCleaner.CleanLevel.Strict);
                        range.insertNode(el);
                        jQuery(el).contents().unwrap();
                    }
                    editor.afterCommand(layoutInfo.editable());
                } else {
                    document.execCommand("removeFormat", false);
                    editor.afterCommand(layoutInfo.editable());
                }
            },
        },
    });
});
