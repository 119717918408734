import { lab } from "d3";
import { XRLabelHistory } from "../../RestResult";
import { globalMatrix, restConnection } from "../../globals";
import { ml } from "../matrixlib";
import { LabelManager } from "./LabelManager";
import { IJSONTools, ILabelTools, ILoggerTools } from "./MatrixLibInterfaces";

export { LabelTools };

class LabelTools extends LabelManager implements ILabelTools {
    constructor(logger: ILoggerTools, json: IJSONTools) {
        super(logger, json, () => globalMatrix.ItemConfig);
    }

    async getLastTimeLabelWasSet(itemId: string, label: string, beforeRevision: number): Promise<number> {
        const labelHistory: XRLabelHistory = <XRLabelHistory>(
            await restConnection.getProject("labelhistory?itemRef=" + itemId)
        );
        return this.decipherLastTimeLabelWasSet(labelHistory, itemId, label, beforeRevision);
    }
}
