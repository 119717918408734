import { ILineEditorLine } from "../common/UI/ILineEditor";
import { app, matrixSession, restConnection } from "../globals";
import { IDisplayedWidget, IWidgetScope } from "./WidgetPluginManager";

export { WidgetSettingsManager };

class WidgetSettingsManager {
    static getHiddenWidgets(): string[] {
        let list: string[] = [];
        let index = matrixSession.serverConfig.currentUserSettings.findIndex((o) => {
            return o.key == "hiddenWidget";
        });
        if (index >= 0) {
            try {
                let templist = JSON.parse(matrixSession.serverConfig.currentUserSettings[index].value);
                list = templist.filter(function (item, pos) {
                    return templist.indexOf(item) == pos;
                });
            } catch (error) {}
        }
        return list;
    }
    static hide(widget: IDisplayedWidget): JQueryDeferred<any> {
        let d: JQueryDeferred<any> = $.Deferred();

        let list: string[] = [];
        let index = matrixSession.serverConfig.currentUserSettings.findIndex((o) => {
            return o.key == "hiddenWidget";
        });
        if (index >= 0) {
            let templist = JSON.parse(matrixSession.serverConfig.currentUserSettings[index].value);
            templist.push(widget.id);
            //Remove duplicates
            list = templist.filter(function (item, pos) {
                return templist.indexOf(item) == pos;
            });

            matrixSession.serverConfig.currentUserSettings[index].value = JSON.stringify(list);
        } else {
            list.push(widget.id);
            matrixSession.serverConfig.currentUserSettings.push({
                key: "hiddenWidget",
                value: JSON.stringify(list),
                secret: false,
            });
        }

        let data = {
            key: "hiddenWidget",
            value: JSON.stringify(list),
            reason: "adding widget Setting",
        };
        return restConnection.postServer("user/" + matrixSession.getUser() + "/setting", data);
    }

    static unhide(displayedWidget: IDisplayedWidget): JQueryDeferred<any> {
        let index = matrixSession.serverConfig.currentUserSettings.findIndex((o) => {
            return o.key == "hiddenWidget";
        });
        let list = WidgetSettingsManager.getHiddenWidgets().filter((w) => {
            return w != displayedWidget.id;
        });

        if (index >= 0) matrixSession.serverConfig.currentUserSettings[index].value = JSON.stringify(list);

        let data = {
            key: "hiddenWidget",
            value: JSON.stringify(list),
            reason: "adding widget Setting",
        };
        return restConnection.postServer("user/" + matrixSession.getUser() + "/setting", data);
    }

    static updatePosition(widget: IDisplayedWidget): JQueryDeferred<any> {
        let d: JQueryDeferred<any> = $.Deferred();

        if (
            widget.parameters.users != undefined &&
            widget.parameters.users.length > 0 &&
            widget.createdBy != matrixSession.getUser()
        ) {
            // Save new position to localstorage
            localStorage.setItem(widget.id, JSON.stringify(widget.parameters.position));
            d.resolve();
            return d;
        } else {
            return WidgetSettingsManager.setSettings(widget);
        }
    }

    static setSettings(widget: IDisplayedWidget): JQueryDeferred<any> {
        let d: JQueryDeferred<any> = $.Deferred();

        if (widget.parameters.users != undefined && widget.parameters.users.length > 0) {
            if (matrixSession.isAdmin()) {
                let index = matrixSession.serverConfig.customerSettings.findIndex((o) => {
                    return o.key == widget.id;
                });
                if (index >= 0) matrixSession.serverConfig.customerSettings[index].value = JSON.stringify(widget);
                else
                    matrixSession.serverConfig.customerSettings.push({
                        key: widget.id,
                        value: JSON.stringify(widget),
                        secret: false,
                    });

                return app.setSettingCustomerJSON(widget.id, widget);
            }
        } else {
            let index = matrixSession.serverConfig.currentUserSettings.findIndex((o) => {
                return o.key == widget.id;
            });
            if (index >= 0) matrixSession.serverConfig.currentUserSettings[index].value = JSON.stringify(widget);
            else
                matrixSession.serverConfig.currentUserSettings.push({
                    key: widget.id,
                    value: JSON.stringify(widget),
                    secret: false,
                });

            if (widget.parameters.canBeAddedOrDeletedBy == IWidgetScope.user) {
                let data = {
                    key: widget.id,
                    value: JSON.stringify(widget),
                    reason: "adding widget Setting",
                };
                return restConnection.postServer("user/" + matrixSession.getUser() + "/setting", data);
            }
        }
        d.fail();
        return d;
    }
    static deleteDeleteWidgetSettings(widget: IDisplayedWidget): JQueryDeferred<any> {
        let d: JQueryDeferred<any> = $.Deferred();

        if (widget.parameters.users != undefined && widget.parameters.users.length > 0) {
            if (matrixSession.isAdmin()) {
                matrixSession.serverConfig.customerSettings = matrixSession.serverConfig.customerSettings.filter(
                    (value, index, arr) => {
                        return value.key != widget.id;
                    },
                );

                return app.setSettingCustomerJSON(widget.id, "");
            }
        } else {
            if (
                widget.parameters.canBeAddedOrDeletedBy == undefined ||
                widget.parameters.canBeAddedOrDeletedBy == IWidgetScope.user
            ) {
                matrixSession.serverConfig.currentUserSettings = matrixSession.serverConfig.currentUserSettings.filter(
                    (value, index, arr) => {
                        return value.key != widget.id;
                    },
                );
                let data = {
                    key: widget.id,
                    value: "",
                    reason: "removing widget Setting",
                };
                return restConnection.postServer("user/" + matrixSession.getUser() + "/setting", data);
            }
        }
        d.fail();
        return d;
    }
    static canBeEdited(displayedWidget: IDisplayedWidget, configurator: ILineEditorLine[]): boolean {
        if (displayedWidget.parameters.users != undefined && displayedWidget.parameters.users.length > 0) {
            if (matrixSession.isAdmin()) return true;
            else return false;
        }

        return (
            (displayedWidget.parameters.canBeAddedOrDeletedBy == IWidgetScope.user ||
                (displayedWidget.parameters.canBeAddedOrDeletedBy == IWidgetScope.admin && matrixSession.isAdmin()) ||
                (displayedWidget.parameters.canBeAddedOrDeletedBy == IWidgetScope.superAdmin &&
                    matrixSession.isSuperAdmin())) &&
            configurator != undefined &&
            configurator.length > 0
        );
    }
    static canBeDeleted(displayedWidget: IDisplayedWidget): boolean {
        if (displayedWidget.parameters.users != undefined && displayedWidget.parameters.users.length > 0) {
            if (matrixSession.isAdmin()) return true;
            else return false;
        }

        return (
            displayedWidget.parameters.canBeAddedOrDeletedBy == undefined ||
            displayedWidget.parameters.canBeAddedOrDeletedBy == IWidgetScope.user ||
            (displayedWidget.parameters.canBeAddedOrDeletedBy == IWidgetScope.admin && matrixSession.isAdmin()) ||
            (displayedWidget.parameters.canBeAddedOrDeletedBy == IWidgetScope.superAdmin &&
                matrixSession.isSuperAdmin())
        );
    }
}
