export type {
    IWidgetDashboardSettings,
    IDisplayedWidget,
    IWidgetPosition,
    IWidgetParameters,
    IWidgetPlugin,
    IGridStackWidget,
};
export { IWidgetScope, widgetRenderEvent, renderMode, WidgetPluginManager, widgetPluginManager };

interface IWidgetDashboardSettings {
    enabled: boolean;
    displayedWidgets: IDisplayedWidget[];
}

interface IDisplayedWidget {
    id: string;
    pluginName: string;
    parameters: IWidgetParameters;
    createdBy?: string;
}

enum widgetRenderEvent {
    load,
    scroll,
    click,
}
enum renderMode {
    placeholder,
    minimized,
    maximized,
}

enum IWidgetScope {
    admin,
    user,
    superAdmin,
}
interface IWidgetPosition {
    dashboard: string;
    w: number;
    h: number;
    x?: number;
    y?: number;
}
interface IWidgetParameters {
    canBeAddedOrDeletedBy: IWidgetScope;
    position: IWidgetPosition;
    users?: string[];
    options: { title: string; canBeHidden: boolean; [key: string]: any };
}
interface IWidgetPlugin {
    id: string;
    help: string;
    _root: JQuery;
    displayedWidget: IDisplayedWidget;
    pluginName(): string;
    defaultParameters(): IWidgetParameters;
    mergeOptions(parameters: IWidgetParameters): IWidgetParameters;
    render(root: JQuery, displayedWidget: IDisplayedWidget): void;
    updatePosition(w: number, h: number, x: number, y: number);
    hide(showConfirm: boolean);

    unload?(): void;

    scrollIntoView?(): void;
    clicked?(): void;
    refresh?(): void;
}
class WidgetPluginManager {
    pluginList: { [key: string]: IWidgetPlugin };

    loadedWidgets: { [key: string]: IWidgetPlugin };
    constructor() {
        this.pluginList = {};
        this.loadedWidgets = {};
    }
    registerPlugin(plugin: IWidgetPlugin) {
        this.pluginList[plugin.pluginName()] = plugin;
    }

    addLoadedWidget(key: string, widget: IWidgetPlugin) {
        let id = key;
        this.loadedWidgets[id] = widget;
    }
    unLoadAllWidgets() {
        for (let key in this.loadedWidgets) {
            if (this.loadedWidgets[key].unload) this.loadedWidgets[key].unload();
        }
        this.loadedWidgets = {};
    }
    removeLoadedWidget(displayedWidget: IDisplayedWidget) {
        let foundWidget = this.loadedWidgets[displayedWidget.id];
        if (foundWidget) {
            if (foundWidget.unload) {
                foundWidget.unload();
            }
            delete this.loadedWidgets[displayedWidget.id];
        }
    }
}

interface IGridStackWidget {
    /** widget position x (default?: 0) */
    x?: number;
    /** widget position y (default?: 0) */
    y?: number;
    /** widget dimension width (default?: 1) */
    w?: number;
    /** widget dimension height (default?: 1) */
    h?: number;
    /** if true then x, y parameters will be ignored and widget will be places on the first available position (default?: false) */
    autoPosition?: boolean;
    /** minimum width allowed during resize/creation (default?: undefined = un-constrained) */
    minW?: number;
    /** maximum width allowed during resize/creation (default?: undefined = un-constrained) */
    maxW?: number;
    /** minimum height allowed during resize/creation (default?: undefined = un-constrained) */
    minH?: number;
    /** maximum height allowed during resize/creation (default?: undefined = un-constrained) */
    maxH?: number;
    /** prevent resizing (default?: undefined = un-constrained) */
    noResize?: boolean;
    /** prevents moving (default?: undefined = un-constrained) */
    noMove?: boolean;
    /** prevents moving and resizing (default?: undefined = un-constrained) */
    locked?: boolean;
    /** widgets can have their own resize handles. For example 'e,w' will make the particular widget only resize east and west. */
    resizeHandles?: string;
    /** value for `gs-id` stored on the widget (default?: undefined) */
    id?: number | string;
    /** html to append inside as content */
    content?: string;
    /** optional nested grid options and list of children, which then turns into actual instance at runtime */
}

var widgetPluginManager: WidgetPluginManager = new WidgetPluginManager(); // plugin manager
