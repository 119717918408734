// event.metaKey
// On Macintosh keyboards, the META key maps to the Command key (⌘).
// On Windows keyboards, the META key maps to the Windows key.
// PC   -> Mac
// CTRL    -> COMMAND
// ALT     -> Option (Alt-Gr)
// WINDOWS ->
/// <reference types="matrixrequirements-type-declarations" />
import { ReviewContextFrame } from "../../../client/plugins/ReviewContextFrame";
import { app, globalMatrix, matrixApplicationUI } from "../../../globals";
import { MR1, IItemViewEvent } from "../../businesslogic/index";
import { mDHF } from "../../businesslogic/index";
import { ml } from "./../../matrixlib";

export interface IShortcut {
    disableEdge: boolean;
    disableSafari: boolean;
    withCtrl: boolean;
    keyCode: number;
    key: string;
    help: string;
    category: string;
    inElement: string[];
    notInElement: string[];
    fct: (event: JQueryEventObject, that?: KeyboardShortcuts) => void;
}
export interface IShortCutOverWrite {
    orgCtrl: boolean;
    newCtrl: boolean;
    orgKey: string;
    newKey: string;
    orgKeyCode: number;
    newKeyCode: number;
}

// TODO: unify with the one from the qms
export class KeyboardShortcuts {
    private lastKeyDown: Date;
    private shortCuts: IShortcut[];
    private documentSectionIdx: number = 0;

    constructor() {
        let that = this;

        this.defineShortcuts();

        MR1.onItemDisplayed().subscribe(this, this.initToggleSection);
    }

    private defineShortcuts() {
        let that = this;
        this.shortCuts = [];
        this.addCtrlShortcut("?", "Global", "show help dialog", null, null, this.help, true, true);
        this.addShortcut(
            "Delete",
            "Delete",
            "delete item",
            null,
            [".baseControl", ".itemTitle", "#itemDetails", "input", "textarea"],
            this.delete,
            true,
            false,
        );
        this.addCtrlShortcut("l", "Global", "(l)ocate / find / search", null, null, this.focusSearch, false, true);
        this.addShortcut(
            '<span class="short-ctrl">ctrl</span> - l - ITEM-ID <span class="short-ctrl">space</span>',
            "Global",
            "(l)ocate and select item in tree",
            null,
            null,
            this.focusSearch,
            false,
            true,
        );
        this.addCtrlShortcut(
            "m",
            "Global",
            "mirror current item in review frame",
            null,
            null,
            this.showInReview,
            false,
            false,
        );
        this.addCtrlShortcut("Z", "Global", "zen preview of items and docs", null, null, this.showZen, false, false);

        this.addCtrlShortcut(
            "1",
            "Item Editing/Creation",
            "click on 1st create / select button",
            null,
            null,
            this.createSelect,
            true,
            false,
        );
        this.addCtrlShortcut(
            "2",
            "Item Editing/Creation",
            "click on 2nd create / select button",
            null,
            null,
            this.createSelect,
            true,
            false,
        );
        this.addCtrlShortcut(
            "3",
            "Item Editing/Creation",
            "click on 3rd create / select button",
            null,
            null,
            this.createSelect,
            true,
            false,
        );
        this.addCtrlShortcut(
            "4",
            "Item Editing/Creation",
            "click on 4th create / select button",
            null,
            null,
            this.createSelect,
            true,
            false,
        );
        this.addCtrlShortcut(
            "s",
            "Item Editing/Creation",
            "save item, create in new item dialog, select in item select dialog",
            null,
            null,
            this.save,
            false,
            false,
        );
        this.addCtrlShortcut("5", "", "", null, null, this.createSelect, true, false);
        this.addCtrlShortcut("6", "", "", null, null, this.createSelect, true, false);
        this.addCtrlShortcut("7", "", "", null, null, this.createSelect, true, false);
        this.addCtrlShortcut("8", "", "", null, null, this.createSelect, true, false);
        this.addCtrlShortcut("9", "", "", null, null, this.createSelect, true, false);
        this.addCtrlShortcutCode(
            39,
            "Documents",
            "right arrow",
            "open sections in document",
            null,
            [".baseControl", "input", "textarea", "#commentDlg"],
            this.toggleSections,
            true,
            false,
        );
        this.addCtrlShortcutCode(
            37,
            "Documents",
            "left arrow",
            "close sections in document",
            null,
            [".baseControl", "input", "textarea", "#commentDlg"],
            this.toggleSections,
            true,
            false,
        );

        this.addShortcutCode(
            39,
            "Documents",
            "right arrow",
            "open current section",
            null,
            [".baseControl", "input", "textarea", "#commentDlg"],
            this.toggleSection,
            true,
            false,
        );
        this.addShortcutCode(
            38,
            "Documents",
            "up arrow",
            "section up",
            null,
            [".baseControl", "input", "textarea", "#commentDlg"],
            this.toggleSection,
            true,
            false,
        );
        this.addShortcutCode(
            37,
            "Documents",
            "left arrow",
            "close current section",
            null,
            [".baseControl", "input", "textarea", "#commentDlg"],
            this.toggleSection,
            true,
            false,
        );
        this.addShortcutCode(
            40,
            "Documents",
            "down arrow",
            "section down",
            null,
            [".baseControl", "input", "textarea", "#commentDlg"],
            this.toggleSection,
            true,
            false,
        );

        this.addCtrlShortcutCode(
            32,
            "Documents",
            "space",
            "download document",
            null,
            [".baseControl", "input", "textarea", "#commentDlg"],
            this.downloadDocument,
            false,
            true,
        );
        let ow = localStorage.getItem("shortcuts_ow");
        if (ow) {
            let oow = <IShortCutOverWrite[]>JSON.parse(ow);
            $.each(oow, function (oowidx, o) {
                $.each(that.shortCuts, function (si, s) {
                    if (s.key == o.orgKey && s.keyCode == o.orgKeyCode && s.withCtrl == o.orgCtrl) {
                        console.log(
                            "change key: " +
                                (s.withCtrl ? "ctrl-" : "") +
                                (s.keyCode ? s.keyCode : s.key) +
                                " to " +
                                (o.newCtrl ? "ctrl-" : "") +
                                (o.newKeyCode ? o.newKeyCode : o.newKey),
                        );
                        s.key = o.newKey;
                        s.keyCode = o.newKeyCode;
                        s.withCtrl = o.newCtrl;
                    }
                });
            });
        }
    }
    public print() {
        $.each(this.shortCuts, function (si, s) {
            console.log(s.help + ": " + (s.withCtrl ? "ctrl-" : "") + (s.keyCode ? s.keyCode : s.key));
        });
    }

    public resetCustomKeys() {
        localStorage.setItem("shortcuts_ow", "");
        console.log("Reset all key to default. F5 (refresh browser) to make effective");
    }
    public setKey(orgCtrl: boolean, newCtrl: boolean, orgKey: string, newKey: string) {
        let ow = localStorage.getItem("shortcuts_ow");
        let oow = <IShortCutOverWrite[]>(ow ? JSON.parse(ow) : []);
        oow.push({ orgCtrl: orgCtrl, orgKey: orgKey, newCtrl: newCtrl, newKey: newKey, orgKeyCode: 0, newKeyCode: 0 });
        localStorage.setItem("shortcuts_ow", JSON.stringify(oow));
        this.defineShortcuts();
        console.log("F5 (refresh browser) to make effective");
    }
    public setKeyCode(orgCtrl: boolean, newCtrl: boolean, orgKeyCode: number, newKeyCode: number) {
        let ow = localStorage.getItem("shortcuts_ow");
        let oow = <IShortCutOverWrite[]>(ow ? JSON.parse(ow) : []);
        oow.push({
            orgCtrl: orgCtrl,
            orgKey: "",
            newCtrl: newCtrl,
            newKey: "",
            orgKeyCode: orgKeyCode,
            newKeyCode: newKeyCode,
        });
        localStorage.setItem("shortcuts_ow", JSON.stringify(oow));
        this.defineShortcuts();
        console.log("F5 (refresh browser) to make effective");
    }
    public addCtrlShortcut(
        key: string,
        category: string,
        help: string,
        inElement: string[],
        notInElement: string[],
        fct: (event: JQueryEventObject, that?: KeyboardShortcuts) => void,
        disableEdge: boolean,
        disableSafari: boolean,
    ) {
        this.shortCuts.push({
            withCtrl: true,
            keyCode: 0,
            category: category,
            key: key,
            help: help,
            inElement: inElement,
            notInElement: notInElement,
            fct: fct,
            disableEdge,
            disableSafari,
        });
    }
    public addShortcut(
        key: string,
        category: string,
        help: string,
        inElement: string[],
        notInElement: string[],
        fct: (event: JQueryEventObject, that?: KeyboardShortcuts) => void,
        disableEdge: boolean,
        disableSafari: boolean,
    ) {
        this.shortCuts.push({
            withCtrl: false,
            keyCode: 0,
            category: category,
            key: key,
            help: help,
            inElement: inElement,
            notInElement: notInElement,
            fct: fct,
            disableEdge,
            disableSafari,
        });
    }
    public addCtrlShortcutCode(
        keyCode: number,
        category: string,
        key: string,
        help: string,
        inElement: string[],
        notInElement: string[],
        fct: (event: JQueryEventObject, that?: KeyboardShortcuts) => void,
        disableEdge: boolean,
        disableSafari: boolean,
    ) {
        this.shortCuts.push({
            withCtrl: true,
            keyCode: keyCode,
            category: category,
            key: key,
            help: help,
            inElement: inElement,
            notInElement: notInElement,
            fct: fct,
            disableEdge,
            disableSafari,
        });
    }
    public addShortcutCode(
        keyCode: number,
        category: string,
        key: string,
        help: string,
        inElement: string[],
        notInElement: string[],
        fct: (event: JQueryEventObject, that?: KeyboardShortcuts) => void,
        disableEdge: boolean,
        disableSafari: boolean,
    ) {
        this.shortCuts.push({
            withCtrl: false,
            keyCode: keyCode,
            category: category,
            key: key,
            help: help,
            inElement: inElement,
            notInElement: notInElement,
            fct: fct,
            disableEdge,
            disableSafari,
        });
    }
    public init() {
        this.lastKeyDown = new Date();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let urlGlobalShiftDown = (globalMatrix.globalShiftDown = urlParams.has("globalShiftDown"));
        let urlGlobalCtrltDown = (globalMatrix.globalCtrlDown = urlParams.has("globalCtrltDown"));

        $(window).on("keydown", (event: JQueryEventObject) => {
            const continueEvent = true;
            // console.log("key:" + event.key + " charCode: " + event.charCode + " keyCode:" + event.keyCode + " which:" + event.which + "ctrlKey: " + event.ctrlKey + "metaKey: " + event.metaKey );

            const notEdge = Modernizr.smil && !/Edge/i.test(navigator.userAgent);
            const notSafari = navigator.userAgent.indexOf("Safari") == -1 || navigator.userAgent.indexOf("Chrome") > -1;

            // shortcuts only work for reasonable browsers
            // edge leads straight to hell MATRIX-1912
            $.each(this.shortCuts, (idx: number, shortcut: IShortcut) => {
                if ((notEdge || !shortcut.disableEdge) && (notSafari || !shortcut.disableSafari)) {
                    // CTRL (PC) or COMMAND (Mac) or background clicked
                    if (event.ctrlKey || event.metaKey || !shortcut.withCtrl) {
                        // CTRL (PC) or COMMAND (Mac) or background clicked
                        if (
                            shortcut.keyCode > 0 &&
                            event.which === shortcut.keyCode &&
                            this.isGoodTarget($(event.target), shortcut.inElement, shortcut.notInElement)
                        ) {
                            shortcut.fct(event, this);

                            if (event.preventDefault) {
                                event.preventDefault();
                            }
                        } else if (
                            (event.key === shortcut.key ||
                                this.handleQuestionMarkWithoutShift(event.key, shortcut.key)) &&
                            this.isGoodTarget($(event.target), shortcut.inElement, shortcut.notInElement)
                        ) {
                            shortcut.fct(event, this);

                            if (event.preventDefault) {
                                event.preventDefault();
                            }
                        }
                    }
                }
            });

            if (event.shiftKey || urlGlobalShiftDown) {
                globalMatrix.globalShiftDown = true;
                ml.UI.updateTooltip();
            }
            if (event.ctrlKey || urlGlobalCtrltDown) {
                globalMatrix.globalCtrlDown = true;
            }
            // every minute (at most) check if the item on the server changed if so inform the user
            // this also make sure that the system does not time out while someone is writing a big text
            // also if someone writes something after he has been away a a login dialog will show
            const newKeyDown = new Date();
            if (newKeyDown.getTime() - this.lastKeyDown.getTime() > 60000) {
                app.pingCurrentItem();
                this.lastKeyDown = newKeyDown;
            }

            return continueEvent;
        });

        $(window).blur(() => {
            globalMatrix.globalShiftDown = false;
        });

        $(window).on("keyup", function (event) {
            // shift does not actually fire an event of a specific key
            if (event.keyCode === 16) {
                globalMatrix.globalShiftDown = false;
            }
            if (event.keyCode === 17 || event.keyCode === 91) {
                globalMatrix.globalCtrlDown = false;
            }
            return true;
        });
    }

    private handleQuestionMarkWithoutShift(eventKey: string, shortCutKey: string) {
        return eventKey === "/" && shortCutKey === "?";
    }

    private isGoodTarget(currentTarget: JQuery, inElement: string[], notInElement: string[]) {
        let isGood = true;
        if (inElement && inElement.length > 0) {
            isGood = false;
            $.each(inElement, function (eidx, el) {
                if (currentTarget.closest(el).length > 0) {
                    isGood = true;
                }
            });
        }
        if (!isGood) {
            return false;
        }
        if (notInElement && notInElement.length > 0) {
            $.each(notInElement, function (eidx, el) {
                if (currentTarget.closest(el).length > 0) {
                    isGood = false;
                }
            });
        }
        return isGood;
    }
    private isDialogOpen(dialogSelector?: string) {
        return KeyboardShortcuts.isDialogOpen(dialogSelector);
    }

    private static isDialogOpen(dialogSelector?: string) {
        try {
            if (dialogSelector) {
                if ($(dialogSelector).hasClass("ui-dialog-content") && $(dialogSelector).is("isOpen")) {
                    return true;
                } else if ($(dialogSelector).hasClass("modal") && $(dialogSelector).css("display") == "block") {
                    return true;
                }

                return false;
            }
            if ($(".ui-dialog").is(":visible")) {
                return true;
            }
            if ($(".modal.in").length > 0) {
                // test if any bootstrap dialog is open
                return true;
            }
        } catch (ex) {
            return false;
        }
        return false;
    }

    private showInReview(event: JQueryEventObject, that: KeyboardShortcuts) {
        // create / select dialog
        if (event.preventDefault) {
            event.preventDefault();
        }
        let itemId = app.getCurrentItemId();
        if (itemId && !ml.Item.parseRef(itemId).isFolder) {
            ReviewContextFrame.renderItem(itemId, 0, true);
        }
    }
    private showZen(event: JQueryEventObject, that: KeyboardShortcuts) {
        if (event.preventDefault) {
            event.preventDefault();
        }

        if (!that.isDialogOpen() && matrixApplicationUI.lastMainItemForm) {
            let currentPanel = matrixApplicationUI.currentPanel;
            if (currentPanel.toggleZen) {
                currentPanel.toggleZen();
            }
        }
    }
    private save(event: JQueryEventObject, that: KeyboardShortcuts) {
        // create / select dialog
        if (event.preventDefault) {
            event.preventDefault();
        }
        KeyboardShortcuts.doSave();
    }
    static doSave() {
        if (KeyboardShortcuts.isDialogOpen("#appPopup")) {
            var createBtn = $(".btnDoIt:visible", $("#appPopup").next());
            if (createBtn.length === 1 && !$(".btnDoIt:visible", $("#appPopup").next()).prop("disabled")) {
                var text = $(".btnDoIt:visible", $("#appPopup").next()).text();
                if (text === "Create" || text === "Select") {
                    createBtn.click();
                    return;
                }
            }
        } else if (!KeyboardShortcuts.isDialogOpen() && app.getNeedsSave()) {
            window.setTimeout(() => {
                matrixApplicationUI.saveSave();
            }, 50);
        }
    }
    private createSelect(event: JQueryEventObject, that: KeyboardShortcuts) {
        var createSelBtns = $(".buttonCreateSelect:visible");
        if (
            createSelBtns.length + 1 >= Number(event.key) &&
            !$(createSelBtns[Number(event.key) - 1]).prop("disabled")
        ) {
            $(createSelBtns[Number(event.key) - 1]).click();
        }
    }

    private toggleSections(event: JQueryEventObject, that: KeyboardShortcuts) {
        if (event.key === "ArrowRight") {
            $(".showHideAdmin:not(:checked)").click();
        }
        if (event.key === "ArrowLeft") {
            $(".showHideAdmin:checked").click();
        }
    }
    private delete(event: JQueryEventObject, that: KeyboardShortcuts) {
        if (!that.isDialogOpen()) {
            let btns = $(".deleteItemBtn");
            if (btns.length === 1) {
                $(btns[0]).click();
            }
        }
    }
    private initToggleSection(event: IItemViewEvent) {
        let that = <KeyboardShortcuts>event.caller;
        that.documentSectionIdx = -1;
        if (!event.item || event.item.isFolder || !mDHF.isDocumentFormType(event.item.type)) {
            return;
        }
        var sections = $("#itemDetails .cbimg");
        if (sections.length > 0) {
            that.documentSectionIdx = 0;
            $(sections[that.documentSectionIdx]).next().css("text-decoration", "underline");
        }
    }
    private toggleSection(event: JQueryEventObject, that: KeyboardShortcuts) {
        if (this.documentSectionIdx < 0) {
            return;
        }
        var sections = $("#itemDetails .cbimg");
        if (that.documentSectionIdx < sections.length) {
            let cb = $(sections[that.documentSectionIdx]).parent().prev();
            if (event.key === "ArrowRight" && !cb.is(":checked")) {
                $(sections[that.documentSectionIdx]).click();
            }
            if (event.key === "ArrowLeft" && cb.is(":checked")) {
                $(sections[that.documentSectionIdx]).click();
            }
        }
        if (event.key === "ArrowUp" && that.documentSectionIdx > 0) {
            that.documentSectionIdx--;
        }
        if (event.key === "ArrowDown" && that.documentSectionIdx < sections.length - 1) {
            that.documentSectionIdx++;
        }
        sections.next().css("text-decoration", "inherit");
        if (that.documentSectionIdx < sections.length) {
            $(sections[that.documentSectionIdx]).next().css("text-decoration", "underline");
        }
    }

    private downloadDocument(event: JQueryEventObject, that: KeyboardShortcuts) {
        $("#btnDownload").click();
    }

    private focusSearch(event: JQueryEventObject, that: KeyboardShortcuts) {
        if ($("#appPopup [name=search]").is(":visible")) {
            $("#appPopup [name=search]").focus();
        } else {
            $("#projectTree [name=search]").focus();
        }

        event.preventDefault();
    }

    private help(event: JQueryEventObject, that: KeyboardShortcuts) {
        if (app.dlgForm.html() !== "" && app.dlgForm.is(":visible")) {
            return;
        }
        if ($("#helpModal").length === 0) {
            let globals = "";
            let globalCategories: string[] = [];
            $.each(that.shortCuts, function (idx: number, shortcut: IShortcut) {
                if (shortcut.category !== "" && globalCategories.indexOf(shortcut.category) === -1) {
                    globalCategories.push(shortcut.category);
                }
            });

            $.each(globalCategories, function (cidx, category) {
                let right = true;
                globals += '<div class="row"><div class="col-sm-12"><h1>' + category + "</h1></div></div>";
                globals += '<div class="row">';

                $.each(that.shortCuts, function (idx: number, shortcut: IShortcut) {
                    if (shortcut.help !== "" && shortcut.category === category) {
                        right = !right;
                        globals +=
                            '<div class="col-sm-2 key">' + (shortcut.withCtrl ? "CMD-" : "") + shortcut.key + "</div>";
                        globals += '<div class="col-sm-4 command">' + shortcut.help + "</div>";
                        if (right && idx !== that.shortCuts.length - 1) {
                            // new row
                            globals += '</div><div class="row">';
                        }
                    }
                });
                globals += "</div>";
            });

            let help: string = `

                <div class="modal" id="helpModal" tabindex="-1" role="dialog" >
                <div class="modal-dialog large" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                      <h4 class="modal-title" >Keyboard Shortcuts</h4>
                    </div>
                    <div class="modal-body scrollable-modal">

                    ${globals}

<div class="row"><div class="col-sm-12"><h1>Text Editor</h1></div></div>

                    <div class="row">
                              <div class="col-sm-2 key">CMD-b</div><div class="col-sm-4 command">bold</div>
                              <div class="col-sm-2 key">CMD-i</div><div class="col-sm-4 command">italic</div>
                    </div>
                    <div class="row">
                              <div class="col-sm-2 key">CMD-u</div><div class="col-sm-4 command">underline</div>
                              <div class="col-sm-2 key">CMD-k</div><div class="col-sm-4 command">hyperlink</div>
                    </div>


                <div class="row"><div class="col-sm-12"><h1>Table Control</h1></div></div>

                    <div class="row">
                              <div class="col-sm-2 key">tab</div><div class="col-sm-4 command">next cell (right)</div>
                              <div class="col-sm-2 key">SHIFT-tab</div><div class="col-sm-4 command">previous cell (left)</div>
                    </div>
                    <div class="row">
                              <div class="col-sm-2 key">CMD-enter</div><div class="col-sm-4 command">below cell (down)</div>
                              <div class="col-sm-2 key">SHIFT-enter</div><div class="col-sm-4 command">open / close text editor</div>
                    </div>
                    <div class="row">
                              <div class="col-sm-2 key">esc</div><div class="col-sm-4 command">cancel edit</div>
                    </div>

<div class="row"><div class="col-sm-12"><h1>Tree Navigation</h1></div></div>

                    <div class="row">
                              <div class="col-sm-2 key">arrow down</div><div class="col-sm-4 command">down</div>
                              <div class="col-sm-2 key">arrow right</div><div class="col-sm-4 command">open folder</div>
                    </div>
                    <div class="row">
                              <div class="col-sm-2 key">arrow up</div><div class="col-sm-4 command">up</div>
                              <div class="col-sm-2 key">arrow left</div><div class="col-sm-4 command">close folder</div>
                    </div>
                    <div class="row">
                              <div class="col-sm-2 key">space</div><div class="col-sm-4 command">select/show item</div>
                              <div class="col-sm-2 key"></div><div class="col-sm-4 command"></div>
                    </div>

                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>`;
            if (navigator.appVersion.indexOf("Mac") > -1) {
                help = help.replace(/CMD-/g, '<span class="short-ctrl">\u2318</span><span class="short-and">-</span>');
                help = help.replace(
                    /SHIFT-/g,
                    '<span class="short-ctrl">\u21E7</span><span class="short-and">-</span>',
                );
            } else {
                help = help.replace(/CMD-/g, '<span class="short-ctrl">ctrl</span><span class="short-and">-</span>');
                help = help.replace(/SHIFT-/g, '<span class="short-ctrl">shift</span><span class="short-and">-</span>');
            }
            $("body").append(help);
        }
        $("#helpModal").modal();
    }
}
