import { ItemSelectionFieldHandler } from "../ItemSelectionFieldHandler";
import { IDocFieldHandler } from "./IDocFieldHandler";
import { IAnyMap, IGenericMap } from "../../../../globals";
import { XRFieldTypeAnnotatedParamJson } from "../../ItemConfiguration";
import { extend } from "../../../matrixlib/JQueryExtendReplacement";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { IDesignReview } from "../../../../ProjectSettings";

export interface IDesignReviewsOptions extends IDHFSectionOptions {
    reviews: string[];
    user: boolean;
    date: boolean;
    revision: boolean;
    comment: boolean;
    includeInToc: boolean;
    numericalOrder: boolean;
    search?: string;
}

interface DesignReviewDocFieldHandlerArgs {
    sectionType: string;
    config?: XRFieldTypeAnnotatedParamJson;
    fieldValue?: string;
    designReviewLabels?: IDesignReview[];
}

export class DesignReviewDocFieldHandler extends ItemSelectionFieldHandler implements IDocFieldHandler {
    // TODO: make stricter
    dhfFieldConfig: IAnyMap;
    private designReviewLabels: IDesignReview[];

    constructor({ sectionType, config, fieldValue, designReviewLabels }: DesignReviewDocFieldHandlerArgs) {
        super(config, sectionType);

        this.designReviewLabels = designReviewLabels || [];

        if (!config) {
            config = this.getDefaultConfig();
        }

        this.dhfFieldConfig = config;
        this.initData(fieldValue);
    }

    getDefaultConfig(): IDesignReviewsOptions {
        return {
            reviews: this.designReviewLabels.map((label) => label.label),
            user: false,
            date: false,
            revision: false,
            comment: false,
            includeInToc: false,
            numericalOrder: false,
        };
    }

    setDHFConfig(config: IAnyMap) {
        this.dhfFieldConfig = config;
    }

    getXmlValue(): string {
        let js = this.getItems();

        if (!js) {
            return JSON.stringify({});
        }

        let controllerConfig = this.dhfFieldConfig;

        let reviews = {
            user: controllerConfig.user,
            date: controllerConfig.date,
            revision: controllerConfig.revision,
            comment: controllerConfig.comment,
            includeInToc: controllerConfig.includeInToc,
            numericalOrder: controllerConfig.numericalOrder,
        };

        if (controllerConfig.reviews != undefined) {
            for (let review of controllerConfig.reviews) {
                (<IGenericMap>reviews)[review] = "1";
            }
        }

        for (let row = 0; row < js.length; row++) {
            extend(true, js[row], reviews);
        }

        return JSON.stringify(js);
    }
}
