import { IPlugin, IProjectPageParam, plugins } from "../../../common/businesslogic/index";
import { mDHF } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IReportOptions } from "../../../common/matrixlib/MatrixLibInterfaces";
import { SelectMode, ILinkType } from "../../../common/UI/Components/index";
import { ItemSelectionTools } from "../../../common/UI/Tools/ItemSelectionView";
import { IItem, globalMatrix, matrixSession, IReference, app, restConnection } from "../../../globals";

import { XRPostProject_LaunchReport_CreateReportJobAck } from "../../../RestResult";

export { initialize };

class Compare implements IPlugin {
    private _item: IItem;
    private _jui: JQuery;
    public isDefault = true;

    initItem(item: IItem, jui: JQuery) {
        this._item = item;
        this._jui = jui;
    }

    initServerSettings() {}
    initProject() {}
    async getProjectPagesAsync() {
        return [];
    }

    supportsControl() {
        return false;
    }

    updateMenu(ul: JQuery) {
        let that = this;

        if (
            !ml.Item.parseRef(this._item.id).isFolder &&
            mDHF.isDocumentType(this._item.type) &&
            this._item.type !== "REPORT"
        ) {
            var extras = globalMatrix.ItemConfig.getExtrasConfig();

            if (
                extras &&
                (ml.JSON.isTrue(extras.compareX) || (extras.compareX === "admin" && matrixSession.isAdmin()))
            ) {
                var compareDocs = $('<li><a href="javascript:void(0)">Compare Docs</a></li>').click(function () {
                    let selectTools = new ItemSelectionTools();
                    let linkTypes: ILinkType[] = [{ type: that._item.type }];
                    let otherProject: string;

                    selectTools.showCrossProjectDialog({
                        linkTypes: linkTypes,
                        getSelectedItems: async function () {
                            return [{ to: that._item.id, title: "" }]; // same ID by default
                        },
                        selectionChange: function (newRefs: IReference[]) {
                            if (newRefs.length === 1) {
                                let signId = newRefs[0].to;
                                that.compareWith(otherProject, signId);
                            }
                        },
                        selectMode: SelectMode.singleItem,
                        crossProjectInit: function (psl: string) {
                            otherProject = psl;
                        },
                        crossProject: true,
                    });
                });
                ul.append(compareDocs);
            }
        }
    }

    private compareWith(toProject: string, toId: string) {
        let that = this;
        let reportProc: number;

        let ui = $("<div style='width:100%'>");
        ui.append(ml.UI.getSpinningWait("retrieving documents..."));

        let left: string;
        let right: string;

        // show dialog
        app.dlgForm.html("").append(ui);

        app.dlgForm.addClass("dlg-v-scroll");
        app.dlgForm.removeClass("dlg-no-scroll");

        app.dlgForm
            .dialog({
                autoOpen: true,
                title: "Compare documents",
                height: app.itemForm.height() * 0.9,
                width: $(document).width() * 0.9,
                modal: true,
                close: function () {
                    app.dlgForm.removeClass("forcePrint");
                    $(".compareTools").remove();
                },
                open: function () {
                    app.dlgForm.addClass("forcePrint");
                },
                resizeStop: function (event, ui) {
                    app.dlgForm.resizeDlgContent([]);
                },
                buttons: [
                    {
                        text: "Ok",
                        class: "btnCancelIt",
                        click: function () {
                            app.dlgForm.dialog("close");
                        },
                    },
                ],
            })
            .resizeDlgContent([], false);

        app.startCreateDocumentAsync(this._item.id, { format: "html" }).done(function (
            result: XRPostProject_LaunchReport_CreateReportJobAck,
        ) {
            mDHF.loadDocument(result.jobId, function (htmlDOM: any) {
                left = htmlDOM;
                mDHF.compareDocumentsContent(that._item.id, toProject + ":" + toId, left, right, ui);
            });
        });
        this.startCreateDocumentOtherProjectAsync(toProject, toId, { format: "html" }).done(function (
            result: XRPostProject_LaunchReport_CreateReportJobAck,
        ) {
            mDHF.loadDocument(result.jobId, function (htmlDOM: any) {
                right = htmlDOM;
                mDHF.compareDocumentsContent(that._item.id, toProject + ":" + toId, left, right, ui);
            });
        });
    }

    startCreateDocumentOtherProjectAsync(
        projectId: string,
        itemId: string,
        reportOptions: IReportOptions,
    ): JQueryDeferred<XRPostProject_LaunchReport_CreateReportJobAck> {
        var res: JQueryDeferred<XRPostProject_LaunchReport_CreateReportJobAck> = $.Deferred();
        reportOptions["url"] = globalMatrix.matrixBaseUrl;
        reportOptions["resturl"] = globalMatrix.matrixRestUrl;
        let isSign = mDHF.isSignedType(ml.Item.parseRef(itemId).type);
        restConnection
            .postServer(projectId + "/" + (isSign ? "signedreport/" : "report/") + itemId, reportOptions)
            .done(function (result) {
                res.resolve(result as XRPostProject_LaunchReport_CreateReportJobAck);
            });

        return res;
    }
}

function initialize() {
    plugins.register(new Compare());
}
