import { ItemConfiguration } from "../../ItemConfiguration";
import { SectionDescriptions } from "./SectionDescriptions";
import { GenericDocFieldHandler } from "./GenericDocFieldHandler";
import { GenericTableDocHandler } from "./GenericTableDocHandler";
import { IDHFControlDefinitionValue } from "../../PluginManagerDocuments";
import { IAnyMap } from "../../../../globals";
import { DateDocHandler } from "./DateDocHandler";
import { IBaseDropdownFieldParams } from "../DropdownFieldHandler";
import { CustomDocFieldHandler } from "./CustomDocFieldHandler";
import { RemarksDocFieldHandler } from "./RemarksDocFieldHandler";
import { CheckBoxDocFieldHandler } from "./CheckBoxDocFieldHandler";
import { DesignReviewDocFieldHandler } from "./DesignReviewDocFieldHandler";
import { ItemIndexDocFieldHander } from "./ItemIndexDocFieldHander";
import { ItemListDocFieldHandler } from "./ItemListDocFieldHandler";
import { LinkListDocFieldHandler } from "./LinkListDocFieldHandler";
import { ItemTableDocFieldHandler } from "./ItemTableDocFieldHandler";
import { RiskStatsDocFieldHandler } from "./RiskStatsDocFieldHandler";
import { ItemRefDocFieldHandler } from "./ItemRefDocFieldHandler";
import { IDocFieldHandler } from "./IDocFieldHandler";
import { ListOfFiguresDocFieldHandler } from "./ListOfFiguresDocFieldHandler";
import { SmartTextDocFieldHandler } from "./SmartTextDocFieldHandler";
import { TableOfContentDocFieldHandler } from "./TableOfContentDocFieldHandler";
import { TestResultsDocFieldHandler } from "./testResultsDocFieldHandler";
import { DerivedFromDocFieldHandler } from "./DerivedFromDocFieldHandler";
import { DocumentOptionsFieldHandler } from "./DocumentOptionsFieldHandler";
import { TextLineDocFieldHandler } from "./TextLineDocFieldHandler";
import { TraceMatrixDocFieldHandler } from "./TraceMatrixDocFieldHandler";
import { TraceDocFieldHandler } from "./TraceDocFieldHandler";
import { MultiSelectDocFieldHandler } from "./DropdownDocFieldHandler";
import { RichTextDocFieldHandler } from "./RichTextDocFieldHandler";
import { DashboardDocFieldHandler } from "./DashboardDocFieldHandler";
import { IDashboardParams } from "../../../../client/plugins/DocumentSections/Dashboard";

export class DocFieldHandlerFactory {
    public static GetDHFFieldConfig(itemConfig: ItemConfiguration, fieldType: string, config: IAnyMap): IAnyMap {
        let dhfConfig = itemConfig.getDHFConfig();
        let foundConfig = undefined;
        // special case for signatures
        if (
            fieldType == SectionDescriptions.section_signaturebox ||
            fieldType == SectionDescriptions.section_templateapproval
        )
            fieldType = "signatures";

        if (dhfConfig && dhfConfig[fieldType]) {
            foundConfig = dhfConfig[fieldType];
        }
        if (foundConfig != undefined) return { ...foundConfig, ...(config || {}) };
        return config;
    }

    static createHandler(itemConfig: ItemConfiguration, dhfValue: IDHFControlDefinitionValue): IDocFieldHandler {
        let returnedFieldHandler: IDocFieldHandler = undefined;

        switch (dhfValue.type) {
            case SectionDescriptions.section_audittrail:
            case SectionDescriptions.section_signatures:
            case SectionDescriptions.section_signaturebox:
            case SectionDescriptions.section_responsibilities:
            case SectionDescriptions.section_genericTable:
            case SectionDescriptions.section_templateapproval:
            case SectionDescriptions.section_dhf_table:
                returnedFieldHandler = new GenericTableDocHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;
            case SectionDescriptions.section_Date:
            case SectionDescriptions.section_duedate:
                returnedFieldHandler = new DateDocHandler(dhfValue.type, dhfValue.ctrlConfig, dhfValue.fieldValue);
                break;
            case SectionDescriptions.section_remarks:
                returnedFieldHandler = new RemarksDocFieldHandler(
                    itemConfig,
                    dhfValue.type,
                    <IBaseDropdownFieldParams>dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;
            case SectionDescriptions.section_multiselect:
                returnedFieldHandler = new MultiSelectDocFieldHandler(
                    itemConfig,
                    dhfValue.type,
                    <IBaseDropdownFieldParams>dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;
            case SectionDescriptions.section_downtraces:
            case SectionDescriptions.section_uptraces:
                returnedFieldHandler = new TraceDocFieldHandler(
                    itemConfig,
                    dhfValue.type,
                    <IBaseDropdownFieldParams>dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_dashboard:
                returnedFieldHandler = new DashboardDocFieldHandler(
                    dhfValue.type,
                    <IDashboardParams>dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_designreviews:
                returnedFieldHandler = new DesignReviewDocFieldHandler({
                    sectionType: dhfValue.type,
                    config: dhfValue.ctrlConfig,
                    fieldValue: dhfValue.fieldValue,
                    designReviewLabels: itemConfig.getLabelsConfig().design_reviews,
                });
                break;

            case SectionDescriptions.section_package:
            case SectionDescriptions.section_itemlist:
                returnedFieldHandler = new ItemRefDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_riskstats:
                returnedFieldHandler = new RiskStatsDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;
            case SectionDescriptions.section_linklist:
                returnedFieldHandler = new LinkListDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;
            case SectionDescriptions.section_itemtable:
                returnedFieldHandler = new ItemTableDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_items:
                returnedFieldHandler = new ItemListDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_Checkbox: // Legacy
            case SectionDescriptions.section_checkbox:
                returnedFieldHandler = new CheckBoxDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;
            case SectionDescriptions.section_Custom: // Legacy
            case SectionDescriptions.section_CustomSection:
                returnedFieldHandler = new CustomDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_item_index:
                returnedFieldHandler = new ItemIndexDocFieldHander(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_figures_tables:
                returnedFieldHandler = new ListOfFiguresDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;
            case SectionDescriptions.section_terms_abbreviations:
                returnedFieldHandler = new SmartTextDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;
            case SectionDescriptions.section_table_of_content:
                returnedFieldHandler = new TableOfContentDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_testresults:
                returnedFieldHandler = new TestResultsDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_derivedfrom:
                returnedFieldHandler = new DerivedFromDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;
            case SectionDescriptions.section_document_options:
                returnedFieldHandler = new DocumentOptionsFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_richtext:
                returnedFieldHandler = new RichTextDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_textline:
                returnedFieldHandler = new TextLineDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;

            case SectionDescriptions.section_tracematrix:
                returnedFieldHandler = new TraceMatrixDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
                break;
            default:
                returnedFieldHandler = new GenericDocFieldHandler(
                    dhfValue.type,
                    dhfValue.ctrlConfig,
                    dhfValue.fieldValue,
                );
        }

        if (dhfValue.name) {
            returnedFieldHandler.setFieldName(dhfValue.name);
        }

        return returnedFieldHandler;
    }
}
