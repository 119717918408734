import { FieldDescriptions } from "../FieldDescriptions";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { IFieldHandler } from "./IFieldHandler";
export { HyperlinkFieldHandler };

class HyperlinkFieldHandler implements IFieldHandler {
    private data: string;
    private config: XRFieldTypeAnnotatedParamJson;

    constructor(configIn: XRFieldTypeAnnotatedParamJson) {
        this.config = configIn;
        this.data = undefined;
    }

    getFieldType(): string {
        return FieldDescriptions.Field_hyperlink;
    }

    initData(serializedFieldData: string | undefined) {
        this.data = serializedFieldData;
    }

    getData(): string | undefined {
        return this.data;
    }

    setData(value: string, doValidation?: boolean) {
        this.data = value;
    }
}
