import { IDocFieldHandler } from "./IDocFieldHandler";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { GenericDocAbstractFieldHandler } from "./GenericDocAbstractFieldHandler";

export interface IListOfFiguresOptions extends IDHFSectionOptions {
    figures?: boolean;
    tables?: boolean;
}

export class ListOfFiguresDocFieldHandler extends GenericDocAbstractFieldHandler implements IDocFieldHandler {
    getXmlValue(): string {
        return JSON.stringify([this.dhfFieldConfig]);
    }

    getDefaultConfig(): IListOfFiguresOptions {
        return {
            figures: true,
            tables: true,
        };
    }
}
