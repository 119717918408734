import { ControlState, globalMatrix, matrixSession } from "../../../globals";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ICheckBoxControlOptions } from "./checkBox";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export { PublishedContentImpl };

$.fn.publishedContent = function (this: JQuery, options: IBaseControlOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_publishedContent,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new PublishedContentImpl(this, options.fieldHandler as GenericFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class PublishedContentImpl extends BaseControl<GenericFieldHandler> {
    private settings: ICheckBoxControlOptions;

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: ICheckBoxControlOptions) {
        let that = this;

        var defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dumy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
            },
        };
        this.settings = options;
        // initialize object

        this._root.append(
            "<a href='" +
                globalMatrix.matrixBaseUrl +
                "/pub/" +
                matrixSession.getProject() +
                "/" +
                this.settings.id +
                "' target='_blank'>Open this published QMS version</a>",
        );
    }

    async hasChangedAsync() {
        return false;
    }
    getValueAsync() {
        return this.settings.fieldValue;
    }
    setValue(value: boolean) {}

    destroy() {}
    resizeItem() {}
}
