import { globalMatrix } from "../globals";

export { ServerStorageMobile };

class ServerStorageMobile {
    private Server: string;
    constructor() {
        this.Server = globalMatrix.matrixBaseUrl + "_";
    }
    setItem(itemKey: string, itemVal: string) {
        localStorage.setItem(this.Server + itemKey, itemVal);
    }
    getItem(itemKey: string): string {
        return localStorage.getItem(this.Server + itemKey);
    }
    getItemDefault(itemKey: string, defaultValue: string): string {
        let s = localStorage.getItem(this.Server + itemKey);
        return s ? s : defaultValue;
    }
}
