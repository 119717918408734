import { IDateTimeUI, ITimeZoneSetting } from "./MatrixLibInterfaces";
import { app, matrixSession, restConnection } from "../../globals";
import { ml } from "../matrixlib";
import { XRGetUser_User_UserType } from "../../RestResult";
import { XCPostAddProjectSetting } from "../../RestCalls";
import { IGetProjectResultDateInfo } from "../businesslogic";
import { DateTimeBL } from "../businesslogic/DateTimeBL";

class DateTimeUI implements IDateTimeUI {
    initDateTimeSettings(update?: boolean): Promise<void> {
        return DateTimeBL.getInstance().initDateTimeSettings(update);
    }
    renderHumanDate(date: Date, dateOnly?: boolean): string {
        return DateTimeBL.getInstance().renderHumanDate(date, dateOnly);
    }
    renderCustomerHumanDate(date: Date, dateOnly?: boolean): string {
        return DateTimeBL.getInstance().renderCustomerHumanDate(date, dateOnly);
    }
    renderHumanMonth(dateObj: Date): string {
        return DateTimeBL.getInstance().renderHumanMonth(dateObj);
    }
    renderDashFormat(dateObj: Date): string {
        return DateTimeBL.getInstance().renderDashFormat(dateObj);
    }

    getSimpleDateFormat(): string {
        return DateTimeBL.getInstance().getSimpleDateFormat();
    }
    getSimpleDateFormatMoment() {
        return DateTimeBL.getInstance().getSimpleDateFormatMoment();
    }
    getSimpleDateTimeFormatMoment() {
        return DateTimeBL.getInstance().getSimpleDateTimeFormatMoment();
    }

    // *******************************************************
    // helper for ui to manage time and date settings
    // *******************************************************

    // gets the current server or user setting from the server and renders
    // the controls to see and modify the settings
    private async showControlsAsync(controlOptions: { table: JQuery; user: string; help: string }): Promise<void> {
        let that = this;
        let table = controlOptions.table;
        // shows the time setting controls for the server or a user
        let currentSettings = await this.initAsync(controlOptions.user);
        try {
            let tbody = $("tbody", table);
            tbody.append($('<tr><td colspan="2" class="baseControlHelp">' + controlOptions.help + "</td></tr>"));

            let isUserOwnSetting =
                controlOptions.user && controlOptions.user.toLowerCase() === matrixSession.getUser().toLowerCase();
            let isUserSetting = controlOptions.user;

            that.renderDateTimeFormat(
                tbody,
                isUserSetting,
                isUserOwnSetting,
                currentSettings,
                "timeformat",
                "Date Time Formatting",
            );
            that.renderDateTimeFormat(
                tbody,
                isUserSetting,
                isUserOwnSetting,
                currentSettings,
                "dateformat",
                "Date Only Formatting",
            );
            that.renderTimeZone(
                tbody,
                isUserSetting,
                isUserOwnSetting,
                currentSettings,
                "timezone",
                controlOptions.user ? "User Timezone" : "Server Timezone",
            );
        } catch (e) {
            ml.UI.showError("Could not retrieve date time settings from server", "");
        }
    }

    // gets the server or user time/date settings from the server (if specified)

    private renderDateTimeFormat(
        tableBody: JQuery,
        isUserSetting: string,
        isUserOwnSetting: boolean,
        currentSettings: ITimeZoneSetting,
        settingName: string,
        explanation: string,
    ): void {
        let that = this;
        let help = $(
            '<a href="https://urlshort.matrixreq.com/d24/manual/datetime" target="_blank"><span style="top:-4px; margin: 0px 3px;font-size: 12px" class="fal fa-info-circle"></span></a>',
        );
        let timeUpdate: number;
        let si = $('<input autocomplete="off" type="text" class="form-control admin_utime">').on("keyup", function () {
            clearTimeout(timeUpdate);
            timeUpdate = window.setTimeout(function () {
                that.showTime(example, si.val());
                if (example.html() === si.data("last_val") || example.html() === "") {
                    setb.prop("disabled", true);
                    setb.removeClass("btn-success");
                    setb.addClass("btn-default");
                } else {
                    setb.prop("disabled", false);
                    setb.addClass("btn-success");
                    setb.removeClass("btn-default");
                }
            }, 300);
        });
        let resetb = $("<button class='btn btn-default' style='margin-right:10px' >Reset</button>").click(function () {
            that.setSetting(isUserOwnSetting, currentSettings.settingsSource, settingName, "");
            si.val(
                settingName === "timeformat"
                    ? DateTimeBL.getInstance().serverDateTimeFormat
                    : DateTimeBL.getInstance().serverDateOnlyFormat,
            );
            that.showTime(example, si.val());
            si.data("last_val", example.html());
            setb.removeClass("btn-success");
            setb.addClass("btn-default");
            setb.prop("disabled", true);
        });
        let setb = $("<button class='btn btn-default' style='margin-right:10px' disabled>Use</button>").click(
            async function () {
                await that.setSetting(isUserOwnSetting, currentSettings.settingsSource, settingName, si.val());
                si.data("last_val", example.html());
                setb.removeClass("btn-success");
                setb.addClass("btn-default");
                setb.prop("disabled", true);
            },
        );
        let example = $("<span>");
        let tr = $("<tr>");
        tableBody.append(tr);
        tr.append(
            $("<td class='admin_utdl'>").append($("<label class='control-label'>").html(explanation)).append(help),
        );
        tr.append($("<td>").append(si));
        tr.append($("<td>").append(setb));
        tr.append($("<td>").append(resetb));
        let tre = $("<tr style='vertical-align:top;height:36px'>");
        tableBody.append(tre);
        tre.append($("<td>"));
        tre.append(
            $("<td colspan='3' style='font-size:75%'>")
                .append("<span style='color:darkgrey'>Example: </span>")
                .append(example),
        );
        let cs: string = currentSettings[settingName] as string;
        si.val(
            cs
                ? cs
                : settingName === "timeformat"
                ? DateTimeBL.getInstance().serverDateTimeFormat
                : DateTimeBL.getInstance().serverDateOnlyFormat,
        );
        this.showTime(example, si.val());
        si.data("last_val", example.html());
    }

    private showTime(uic: JQuery, format: string): void {
        let date = new Date(2014, 1, 15, 6, 23, 55);
        let df = new SimpleDateFormat(format);
        uic.html(df.format(date));
    }

    private async setSetting(
        isUserOwnSetting: boolean,
        serverOrUserPrefix: string,
        key: string,
        val: string,
    ): Promise<void> {
        let that = this;
        let postSetSetting: XCPostAddProjectSetting = {
            value: val,
            key: key,
            reason: "settings change",
        };
        try {
            await restConnection.postServer(serverOrUserPrefix, postSetSetting);
            await that.initDateTimeSettings(true);
        } catch (e) {
            ml.UI.showError("Failed to change setting!", "");
            throw e;
        }
    }

    private renderTimeZone(
        tableBody: JQuery,
        isUserSetting: string,
        isUserOwnSetting: boolean,
        currentSettings: ITimeZoneSetting,
        settingName: string,
        explanation: string,
    ): void {
        let that = this;

        let dd = $('<select class="form-control admin_utime">');
        dd.change(function () {
            example.html("");
            if (dd.val() === example.data("last_val")) {
                setb.removeClass("btn-success");
                setb.addClass("btn-default");
            } else {
                setb.addClass("btn-success");
                setb.removeClass("btn-default");
            }
        });
        for (let idx = 0; idx < currentSettings.timeZoneOptions.length; idx++) {
            dd.append(
                $(
                    "<option value='" +
                        currentSettings.timeZoneOptions[idx].val +
                        "'>" +
                        currentSettings.timeZoneOptions[idx].text +
                        "</option>",
                ),
            );
        }

        let resetb = $("<button class='btn btn-default' style='margin-right:10px'>Reset</button>").click(async () => {
            await that.setSetting(isUserOwnSetting, currentSettings.settingsSource, settingName, "");
            dd.val(DateTimeBL.getInstance().serverTimezone);
            example.html(that.renderTimeZoneWarning(isUserOwnSetting));
            setb.removeClass("btn-success");
            setb.addClass("btn-default");
        });
        let setb = $("<button class='btn btn-default' style='margin-right:10px'>Use</button>").click(async () => {
            await that.setSetting(isUserOwnSetting, currentSettings.settingsSource, settingName, dd.val());
            example.html(that.renderTimeZoneWarning(isUserOwnSetting));
            setb.removeClass("btn-success");
            setb.addClass("btn-default");
        });
        let example = $('<span style="color:red">');
        let tr = $("<tr>");
        tableBody.append(tr);
        tr.append($("<td class='admin_utdl'>").append($("<label class='control-label'>").html(explanation)));
        tr.append($("<td>").append(dd));
        tr.append($("<td>").append(setb));
        tr.append($("<td>").append(resetb));
        let tre = $("<tr>");
        tableBody.append(tre);
        tre.append($("<td>"));
        tre.append($("<td colspan='3'>").append(example));
        let cs: string = currentSettings[settingName] as string;
        dd.val(cs ? cs : DateTimeBL.getInstance().serverTimezone);
        example.html(this.renderTimeZoneWarning(isUserOwnSetting)).data("last_val", dd.val());
    }

    private renderTimeZoneWarning(isUserOwnSetting: boolean): string {
        return isUserOwnSetting && ml.UI.DateTime.requiresTimeZoneWarning()
            ? "the timezone of your PC seems different!"
            : "";
    }

    // render user controls to see / change the settings
    async renderSettingControlsAsync(options: { table: JQuery; user: string; help: string }) {
        await this.showControlsAsync(options);
    }

    // shows a pop dialog with control to modify date time settings
    renderSettingDialog(user: string): void {
        app.dlgForm.hide();
        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-no-scroll");
        app.dlgForm.addClass("dlg-v-scroll");
        let table = $("<table style='width:100%'><tbody/></table>");

        app.dlgForm.append(table);
        app.dlgForm.dialog({
            autoOpen: true,
            title: "User Date Time Settings: " + user,
            width: 700,
            height: 430,
            modal: true,
            resizeStop: function () {
                //We don't do anything here.
            },
            open: async () => {
                await ml.UI.DateTime.renderSettingControlsAsync({
                    user: user,
                    help: "Settings",
                    table: table,
                });
            },
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: function () {
                        app.dlgForm.dialog("close");
                    },
                },
            ],
        });
    }

    // returns an empty string or a warning if the time zone on the PC is different to the server settings
    requiresTimeZoneWarning(): boolean {
        let d = new Date(DateTimeBL.getInstance().dateIso8601FromServer);
        let ds = DateTimeBL.getInstance().simpleDateTimeFormat.format(d);
        if (ds !== DateTimeBL.getInstance().dateUserFromServer) {
            return true;
        } else {
            return false;
        }
    }

    // return a clickable link button if there are timezone issues
    getTimeZoneCTA(): JQuery {
        if (!ml.UI.DateTime.requiresTimeZoneWarning()) {
            return $("");
        }

        let button = $("<button class='buttonCTA'></button>")
            .text("verify your time zone")
            .click(function () {
                try {
                    if (app.dlgForm.dialog("isOpen")) {
                        app.dlgForm.dialog("close");
                    }
                } catch (exception) {
                    // nothing bad - it just means no dialog was ever opened before
                }
                matrixSession.changePassword();
            });
        return button;
    }

    protected async initAsync(user: string): Promise<ITimeZoneSetting> {
        // returns the following settings from the server... currently 3 rest calls are needed
        // if no user is passed the global server settings are retrieved
        let result: ITimeZoneSetting = {
            timeZoneOptions: [],
            settingsSource: user ? "user/" + user + "/setting" : "all/setting",
        };
        let allTimeZones = <string[]>await restConnection.getServer("all/timezone");

        for (let idx = 0; idx < allTimeZones.length; idx++) {
            result.timeZoneOptions.push({
                val: allTimeZones[idx],
                text: allTimeZones[idx],
            });
        }
        // check for user overwrites
        if (user) {
            let userInfo = <XRGetUser_User_UserType>await restConnection.getServer("user/" + user);
            let settings = userInfo.userSettingsList;
            for (let idx = 0; idx < settings.length; idx++) {
                if (settings[idx].key === "timeformat") {
                    result.timeformat = settings[idx].value;
                }
                if (settings[idx].key === "dateformat") {
                    result.dateformat = settings[idx].value;
                }
                if (settings[idx].key === "timezone") {
                    result.timezone = settings[idx].value;
                }
            }
        }
        return result;
    }
}

export { DateTimeUI };
