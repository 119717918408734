import { IDropDownConfig, IDropdownOption } from "../../../../ProjectSettings";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { DropdownAbstractDocFieldHandler } from "./DropdownAbstractDocFieldHandler";

export interface IMultiSelectOptions extends IDHFSectionOptions {
    maxItems: number;
    create: boolean;
    sort: boolean;
    optionSetting: string;
}

export class MultiSelectDocFieldHandler extends DropdownAbstractDocFieldHandler {
    getXmlValue(): string {
        let selectionIds = this.getValues(false);

        // convert to values
        let selectionLabels: IDropdownOption[] = [];
        let dropdownOptions = <IDropDownConfig>this.itemConfig.getSettingJSON(this.dhfFieldConfig.optionSetting);
        if (dropdownOptions) {
            for (let id of selectionIds) {
                let label = id;
                for (let option of dropdownOptions.options) {
                    if (option.id == id) {
                        label = option.label;
                    }
                }
                selectionLabels.push({ label: label, id: id });
            }
            selectionLabels.push(<any>{ optionSetting: this.dhfFieldConfig.optionSetting });
        }
        return JSON.stringify(selectionLabels);
    }

    getDefaultConfig(): IMultiSelectOptions {
        return {
            maxItems: 1,
            create: false,
            sort: false,
            optionSetting: "",
        };
    }
}
