import { ControlState, globalMatrix } from "../../../globals";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";

export type { ISectionOptions, ISectionParams };
export { SectionImpl };

interface ISectionOptions extends IBaseControlOptions {
    controlState?: ControlState;
    help?: string;
    parameter?: ISectionParams;
}

interface ISectionParams {
    lineBefore?: boolean;
    lineAfter?: boolean;
}

$.fn.section = function (this: JQuery, options: ISectionOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_section,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new SectionImpl(this, options.fieldHandler as EmptyFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class SectionImpl extends BaseControl<EmptyFieldHandler> {
    private curValue: any;

    constructor(control: JQuery, fieldHandler: EmptyFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: ISectionOptions) {
        if (options.parameter != undefined && (options.parameter.lineBefore ?? false)) {
            this._root.append($("<hr/>"));
        }

        this._root.append(super.createHelp(options));
        if (options.parameter != undefined && (options.parameter.lineAfter ?? false)) {
            this._root.append($("<hr/>"));
        }
    }
    async getValueAsync() {
        return "";
    }
    setValue(newValue: any) {}
    async hasChangedAsync() {
        return false;
    }
    destroy() {}
    resizeItem() {}
}
