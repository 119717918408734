import { app, globalMatrix, matrixSession } from "../../../globals";
import { IPanel } from "../Application";
import { HistoryTools } from "../Tools/ItemHistoryView";

export { DocumentPanel };

class DocumentPanel implements IPanel {
    title = "Document Status";
    constructor() {
        document.title = this.title + " - " + matrixSession.getProject();

        var ht = new HistoryTools();
        ht.showReadersDigest(app.itemForm);
    }

    destroy() {}
}
