/// <reference types="matrixrequirements-type-declarations" />
import { ICompanyUISettings } from "../../businesslogic/index";
import { ISearchCounts } from "./ProjectTree";
import { MyNodeData, MyNode, SelectMode } from "./ProjectViewDefines";
import { ProjectView } from "./ProjectView";
import { ml } from "./../../matrixlib";
import { NavBar } from "./NavigationBar";
import { refLinkStyle, refLinkTooltip } from "../Parts/RefLinkDefines";
import { app, globalMatrix, matrixSession } from "../../../globals";

export type { IUIMap, IKeyTitle };
export { ListView };

interface IUIMap {
    [key: string]: JQuery;
}

interface IKeyTitle {
    key: string;
    title: string;
}
class ListView {
    // list control
    public control: JQuery;
    // results from the last search
    private hits: string[] = [];
    private selected: string[] = [];
    private projectWarn: boolean = false;
    private panel: ProjectView;
    private sRoot: JQuery = $("<div>"); // a list with all selected nodes
    private uRoot: JQuery = $("<div>"); // a list with all search results
    private nodes: IUIMap = {}; // a lookup from id to ui node to show / hide search results
    private noSelected: JQuery; // node showing that nothing is selected

    constructor(panel: ProjectView) {
        this.panel = panel;
        this.control = $("<div class='listContent'>");

        this.panel.appendController(this.control);
        if (panel.settings.glueToBottom) {
            this.control.css("bottom", 0);
        }
        this.control.hide();
    }

    // show the list
    show() {
        this.control.show();
    }
    // hide the list
    hide() {
        this.control.hide();
    }
    setSelectedItems(selectedItems: string[]) {
        let that = this;

        $.each(selectedItems, function (hidx, hit) {
            if (that.selected.indexOf(hit) === -1) {
                that.selected.push(hit);
                var node = that.getFancyTree().getNodeByKey(hit);
                if (node) {
                    node.setSelected(true);
                }
            }
        });
        this.showNodes();
    }

    redrawItem(itemId: string) {
        let newNode = this.getFancyTree().getNodeByKey(itemId);

        // this can be called if the visibility status changed or the item was renamed
        $.each($(".node_" + itemId, this.control), function (idx, ui_node) {
            if (!newNode) {
                $(ui_node).hide();
            } else {
                (<MyNodeData>newNode.data).isUnselected ? $(ui_node).hide() : $(ui_node).show();
                let title = newNode.title;
                if (title.indexOf(newNode.key + " ") == 0) {
                    // that's a weird hack -> in items the newNode.title has the item ID inside, in folders the newNode.data.title
                    // we need to have just the title
                    title = title.replace(newNode.key + " ", "");
                }
                $(".refTitle", $(ui_node)).html(title);
            }
        });
    }

    filterList(match: string): ISearchCounts {
        let that = this;

        this.projectWarn = false;
        this.selected = [];
        this.hits = [];
        var hitCount = 0;

        if (match) {
            var treeMatch = (match + "").replace(/([.?*+\^\$\[\]\\(){}|-])/g, "\\$1"); // make sure a '.' is treated literally
            var treeMatchRegEx = new RegExp(".*" + treeMatch + ".*", "i");

            this.getFancyRootNode().visit(function (node: Fancytree.FancytreeNode) {
                if (
                    (!that.panel.prefixCategory || ml.Item.parseRef(node.key).type == that.panel.prefixCategory) &&
                    !!treeMatchRegEx.exec(node.title)
                ) {
                    if ((<MyNodeData>node.data).isUnselected) {
                        // project label filter
                        that.projectWarn = true;
                    } else {
                        that.hits.push(node.key);
                        hitCount++;
                    }
                }
                if ((<MyNode>node).selected) {
                    that.selected.push(node.key);
                }
            });
        } else {
            // project label filter
            this.getFancyRootNode().visit(function (node: Fancytree.FancytreeNode) {
                if ((<MyNodeData>node.data).isUnselected) {
                    // project label filter
                    that.projectWarn = true;
                } else {
                    hitCount++;
                }
                if ((<MyNode>node).selected) {
                    that.selected.push((<MyNode>node).key);
                }
            });
        }
        return this.showNodes();
    }

    showSearchResults(serverSearchResults: string[]) {
        let that = this;

        this.projectWarn = false;
        this.selected = [];
        this.hits = [];
        // only show items in list which would be in (subtree)
        $.each(serverSearchResults, function (idx, key) {
            var node = (<Fancytree.Fancytree>that.getFancyTree()).getNodeByKey(key);
            if (node) {
                that.hits.push(key);
            }
        });
        this.getFancyRootNode().visit(function (node: Fancytree.FancytreeNode) {
            if ((<MyNodeData>node.data).isUnselected) {
                // project label filter
                that.projectWarn = true;
            }
            if ((<MyNode>node).selected) {
                that.selected.push(node.key);
            }
        });
        return this.showNodes(); //serverSearchResults, this.selected, this.projectWarn );
    }

    selectAll(selected: boolean) {
        const selectedNodes = [];

        // TODO: potentially problematic on a big trees, consider updating the lib to get "selectAll" method
        // https://wwwendt.de/tech/fancytree/doc/jsdoc/Fancytree.html#selectAll
        this.getFancyTree().visit((node) => {
            node.setSelected(selected);

            // using node.isSelected() instead of selected, because we're looping through all the items,
            // not just the ones that can be selected.
            // e.g. if item is not selectable, node.isSelected() = false even after node.setSelected(true)
            if (node.isSelected()) {
                selectedNodes.push(node.key);
            }
        });

        this.selected = selectedNodes;

        this.showNodes();
    }

    selectMatched(selected: boolean) {
        // using set because we're only interested in the unique values
        const selectedNodesSet = new Set(this.selected);

        $.each(this.hits, (hidx, hit) => {
            if (this.panel.settings.selectMode == SelectMode.auto || !ml.Item.parseRef(hit).isFolder) {
                const node = this.getFancyTree().getNodeByKey(hit);

                if (node) {
                    node.setSelected(selected);
                }

                node.isSelected() ? selectedNodesSet.add(hit) : selectedNodesSet.delete(hit);
            }
        });

        this.selected = Array.from(selectedNodesSet);

        this.showNodes();
    }

    private getTitleFromTree(itemId: string) {
        let title = "";
        this.getFancyRootNode().visit(function (node: Fancytree.FancytreeNode) {
            if (node.key == itemId) {
                title = (<MyNodeData>node.data).shortTitle;
            }
        });
        return title;
    }

    private showNodes(all?: boolean): ISearchCounts {
        let that = this;

        function divider(text: string) {
            var x = $("<div style='position:relative'>");
            x.append($("<div>").append($("<hr style='margin-top:16px;margin-bottom:20px;border-color: lightgrey;'>")));
            x.append($("<div style='color:lightgrey;position:absolute;right:0;margin-top:-20px'>").html(text));
            return x;
        }
        // maybe remove folders if checkboxes should be shown
        var hitsDisplay: string[] = [];

        if (!this.panel.settings.canSelectItems || this.panel.settings.selectMode == SelectMode.auto) {
            hitsDisplay = this.hits;
        } else {
            $.each(this.hits, function (hidx, hit) {
                if (!ml.Item.parseRef(hit).isFolder) {
                    hitsDisplay.push(hit);
                }
            });
        }

        let counts: ISearchCounts = {
            current: hitsDisplay.length,
            total: hitsDisplay.length,
            perTab: that.panel.settings.crossProject ? [] : NavBar.countPerTab(hitsDisplay),
        };
        if (this.panel.settings.isMainTree) {
            hitsDisplay = hitsDisplay.filter(function (itemId) {
                return NavBar.isInCurrentTab(itemId);
            });
            counts.current = hitsDisplay.length;
        }
        // split list in checked and unchecked

        this.sRoot = $("<div>");
        this.uRoot = $("<div>");
        this.nodes = {};
        this.noSelected = $(
            "<span style='color:grey;width:100%;text-align:center;padding-top:20px'>nothing selected</span>",
        );

        this.control.html("");
        if (this.panel.settings.canSelectItems) {
            this.control.append(divider("selected"));
            this.control.append(this.sRoot);
            this.control.append(divider("search results"));
        }

        this.control.append(this.uRoot);
        this.sRoot.append(this.noSelected);
        if (this.selected.length > 0) {
            this.noSelected.hide();
            $.each(this.selected, function (sn, n) {
                that.sRoot.append(that.createNode(n, true));
            });
        }

        // decide how many items to show in list view -> by default 200
        let ui: ICompanyUISettings = <ICompanyUISettings>matrixSession.getCustomerSettingJSON("ui", {});
        let maxShow = ui.maxHits ? ui.maxHits : 200;
        let actualShow = all ? hitsDisplay.length : Math.min(hitsDisplay.length, maxShow);

        for (let sn = 0; sn < actualShow; sn++) {
            let n = hitsDisplay[sn];
            let node = that.createNode(n, false);
            that.uRoot.append(node);
            that.nodes[n] = node;
            if (that.selected.indexOf(n) !== -1) {
                node.hide();
            }
        }
        // if not allow were shown, allow user to show the rest
        if (hitsDisplay.length > actualShow) {
            let showRest = $(`<div class='showMore' >show ${hitsDisplay.length - actualShow} remaining...</div>`);
            that.uRoot.append(showRest);
            showRest.on("click", () => {
                showRest.replaceWith(ml.UI.getSpinningWait("please wait..."));
                window.setTimeout(() => {
                    that.showNodes(true);
                }, 1);
            });
        }
        return counts;
    }

    private createNode(itemId: string, checked: boolean) {
        let that = this;

        function toggleSelect(checkbox: JQuery) {
            var id = checkbox.data("itemId");

            if (checkbox.prop("checked")) {
                // now it has been checked
                if (that.panel.settings.singleSelect) {
                    // unselect the current
                    that.panel.toggleSelection(false, "all");
                }
                // hide it
                if (that.nodes[id]) {
                    checkbox.prop("checked", "");
                    that.nodes[id].hide();
                }
                // add it to the  list of hits
                that.sRoot.append(that.createNode(id, true));

                // add it to global selection
                var node = that.getFancyTree().getNodeByKey(id);
                if (node) {
                    node.setSelected(true);
                }
            } else {
                // uncheck it,
                checkbox.parent().remove();
                // show it in search results (if it is a hit)
                if (that.nodes[id]) {
                    that.nodes[id].show();
                }
                // remove from global selection
                var node = that.getFancyTree().getNodeByKey(id);
                if (node) {
                    node.setSelected(false);
                }
            }
            if (that.sRoot.children().length > 1) {
                that.noSelected.hide();
            } else {
                that.noSelected.show();
            }
        }
        var cnode = $("<div class='search-list-node node_" + itemId + "'>");

        if (this.panel.settings.canSelectItems) {
            var cb = $('<input type="checkbox" ' + (checked ? "checked" : "") + ' class="listSelect" >');
            cnode.append(cb);
            cb.click(function (event: JQueryEventObject) {
                // this is the item
                toggleSelect($(event.delegateTarget));
            }).data("itemId", itemId);
        }

        var node = $("<span>");
        cnode.append(node);

        if (this.panel.settings.selectionChanged && !this.panel.settings.canSelectItems) {
            // call app
            node.refLink({
                id: itemId,
                folder: false,
                title: this.panel.settings.crossProject ? that.getTitleFromTree(itemId) : app.getItemTitle(itemId),
                style: app.isHiddenLink(itemId) ? refLinkStyle.show : refLinkStyle.select,
                tooltip: this.panel.settings.tooltips ? refLinkTooltip.html : refLinkTooltip.none,
                crossProject: this.panel.settings.crossProject,
                callback: function () {
                    $(".search-list-node-selected", cnode.parent()).removeClass("search-list-node-selected");
                    cnode.addClass("search-list-node-selected");
                    app.treeSelectionChangeAsync(itemId);
                },
            });
            node.addClass("search-list-node");
            $(".refTitle", cnode).click(function () {
                $(".search-list-node-selected", cnode.parent()).removeClass("search-list-node-selected");
                cnode.addClass("search-list-node-selected");
                app.treeSelectionChangeAsync(itemId);
            });
        } else {
            node.refLink({
                id: itemId,
                folder: false,
                title: this.panel.settings.crossProject ? that.getTitleFromTree(itemId) : app.getItemTitle(itemId),
                style: refLinkStyle.link,
                crossProject: this.panel.settings.crossProject,
                tooltip: this.panel.settings.tooltips ? refLinkTooltip.html : refLinkTooltip.none,
            });
            node.click(function (event: JQueryEventObject) {
                var checkbox = $(event.delegateTarget).parent().find("input");
                checkbox.prop("checked", !checkbox.prop("checked"));
                toggleSelect(checkbox);
            });
        }
        return cnode;
    }

    getFancyRootNode(): Fancytree.FancytreeNode {
        return <Fancytree.FancytreeNode>this.panel.tree.fancytree("getRootNode");
    }
    getFancyTree(): Fancytree.Fancytree {
        return <Fancytree.Fancytree>this.panel.tree.fancytree("getTree");
    }
}
