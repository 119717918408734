import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { ItemSelectionFromToAbstractDocFieldHandler } from "./ItemSelectionFromToAbstractDocFieldHandler";
import { IGenericMap } from "../../../../globals";

export interface IDerivedFromOptions extends IDHFSectionOptions {
    render: string;
    includeInToc: boolean;
    numericalOrder: boolean;
    noHitMessage: string;
    searchFrom?: string;
    searchTo?: string;
}

export interface IDHFDerivedFromConfig {
    default: IDerivedFromOptions;
}

export class DerivedFromDocFieldHandler extends ItemSelectionFromToAbstractDocFieldHandler {
    // TODO: we have a weird usage of this property in DerivedFrom.ts, try to remove it without breaking anything
    static default_config: IDHFDerivedFromConfig = {
        default: { render: "items", includeInToc: false, numericalOrder: false, noHitMessage: "" }, // list, items, tree, table
    };

    getDefaultConfig(): any {
        return { ...DerivedFromDocFieldHandler.default_config.default };
    }

    getXmlValue(): string {
        let reflist = this.getSelectedItems();
        let td: IGenericMap[] = [];
        let r0: IGenericMap = {};
        for (let idx = 0; idx < reflist.from.length; idx++) {
            r0["from" + idx] = reflist.from[idx].to;
        }
        td.push(r0);

        let r1: IGenericMap = {};
        for (let idx = 0; idx < reflist.to.length; idx++) {
            r1["to" + idx] = reflist.to[idx].to;
        }
        td.push(r1);

        // copy parameters

        let r2: IGenericMap = { ...this.dhfFieldConfig };
        td.push(r2);
        // update the xml dump
        return JSON.stringify(td);
    }
}
