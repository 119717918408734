import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { IFieldHandler } from "./IFieldHandler";
export { TextlineFieldHandler };

class TextlineFieldHandler implements IFieldHandler {
    private data: string;
    private config: XRFieldTypeAnnotatedParamJson;
    private fieldType: string;

    constructor(fieldType: string, configIn: XRFieldTypeAnnotatedParamJson) {
        this.fieldType = fieldType;
        this.config = configIn;
        this.data = undefined;
    }

    getFieldType(): string {
        return this.fieldType;
    }

    initData(serializedFieldData: string | undefined) {
        if (serializedFieldData !== undefined) {
            this.data = serializedFieldData;
        } else if (this.config.initialContent) {
            this.data = this.config.initialContent;
        }
    }
    getData(): string | undefined {
        return this.data;
    }
    setData(value: string, doValidation?: boolean) {
        this.data = value;
    }
    getText(): string {
        return this.data;
    }
    setText(str: string) {
        // make sure there are no newlines if this is a one row field.
        if (this.config.rows && this.config.rows == 1) {
            this.data = str.replace("\n", "");
        } else {
            this.data = str;
        }
    }
}
