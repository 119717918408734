import { ILabel, ILabelsConfig, IDesignReview, ILabelGroup } from "../../ProjectSettings";
import { XRLabelHistory } from "../../RestResult";
import { ItemConfiguration } from "../businesslogic";
import { ILoggerTools, IJSONTools, IChangedLabels, ILabelManager } from "./MatrixLibInterfaces";
export { LabelManager };

type GetItemConfigFunction = () => ItemConfiguration;

class LabelManager implements ILabelManager {
    public ignoreProjectFilter = false;
    private activeFilter = "";

    constructor(
        protected logger: ILoggerTools,
        protected json: IJSONTools,
        protected getConfig: GetItemConfigFunction,
    ) {}

    getFilterColor(): string {
        var labels = this.getConfig().getLabelsConfig();

        if (!labels || !labels.filterBackgroundColor) {
            return null;
        }
        return labels.filterBackgroundColor;
    }

    // return list of selected filters as , separated list
    getFilter(): string {
        if (this.ignoreProjectFilter) {
            return "";
        }
        var filters: string[] = [];

        var labelsAll = this.getLabelNames();
        if (labelsAll.length === 0) {
            return "";
        }

        var stored: string = this.activeFilter;
        if (!stored || stored === "") {
            return "";
        }

        // only return filters which (still) exist
        var cs = stored.split(",");
        for (let sf of cs) {
            if (labelsAll.indexOf(sf.replace("!", "")) > -1) {
                filters.push(sf);
            }
        }

        return filters.length > 0 ? filters.join(",") : "";
    }

    getDisplayName(labelId: string) {
        let labels = this.getLabelList();
        let displayName = "";
        for (let label of labels) {
            if (label.label == labelId) {
                displayName = label.displayName;
                if (label.style.label.on.displayName) {
                    displayName = label.style.label.on.displayName;
                }
            }
        }
        return displayName;
    }

    getFilterName(labelId: string) {
        let labels = this.getLabelList();
        let displayName = "";
        for (let label of labels) {
            if (label.label == labelId) {
                displayName = label.displayName;
                if (
                    label.style &&
                    label.style.filter &&
                    label.style &&
                    label.style.filter.on &&
                    label.style.filter.on.displayName
                ) {
                    displayName = label.style.filter.on.displayName;
                }
            }
        }
        return displayName;
    }

    // return the existing labels for the passed types (or all labels if no type is passed)
    getLabelDefinitions(categories: string[]): ILabel[] {
        var all = this.getLabelList();
        if (!categories || categories.length === 0) {
            return all;
        }
        let relevant: string[] = [];
        let relevantLabel: ILabel[] = [];
        for (let label of all) {
            for (let category of label.categories) {
                if (categories.indexOf(category) > -1 && relevant.indexOf(label.label) === -1) {
                    relevant.push(label.label);
                    relevantLabel.push(label);
                }
            }
        }
        return relevantLabel;
    }

    setFilter(filter: string[]) {
        this.activeFilter = filter.join(",");
    }

    /* clean up the set labels: in case a set label is in review or design review group, 
        it is possible that the label is removed from the list of set labels / replaced by another label in the group.

        This depends on the groups's setting.

        @param addXor If no label is set in a xor group, the default can be automatically set.
    
        @return the list of labels after the review groups have been reset
    
     */
    resetReviewLabels(labelIds: string[], category: string, addXor?: boolean): string[] {
        let that = this;
        let cleaned: string[] = [];
        // get label groups
        let groups = this.getLabelGroups();
        let labelList = this.getLabelList();

        // copy all lables besides the review labels
        for (let label of labelIds) {
            let isReviewLabel = false;
            for (var gid = 0; gid < groups.length; gid++) {
                if (
                    ((groups[gid].selection === "review" && this.json.isTrue(groups[gid].reset)) ||
                        (groups[gid].selection === "design_review" && !this.json.isFalse(groups[gid].reset))) &&
                    groups[gid].labels.indexOf(label) !== -1
                ) {
                    isReviewLabel = true;
                }
            }

            if (!isReviewLabel) {
                cleaned.push(label);
            }
        }
        // add all default review states - if no other label is set in the review group
        for (var gid = 0; gid < groups.length; gid++) {
            if (
                (groups[gid].selection === "review" || (addXor && groups[gid].selection === "xor")) &&
                groups[gid].default
            ) {
                var groupIsSet = false;
                for (let cleanLabel of cleaned) {
                    if (groups[gid].labels.indexOf(cleanLabel) !== -1) {
                        groupIsSet = true;
                    }
                }
                if (!groupIsSet) {
                    // verify if label actually is defined for group...
                    var defaultIsForType = false;
                    for (let label of labelList) {
                        if (groups[gid].default === label.label && label.categories.indexOf(category) !== -1) {
                            defaultIsForType = true;
                        }
                    }
                    if (defaultIsForType) {
                        cleaned.push(groups[gid].default);
                    }
                }
            }
        }

        return cleaned;
    }

    /* return the list of default labels for a category */
    getDefaultLabels(category: string): string[] {
        let that = this;

        // get current positive filters
        let currentFilter = this.getFilter();
        let posFilter: string[] = [];
        const filterItems = currentFilter.split(",");
        for (let f of filterItems) {
            if (f.indexOf("!") !== 0) {
                posFilter.push(f);
            }
        }
        // each label which is not in a xor/review group can be default
        let defaultLabels: string[] = [];
        let labelList = this.getLabelList();
        for (let label of labelList) {
            let group = that.getGroupOfLabel(label.label);

            if (
                group &&
                label.categories.indexOf(category) !== -1 && // label must exist for cateogry type
                group.selection !== "xor" &&
                group.selection !== "review" && // it must not be in a xor or review group
                (this.json.isTrue(label.default) || // the default value must be set to on OR
                    (label.default === "filter" && posFilter.indexOf(label.label) !== -1))
            ) {
                // the default must be filter and it must be set in the current filter
                defaultLabels.push(label.label);
            }
        }

        return this.resetReviewLabels(defaultLabels, category, true);
    }

    hasLabels() {
        return this.getLabelList().length > 0;
    }

    // implement logic to set labels.
    setLabels(oldLabelIds: string, labels: string[]): string {
        let that = this;
        let old = oldLabelIds ? oldLabelIds.split(",") : [];
        for (let label of labels) {
            old = that.setLabel(old, label);
        }
        return old.join(",");
    }

    // implement logic to set a label.
    // This depends on the type of group: for xor / review labels other group members must be reset
    setLabel(oldLabelIds: string[], label: string): string[] {
        let labelIds: string[] = this.json.clone(oldLabelIds);

        if (labelIds.indexOf(label) !== -1) {
            // label already set - ignore
            return labelIds;
        }

        let group = this.getGroupOfLabel(label);

        if (!group || group.selection == "or") {
            // simple label without group / or group: just set it
            labelIds.push(label);
        } else if (group.selection == "xor" || group.selection == "review") {
            // remove all labels from group
            for (let otherLabel of group.labels) {
                labelIds = labelIds.filter(function (existingLabel) {
                    return existingLabel != otherLabel;
                });
            }
            // set the label
            labelIds.push(label);
        } else {
            this.logger.log("warn", "setLabel: unkown group type of label: '" + label + "'");
        }

        return labelIds;
    }

    // return the last time a label was set on a given item before a revision (=0 if any)
    decipherLastTimeLabelWasSet(
        labelHistory: XRLabelHistory,
        itemId: string,
        label: string,
        beforeRevision: number,
    ): number {
        let itemHistory = labelHistory.entries.filter((entry) => entry.itemRef == itemId);
        if (itemHistory.length > 0) {
            let history = itemHistory[0].labels.filter((l) => l.label == label);
            if (history.length > 0 && history[0].set.length > 0) {
                // ignore all sets after the beforeRevision  (unless 0 is passed)
                let sets = history[0].set.filter((set) => !beforeRevision || set.version < beforeRevision);
                return sets[sets.length - 1].version;
            }
        }
        // label was never set all good
        return 0;
    }

    // implement logic to reset a label.
    // This depends on the type of group: for xor / review labels a default label might need to be set
    unsetLabel(oldLabelIds: string[], label: string): string[] {
        let labelIds: string[] = this.json.clone(oldLabelIds);

        if (labelIds.indexOf(label) === -1) {
            // label is not actually set - ignore
            return labelIds;
        }

        let group = this.getGroupOfLabel(label);

        // remove the label
        labelIds = labelIds.filter(function (existingLabel) {
            return existingLabel != label;
        });

        if (!group || group.selection == "or") {
            // simple label without group / or group - nothing else to do
        } else if (group.selection == "xor" || group.selection == "review") {
            if (group.default) {
                labelIds.push(group.default);
            }
        } else {
            this.logger.log("warn", "unsetLabel: unkown group type of label: '" + label + "'");
        }

        return labelIds;
    }

    compareLabels(before: string[], after: string[]): IChangedLabels {
        let result: IChangedLabels = {
            changed: false,
            added: [],
            removed: [],
            delta: "",
        };

        before = before || [];
        after = after || [];
        let delta: string[] = [];

        for (var idx = 0; idx < after.length; idx++) {
            if (before.indexOf(after[idx]) === -1) {
                result.added.push(after[idx]);
                delta.push(after[idx]);
            }
        }

        for (var idx = 0; idx < before.length; idx++) {
            if (after.indexOf(before[idx]) === -1) {
                result.removed.push(before[idx]);
                delta.push("-" + before[idx]);
            }
        }
        result.delta = delta.join(",");
        result.changed = result.added.length + result.removed.length > 0;
        return result;
    }
    static timeWarpLabel = "_timewarp_";

    protected getLabelList(): ILabel[] {
        let labelsAll = this.getConfig().getSetting("labels");
        if (!labelsAll) {
            return [];
        }
        let labelsJson = this.json.fromString(labelsAll);
        if (labelsJson.status !== "ok" || !(<ILabelsConfig>labelsJson.value).labels) {
            return [];
        }

        return (<ILabelsConfig>labelsJson.value).labels;
    }

    getLabelNames(): string[] {
        let labelList = this.getLabelList();

        let allLabels: string[] = [];
        for (let label of labelList) {
            allLabels.push(label.label);
        }

        return allLabels;
    }

    protected getDesignReviews(): IDesignReview[] {
        let labelsAll: string = this.getConfig().getSetting("labels");
        if (!labelsAll) {
            return [];
        }
        var labelsJson = this.json.fromString(labelsAll);
        if (labelsJson.status !== "ok") {
            return [];
        }

        return (<ILabelsConfig>labelsJson.value).design_reviews ? (<ILabelsConfig>labelsJson.value).design_reviews : [];
    }

    getDesignReview(labelId: string): IDesignReview | null {
        var dr = this.getDesignReviews();
        for (var idx = 0; idx < dr.length; idx++) {
            if (dr[idx].label === labelId) {
                return dr[idx];
            }
        }
        return null;
    }

    isFiltered(category: string, labels: string): boolean {
        let filters = this.getFilter();
        if (!filters) return false; // no filters -> not filtered out

        let isFilteredOut = false;
        // there are some filters so lets check
        let labelsSet = labels ? labels.split(",") : [];
        let labelsForItem = this.getLabelDefinitions([category]).map(function (labelDef) {
            return labelDef.label;
        });

        const filtersArray = filters.split(",");
        filtersArray.forEach((filter) => {
            let label = filter.replace("!", ""); // to get rid of negation of filters
            if (labelsForItem.indexOf(label) == -1) return; // irrelevant, try next filter
            if (label != filter) {
                // negative filter
                isFilteredOut = isFilteredOut || labelsSet.indexOf(label) != -1;
            } else {
                isFilteredOut = isFilteredOut || labelsSet.indexOf(label) == -1;
            }
        });
        return isFilteredOut;
    }

    getLabelsOfLabelGroupsType(labelGroupType: string): string[] {
        if (!labelGroupType) {
            return [];
        }
        let labels: string[] = [];
        for (let lg of this.getLabelGroups()) {
            if (lg.groupType == labelGroupType) {
                labels = labels.concat(lg.labels);
            }
        }
        for (let lg of this.getDesignReviews()) {
            if (lg.groupType == labelGroupType) {
                labels.push(lg.label);
            }
        }

        return labels;
    }
    getLabelGroups(category?: string): ILabelGroup[] {
        let labelsAll = this.getConfig().getSetting("labels");
        if (!labelsAll) {
            return [];
        }
        let labelsJson = this.json.fromString(labelsAll);
        if (labelsJson.status !== "ok") {
            return [];
        }

        let labelList = this.getLabelList();
        let groups: ILabelGroup[] = (<ILabelsConfig>labelsJson.value).groups
            ? (<ILabelsConfig>labelsJson.value).groups
            : [];
        let design_reviews = this.getDesignReviews();

        // by default all labels end up in the last group
        for (var gid = 0; gid < groups.length; gid++) {
            // prepare to add label definitions of actual labels to display
            groups[gid].labelDef = [];
            // by default all filters are in "or" mode
            if (!groups[gid].filterSelection) {
                groups[gid].filterSelection = "or";
            }
        }

        // now add actual labels to display to each group, any label should be displayed at most once
        labelList.forEach((label) => {
            if (category && label.categories.indexOf(category) === -1) {
                // this is an item, and label does not exist for item
                return;
            }

            // check if label is in group
            for (var gid = 0; gid < groups.length; gid++) {
                for (var lid = 0; lid < groups[gid].labels.length; lid++) {
                    if (groups[gid].labels[lid] === label.label) {
                        groups[gid].labelDef.push(label);
                        return;
                    }
                }
            }

            // check if label is in review
            for (var gid = 0; gid < design_reviews.length; gid++) {
                if (design_reviews[gid].label === label.label) {
                    groups.push({
                        selection: "design_review",
                        labels: [label.label],
                        filterSelection: "or",
                        labelDef: [label],
                        reset: design_reviews[gid].reset,
                        filterMenu: design_reviews[gid].filterMenu,
                    });
                    return;
                }
            }

            // create a new group
            groups.push({
                virtualGroup: true,
                selection: "or",
                labels: [label.label],
                filterSelection: "or",
                labelDef: [label],
            });
        });

        return groups;
    }

    // returns the group of a label
    protected getGroupOfLabel(labelID: string): ILabelGroup | null {
        let groups = this.getLabelGroups();
        for (var gid = 0; gid < groups.length; gid++) {
            if (groups[gid].labels.indexOf(labelID) !== -1) {
                return groups[gid];
            }
        }
        return null;
    }
    // returns the background color of the main tool bar to be used if filters are activated
}
