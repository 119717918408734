import { BaseDHFSection } from "./client/plugins/DocumentSections/BaseDHFSection";
import { IDHFControlDefinition, IDHFSection } from "./common/businesslogic";
import { IBaseControlOptions } from "./common/UI/Controls/BaseControl";
import { app, IReference } from "./globals";
import { ItemSelectionFromToImpl } from "./common/UI/Controls/itemSelectionFromTo";
import { SelectSearchQueue } from "./SelectSearchQueue";
import { ml } from "./common/matrixlib";
import { IDoubleSelectOptions } from "./SingleSelectBase";

abstract class DoubleSelectBase<T extends IDoubleSelectOptions> extends BaseDHFSection<T> implements IDHFSection {
    abstract renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions): void;

    addSignatures(signatures: string[], currentValue: IDHFControlDefinition) {}

    abstract showSpecificSettings(
        ctrl: IDHFControlDefinition,
        ctrlParameter: IBaseControlOptions,
        custom: JQuery,
    ): void;

    protected addSpecificSettings(controllerConfig: T, custom: JQuery) {
        let searchFrom = $(
            '<input autocomplete="off" style="height: 20px;width: 420px;float: right;" class="form-control p_searchFrom" type="text" name="search" value="" /> ',
        );
        var sp = $("<div class='sectionConfOption'>Find from items by search: </div>").append(searchFrom);
        custom.append(sp);
        searchFrom.val(controllerConfig.searchFrom ? controllerConfig.searchFrom : "");

        let searchTo = $(
            '<input autocomplete="off" style="height: 20px;width: 420px;float: right;" class="form-control p_searchTo" type="text" name="search" value="" /> ',
        );
        var sp = $("<div class='sectionConfOption'>Find to items by search: </div>").append(searchTo);
        custom.append(sp);
        searchTo.val(controllerConfig.searchTo ? controllerConfig.searchTo : "");
    }

    abstract saveSpecificSettingsAsync(
        ctrl: IDHFControlDefinition,
        ctrlParameter: IBaseControlOptions,
        custom: JQuery,
    ): Promise<boolean>;

    protected setSpecificSettings(controllerConfig: IDoubleSelectOptions, custom: JQuery) {
        let changed = controllerConfig.searchFrom != $(custom.find(".p_searchFrom")[0]).val();
        controllerConfig.searchFrom = $(custom.find(".p_searchFrom")[0]).val();

        changed = changed || controllerConfig.searchTo != $(custom.find(".p_searchTo")[0]).val();
        controllerConfig.searchTo = $(custom.find(".p_searchTo")[0]).val();

        return changed;
    }

    hasSearch(ctrl: IDHFControlDefinition) {
        var controllerConfig = this.getConfig(ctrl);
        return !!controllerConfig.searchFrom || !!controllerConfig.searchTo;
    }

    executeSearch(ctrl: IDHFControlDefinition) {
        var controllerConfig = this.getConfig(ctrl);

        let rn = $(".refreshNeeded", ctrl.control).removeClass("refreshNeeded");

        if (controllerConfig.searchFrom) {
            app.searchAsyncMinimalOutput(controllerConfig.searchFrom, null, true).done(function (itemIds) {
                if (itemIds.length == 0) {
                    rn.addClass("refreshNeeded");
                } else {
                    // We have items, so we don't need to have the contentNeeded class.
                    $(".contentNeeded", ctrl.control).removeClass("contentNeeded");
                }

                (<ItemSelectionFromToImpl>ctrl.control.getController()).setValueFrom(itemIds);
            });
        }
        if (controllerConfig.searchTo) {
            app.searchAsyncMinimalOutput(controllerConfig.searchTo, null, true).done(function (itemIds) {
                if (itemIds.length == 0) {
                    rn.addClass("refreshNeeded");
                }
                (<ItemSelectionFromToImpl>ctrl.control.getController()).setValueTo(itemIds);
            });
        }
    }

    async verifySearchAsync(ctrl: IDHFControlDefinition) {
        let controllerConfig = this.getConfig(ctrl);

        let lastValue = await ctrl.control.getController().getValueAsync();
        let lastVal = lastValue ? JSON.parse(lastValue) : { from: [], to: [] };

        let rn = $(".refreshNeeded", ctrl.control).removeClass("refreshNeeded");
        if (controllerConfig.searchFrom) {
            $(".fa-sync-alt", ctrl.control).addClass("icon-refresh-animate");
            SelectSearchQueue.addToSearchQueue(() => {
                return app.searchAsyncMinimalOutput(controllerConfig.searchFrom, null, true).done(function (current) {
                    $(".fa-sync-alt", ctrl.control).removeClass("icon-refresh-animate");
                    let last: IReference[] = lastVal.from;
                    if (current.length == 0) {
                        rn.addClass("refreshNeeded");
                        return;
                    } else if (last.length != current.length) {
                        rn.addClass("refreshNeeded");
                        return;
                    } else {
                        let lastItems = last
                            .map(function (lastItem) {
                                return lastItem.to;
                            })
                            .sort(function (a, b) {
                                return ml.Item.sort(a, b);
                            });
                        let currentItems = current.sort(function (a, b) {
                            return ml.Item.sort(a, b);
                        });
                        for (var idx = 0; idx < lastItems.length; idx++) {
                            if (lastItems[idx] != currentItems[idx]) {
                                rn.addClass("refreshNeeded");
                                return;
                            }
                        }
                    }
                });
            });
        }

        if (controllerConfig.searchTo) {
            $(".fa-sync-alt", ctrl.control).addClass("icon-refresh-animate");
            SelectSearchQueue.addToSearchQueue(() => {
                return app.searchAsyncMinimalOutput(controllerConfig.searchTo, null, true).done(function (current) {
                    $(".fa-sync-alt", ctrl.control).removeClass("icon-refresh-animate");
                    let last: IReference[] = lastVal.to;
                    if (current.length == 0) {
                        rn.addClass("refreshNeeded");
                        return;
                    } else if (last.length != current.length) {
                        rn.addClass("refreshNeeded");
                        return;
                    } else {
                        let lastItems = last
                            .map(function (lastItem) {
                                return lastItem.to;
                            })
                            .sort(function (a, b) {
                                return ml.Item.sort(a, b);
                            });
                        let currentItems = current.sort(function (a, b) {
                            return ml.Item.sort(a, b);
                        });
                        for (var idx = 0; idx < lastItems.length; idx++) {
                            if (lastItems[idx] != currentItems[idx]) {
                                rn.addClass("refreshNeeded");
                                return;
                            }
                        }
                    }
                });
            });
        }
    }

    async verifyContentAsync(ctrl: IDHFControlDefinition) {
        let lastValue = await ctrl.control.getController().getValueAsync();
        let lastVal = lastValue ? JSON.parse(lastValue) : { from: [], to: [] };
        if (lastVal.from.length == 0 || lastVal.to.length == 0) {
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").addClass("contentNeeded");
        } else {
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").removeClass("contentNeeded");
        }
    }

    protected removeSpaces(str: string) {
        if (!str) return "";
        return str
            .split("|")
            .map(function (strp) {
                return strp.trim();
            })
            .join("|")
            .split(",")
            .map(function (strp) {
                return strp.trim();
            })
            .join(",");
    }
}

export { DoubleSelectBase };
