import { ISimpleTree } from "./MatrixReq";
import { XRFieldTypeAnnotated } from "./ItemConfiguration";
import { IDHFConfig } from "../../ProjectSettings";
import { IControlDefinition } from "../../globals";
import { IBaseControlOptions } from "../UI/Controls/BaseControl";
import { IDocFieldHandler } from "./FieldHandlers/Document/IDocFieldHandler";

export interface IDHFSectionOptions {
    globalOptions?: boolean;
    show_section_title?: string;
    automation?: string;
    page_break?: string;
    landscape?: boolean;
    sub_section?: string;
}

export interface IDHFFieldParameter {
    autoHide?: boolean;
    // legacy?
    perItemVisibility?: boolean;
}

export interface IDHFFieldValue {
    type: string;
}

export interface IDHFXMLOptions {}

export interface IDHFPasteBuffer {
    tree: ISimpleTree;
    items: IDHFPasteBufferItem[];
}

export interface IDHFPasteBufferItem {
    sourceItem: string;
    sourceProject: string;
    pasteSource: string;
    title: string;
    item: IDHFPasteBufferValue[];
}

export interface IDHFPasteBufferValue {
    def: XRFieldTypeAnnotated;
    val: string;
}

export interface IDHFReorder {
    idx: number;
    id: string;
    name: string;
    type: string;
}

export interface IDHFFileOption {
    name: string;
    click: Function;
}

export interface IDHFGuidOid {
    guid: string;
    oid: string;
}

export interface IDHFWizardData {
    dhfItems: any[];
    dhfNumber: string;
    dhfGUID: string;
    dhfName: string;
    template?: string;
}

export interface IDHFFactory {
    [key: string]: (config: IDHFConfig) => IDHFSection;
}

export interface ISectionInfo {
    sectionName: string;
    sectionType: DocumentSectionType;
    hidden: boolean;
    dynamic: boolean; // true for sections which render items
}

export enum DocumentSectionType {
    Static = 1,
    Database = 2,
    Table = 3,
    Technical = 4,
    CustomTable = 5,
}

export interface IGrandMother {
    id: string;
    version: number;
    fullId: string;
    title: string;
}
export interface ISectionMap {
    [key: string]: ISectionInfo;
}

export interface ItemSortInfo {
    itemId: string;
    item: JQuery;
    existsInBoth: boolean;
}

export interface IPluginDocumentSection {}
export interface IDHFFieldListItem {
    id: string;
    label: string;
}
export interface IDHFField {
    type: string;
    name: string;
}

export interface IDHFControlDefinition extends IControlDefinition {
    dhfValue?: IDHFControlDefinitionValue;
    configTouched?: boolean;
}

export interface IDHFControlDefinitionValue {
    fieldValue?: string;
    fieldValueXML?: string;
    name?: string;
    type?: string;
    ctrlConfig?: IDHFSectionOptions;
}

export interface IDHFSection {
    renderControl: (ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) => void;
    setFieldHandler: (fieldHandler: IDocFieldHandler) => void;
    getFieldHandler: () => IDocFieldHandler;
    getConfig: (ctrl: IDHFControlDefinition) => IDHFSectionOptions;
    showSpecificSettings: (ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) => void;
    saveSpecificSettingsAsync: (
        ctrl: IDHFControlDefinition,
        ctrlParameter: IBaseControlOptions,
        custom: JQuery,
    ) => Promise<boolean>;
    hasSearch?: (ctrl: IDHFControlDefinition) => boolean; // return true if section supports search expressions for input and has them defined
    executeSearch?: (ctrl: IDHFControlDefinition) => void; // if search expressions are defined for input parameters they can be executed using this function
    verifySearchAsync?: (ctrl: IDHFControlDefinition) => Promise<void>; // check if after load item selection needs to be changed
    verifyContentAsync: (ctrl: IDHFControlDefinition) => Promise<void>; // check if some/all required content exists
}
