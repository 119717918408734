import { IDHFSection, IDHFControlDefinition } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { IDropdownOption } from "../../../ProjectSettings";
import { IRemarkOptions } from "../../../common/businesslogic/FieldHandlers/Document/RemarksDocFieldHandler";
import { BaseDHFSection } from "./BaseDHFSection";

export { Remarks };

class Remarks extends BaseDHFSection<IRemarkOptions> implements IDHFSection {
    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {}

    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        // save global settings
        var controllerConfig = this.getConfig(ctrl);
        this.fieldHandler.setDHFConfig(controllerConfig);

        // nothing changed
        return false;
    }

    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        var lines: IDropdownOption[] = [];
        for (var udx = 0; udx < 50; udx++) {
            lines.push({ id: udx.toString(), label: udx + " lines" });
        }

        ctrl.control.mxDropdown(
            ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    placeholder: "select number of lines",
                    create: false,
                    options: lines,
                    maxItems: 1,
                    sort: false,
                },
            }),
        );
    }

    async verifyContentAsync(ctrl: IDHFControlDefinition) {}
}
