import { IDHFControlDefinition } from "../../../../common/businesslogic";
import { ml } from "../../../../common/matrixlib";
import { IBaseControlOptions } from "../../../../common/UI/Controls/BaseControl";
import { globalMatrix } from "../../../../globals";
import { SingleSelectBase } from "../../../../SingleSelectBase";
import { IDesignReviewsOptions } from "../../../../common/businesslogic/FieldHandlers/Document/DesignReviewDocFieldHandler";

export { DesignReviews };

class DesignReviews extends SingleSelectBase<IDesignReviewsOptions> {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        ctrl.control.itemSelection(
            ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    prefix: "Include the design review labels of these items:",
                    buttonName: "Select Items",
                    showNot: ["REPORT"],
                },
            }),
        );
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        var controllerConfig = this.getConfig(ctrl);

        var user =
            '<div class="checkbox" ><label><input type="checkbox" class="p_user" ' +
            (controllerConfig.user ? "checked" : "") +
            "> Show reviewer</label></div>";
        custom.append($(user));
        var date =
            '<div class="checkbox" ><label><input type="checkbox" class="p_date" ' +
            (controllerConfig.date ? "checked" : "") +
            "> Show review date</label></div>";
        custom.append($(date));
        var revision =
            '<div class="checkbox" ><label><input type="checkbox" class="p_revision" ' +
            (controllerConfig.revision ? "checked" : "") +
            "> Show reviewed revision</label></div>";
        custom.append($(revision));
        var comment =
            '<div class="checkbox" ><label><input type="checkbox" class="p_comment" ' +
            (controllerConfig.comment ? "checked" : "") +
            "> Show review comment</label></div>";
        custom.append($(comment));
        var options =
            '<div class="checkbox" ><label><input type="checkbox" class="includeInToc" ' +
            (controllerConfig.includeInToc ? "checked" : "") +
            "> Add a reference to reviewed items to table of content</label></div>";
        custom.append($(options));
        var optionsNum =
            '<div class="checkbox" ><label><input type="checkbox" class="numericalOrder" ' +
            (controllerConfig.numericalOrder ? "checked" : "") +
            "> List elements in numerical order (flat)</label></div>";
        custom.append($(optionsNum));

        custom.append("<span>Include the following reviews:</span>");
        var labels = globalMatrix.ItemConfig.getLabelsConfig();
        if (labels && labels.design_reviews) {
            $.each(labels.design_reviews, function (idx, dr) {
                var options =
                    '<div class="checkbox" ><label><input type="checkbox" class="p' +
                    idx +
                    '" ' +
                    (controllerConfig.reviews.indexOf(dr.label) !== -1 ? "checked" : "") +
                    ">" +
                    dr.reviewName +
                    "</label></div>";
                custom.append($(options));
            });
        }

        this.addSpecificSettings(controllerConfig, custom);
    }
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        var controllerConfig = this.getConfig(ctrl);

        var changed = false;
        var newLabels: string[] = [];
        var labels = globalMatrix.ItemConfig.getLabelsConfig();
        if (labels && labels.design_reviews) {
            $.each(labels.design_reviews, function (idx, dr) {
                var newVal = $(custom.find(".p" + idx)[0]).prop("checked");
                var oldVal = controllerConfig.reviews.indexOf(dr.label) !== -1;
                changed = changed || newVal !== oldVal;
                if (newVal) {
                    newLabels.push(dr.label);
                }
            });
        }
        controllerConfig.reviews = newLabels;

        var user = $(custom.find(".p_user")[0]).prop("checked");
        changed = changed || user != controllerConfig.user;
        controllerConfig.user = user;

        var date = $(custom.find(".p_date")[0]).prop("checked");
        changed = changed || date != controllerConfig.date;
        controllerConfig.date = date;

        var revision = $(custom.find(".p_revision")[0]).prop("checked");
        changed = changed || revision != controllerConfig.revision;
        controllerConfig.revision = revision;

        var comment = $(custom.find(".p_comment")[0]).prop("checked");
        changed = changed || comment != controllerConfig.comment;
        controllerConfig.comment = comment;

        changed = changed || controllerConfig.includeInToc !== $(custom.find(".includeInToc")[0]).prop("checked");
        controllerConfig.includeInToc = $(custom.find(".includeInToc")[0]).prop("checked");

        changed = changed || controllerConfig.numericalOrder !== $(custom.find(".numericalOrder")[0]).prop("checked");
        controllerConfig.numericalOrder = $(custom.find(".numericalOrder")[0]).prop("checked");

        let specificChanged = this.setSpecificSettings(controllerConfig, custom);

        this.fieldHandler.setDHFConfig(controllerConfig);

        return changed || specificChanged;
    }
}
