/// <reference types="matrixrequirements-type-declarations" />
import { JsonEditorValidation } from "../../../jsonvalidation/JsonValidator";
import { app, ControlState, globalMatrix } from "../../globals";
import { ml } from "../matrixlib";
import { UIToolsConstants } from "../matrixlib/MatrixLibInterfaces";

export type { IValidationSpec };
export { JsonEditor };

interface IValidationSpec {
    validationFunction?: JsonEditorValidation | null | any;
    schema?: string;
    apiHelp?: string;
}

class JsonEditor {
    constructor() {}
    showDialog(title: string, value: string, onOk: (update: string) => void, semanticValidate: IValidationSpec = {}) {
        let dlg = $("<div>").appendTo("body");

        dlg.html("");

        dlg.addClass("dlg-v-scroll");
        dlg.removeClass("dlg-no-scroll");

        const container = $("<div id='jsonEditorContainer' class='jsonEditorContainer'>");
        // const containerContainer = $("<div id='jsonEditorContainerContainer'>");
        const editor = $("<div class='theeditor'>").appendTo(container);
        // container.append(containerContainer);
        // dlg.append(container);

        ml.UI.showDialog(
            dlg,
            title,
            container,
            $(document).width() * 0.9,
            app.itemForm.height() * 0.9,
            [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: async function (event: JQueryMouseEventObject) {
                        let value = await editor.getController().getValueAsync();
                        try {
                            let escapedJsonStr = ml.JSON.escapeJson(value);
                            jsl.parser.parse(escapedJsonStr);
                            let json = JSON.parse(escapedJsonStr);
                            const callback = (error: string) => {
                                const submit = () => {
                                    onOk(value);
                                    dlg.dialog("close");
                                };

                                if (error) {
                                    if (event.shiftKey) {
                                        console.error("Validation error suppressed", error);
                                        submit();
                                        return;
                                    }
                                    ml.UI.showError(
                                        "JSON Validation error",
                                        `<div style='font-weight: bold'>You can still save if you press the Shift key while clicking OK.</div><div>${error}</div>`,
                                    );
                                    // ignore OK
                                    event.preventDefault();
                                    //return false;
                                } else {
                                    submit();
                                }
                            };
                            if (semanticValidate.validationFunction) {
                                semanticValidate
                                    .validationFunction(json)
                                    .then(callback)
                                    .catch((err) => console.log("error", err));
                            } else if (semanticValidate.schema) {
                                globalMatrix.jsonValidator
                                    .validateType(json, semanticValidate.schema)
                                    .then((errors) => callback(globalMatrix.jsonValidator.errorString(errors)))
                                    .catch((err) => console.log("error", err));
                            } else {
                                onOk(value);
                                dlg.dialog("close");
                            }
                        } catch (parseException) {
                            if (typeof parseException === "object" && "message" in parseException) {
                                alert(parseException.message);
                            }
                        }
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.None,
            false,
            true,
            () => {
                // close
                dlg.remove();
            },
            () => {
                // open
                editor.html("").plainText({
                    id: "",
                    help: "&nbsp;",
                    controlState: ControlState.DialogCreate,
                    valueChanged: function () {},
                    isFolder: true,
                    canEdit: true,
                    fieldValue: value,
                    parameter: {
                        code: "json",
                        height: 0,
                        autoFormat: true,
                        showJSONFormat: true,
                        apiHelp: semanticValidate.apiHelp,
                        hideFullscreen: true,
                    },
                });
                if (semanticValidate.schema) {
                    globalMatrix.jsonValidator
                        .schemaView(semanticValidate.schema)
                        .then((view) => {
                            editor.addClass("split");
                            let drag = $("<div id='codeDrag'>");
                            container.append(drag);
                            let help = $(view.render());
                            help.addClass("editorHelp");
                            container.append(help);
                            drag.mousedown(function (e) {
                                if (e.preventDefault) e.preventDefault();

                                let mi = container.offset().left;
                                let ma = container.width() + mi;

                                $(document).mousemove(function (e) {
                                    if (e.pageX > mi + 100 && e.pageX < ma - 100) {
                                        let per = (100 * (e.pageX - mi)) / container.width();
                                        editor.css("width", per + "%");
                                        help.css("width", 100 - per + "%");
                                    }
                                });
                            });
                        })
                        .catch((err) => console.log("error", err));
                } else {
                    editor.css("width", "100%");
                }
            },
            () => {
                // resize
                $(".CodeMirror", dlg).height($(".apiEdit", dlg).height() - 50);
            },
            false,
        );
    }
}
