import { ml } from "./../matrixlib";
import { plugins } from "./PluginManager";
import type { ICategoryGroup, ICategoryGroups } from "../../ProjectSettings";
import { XRSettingType, XRCategoryAndSettingListType } from "../../RestResult";
import { mDHF } from "./PluginManagerDocuments";
import { UIToolsConstants } from "../matrixlib/MatrixLibInterfaces";
import { NavBar } from "../UI/Components";
import { IItem, IStringStringArrayMap, globalMatrix, matrixSession, app } from "../../globals";

export type { IDB, IDBParent, XRCategoryAndSettingListTypeExt };
export { DBCache };

interface ICategoryGroupDB extends ICategoryGroup {
    kids: IDB[];
}

interface IDB {
    children?: IDB[];
    id?: string;
    title?: string;
    type?: string;
    isUnselected?: number;
    background?: string;
    border?: string;
    icon?: string;
    iconClass?: string;
    version?: string;
    extraStyle?: string;
    mode?: string;
    order?: number; // for now used only for dashboards
}
interface IDBParent {
    parent: string;
    position: number;
    item: IItem;
}

interface XRCategoryAndSettingListTypeExt {
    settingList: XRSettingType[];
    categoryId: number | string;
    categoryShort: string;
}

interface IDBCache {
    sortChildren(itemId: string);
    initMatrixTree(init: IDB[], includeActivity: boolean);
    initConfigTree(init: IDB[]);
    getTree(subtreeFilter: string[]): IDB[];
    getParentId(itemId: string): string;
    getCategoryBreadcrumbs(category: string);
    getBreadcrumbs(itemId: string): string[];
    getType(itemId: string): string;
    getIcon(itemId: string): string;
    setStyle(itemIds: string[], style: string, computeFolder: number);
    setStyleRec(folder: IDB[], itemIds: string[], style: string, computeFolder: number): boolean;
    isFolder(itemId: string): boolean;
    getItemTitle(itemId: string): string;
    getItemType(itemId: string): string;
    isHiddenLink(itemId: string): boolean;
    setHiddenLink(itemId: string, hidden: number): void;
    hasChildren(itemId: string): boolean;
    doesExist(itemId: string): boolean;
    insertItem(itemJson: IItem, parentId: string): IDBParent;
    copyFrom(target: string, source: IDB): boolean;
    getRootOfType(category: string): string;
    deleteItem(itemId: string): IDB;
    moveItem(itemId: string, newFolder: string, newPosition: number);
    updateItem(itemJson: IItem): void;
    getChildrenIds(parentId: string): string[];
    getItemFromCache(itemId: string): IDB;
}

class DBCache implements IDBCache {
    private db: IDB[] = [];
    private activities: IDB[] = [];
    private groupPos: number;
    private groupDefintion: ICategoryGroups;
    private breadCrumbs: IStringStringArrayMap = {};

    constructor() {}

    protected createVirtualFolder(id: string, title: string, icon?: string, color?: string) {
        let folder: IDB = { children: [], iconClass: icon ? icon : "", id: id, title: title, type: "_" + id };
        let cst: XRCategoryAndSettingListTypeExt = {
            settingList: [
                {
                    key: "texticon",
                    value: JSON.stringify({
                        color: color ? color : UIToolsConstants.CIColorsGrey.G3_GreyDark.color,
                        text: id,
                    }),
                    secret: false,
                },
            ],
            categoryId: id,
            categoryShort: "_" + id,
        };
        globalMatrix.ItemConfig.addCategorySetting(<XRCategoryAndSettingListType>cst);
        return folder;
    }

    protected createVirtualItem(order: number, id: string, title: string, icon?: string) {
        let item: IDB = {
            iconClass: icon ? icon : "",
            id: id,
            title: title,
            type: "_" + id,
            order: order,
        };
        return item;
    }

    sortChildren(itemId: string) {
        let obj: IDB = this.findInDB(this.db, itemId);
        if (!obj || !obj.children || obj.children.length == 0) {
            // nothing to sort
            return;
        }

        obj.children.sort(function (a: IDB, b: IDB) {
            return a.title < b.title ? -1 : 1;
        });
    }
    async initMatrixTree(init: IDB[], includeActivity: boolean) {
        let that = this;

        // a cache for breadcrumbs
        this.breadCrumbs = {};

        // create the nodes for the WORK tab
        let myWork: IDB = this.createVirtualFolder("MYWORK", "My Work");

        let dashboards: IDB = this.createVirtualFolder("DASHBOARDS", "Dashboards");

        let tools: IDB = this.createVirtualFolder("TOOLS", "Tools");

        let qms: IDB = this.createVirtualFolder("QMS", "QMS");

        let auditTools: IDB = this.createVirtualFolder("AUDIT", "Audit Tools");

        let connectionTools: IDB = this.createVirtualFolder("ADDONS", "Add Ons");

        let branches: IDB = this.createVirtualFolder("BRANCHES", "Branching");
        let imports: IDB = this.createVirtualFolder("COMPOSE", "Compose");

        let otherTools: IDB = this.createVirtualFolder("OTHER", "Other");
        let drawers = [myWork, auditTools, branches, imports, dashboards, connectionTools, tools, otherTools, qms];

        //
        if (includeActivity) {
            if (!globalMatrix.ItemConfig.getTimeWarp()) {
                auditTools.children.push(this.createVirtualItem(2000, "DELETED", "Deleted Items", "fal fa-trash"));
            }
            if (matrixSession.hasAgileSync() && !globalMatrix.ItemConfig.getTimeWarp()) {
                connectionTools.children.push(this.createVirtualItem(1000, "SYNC", "Agile Sync", "fal fa-sync-alt"));
            }
            if (app.auditTrailAvailable()) {
                auditTools.children.push(this.createVirtualItem(4000, "TAGS", "Tags", "fal fa-tags"));

                auditTools.children.push(this.createVirtualItem(1000, "CHANGES", "Changes", "fal fa-calendar-alt"));

                if (matrixSession.hasDocs() && !globalMatrix.ItemConfig.getTimeWarp()) {
                    auditTools.children.push(
                        this.createVirtualItem(5000, "DOCS", "Document Changes and Downloads", "fal fa-file-alt"),
                    );
                }
                if (matrixSession.hasDocs() && !globalMatrix.ItemConfig.getTimeWarp()) {
                    myWork.children.push(
                        this.createVirtualItem(3000, "MYDOCS", "My Signatures", "fal fa-file-signature"),
                    );
                }
            }

            let toolIcons = ["utensil-knife", "shovel-snow", "hammer", "screwdriver", "axe", "shovel", "knife-kitchen"];

            let pages = await plugins.getProjectPages();
            for (let page of pages) {
                // default place for addons = TOOLS
                page.folder = page.folder ? page.folder : "OTHER";
                page.folderTitle = page.folderTitle ? page.folderTitle : "Other Tools";
                let targets = drawers.filter(function (d) {
                    return d.id == page.folder;
                });
                if (targets.length == 0) {
                    let targetFolder: IDB = that.createVirtualFolder(page.folder, page.folderTitle);
                    drawers.push(targetFolder);
                    targets = [targetFolder];
                }
                targets[0].children.push(
                    that.createVirtualItem(
                        page.order,
                        page.id,
                        page.title,
                        page.icon ? page.icon : "fal fa-" + toolIcons[idx % toolIcons.length],
                    ),
                );
            }
        }

        if ((typeof mDHF !== "undefined" || includeActivity) && app) {
            // remove the empty drawers
            drawers = drawers.filter(function (d) {
                return d.children.length > 0;
            });
            // sort the items in the drawer
            $.each(drawers, function (dIdx, drawer) {
                drawer.children.sort(function (tool1, tool2) {
                    if (tool1.order < tool2.order) return -1;
                    if (tool1.order > tool2.order) return 1;
                    if (tool1.id < tool2.id) return -1;
                    if (tool1.id > tool2.id) return 1;
                    return 0;
                });
            });
            // add to navbar
            if (NavBar.isEnabled()) {
                let folderNames: string[] = [];
                $.each(drawers, function (dIdx, drawer) {
                    that.db.push(drawer);
                    folderNames.push(drawer.id);
                });
                NavBar.setWorkFolders(folderNames);
            } else {
                let work: IDB = this.createVirtualFolder("TOOLS", "Tools");
                work.children = drawers;

                // add to project folder
                this.db.push({
                    children: [work],
                    icon: "projectw.png",
                    id: "F-PROJECT",
                    title:
                        mDHF && mDHF.getToolFolderName()
                            ? mDHF.getToolFolderName()
                            : "Project, Reports & Controlled Documents",
                    type: "_PROJECT",
                });
            }
        }

        this.groupDefintion = globalMatrix.ItemConfig ? globalMatrix.ItemConfig.getCategoryGroupConfig() : null;
        if (this.groupDefintion) {
            $.each(this.groupDefintion.groups, function (gidx: number, group: ICategoryGroupDB) {
                // MATRIX-1557 delete group kids cache
                group.kids = null;
            });
        }
        this.groupPos = this.db.length; // default pos for groups

        // add all items not in a group
        for (var idx = 0; idx < init.length; idx++) {
            if (
                typeof mDHF !== "undefined" &&
                mDHF.isDocumentType(init[idx].type) &&
                mDHF.showInProjectFolder(init[idx].type) &&
                !NavBar.isEnabled()
            ) {
                this.db[0].children.push(init[idx]);
            } else {
                var inGroup = false;
                if (this.groupDefintion) {
                    // casting group from definition to allow to add kids
                    $.each(this.groupDefintion.groups, function (gidx: number, group: ICategoryGroupDB) {
                        // [{"name":"Design Input", "color":"brown","text":"DI", "categories":["DI1","DI2","DI3","DI3"]}]
                        if (!group.kids) {
                            group.kids = [];
                        }
                        if (group.categories.indexOf(init[idx].type) !== -1) {
                            inGroup = true;
                            group.kids.push(init[idx]);
                        }
                    });
                }
                if (!inGroup) {
                    this.db.push(init[idx]);
                }
            }
        }
        // insert groups
        if (this.groupDefintion) {
            var autoPos = 0; // in case there is no position specified
            // casting group to allow to add DB items
            $.each(this.groupDefintion.groups, function (gidx: number, group: ICategoryGroupDB) {
                //         [{"name":"Design Input", "color":"brown","text":"DI", "categories":["DI1","DI2","DI3","DI3"],"helpPage":"www.matrixreq.com"}]
                var gi: IDB = {
                    children: group.kids,
                    icon: "project.png",
                    id: "F-" + group.text,
                    title: group.name,
                    type: "_" + group.text,
                };
                var position = that.groupPos + (group.position ? group.position : autoPos);
                autoPos++;
                if (position >= that.db.length) {
                    that.db.push(gi);
                } else {
                    that.db.splice(position, 0, gi);
                }
                let cst: XRCategoryAndSettingListTypeExt = {
                    settingList: [
                        {
                            key: "texticon",
                            value: JSON.stringify({
                                color: group.color,
                                text: group.text,
                            }),
                            secret: false,
                        },
                    ],
                    categoryId: "_" + group.text,
                    categoryShort: "_" + group.text,
                };
                globalMatrix.ItemConfig.addCategorySetting(<XRCategoryAndSettingListType>cst);
            });
        }
        plugins.filterProject(this.db);
    }

    initConfigTree(init: IDB[]) {
        let that = this;

        // add all items not in a group
        for (var idx = 0; idx < init.length; idx++) {
            this.db.push(init[idx]);
        }
    }

    /** getTree returns a tree or a sub tree of the project.
     * this call is synchronous. The database tree is created once during the
     * initialization and filtered after as needed.
     *
     * @param {type} subtreeFilter
     * @returns tree object
     */
    getTree(subtreeFilter: string[]): IDB[] {
        if (typeof subtreeFilter === "undefined" || subtreeFilter.length === 0) {
            return this.db;
        }
        var st: IDB[] = [];
        for (var idx = 0; idx < this.db.length; idx++) {
            if (subtreeFilter.indexOf(this.db[idx].type) !== -1) {
                st.push(this.db[idx]);
            }
            if (this.db[idx].type[0] === "_") {
                // also look in project filter. There are some 'hidden' trees like REPORT, ECO, CAPA, SIGN...
                // examples of these meta folders are _PROJECT
                var stree = this.db[idx].children;
                for (var sdx = 0; sdx < stree.length; sdx++) {
                    if (subtreeFilter.indexOf(stree[sdx].type) !== -1) {
                        st.push(stree[sdx]);
                    }
                }
            }
        }

        return st;
    }

    getParentId(itemId: string): string {
        return this.findParentId(this.db, itemId);
    }

    // this is actually really expensive (it's a depth first search, so for category roots we cache the results)
    getCategoryBreadcrumbs(category: string) {
        if (!this.breadCrumbs[category]) {
            this.breadCrumbs[category] = this.getBreadcrumbs("F-" + category + "-1");
        }
        return this.breadCrumbs[category];
    }

    // this is actually really expensive (it's a depth first search, so for category roots we cache the results)
    getBreadcrumbs(itemId: string): string[] {
        let all = [itemId];
        this.findParentId(this.db, itemId, all);
        return all;
    }

    getType(itemId: string): string {
        var ir = ml.Item.parseRef(itemId);
        if (ir.type !== "") {
            return ir.type;
        }
        // otherwise the id is not a normal id, but something special, like "-1" for "Deleted items... ask the DB
        var obj: IDB = this.findInDB(this.db, itemId);
        if (obj && obj.type) {
            return obj.type;
        }
        // no idea...
        return "";
    }

    getIcon(itemId: string): string {
        var obj: IDB = this.findInDB(this.db, itemId);
        if (obj && obj.icon) {
            return obj.icon;
        }
        return null;
    }

    /* set style of item / folder
        computeFolder: 0=look (folder style from lookup)
        computeFolder: 1=all (all children in folder have style: folder has style)
        computeFolder: 2=any (any of the children has style: folder has style) */
    setStyle(itemIds: string[], style: string, computeFolder: number) {
        this.setStyleRec(this.db, itemIds, style, computeFolder);
    }
    setStyleRec(folder: IDB[], itemIds: string[], style: string, computeFolder: number): boolean {
        let that = this;
        let allOrAny = computeFolder == 1 ? true : false;

        $.each(folder, function (idx, idb) {
            if (idb.children) {
                if (idb.children.length == 0 && computeFolder == 1) {
                    allOrAny = false;
                } else {
                    // a folder
                    let children = that.setStyleRec(idb.children, itemIds, style, computeFolder);
                    let color = false;
                    switch (computeFolder) {
                        case 0:
                            color = itemIds.indexOf(idb.id) != -1;
                            break;
                        case 1:
                            allOrAny = allOrAny && children;
                            color = children;
                            break;
                        case 2:
                            allOrAny = allOrAny || children;
                            color = children;
                            break;
                    }
                    if (color && (idb.children.length || computeFolder == 0)) {
                        idb.extraStyle = style;
                    }
                }
            } else {
                // an item
                if (itemIds.indexOf(idb.id) != -1) {
                    idb.extraStyle = style;
                    if (computeFolder == 2) {
                        allOrAny = true;
                    }
                } else if (computeFolder == 1) {
                    allOrAny = false;
                }
            }
        });

        return allOrAny;
    }

    isFolder(itemId: string): boolean {
        var obj = this.findInDB(this.db, itemId);
        if (!obj) return false;
        return obj.children ? true : false;
    }

    getItemTitle(itemId: string): string {
        var obj = this.findInDB(this.db, itemId);
        if (!obj) return globalMatrix.ITEM_DOES_NOT_EXIST;
        return obj.title;
    }
    getItemType(itemId: string): string {
        var obj = this.findInDB(this.db, itemId);
        if (!obj) return globalMatrix.ITEM_DOES_NOT_EXIST;
        return obj.type;
    }
    isHiddenLink(itemId: string): boolean {
        var obj = this.findInDB(this.db, itemId);
        if (!obj) return true;
        return obj.isUnselected && obj.isUnselected === 1;
    }

    setHiddenLink(itemId: string, hidden: number): void {
        var obj = this.findInDB(this.db, itemId);
        if (obj) obj.isUnselected = hidden;
    }

    hasChildren(itemId: string): boolean {
        var children = this.findInDB(this.db, itemId).children;
        return children && children.length > 0;
    }

    doesExist(itemId: string): boolean {
        var obj = this.findInDB(this.db, itemId);
        return obj ? true : false;
    }

    insertItem(itemJson: IItem, parentId: string): IDBParent {
        if (typeof parentId !== "undefined") {
            var parent = this.findInDB(this.db, parentId);
            if (!itemJson.type) {
                itemJson.type = parent.type;
            }
            parent.children.push(<IDB>itemJson);

            return { parent: parentId, position: parent.children.length - 1, item: itemJson };
        }
        for (var idx = 0; idx < this.db.length; idx++) {
            if (this.db[idx].type === itemJson.type) {
                this.db[idx].children.push(<IDB>itemJson);
                return { parent: this.db[idx].id, position: this.db[idx].children.length - 1, item: itemJson };
            }
        }
        return null;
    }

    // replaces the target, a child inside the target or creates a new child with source
    copyFrom(target: string, source: IDB): boolean {
        var parent = this.findInDB(this.db, target);
        // check if other tree already exists... if so replace it
        if (!parent) {
            ml.Logger.log("error", "cannot find parent folder in tree replacement.");
            return false;
        }
        if (parent.id === source.id) {
            this.internalReplace(parent, source);
            return true;
        }
        if (!parent.children) {
            ml.Logger.log("error", "parent is not a folder.");
            return false;
        }
        for (var idx = 0; idx < parent.children.length; idx++) {
            if (parent.children[idx].id === source.id) {
                // replace child
                this.internalReplace(parent.children[idx], source);
                return true;
            }
        }
        // create a new child and replace
        var newChild: IDB = { id: source.id, title: "", type: "" };
        parent.children.push(newChild);
        this.internalReplace(newChild, source);
        return true;
    }

    getRootOfType(category: string): string {
        var groups: string[] = [];
        var gdef: ICategoryGroups = globalMatrix.ItemConfig.getCategoryGroupConfig();
        if (gdef) {
            $.each(gdef.groups, function (gi, g) {
                groups.push("_" + g.text);
            });
        }
        for (var idx = 0; idx < this.db.length; idx++) {
            if (this.db[idx].type === category) {
                return this.db[idx].id;
            }
            if (groups.indexOf(this.db[idx].type) !== -1) {
                for (var j = 0; j < this.db[idx].children.length; j++) {
                    if (this.db[idx].children[j].type === category) {
                        return this.db[idx].children[j].id;
                    }
                }
            }
        }
        return null;
    }

    deleteItem(itemId: string): IDB {
        return this.deleteItemRec(this.db, itemId);
    }

    moveItem(itemId: string, newFolder: string, newPosition: number) {
        var item = this.findInDB(this.db, itemId);
        if (item) {
            this.deleteItemRec(this.db, itemId);
            this.insertAtRec(this.db, newFolder, newPosition, item);
        }
    }

    updateItem(itemJson: IItem): void {
        if (typeof itemJson.title == "undefined") {
            // can happen if item is only partially udpated (lables or some fields only)
            return;
        }
        var item = this.findInDB(this.db, itemJson.id);
        if (item) {
            item.title = itemJson.title;
        }
    }

    getChildrenIds(parentId: string): string[] {
        var ids: string[] = [];
        var parent = this.findInDB(this.db, parentId);
        for (var idx = 0; idx < parent.children.length; idx++) {
            ids.push(parent.children[idx].id);
        }
        return ids;
    }

    getItemFromCache(itemId: string): IDB {
        return this.findInDB(this.db, itemId);
    }

    private internalReplace(oldItem: IDB, newItem: IDB) {
        oldItem.children = newItem.children;
        oldItem.icon = newItem.icon;
        oldItem.isUnselected = newItem.isUnselected;
        oldItem.title = newItem.title;
        oldItem.type = newItem.type;
    }

    private findInChildren(node: IDB, itemId: string): IDB {
        if (typeof node.children === "undefined") {
            return null;
        }
        for (var child = 0; child < node.children.length; child++) {
            var res = this.findInDB(node.children[child], itemId);
            if (res !== null) {
                return res;
            }
        }
        return null;
    }

    private findInDB(tree: IDB[], itemId: string): IDB;
    private findInDB(tree: IDB, itemId: string): IDB;
    private findInDB(tree: any, itemId: string): IDB {
        if ((<IDB>tree).id === itemId) {
            return tree;
        }
        var res = this.findInChildren(tree, itemId);
        if (res) {
            return res;
        }
        for (var idx = 0; idx < (<IDB[]>tree).length; idx++) {
            if ((<IDB[]>tree)[idx].id === itemId) {
                return tree[idx];
            }
            res = this.findInChildren(tree[idx], itemId);
            if (res) {
                return res;
            }
        }
        return null;
    }

    // get the parent of an item or all parents if all is passed
    private findParentId(tree: IDB, itemId: string, all?: string[]): string;
    private findParentId(tree: IDB[], itemId: string, all?: string[]): string;
    private findParentId(tree: any, itemId: string, all?: string[]): string {
        if ((<IDB>tree).id === itemId) {
            // in case it is a leaf
            return null; // should have found parent!
        }
        if ((<IDB>tree).children) {
            // in case it is a folder
            for (var idx = 0; idx < (<IDB>tree).children.length; idx++) {
                if ((<IDB>tree).children[idx].id === itemId) {
                    if (all) {
                        all.push((<IDB>tree).id);
                    }
                    return (<IDB>tree).id;
                }
                // maybe it is a child of it's children
                var res = this.findParentId((<IDB>tree).children[idx], itemId, all);
                if (res) {
                    if (all) {
                        all.push((<IDB>tree).id);
                    }
                    return res;
                }
            }
        }
        if ((<IDB[]>tree).length) {
            // in case it is a collection of folders
            for (var idx = 0; idx < (<IDB[]>tree).length; idx++) {
                if ((<IDB[]>tree)[idx].id === itemId) {
                    return null;
                }
                var res = this.findParentId((<IDB[]>tree)[idx], itemId, all);
                if (res) {
                    return res;
                }
            }
        }
        return null;
    }

    private deleteItemRec(tree: IDB, itemId: string): IDB;
    private deleteItemRec(tree: IDB[], itemId: string): IDB;
    private deleteItemRec(tree: any, itemId: string): IDB {
        if (typeof (<IDB>tree).children !== "undefined") {
            for (var idx = 0; idx < (<IDB>tree).children.length; idx++) {
                if ((<IDB>tree).children[idx].id === itemId) {
                    (<IDB>tree).children.splice(idx, 1);
                    return tree;
                }
                var res = this.deleteItemRec((<IDB>tree).children[idx], itemId);
                if (res) {
                    return res;
                }
            }
        } else if (typeof (<IDB[]>tree).length !== "undefined") {
            for (var idx = 0; idx < (<IDB[]>tree).length; idx++) {
                var res = this.deleteItemRec(tree[idx], itemId);
                if (res) {
                    return res;
                }
            }
        }

        return null;
    }

    private insertAtRec(tree: IDB, newFolder: string, newPosition: number, item: IDB): boolean;
    private insertAtRec(tree: IDB[], newFolder: string, newPosition: number, item: IDB): boolean;
    private insertAtRec(tree: any, newFolder: string, newPosition: number, item: IDB): boolean {
        //moveDetails:
        // "parentId":sourceNode.parent.key,
        // "index":sourceNode.getIndex()
        if ((<IDB>tree).id === newFolder) {
            if (typeof (<IDB>tree).children !== "undefined") {
                (<IDB>tree).children.splice(newPosition, 0, item);
                return true;
            } else {
                // something went wrong...
                return false;
            }
        } else if (typeof (<IDB>tree).children !== "undefined") {
            // this is a folder
            for (var idx = 0; idx < (<IDB>tree).children.length; idx++) {
                var res = this.insertAtRec((<IDB>tree).children[idx], newFolder, newPosition, item);
                if (res) {
                    return res;
                }
            }
            return false;
        } else if (typeof (<IDB[]>tree).length !== "undefined") {
            for (var idx = 0; idx < (<IDB[]>tree).length; idx++) {
                var res = this.insertAtRec(tree[idx], newFolder, newPosition, item);
                if (res) {
                    return res;
                }
            }
            return false;
        }
        return false;
    }
}
