// -----------------------------------------------------------
// Dialog to selection items
// -----------------------------------------------------------

import { IDropdownOption } from "../../../ProjectSettings";
import {
    XRGetProject_CategoryList_GetProjectStructAck,
    XRGetProject_ProjectSettingAll_GetSettingAck,
    XRGetProject_FullTree_FancyFolderList,
    XRProjectType,
} from "../../../RestResult";
import { IDB, DBCache, ItemConfiguration } from "../../businesslogic/index";
import { RestDB } from "../../businesslogic/index";
import { ml } from "../../matrixlib";
import { ILinkType } from "../Components/ItemForm";
import { SelectMode } from "../Components/ProjectViewDefines";
import { ProjectView } from "../Components/ProjectView";
import { UIToolsConstants } from "../../matrixlib/MatrixLibInterfaces";
import {
    IReference,
    app,
    ControlState,
    globalMatrix,
    matrixSession,
    setIC,
    restConnection,
    IRestParam,
} from "../../../globals";

export type { IItemSelectDialogOptions, IItemSelectButtonOptions };
export { ItemSelectionTools };

interface IItemSelectDialogOptions {
    linkTypes: ILinkType[];
    getSelectedItems: () => Promise<IReference[]>; // is called before dialog is opened to get currently selected items
    selectionChange: (newSelection: IReference[]) => void; // is called when the dialog is closed (with select)

    crossProjectInit?: Function;
    crossProject?: boolean;
    crossProjectProject?: string; // set to project name if project should be preselected (instead of letting user pick one)
    crossProjectFilter?: string; // optional to filter by label
    crossProjectFilterStrict?: boolean; // optional to remove no matching items
    allowedProjects?: XRProjectType[]; // optional to limit the allowed projects to the passed list
    allowedCategories?: string[]; // optional list of categories which can be selected
    selectMode?: SelectMode; // select Mode
    selectOptions?: JQuery; // allows to show some additional input underneath tree
    allowAutoDownlinkSelection?: boolean; // if set to true, the user can click a button and all downlinks of the current selection are included
    dialogTitle?: string; // optional. possibility to change the default dialog title
    focusOn?: string; // open tree to a specific place

    height?: number; // optional a dialog height
    autoScroll?: boolean; // should the view scroll to the active node? defaults to true
}
interface IItemSelectButtonOptions extends IItemSelectDialogOptions {
    buttonName?: string;
    smallbutton?: boolean;
    isRiskControl?: boolean;
    control: JQuery;
    tinybutton?: boolean;
}
class ItemSelectionTools {
    constructor() {}

    showDialog(options: IItemSelectDialogOptions): void {
        this.showSelectDialog(options);
    }

    renderButtons(options: IItemSelectButtonOptions): void {
        options.control.addClass("rowFlex");
        var buttonName = options.buttonName ? options.buttonName : "Select Existing";
        var renderStyle = options.smallbutton ? "btn-sm " : "";

        var seleniumClass = "sel_" + buttonName.replace(/ /g, "");

        var riskFormat = options.isRiskControl ? "btn-sm rcbn" : "";

        if (options.tinybutton) {
            buttonName = "()";
            renderStyle = "btn-xs";
            riskFormat = options.isRiskControl ? "rcbn" : "";
        }
        var button = $(
            "<button name='selectRef' class='" +
                riskFormat +
                " buttonCreateSelect btn btn-default " +
                seleniumClass +
                " " +
                renderStyle +
                "'>" +
                buttonName +
                "</button>",
        );
        var dlgOptions: IItemSelectDialogOptions = {
            linkTypes: options.linkTypes,
            getSelectedItems: options.getSelectedItems,
            selectionChange: options.selectionChange,
            selectMode: options.selectMode,
            crossProject: options.crossProject,
            crossProjectInit: options.crossProjectInit,
            crossProjectProject: options.crossProjectProject,
            allowedProjects: options.allowedProjects,
            allowedCategories: options.allowedCategories,
            allowAutoDownlinkSelection: options.allowAutoDownlinkSelection,
            crossProjectFilter: options.crossProjectFilter ? options.crossProjectFilter : null,
            crossProjectFilterStrict: options.crossProjectFilterStrict,
        };
        if (options.crossProject) {
            button.click(() => this.showCrossProjectDialog(dlgOptions));
        } else {
            button.click(() => this.showSelectDialog(dlgOptions));
        }

        options.control.append(button);
    }

    private showSelectDialog(options: IItemSelectDialogOptions) {
        let that = this;

        // remove global highlight and show only matches in dlg after
        ml.Search.searchInDialog();

        var linkTypes: string[] = [];
        for (var idx = 0; idx < options.linkTypes.length; idx++) {
            linkTypes.push(options.linkTypes[idx].type);
        }

        let container = $("<div class='selectItemDlgTreeContainer'>");
        let tree = $("<div class='itemSelectionContainer'>").appendTo(container);
        tree.append(ml.UI.getSpinningWait("Loading tree"));

        app.waitForMainTree(loadTree);

        let dlg = $("#selectItemDlg");
        ml.UI.showDialog(
            dlg,
            options.dialogTitle ? options.dialogTitle : "Select Items",
            container,
            Math.min(900, window.innerWidth - 100),
            options.height ? options.height : window.innerHeight - 50,
            [
                {
                    text: "Select",
                    class: "btnDoIt",
                    click: async function () {
                        options.selectionChange(await tree.getController().getValueAsync());
                        dlg.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.None,
            true,
            true,
            function () {
                // dlg is gone, remove highlights and back to global highlighting
                ml.Search.endSearchInDialog();
                ml.UI.popDialog(dlg);
            },
            function () {
                ml.UI.pushDialog(dlg);
                if (options.selectOptions) {
                    $(".treeContent", container).css("bottom", options.selectOptions.height());
                    $(".listContent", container).css("bottom", options.selectOptions.height());
                }
                if (options.focusOn) {
                    (<ProjectView>tree.getController()).select(options.focusOn);
                }
            },
        );

        async function loadTree() {
            //Let's remove the loading stuff
            tree.html("");
            let treeData = app.getTree(linkTypes);
            tree.projectView({
                tree: treeData,
                controlState: ControlState.DialogCreate,
                selectedItems: await options.getSelectedItems(),
                canSelectItems: true,
                selectMode: options.selectMode,
                expand: options.selectMode == SelectMode.auto || options.selectMode == SelectMode.independent ? 0 : 1,
                autoScroll: options.autoScroll,
            });
            if (options.selectOptions) {
                container.parent().append(options.selectOptions);
            }
        }
    }

    private toggleSelect(enabled: boolean) {
        ml.UI.setEnabled($(".btnDoIt", $("#selectItemDlg").parent()), enabled);
    }
    showCrossProjectDialog(options: IItemSelectDialogOptions) {
        let that = this;

        let originalIC = ml.JSON.clone(globalMatrix.ItemConfig);

        // remove global highligh and show only matches in dlg after
        ml.Search.searchInDialog();

        var linkTypes: string[] = [];
        for (var idx = 0; idx < options.linkTypes.length; idx++) {
            linkTypes.push(options.linkTypes[idx].type);
        }

        var dlgUi = $("<div class='selectItemDlgTreeContainer'>");
        dlgUi.append('<span class="ui-helper-hidden-accessible"><input autocomplete="off" type="text"/></span>');
        var projectList = options.allowedProjects ? options.allowedProjects : matrixSession.getProjectList(true);
        var projectSelect: IDropdownOption[] = [];
        $.each(projectList, function (projectIdx, project) {
            if (!options.crossProjectProject || options.crossProjectProject === project.shortLabel) {
                projectSelect.push({ id: project.shortLabel, label: project.shortLabel + " - " + project.label });
            }
        });
        var tree = $("<div class='dlg-no-scroll'>").html("Select a project");
        var projectSelection = $("<div>").mxDropdown({
            controlState: ControlState.DialogCreate,
            canEdit: true,
            dummyData: false,
            help: "Project",
            parameter: {
                readonly: false,
                placeholder: "select project",
                splitHuman: false,
                options: projectSelect,
            },
            valueChanged: async function () {
                var newProjectId = await projectSelection.getController().getValueAsync();
                projectSelection.hide();
                options.crossProjectInit(newProjectId);
                setIC(new ItemConfiguration(ml.Logger, ml.JSON));

                if (options.allowAutoDownlinkSelection) {
                    // add a button to auto select downlinked items
                    $(`<div class='dlgLinkButton'>select all downlinks</div>`)
                        .appendTo($(".ui-dialog-buttonpane", dlg.parent()))
                        .click(async () => {
                            let selection = (await tree.getController().getValueAsync()).map((sel) => sel.to);
                            let di = selection.map((sel) => `uplinkm=${sel}`);
                            if (di.length) {
                                ml.UI.BlockingProgress.Init([{ name: "getting downlinks of current selection" }], true);
                                ml.UI.BlockingProgress.SetProgress(0, 1);
                                let search = `/needleminimal`;
                                let params: IRestParam = { search: `mrql:${di.join(" or ")}` };
                                restConnection.postServer(newProjectId + search, params, true).done((downlinks) => {
                                    tree.getController().setValue(selection.concat(downlinks));
                                    ml.UI.BlockingProgress.SetProgress(0, 100);
                                });
                            }
                        });
                }

                restConnection
                    .getServer(newProjectId + "/cat")
                    .done(function (catDetails) {
                        globalMatrix.ItemConfig.addCategories(
                            catDetails as XRGetProject_CategoryList_GetProjectStructAck,
                        );

                        restConnection
                            .getServer(newProjectId + "/setting")
                            .done(function (settings) {
                                globalMatrix.ItemConfig.addSettings(
                                    settings as XRGetProject_ProjectSettingAll_GetSettingAck,
                                );
                                let treeSearch =
                                    "/tree?fancy" +
                                    (options.crossProjectFilter ? "&filter=" + options.crossProjectFilter : "");
                                restConnection
                                    .getServer(newProjectId + treeSearch)
                                    .done(async function (response) {
                                        let result = response as XRGetProject_FullTree_FancyFolderList;
                                        result = RestDB.filterLegacyReportCat(result);
                                        if (options.allowedCategories) {
                                            result = result.filter((node) =>
                                                options.allowedCategories.includes(node.type),
                                            );
                                        }
                                        if (options.crossProjectFilterStrict) {
                                            result = <XRGetProject_FullTree_FancyFolderList>(
                                                that.removeHidden(<IDB[]>result)
                                            );
                                        }
                                        var pdb = new DBCache();
                                        await pdb.initMatrixTree(result, false);
                                        var treeContent = pdb.getTree(linkTypes);

                                        // height: 100% is to make tree list scrollable
                                        tree.attr("style", "height: 100%")
                                            .html("")
                                            .projectView({
                                                tree: treeContent,
                                                controlState: ControlState.DialogCreate,
                                                selectedItems: await options.getSelectedItems(),
                                                canSelectItems: true,
                                                selectMode: options.selectMode,
                                                expand: options.selectMode == SelectMode.auto ? 0 : 1,
                                                crossProject: newProjectId,
                                            });
                                        if (options.selectOptions) {
                                            $(".treeContent", tree).css("bottom", options.selectOptions.height() + 4);
                                        }
                                        that.toggleSelect(true);
                                    })
                                    .fail(function () {
                                        setIC(originalIC);
                                    });
                            })
                            .fail(function () {
                                setIC(originalIC);
                            });
                    })
                    .fail(function () {
                        setIC(originalIC);
                    });
            },
        });

        dlgUi.append(projectSelection);
        dlgUi.append(tree);

        var niceSize = ml.UI.getNiceDialogSize(750, 600);

        let dlg = $("#selectItemDlg");
        dlg.html("");
        dlg.removeClass("dlg-v-scroll");
        dlg.addClass("dlg-no-scroll");
        dlg.append(dlgUi);

        if (options.selectOptions) {
            dlg.append(options.selectOptions);
        }
        dlg.dialog({
            autoOpen: true,
            title: "Select Project and Items",
            height: niceSize.height,
            width: niceSize.width,
            modal: true,
            close: function () {
                // just in case it was added
                setIC(originalIC);
                $(".dlgLinkButton").remove();
                // dlg is gone, remove highlights and back to global highlighting
                ml.Search.endSearchInDialog();
                ml.UI.popDialog(dlg);
            },
            open: function () {
                ml.UI.pushDialog(dlg);
                if (options.crossProjectProject) {
                    projectSelection.getController().setValue(options.crossProjectProject, false, true);
                }
                that.toggleSelect(false);
            },
            resizeStop: function (event, ui) {
                if (tree) {
                    dlg.resizeDlgContent([tree]);
                }
            },
            buttons: [
                {
                    text: "Select",
                    class: "btnDoIt",
                    click: async function () {
                        options.selectionChange(await tree.getController().getValueAsync());

                        dlg.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
        }).resizeDlgContent([tree], false);
    }

    private removeHidden(tree: IDB[]) {
        let that = this;
        let newTree: IDB[] = [];
        $.each(tree, function (idx, node) {
            if (!node.isUnselected) {
                let pushy: IDB = {
                    id: node.id,
                    title: node.title,
                    type: node.type,
                    isUnselected: node.isUnselected,
                };
                if (typeof node.children !== "undefined") {
                    pushy.children = that.removeHidden(node.children);
                }
                newTree.push(pushy);
            }
        });

        return newTree;
    }
}
