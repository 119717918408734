import { IPlugin, IProjectPageParam, plugins } from "../../common/businesslogic/index";
import { ContextFrameManager } from "../../common/businesslogic/index";
import { ml } from "../../common/matrixlib";
import { IContextInformation } from "../../common/matrixlib/MatrixLibInterfaces";
import { ItemControl } from "../../common/UI/Components/index";
import { globalMatrix, app, IItem, matrixApplicationUI, ControlState } from "../../globals";

import { IContextPageConfigTab } from "../../ProjectSettings";

export { ReviewContextFrame };
export { initialize };

class ReviewContextFrame implements IPlugin {
    static reviewContextFrame = "reviewContextFrame";
    static lastDisplayedItemId = "";
    constructor() {}
    onUpdate(ui: JQuery, config: IContextPageConfigTab, context: IContextInformation) {
        ui.addClass("reviewContextFrame");
        ui.addClass("noRefresh");

        if (!ReviewContextFrame.lastDisplayedItemId) {
            ui.html("no pinned item (use ctrl-m or the item menu to pin an item here)");
        }
    }

    static lastRenderedItem: string;
    static lastRenderedVersion: number;
    static renderItem(itemId: string, version: number, showContextFrameIfNotVisible: boolean) {
        ReviewContextFrame.lastDisplayedItemId = itemId;

        // check if review context frame is configured
        let cp = globalMatrix.ItemConfig.getContextPagesConfig();
        if (!cp || !cp.tabs) return;
        let cps = cp.tabs.filter(function (cpc) {
            return <string>cpc.type == ReviewContextFrame.reviewContextFrame;
        });
        if (cps.length == 0) return;

        // make sure context frames are visible and tab is selected
        let visible = ml.ContextFrames.showContextFrame(
            ReviewContextFrame.reviewContextFrame,
            showContextFrameIfNotVisible,
        );
        if (!visible) return;
        // avoid getting same item again and again
        if (ReviewContextFrame.lastRenderedItem == itemId && ReviewContextFrame.lastRenderedVersion == version) return;
        app.getItemAsync(itemId, version ? version : undefined).done(async function (item: IItem) {
            $(".reviewContextFrame").html("");
            if (item.title === undefined) {
                matrixApplicationUI.renderErrorControl(
                    $(".reviewContextFrame"),
                    "The item " + item.id + " was deleted",
                    "Ýou can find it in the deleted item log.",
                    true,
                );
            } else if (app.canViewItem(item)) {
                var ctrl = new ItemControl({
                    control: $(".reviewContextFrame"),
                    controlState: ControlState.Tooltip,
                    item: item,
                    isItem: typeof item.children === "undefined",
                });
                await ctrl.load();

                let bcs = app.getBreadcrumbs(item.id).filter(function (bc) {
                    return bc != item.id;
                });
                let breadcrumb = bcs
                    .reverse()
                    .map(function (bid: string) {
                        return bid + " " + app.getItemTitle(bid);
                    })
                    .join(" > ");
                let title = $(".reviewContextFrame .itemTitle")
                    .addClass("tooltipItem")
                    .removeClass("pull-left")
                    .removeClass("itemTitle");
                title.closest(".itemTitleBarNoToolsNoEdit").removeClass("itemTitleBarNoToolsNoEdit");
                $("<hr style='border-top-color: #aaa;margin-top: 10px;width: 100%;margin-bottom: 10px;'/>").insertAfter(
                    title,
                );
                $('<div style="font-size: smaller;">')
                    .append($("<span class='inlineHelp'>").html(breadcrumb))
                    .insertAfter(title);
            } else {
                matrixApplicationUI.renderErrorControl(
                    $(".reviewContextFrame"),
                    "You have no rights to view " + item.id + "",
                    "Talk to the project administrator.",
                    true,
                );
            }
        });
    }

    private _item: IItem;

    public isDefault = true;

    initItem(item: IItem, jui: JQuery) {
        this._item = item;
    }

    initServerSettings() {}
    initProject() {}
    async getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return [];
    }
    updateMenu(ul: JQuery) {
        let that = this;

        let cp = globalMatrix.ItemConfig.getContextPagesConfig();
        if (!cp || !cp.tabs) return;
        let cps = cp.tabs.filter(function (cpc) {
            return <string>cpc.type == ReviewContextFrame.reviewContextFrame;
        });

        if (this._item.id && cps.length == 1 && !ml.Item.parseRef(this._item.id).isFolder) {
            var dev = $('<li><span class="toolmenu">Pin item in review pane</span></li>').appendTo(ul);
            dev.click(function () {
                ReviewContextFrame.renderItem(that._item.id, 0, true);
            });
        }
    }
    supportsControl() {
        return false;
    }
}

function initialize() {
    let reviewContextFrame = new ReviewContextFrame();

    ContextFrameManager.register({
        id: "reviewContextFrame",
        title: "Review Pane",
        adminOption: "allows to pin items for review",
        onUpdate: function (ui: JQuery, config: IContextPageConfigTab, context: IContextInformation) {
            reviewContextFrame.onUpdate(ui, config, context);
        },
    });

    plugins.register(reviewContextFrame);
}
