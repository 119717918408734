import { IDHFSection, IDHFControlDefinition } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { IRichTextOptions } from "../../../common/businesslogic/FieldHandlers/Document/RichTextDocFieldHandler";
import { BaseDHFSection } from "./BaseDHFSection";
export { RichText };

class RichText extends BaseDHFSection<IRichTextOptions> implements IDHFSection {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        ctrl.control.richText(
            ml.JSON.setOptions(ctrlParameter, { parameter: { showSmartText: true, docMode: true, height: "400px" } }),
        );
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {}
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        return false;
    }
    async verifyContentAsync(ctrl: IDHFControlDefinition) {
        let lastVal = await ctrl.control.getController().getValueAsync();
        if (!lastVal) {
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").addClass("contentNeeded");
        } else {
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").removeClass("contentNeeded");
        }
    }
}
