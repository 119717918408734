import { ControlState, globalMatrix, matrixSession } from "../../../globals";
import { HTMLCleaner } from "../../matrixlib/index";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { IUploadedFileInfo } from "../../matrixlib/MatrixLibInterfaces";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export interface IFileManagerParams {
    readonly?: boolean; // can be set to overwrite the default readonly status
    replace?: ReplaceOptions; // whether to replace files
    autohide?: boolean; //  if set to true the file attachment control is only shown if > 1 files are attached. before that files can be attached using the context menu.
    hidden?: boolean; //  if set to true to always hide the control
    manualOnly?: boolean; // if true files dragged into richtext will not be added
    titleBarControl?: JQuery; // if set an attach entry will be added to item menu
    extensions?: string[]; // can be a list of valid file extensions
    textTodo?: string; // can be used to overwrite default text "Select and upload files"
    single?: boolean;
    hideNoFileInfo?: boolean; // if true do not show that there is no files
    maxWidth?: number; // a maximum width of the input control, in pixels
    naked?: boolean; // if true, only display the file selector but no labels
}

export interface IFileManagerOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: IFileManagerParams;
    processExternally?: (fileList: FileList) => boolean; // if a function is specified, it can be used to supress the upload if the files do not match certain criteria
    id?: string;
}
type ReplaceOptions =
    | "never" // never (default): it is possible to add twice the and attachment with the same name
    | "name" // if an attachment with the same name exists it is replaced, user needs to confirm
    | "name_auto" // as above, user is not even asked
    | "type" // if an attachment with the same file extension exists it will be replaced, user needs to confirm
    | "type_auto"; //_ as above, user is not even asked

$.fn.fileManager = function (this: JQuery, options: IFileManagerOptions) {
    if (!options.fieldHandler) {
        //No need for a field handler here, so let's create a dummy one.
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_fileManager,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new FileManagerImpl(this, options.fieldHandler as GenericFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);

    return this;
};

export class FileManagerImpl extends BaseControl<GenericFieldHandler> {
    private settings: IFileManagerOptions;
    private data: IUploadedFileInfo[] = [];
    private fileInfo: JQuery;
    private dragCounter: number;

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IFileManagerOptions) {
        let that = this;

        var defaultOptions: IFileManagerOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dumy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
                replace: "never", // whether to replace files
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        this.data = [];

        if (this.settings.fieldValue && this.settings.fieldValue !== "") {
            this.data = JSON.parse(this.settings.fieldValue);
        }

        this._root.data("original", ml.JSON.clone(this.data));
        this._root.data("new", this.data);

        if (this.settings.parameter.naked !== true) {
            this._root.append(super.createHelp(this.settings));
        }

        const maxWidth = this.settings.parameter.maxWidth
            ? `style="max-width:${this.settings.parameter.maxWidth}px"`
            : "";

        var ctrlContainer = $("<div>").addClass("baseControl");
        this._root.append(ctrlContainer);

        var table = $(`<table class="uploadTable" ${maxWidth}>`);
        ctrlContainer.append(table);
        var tbody = $("<tbody>");
        table.append(tbody);

        this.fileInfo = $("<span></span>");
        var progress = $("<span></span>");
        if (!this.settings.parameter.naked) {
            tbody.append($("<tr>").append($("<td colspan='2'>").append(this.fileInfo)));
            tbody.append($("<tr>").append($("<td colspan='2' style='padding-left:24px;'>").append(progress)));
        }

        if (
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.Tooltip ||
            !this.settings.canEdit
        ) {
            this.showFiles(true);
            return;
        }
        this.showFiles();

        var edit = $("<div>");
        this._root.append(edit);
        var editorRow = $("<tr>");
        tbody.append(editorRow);

        if (this.settings.parameter.naked !== true) {
            let todo = this.settings.parameter.textTodo
                ? this.settings.parameter.textTodo
                : "Select and upload files: ";
            editorRow.append($("<td style='white-space: nowrap;padding-left:24px;padding-right:8px'>").append(todo));
        }

        let fileFilter = "";
        if (this.settings.parameter.extensions && this.settings.parameter.extensions) {
            let fileTypes: string[] = this.settings.parameter.extensions.map(function (ext) {
                return "." + ext.replace(".", "");
            });
            fileFilter = ' accept="' + fileTypes.join(",") + '"';
        }
        let multiple = this.settings.parameter.single ? "" : " multiple";
        const selectFile = $(`<form method="post" enctype="multipart/form-data" ${maxWidth}>
                <div class="input-group" ${maxWidth}>
                    <input autocomplete="off" type="text" class="form-control " readonly="" placeholder="drag files here">
                    <span class="input-group-btn">
                        <span class="btn btn-success btn-file ">
                            Browse... <input type="file" ${fileFilter + multiple}>
                        </span>
                    </span>
                </div>
            </form>`);
        selectFile.find(":file").on("change", function (e: JQueryEventObject) {
            that.uploadFilesUser((<HTMLInputElement>$(e.delegateTarget).get(0)).files);
        });
        this.dragCounter = 0;
        this._root
            .on("dragover", function (e: JQueryEventObject) {
                if (e.preventDefault) e.preventDefault();
                if (e.stopPropagation) e.stopPropagation();
                return false;
            })
            .on("dragenter", function (e: JQueryEventObject) {
                that._root.addClass("drop-target-active");
                if (e.preventDefault) e.preventDefault();
                if (e.stopPropagation) e.stopPropagation();
                that.dragCounter++;
                return false;
            })
            .on("dragleave", function (e: JQueryEventObject) {
                that.dragCounter--;
                if (that.dragCounter === 0) {
                    that._root.removeClass("drop-target-active");
                }
                if (e.preventDefault) e.preventDefault();
                if (e.stopPropagation) e.stopPropagation();
            });

        // for whatever reason: ctrl.on("drop", does not attach
        this._root[0].addEventListener("drop", (event) => this.onDrop(event), false);

        editorRow.append($("<td>").append(selectFile));

        // add something in the share menu -> if it does not exist yet

        var sm = $(".sharemenu");

        if (this.settings.parameter.titleBarControl) {
            sm = this.settings.parameter.titleBarControl.find(".sharemenu");

            if (sm) {
                if ($(".uploadfile", sm).length === 0) {
                    var uploadFile = $(
                        '<li title="Upload file to server" class="uploadfile" ><a href="javascript:void(0)">Attach file(s)</a></li>',
                    )
                        .data("itemId", this.settings.id)
                        .click(function () {
                            that._root.show();

                            $("input[type=file]", selectFile).trigger("click");
                        });
                    sm.append(uploadFile);
                }
            }
            // add possibility to hide the control

            if (this.settings.parameter.hidden) {
                this._root.hide();
            } else if (this.data.length === 0 && this.settings.parameter.autohide) {
                this._root.hide();
            }
        }
    }
    async hasChangedAsync() {
        return JSON.stringify(this._root.data("original")) !== JSON.stringify(this._root.data("new"));
    }
    async getValueAsync() {
        let files = <IUploadedFileInfo[]>this._root.data("new");
        if (files) {
            for (let file of files) {
                if (file.fileName)
                    file.fileName = new HTMLCleaner(file.fileName, false).getClean(HTMLCleaner.CleanLevel.Basic);
            }
        }
        return JSON.stringify(files);
    }
    setValue(files: string) {
        this.data = JSON.parse(files);
        this._root.data("new", JSON.parse(files));
        this.showFiles();
        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }
    destroy() {
        if (this._root) {
            this._root.off();
            this._root[0].removeEventListener("drop", this.onDrop, false);
            this.data = [];
        }
    }

    resizeItem(): void {}
    addLinks(uploads: IUploadedFileInfo[]): void {
        let that = this;

        $.each(uploads, function (fi, u) {
            that.data.push(u);
        });
        this._root.data("new", this.data);
        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
        this.showFiles();
    }
    populateFromRichtext() {
        return this.settings.parameter.manualOnly ? false : true;
    }

    private showFiles(readonly?: boolean): void {
        let that = this;

        var ul = $("<ul style='margin-bottom:2px'>");
        this.fileInfo.html("").append(ul);
        if (this.data.length === 0 && !this.settings.parameter.hideNoFileInfo) {
            ul.append($("<li>").append("<span style='color: #aaa'>no files have been attached</span>"));
        }

        for (var idx = 0; idx < this.data.length; idx++) {
            if (readonly) {
                ul.append(
                    $("<li>").append(
                        "<a href='" +
                            globalMatrix.matrixRestUrl +
                            "/" +
                            matrixSession.getProject() +
                            "/file/" +
                            this.data[idx].fileId +
                            "' target='_blank'>" +
                            this.data[idx].fileName +
                            "</a>",
                    ),
                );
            } else {
                var del = $(
                    "<span style='padding-left:20px' title='delete file'><i class='fal fa-trash-alt'></i></span>",
                )
                    .data("fileIdx", idx)
                    .click(function (event: JQueryEventObject) {
                        that.deleteFile($(event.delegateTarget).data("fileIdx"));
                    })
                    .tooltip();
                if (this.data[idx].fileId) {
                    ul.append(
                        $("<li>")
                            .append(
                                "<a href='" +
                                    globalMatrix.matrixRestUrl +
                                    "/" +
                                    matrixSession.getProject() +
                                    "/file/" +
                                    this.data[idx].fileId +
                                    "' target='_blank'>" +
                                    this.data[idx].fileName +
                                    "</a>",
                            )
                            .append(del),
                    );
                } else {
                    ul.append(
                        $("<li>")
                            .append("<san>" + this.data[idx].fileName + "</span>")
                            .append(del),
                    );
                }
            }
        }
    }

    private deleteFile(didx: number): void {
        this.deleteFiles([didx]);
    }

    private deleteFiles(idxs: number[]): void {
        var newData: IUploadedFileInfo[] = [];
        $.each(this.data, function (didx, attachment) {
            if (idxs.indexOf(didx) === -1) {
                // keep
                newData.push(attachment);
            }
        });
        this.data = newData;
        this._root.data("new", this.data);
        // MATRIX-6551: we're triggering file uploading logic on "change" event.
        // in order to allow user to upload the same file once again after this file was deleted
        // we need to reset the input value. otherwise value stays the same -> change event is not triggered
        this.getInputEl().val("");
        this.showFiles();
        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }

    private getInputEl() {
        return this._root.find(":file");
    }

    // do upload files without user interaction
    private uploadFiles(files: FileList): void {
        let that = this;
        let unsafeFiles = [];
        for (let it = 0; it < files.length; it++) {
            const name = files.item(it).name;
            const cleaned = new HTMLCleaner(name, false).getClean(HTMLCleaner.CleanLevel.PurifyOnly);
            if (cleaned !== name) {
                unsafeFiles.push(cleaned);
            }
        }
        if (unsafeFiles.length > 0) {
            ml.UI.showError("Invalid file names selected", "These file names are not valid: " + unsafeFiles.join(", "));
            return;
        }

        // do the actual upload
        ml.File.UploadFilesAsync(files)
            .done(function (uploads) {
                $.each(uploads, function (idx, file) {
                    that.data.push({
                        fileName: file.fileName,
                        fileId: file.fileId,
                    });
                });
                that._root.data("new", that.data);
                that.showFiles();
                if (that.settings.valueChanged) {
                    that.settings.valueChanged.apply(null);
                }
            })
            .fail(function () {});
    }

    private badExtension(files: FileList) {
        let that = this;
        if (!that.settings.parameter.extensions || that.settings.parameter.extensions.length == 0) {
            return false;
        }
        let badExtension = false;
        $.each(files, function (fidx: number, file: File) {
            var fn = file.name.toLowerCase();
            var ftype = fn.split(".")[fn.split(".").length - 1];
            let allowed = false;
            $.each(that.settings.parameter.extensions, function (idx, ext) {
                if (ftype === ext.toLowerCase().replace(".", "")) {
                    allowed = true;
                }
            });
            if (!allowed) {
                badExtension = true;
            }
        });
        return badExtension;
    }
    // ask user whether to upload (if configure like that...)
    private uploadFilesUser(files: FileList): void {
        let that = this;

        if (files.length == 0) {
            // after cancel
            return;
        }
        // figure out if there are too many (e.g. drag and drop)
        if (this.settings.parameter.single && files.length > 1) {
            ml.UI.showError("Cannot upload files", "Only one file can be uploaded.");
            return;
        }

        // figure out if files have correct extensions
        if (this.badExtension(files)) {
            ml.UI.showError(
                "Cannot upload file",
                "Must be any of these tyes: " + this.settings.parameter.extensions.join(", "),
            );
            return;
        }

        if (this.settings.processExternally) {
            if (this.settings.parameter.single) {
                this.data = [];
            }
            if (!this.settings.processExternally(files)) {
                $.each(files, function (idx, file) {
                    that.data.push({
                        fileName: file.name,
                        fileId: "-1",
                    });
                });
                that._root.data("new", that.data);
                that.showFiles();

                return;
            }
        }

        if (this.settings.parameter.single) {
            this.data = [];
            this.uploadFiles(files);
            return;
        }

        // determine replacement logic
        var replaceName = false;
        var replaceType = false;
        var replaceAsk = true;
        switch (this.settings.parameter.replace) {
            case "name":
                replaceName = true;
                break;
            case "name_auto":
                replaceName = true;
                replaceAsk = false;
                break;
            case "type":
                replaceType = true;
                break;
            case "type_auto":
                replaceType = true;
                replaceAsk = false;
                break;
            default:
                break;
        }

        // figure out if / what should be replaced
        var duplicates: number[] = [];
        $.each(this.data, function (didx, attachment) {
            $.each(files, function (fidx: number, file: File) {
                var an = attachment.fileName.toLowerCase();
                var fn = file.name.toLowerCase();
                var atype = an.split(".")[an.split(".").length - 1];
                var ftype = fn.split(".")[fn.split(".").length - 1];
                if ((an === fn && replaceName) || (atype === ftype && replaceType)) {
                    duplicates.push(didx);
                }
            });
        });

        // get user feedback (if needed) and replace the files
        if (duplicates.length > 0 && replaceAsk) {
            var ask =
                "Some files would be replaced because the " + (replaceType ? "extension" : "name") + " exists already";
            ml.UI.showConfirm(
                1,
                { title: ask, ok: "Replace" },
                function () {
                    that.deleteFiles(duplicates);
                    that.uploadFiles(files);
                },
                function () {
                    // cancel -> do nothing
                },
            );
        } else if (duplicates.length > 0) {
            this.deleteFiles(duplicates);
            this.uploadFiles(files);
        } else {
            this.uploadFiles(files);
        }
    }

    // on drop private
    private onDrop(e: DragEvent) {
        if (e.preventDefault) e.preventDefault();
        if (e.stopImmediatePropagation) e.stopImmediatePropagation();

        this.dragCounter = 0;
        this._root.removeClass("drop-target-active");

        this.uploadFilesUser(e.dataTransfer.files);
    }
}
