import { app, ControlState, globalMatrix, IItemGet, matrixSession } from "../../../globals";
import { IDropdownOption } from "../../../ProjectSettings";
import { FieldHandlerFactory, MR1 } from "../../businesslogic/index";
import { mDHF } from "../../businesslogic/index";
import { ml } from "../../matrixlib";
import { IBaseControlOptions } from "./BaseControl";
import { ISignaturesInfo } from "./docBase.";
import { DocSignImpl } from "./docSign";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export type {
    IDocMarkAsTemplateOptions,
    IPasteSourceSetting,
    IPasteSource,
    ITempSignatures,
    ITempSignature,
    ITemplateProjects,
};
export { MarkAsTemplateImpl };

interface IDocMarkAsTemplateOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: {
        inlineHelp?: string;
    };
}
interface IPasteSourceSetting {
    templates: IPasteSource[];
}
interface IPasteSource {
    fromProject: string; // project where template comes from
    fromSign: string; // template (a SIGN or a DOC)
    fromName: string; // the name at time of publishing
    fromDOC: string; // if SIGN, the DOC which was made a source
    canUseIn: string[]; // list of projects where template can be instantiated
}

interface ITempSignatures {
    signatures: ITempSignature[];
}
interface ITempSignature {
    user: string; // user to sign
    datetime: string; // date when it was signed
}

$.fn.markAsTemplate = function (this: JQuery, options: IDocMarkAsTemplateOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_markAsTemplate,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new MarkAsTemplateImpl(this, options.fieldHandler as GenericFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

interface ITemplateProjects {
    projects: string[];
    targets: string[];
}

class MarkAsTemplateImpl extends DocSignImpl {
    public static PROJECT_SETTING: string = "templates";
    public static PROJECT_SETTING_Projects: string = "templateProjects";

    private originalValue: ITempSignatures;
    private newValue: ITempSignatures;
    private pub: JQuery;
    private publishTo: string[] = [];
    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    private formatUserLogin(userLogin: string) {
        return globalMatrix.ItemConfig.hasUserInfo(userLogin) ? userLogin : `<s>${userLogin}</s>`;
    }

    init(options: IDocMarkAsTemplateOptions) {
        let that = this;

        var defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                // item the item containing the rest of the information
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        // changes shall not overwrite other people's changes
        this.needsLatest = true;

        // get current value
        this.originalValue = <ITempSignatures>(this.settings.fieldValue
            ? JSON.parse(this.settings.fieldValue)
            : {
                  signatures: [],
              });
        this.newValue = ml.JSON.clone(this.originalValue);

        if (options.controlState == ControlState.Tooltip) {
            return;
        }
        let docTypes = mDHF.getDocumentFormTypes(); // ["DOC"]
        let templateTypes = mDHF.getDocumentTemplatesTypes(); // ["SIGN"] could be ["SIGN","DOC"]

        if (docTypes.indexOf(options.type) != -1) {
            // in print version do not show anything
            if (options.controlState == ControlState.Print) {
                return;
            }
            // this is a DOC, user needs to decide who needs to sign the template to release it
            this.showSignatureTableEdit();
            return;
        }

        if (templateTypes.indexOf(options.type) == -1) {
            // neither a DOC not a SIGN...
            this._root.html("config error: This category cannot be used as template - contact support.");
            return;
        }

        // so this is a SIGN and it's a DOC which still exists
        let signs = this.getTemplateSignatureStatus();
        if (signs.signatures.length == 0) {
            // in print version do not show anything
            if (options.controlState == ControlState.Print) {
                this.hideSignatureCtrl();
            }
            // this is a normal document as SIGN .. ignore all template  stuff
            return;
        }

        // this is a SIGN or something which has been created from a DOC
        let uplinks = options.item.upLinkList
            ? options.item.upLinkList.filter(function (uplink) {
                  return docTypes.indexOf(ml.Item.parseRef(uplink.itemRef).type) != -1;
              })
            : [];
        if (uplinks && uplinks.length) {
            // this is actually a template, so the template needs approvals

            // hide signature box
            this.hideSignatureCtrl();

            // print approval box / template publishing feature
            if (signs.missingSignatures) {
                if (this.settings.help.indexOf("|") != -1 && this.settings.help.lastIndexOf("|") != -1)
                    this.settings.help = this.getHelpPart(1)
                        ? this.getHelpPart(1)
                        : this.settings.help + " - (required approvals)";
                if (
                    this.settings.parameter &&
                    this.settings.parameter.inlineHelp &&
                    this.settings.parameter.inlineHelp.indexOf("|") != -1
                ) {
                    this.settings.parameter.inlineHelp = this.settings.parameter.inlineHelp.split("|")[0];
                }
                let help = this._root.append(super.createHelp(this.settings));
                this.pub = $("<div class='baseControl'>").appendTo(this._root);
                this.askForSignatures(signs);
            } else {
                this.settings.help = this.getHelpPart(2)
                    ? this.getHelpPart(2)
                    : this.settings.help + " - (distribution to projects)";
                let help = this._root.append(super.createHelp(this.settings));
                if (
                    this.settings.parameter &&
                    this.settings.parameter.inlineHelp &&
                    this.settings.parameter.inlineHelp.indexOf("|") != -1
                ) {
                    this.settings.parameter.inlineHelp = this.settings.parameter.inlineHelp.split("|")[0];
                }
                this.pub = $("<div class='baseControl'>").appendTo(this._root);
                let vl = ml.Item.parseRef(uplinks[0].itemRef).id;
                if (signs.signatures.length > 0) {
                    let approvedBy = $("<div class='inlineHelp approvedBy'>").appendTo(this.pub);
                    let text = "Approved by ";
                    $.each(signs.signatures, function (sidx, s) {
                        text +=
                            that.formatUserLogin(s.userid) +
                            " (" +
                            ml.UI.DateTime.renderHumanDate(new Date(s.signDate)) +
                            " )" +
                            (sidx < signs.signatures.length - 1 ? ", " : "");
                    });

                    approvedBy.html(text);
                }
                if (options.controlState == ControlState.Print) {
                    return;
                }
                this.showProjects(vl, options.id);
            }
        } else {
            let help = this._root.append(super.createHelp(this.settings));
            this.pub = $("<div class='baseControl'>").appendTo(this._root);
            this.pub.html("Cannot be used as template: underlying " + docTypes.join(",") + " has been deleted.");
        }
    }

    private getHelpPart(part: number) {
        let parts = this.settings.help.split("|");
        return parts.length == 3 ? parts[part] : null;
    }
    // public interface
    async hasChangedAsync() {
        return JSON.stringify(this.originalValue) != JSON.stringify(this.newValue);
    }
    async getValueAsync() {
        return JSON.stringify(this.newValue);
    }
    setValue() {
        return "";
    }

    destroy() {}
    resizeItem() {
        let width = $("input.signatureInfo", this._root).width();
        $("table td:first-child", this._root).width(width);
    }

    static getRequiredApprovals(value: string) {
        let required: string[] = [];

        if (!value) return required;
        let jval = <ITempSignatures>JSON.parse(value);
        if (!jval.signatures) return required;

        for (let idx = 0; idx < jval.signatures.length; idx++) {
            if (!jval.signatures[idx].datetime) {
                required.push(jval.signatures[idx].user);
            }
        }

        return required;
    }

    static getTemplateSignatureStatus(value: string): ISignaturesInfo {
        let signatureStatus: ISignaturesInfo = {
            signatures: [],
            missing: [],
            given: [],
            signatureDate: {},
            missingSignatures: 0,
            givenSignatures: 0,
            needSignature: false,
            hasSignature: false,
            isTemplate: true,
        };
        if (!value) return signatureStatus;
        let jval = <ITempSignatures>JSON.parse(value);
        if (!jval.signatures) return signatureStatus;
        // matrixspecs

        $.each(jval.signatures, function (key, val) {
            if (val.user) {
                // workaround handle existing docs with BUG MATRIX-2442 correctly
                signatureStatus.signatures.push({
                    orgid: val.user,
                    userid: val.user,
                    signDateCustomer: ml.UI.DateTime.renderHumanDate(new Date(val.datetime)),
                    signDate: val.datetime,
                    signaturefileid: "",
                });
                if (val.datetime) {
                    signatureStatus.given.push(val.user);
                    signatureStatus.givenSignatures++;
                    signatureStatus.hasSignature = signatureStatus.hasSignature || val.user == matrixSession.getUser();
                } else {
                    signatureStatus.missing.push(val.user);
                    signatureStatus.missingSignatures++;
                    signatureStatus.needSignature =
                        signatureStatus.needSignature || val.user == matrixSession.getUser();
                }
                signatureStatus.signatureDate[val.user] = ml.UI.DateTime.renderHumanDate(new Date(val.datetime));
            }
        });
        return signatureStatus;
    }

    static removeFromTemplates(deletedItems: string[]) {
        let cached = <IPasteSourceSetting>globalMatrix.ItemConfig.getSettingJSON(MarkAsTemplateImpl.PROJECT_SETTING);

        // check if any item is used as template if not // nothing to do
        if (
            !cached ||
            !cached.templates ||
            cached.templates.filter(function (temp) {
                return deletedItems.indexOf(temp.fromSign) != -1;
            }).length == 0
        ) {
            return;
        }
        // get most recent setting
        app.readSettingJSONAsync(MarkAsTemplateImpl.PROJECT_SETTING).done(function (templates: IPasteSourceSetting) {
            templates.templates = templates.templates.filter(function (temp) {
                return deletedItems.indexOf(temp.fromSign) == -1;
            });
            app.setSettingJSON(MarkAsTemplateImpl.PROJECT_SETTING, templates);
        });
    }

    private hideSignatureCtrl() {
        $(".ft_signatureControl").hide();
        window.setTimeout(function () {
            // just to be sure...
            $(".ft_signatureControl").hide();
        }, 100);
    }
    private showProjects(sourceDoc: string, sourceSign: string) {
        let that = this;

        // get current project setting
        app.readSettingJSONAsync(MarkAsTemplateImpl.PROJECT_SETTING).done(function (result) {
            let templates = <IPasteSourceSetting>result;
            if (!templates || !templates.templates) {
                templates = { templates: [] };
            }

            // print table
            that.publishTo = [];
            that.showCurrentUsages(templates.templates, sourceDoc, sourceSign);
            let pb = $(
                "<button id='pubTemp' class='btn btn-success btn-sm'>Publish as template for selected projects</button>",
            ).appendTo(that.pub);
            pb.click(function () {
                // make sure to have latest and greatest
                app.readSettingJSONAsync(MarkAsTemplateImpl.PROJECT_SETTING).done(function (result) {
                    templates = <IPasteSourceSetting>result;
                    if (!templates || !templates.templates) {
                        templates = { templates: [] };
                    }
                    // update
                    $.each(templates.templates, function (idx, ps) {
                        if (ps.fromProject == matrixSession.getProject() && ps.fromSign != sourceSign) {
                            if (ps.fromDOC == sourceDoc) {
                                // for other items based on the same docs, remove everything which is being published somewhere else
                                ps.canUseIn = ps.canUseIn.filter(function (p) {
                                    let doPublish = that.publishTo.indexOf(p) != -1;
                                    return !doPublish;
                                });
                            }
                        }
                    });
                    // remove templates which are not visible in any projects
                    templates.templates = templates.templates.filter(function (temp) {
                        return temp.canUseIn.length > 0;
                    });
                    // remove publishing info for this sign
                    templates.templates = templates.templates.filter(function (temp) {
                        return temp.fromProject != matrixSession.getProject() || temp.fromSign != sourceSign;
                    });
                    // add publishing info for this sign
                    templates.templates.push({
                        fromProject: matrixSession.getProject(),
                        fromDOC: sourceDoc,
                        fromSign: sourceSign,
                        fromName: app.getItemTitle(sourceSign),
                        canUseIn: ml.JSON.clone(that.publishTo),
                    });

                    app.setSettingJSON(MarkAsTemplateImpl.PROJECT_SETTING, templates).done(function () {
                        that.pub.html("");
                        that.showProjects(sourceDoc, sourceSign);
                    });
                });
            });
        });
    }

    private showCurrentUsages(current: IPasteSource[], sourceDoc: string, sourceSign: string) {
        let that = this;

        // figure out what is currently used as template
        let others: string[] = [];
        $.each(current, function (idx, ps) {
            if (ps.fromProject == matrixSession.getProject()) {
                if (ps.fromSign == sourceSign) {
                    // this is currently published
                    that.publishTo = ps.canUseIn;
                } else if (ps.fromDOC == sourceDoc) {
                    // another version of DOC is used as template in some projects
                    others = others.concat(ps.canUseIn);
                }
            }
        });

        let projects = matrixSession.getProjectList(false).filter(function (p) {
            return p.shortLabel != "EMPTY" && p.shortLabel != matrixSession.getProject();
        });

        let templateProjects = <ITemplateProjects>(
            matrixSession.getCustomerSettingJSON(MarkAsTemplateImpl.PROJECT_SETTING_Projects)
        );
        if (templateProjects && templateProjects.targets && templateProjects.targets.length) {
            projects = projects.filter(function (p) {
                return templateProjects.targets.indexOf(p.shortLabel) != -1;
            });
        }

        let hasUsedOther = false; // whether some have other published versions
        $.each(projects, function (idx, project) {
            var short = project.shortLabel;
            hasUsedOther = hasUsedOther || others.indexOf(project.shortLabel) != -1;

            var cb = $("<div>")
                .appendTo(that.pub)
                .checkBox({
                    canEdit: true,
                    help:
                        (others.indexOf(project.shortLabel) != -1 ? "* " : "") +
                        project.shortLabel +
                        " - " +
                        project.label,
                    valueChanged: async function () {
                        if (await cb.getController().getValueAsync()) {
                            that.publishTo.push(short);
                        } else {
                            that.publishTo = that.publishTo.filter(function (p) {
                                return p != short;
                            });
                        }
                        $("#pubTemp").addClass("btn-success");
                    },
                    parameter: {},
                    fieldValue: that.publishTo.indexOf(project.shortLabel) == -1 ? "0" : "1",
                });
        });

        if (hasUsedOther) {
            $("<div>").appendTo(that.pub).html("* Some project(s) use another version of template");
        }
    }

    // show a table in DOC, where author can decide who needs to sign to release the document
    private showSignatureTableEdit() {
        let that = this;

        var userDropdown: IDropdownOption[] = [];
        var userList = globalMatrix.ItemConfig.getUserNames();
        for (var udx = 0; udx < userList.length; udx++) {
            userDropdown.push({ id: userList[udx].login.toLowerCase(), label: userList[udx].login.toLowerCase() });
        }
        let dd = $("<div>").appendTo(this._root);

        let cb = that.settings.valueChanged;

        let userSelectionChanged = async function () {
            let val = await dd.getController().getValueAsync();
            if (val) {
                that.newValue = {
                    // MATRIX-4764 Don't save deleted user.
                    signatures: MarkAsTemplateImpl.removeDeletedUsers(val.split(",")).map(function (usr: string) {
                        return { user: usr, datetime: "" };
                    }),
                };
            } else {
                // fix MATRIX-2442
                that.newValue = {
                    signatures: [],
                };
            }
            if (cb) {
                cb.apply(null);
            }
        };
        let params = {
            fieldValue: that.originalValue.signatures
                .map(function (us) {
                    return us.user;
                })
                .join(","),
            canEdit: true,
            help: that.getHelpPart(0) ? that.getHelpPart(0) : that.settings.help + " - (required approvals)",
            parameter: {
                placeholder: "select user who need to sign template",
                create: false,
                options: userDropdown,
                maxItems: 100,
                sort: true,
                inlineHelp:
                    that.settings.parameter && that.settings.parameter.inlineHelp
                        ? that.settings.parameter.inlineHelp
                        : "",
            },
            valueChanged: userSelectionChanged,
        };

        dd.mxDropdown(params);

        //Fix for MATRIX-4764 Problem with Template approver and deleted users
        let missingUser = that.originalValue.signatures.filter(
            (sig) =>
                globalMatrix.ItemConfig.getUserNames().findIndex((user) => user.login.toLowerCase() == sig.user) == -1,
        );
        if (missingUser.length > 0) {
            let cleanupUserList = (a) => {
                ml.UI.showAck(
                    -1,
                    "These users has been removed sinced they are no longer active : <ul style='margin-left:80px'>" +
                        missingUser.map((o) => "<li style='text-align:left;'>" + o.user + "</li>").join("") +
                        "</ul> Please save to correct.",
                    "User list has been cleaned in '" + params.help + "'",
                );
                userSelectionChanged();
                MR1.onItemDisplayed().unsubscribe(cleanupUserList);
            };
            MR1.onItemDisplayed().subscribe(this, cleanupUserList);
        }
    }
    static removeDeletedUsers(userList: string[]): string[] {
        return userList.filter(
            (u) =>
                globalMatrix.ItemConfig.getUserNames().findIndex(
                    (un) => un.login.toLowerCase() == (<String>u).toLowerCase(),
                ) != -1,
        );
    }

    private getTemplateSignatureStatus(): ISignaturesInfo {
        return MarkAsTemplateImpl.getTemplateSignatureStatus(JSON.stringify(this.originalValue));
    }

    // ask for signatures
    private askForSignatures(signatureStatus: ISignaturesInfo) {
        let that = this;

        let uiCtrl = $("<div>").appendTo(this.pub);
        // render a signature table with current status if there is 1+ signature in document
        this.renderSignatureTable(
            signatureStatus,
            uiCtrl,
            "not yet approved",
            "you need to approve this template",
            "approved at",
        );
        if (this.settings.controlState == ControlState.Print || this.settings.controlState == ControlState.Tooltip) {
            return;
        }
        // show signature box unless user needs to sign or this is a tooltip, print or other special view
        this.showSignatureField(
            signatureStatus,
            uiCtrl,
            $(uiCtrl.find("td")[0]).width(),
            "Approve",
            function (pwd: string) {
                that.signTemplate(pwd);
            },
            true,
        );
    }

    protected signTemplate(pwd: string) {
        let that = this;

        app.checkPassword(pwd)
            .done(function () {
                // pwd is correct

                // get item with most current approvals
                app.getItemAsync(that.settings.id)
                    .done(function (item: IItemGet) {
                        let current = (<any>item)[that.settings.fieldId];

                        that.originalValue = <ITempSignatures>(current
                            ? JSON.parse(current)
                            : {
                                  signatures: [],
                              });
                        that.newValue = <ITempSignatures>ml.JSON.clone(that.originalValue);
                        $.each(that.newValue.signatures, function (idx, sig) {
                            if (sig.user == matrixSession.getUser()) {
                                sig.datetime = new Date().toJSON();
                            }
                        });

                        app.saveAsync(false);
                    })
                    .fail(function (error) {
                        ml.UI.showError("cannot approve template", error);
                    });
            })
            .fail(function (error) {
                ml.UI.showError("invalid password", "");
            });
    }
}
