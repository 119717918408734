import { DropdownFieldHandler, IBaseDropdownFieldParams } from "../DropdownFieldHandler";
import { IDocFieldHandler } from "./IDocFieldHandler";
import { IAnyMap } from "../../../../globals";
import { ItemConfiguration } from "../../ItemConfiguration";

export abstract class DropdownAbstractDocFieldHandler extends DropdownFieldHandler implements IDocFieldHandler {
    private sectionType: string;
    dhfFieldConfig: IAnyMap;

    constructor(
        protected itemConfig: ItemConfiguration,
        type: string,
        config: IBaseDropdownFieldParams,
        initialValue?: string,
    ) {
        super(config, initialValue);
        if (!config) config = this.getDefaultConfig();
        this.dhfFieldConfig = config;
        this.sectionType = type;
    }
    getFieldType(): string {
        return this.sectionType;
    }
    setDHFConfig(config: IAnyMap) {
        this.dhfFieldConfig = config;
    }

    addSignatures(signatures: string[], includeAll: boolean) {
        //Nothing to do here
    }
    abstract getDefaultConfig(): any;

    abstract getXmlValue(): string;

    protected name = "";
    getFieldName(): string {
        return this.name;
    }

    setFieldName(value: string): void {
        this.name = value;
    }
}
