import { app, ControlState, globalMatrix, IReference, IReferenceChange, matrixSession } from "../../../globals";
import { HTMLCleaner } from "../../matrixlib/index";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import {
    IRiskConfig,
    IRiskConfigFactorWeightValue,
    IRiskConfigFactor,
    IRiskConfigFactorWeight,
    IRiskConfigReduction,
    IRiskConfigSelectChanges,
} from "../../../ProjectSettings";
import { IRiskTableParams } from "../../../client/plugins/RiskControlFolder";
import { UIToolsConstants } from "../../matrixlib/MatrixLibInterfaces";
import { ColumnEditor, FieldHandlerFactory, ITableControlOptionsColumn } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export type {
    IRiskControlOptions,
    IRiskParameter,
    IRiskValue,
    IRiskValueFactor,
    IRiskValueFactorWeight,
    IRiskValueMitigation,
    IRiskValueMitigationChange,
    IRiskValueMap,
    IRiskRender,
};
export { RiskCalculator, RiskControlImpl };

interface IRiskControlOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: IRiskParameter;
    links?: IReference[];
    hideReadonlyColumns?: boolean; // for rendering risks in tables
}

interface IRiskParameter {
    riskConfig?: IRiskConfig;
    showAttributeNames?: boolean; // if set to true shows attributes of the attributes for selection printing
    forceAfterWeightsInPrint?: boolean; // if true shows weights after controls in RBM
    hide_UI?: boolean; // if set to true don't render
}

interface IRiskValue {
    factors: IRiskValueFactor[];
    mitigations: IRiskValueMitigation[];
    postWeights?: IRiskValueFactorWeight[];
}
interface IRiskValueFactor {
    label: string;
    type: string;
    value: string;
    inputType?: string;
    weights: IRiskValueFactorWeight[];
}
interface IRiskValueFactorWeight {
    description: string;
    label: string;
    type: string;
    value: number;
}
interface IRiskValueMitigation {
    to: string;
    title: string;
    changes: IRiskValueMitigationChange[];
}
interface IRiskValueMitigationChange {
    by: number;
    changes: string;
    description: string;
    name: string;
}
interface IRiskValueMap {
    [key: string]: number;
}

interface IRiskRender {
    text: string;
    foregroundColor: string;
    backgroundColor: string;
    css: string;
}

$.fn.riskCtrl2 = function (this: JQuery, options: IRiskControlOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_risk2,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }

    let baseControl = new RiskControlImpl(this, options.fieldHandler as GenericFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class RiskCalculator {
    private riskValue: IRiskValue;
    private config: IRiskConfig;

    constructor(config: IRiskConfig) {
        this.config = config;
    }

    /* read the value from the json string */
    parse(fieldValue: string) {
        if (typeof fieldValue === "undefined" || fieldValue === "") {
            const factors = this.config.factors.map((factor) => {
                let defaultValue = "";
                let weightsSetByChangeConfig = {};
                if (factor.options && factor.options.length > 0) {
                    const optionDefault = factor.options[0];
                    defaultValue = optionDefault.value;
                    optionDefault.changes.forEach((change) => {
                        if (change.changesWeight) {
                            weightsSetByChangeConfig[change.changesWeight] = change.value;
                        }
                    });
                }
                const weightValues = factor.weights.map((weight) => {
                    let label = "";
                    let value = 1;
                    if (weight.values.length > 0) {
                        label = RiskCalculator.labelDisplay(weight.values[0]);
                        value = weight.values[0].factor;
                        if (weightsSetByChangeConfig[weight.type]) {
                            const activeValue = weight.values.filter(
                                (value) => value.factor == weightsSetByChangeConfig[weight.type],
                            );
                            if (activeValue.length > 0) {
                                const matching = activeValue[0];
                                label = RiskCalculator.labelDisplay(matching);
                                value = matching.factor;
                            }
                        }
                    }
                    return {
                        type: weight.type,
                        label: weight.label,
                        description: label,
                        value: value,
                    };
                });
                return {
                    label: factor.label,
                    type: factor.type,
                    value: defaultValue,
                    inputType: factor.inputType,
                    weights: weightValues,
                };
            });
            this.riskValue = { factors: factors, mitigations: [] };
        } else {
            this.riskValue = JSON.parse(fieldValue);
            if (!this.riskValue.mitigations) {
                this.riskValue.mitigations = [];
            }
            if (!this.riskValue.factors) {
                this.riskValue.factors = [];
            }
        }

        // replace weird  &dash, with a simple -
        // the dash is in use in versions before 1.6
        if (this.riskValue.factors) {
            $.each(this.riskValue.factors, function (factorIdx, factor) {
                if (factor.weights) {
                    $.each(factor.weights, function (weightIdx, weight) {
                        weight.description = weight.description ? weight.description.replace(/[\u2010]/g, "-") : "";
                    });
                }
            });
        }
    }

    /* remove missing downlinks */
    updateMitigations(possibleRefs: IReference[]) {
        let possibleLinks = possibleRefs ? possibleRefs.map((link) => link.to) : [];
        let mitigationsBefore = this.riskValue.mitigations.length;
        this.riskValue.mitigations = this.riskValue.mitigations.filter(
            (mitigation) => possibleLinks.indexOf(mitigation.to) != -1,
        );
        return mitigationsBefore != this.riskValue.mitigations.length;
    }

    /* remove missing downlinks */
    updateMitigationTitles(possibleLinks: IReference[]) {
        if (!possibleLinks) {
            possibleLinks = [];
        }
        // mitigations are just normal downlinks in the project. the json definition of the risk saves additional meta info
        // for each downlink (like what is mitigated how much). In case the downlink does not exist anymore the mitigation is removed.
        // In case the title of the mitigated item was updated the new title is used
        for (let idx = this.riskValue.mitigations.length - 1; idx >= 0; idx--) {
            // verify if the reference item still exists (here we rely on the item refs from the server)
            // if the reference still exists, make sure the title is the current title, if not delete the mitigation
            let mitTitle = "";
            for (var refs = 0; refs < possibleLinks.length; refs++) {
                if (possibleLinks[refs].to === this.riskValue.mitigations[idx].to) {
                    mitTitle = possibleLinks[refs].title;
                }
            }
            if (mitTitle) {
                this.riskValue.mitigations[idx].title = mitTitle;

                if (this.riskValue.mitigations[idx].changes) {
                    $.each(this.riskValue.mitigations[idx].changes, function (changeIdx, change) {
                        if (!change["changes"]) {
                            change["changes"] = "";
                        }
                    });
                }
            } else {
                // does not exist remove it. someone deleted the item
                this.riskValue.mitigations.splice(idx, 1);
            }
        }
    }

    init(riskValue: IRiskValue) {
        this.riskValue = ml.JSON.clone({ ...{}, ...riskValue }); // copy/clone the value
    }

    /** get the value */
    getValue() {
        return this.riskValue; // copy/clone the value
    }

    // get one attribute (factor, weight, ram, rbm, ... ) as html blob
    getAttributeHTML(attributeIn: string): string {
        let attributeLower = attributeIn.toLowerCase();
        // all after first dot
        let attr = attributeIn.substr(attributeIn.indexOf(".") + 1);
        let attrLower = attr.toLowerCase();

        if (attributeLower.indexOf("before.") == 0) {
            let rbm = this.getRBM();
            let rbmLower = {};
            for (let p in rbm) rbmLower[p.toLowerCase()] = rbm[p];
            if (rbm[attr] != undefined) {
                return `<span class="${attributeLower}">${rbm[attr]}</span>`;
            } else if (rbmLower[attrLower] != undefined) {
                return `<span class="${attributeLower}">${rbmLower[attrLower]}</span>`;
            } else {
                return "unset attribute '" + attributeLower + "'";
            }
        } else if (attributeLower.indexOf("after.") == 0) {
            let rbm = this.getRBM();
            let ram = this.getRAM(rbm);
            let ramLower = {};
            for (let p in ram) ramLower[p.toLowerCase()] = ram[p];
            if (ram[attr] != undefined) {
                return `<span class="${attributeLower}">${ram[attr]}</span>`;
            } else if (ramLower[attrLower] != undefined) {
                return `<span class="${attributeLower}">${ram[attrLower]}</span>`;
            } else {
                return "unset attribute '" + attributeLower + "'";
            }
        } else if (attributeLower == "totalrbm") {
            return this.getRiskSumSpan(this.getRBM());
        } else if (attributeLower == "totalram") {
            let rbm = this.getRBM();
            return this.getRiskSumSpan(this.getRAM(rbm));
        } else if (attributeLower == "colorbeforeforeground") {
            return this.getColor(this.getRBM(), true);
        } else if (attributeLower == "colorbeforebackground") {
            return this.getColor(this.getRBM(), false);
        } else if (attributeLower == "colorafterforeground") {
            let rbm = this.getRBM();
            return this.getColor(this.getRAM(rbm), true);
        } else if (attributeLower == "colorafterbackground") {
            let rbm = this.getRBM();
            return this.getColor(this.getRAM(rbm), false);
        } else if (attributeLower == "riskcontrols") {
            let riskControls = "<div>";
            for (let mitigation of this.riskValue.mitigations) {
                let riskControl = "<div class='riskControl'>";
                // add the hyper link
                riskControl += `<span class='riskControlLink'>_LINK_${mitigation.to}_LINK_<span> `;

                // add the description (there's no description for post reduction kind of risk controls afaik)
                if (!this.config.postReduction && mitigation.changes) {
                    for (let reduction of this.config.reductions) {
                        for (let change of mitigation.changes) {
                            if (change.name == reduction.name) {
                                let chg = `<span class="riskControlChange">${change.description}!</span> `;
                                riskControl += chg;
                            }
                        }
                    }
                }
                // if it is a mitigation table there might be some more fields
                if (this.config.mitigationTable) {
                    // but we don't actually print those...
                }
                riskControl += "</div>";
                riskControls += riskControl;
            }

            riskControls += "</div>";
            return riskControls;
        } else {
            // a factor
            let factors = this.riskValue.factors.filter((factor) => factor.type.toLowerCase() == attributeLower);
            if (factors.length) {
                let factorsConfig = this.config.factors.filter((factor) => factor.type == factors[0].type);
                let text = factors[0].value;
                let style = "";
                if (factorsConfig.length) {
                    if (factorsConfig[0].inputType == "textarea") {
                        style = "white-space:pre-wrap";
                    } else if (factorsConfig[0].inputType == "select") {
                        let opts = factorsConfig[0].options.filter((option) => option.value == text);
                        if (opts.length) {
                            text = opts[0].label;
                        }
                    }
                }
                return `<span class="${factors[0].type}" style="${style}">${text ? text : ""}</span>`;
            }
            return "unknown factor '" + attributeLower + "'";
        }
    }

    /* get a weight before risk controls */
    getWeight(factorType: string, weightType: string) {
        let factors = this.riskValue.factors.filter((factor) => factor.type == factorType);
        if (!factors.length) return 0;
        let weights = factors[0].weights.filter((weight) => weight.type == weightType);
        if (!weights.length) return 0;
        return weights[0].value;
    }
    /* get a weights before risk controls */
    getRBM() {
        let that = this;

        let rbm: IRiskValueMap = {};
        $.each(this.config.factors, function (factorIdx, factor) {
            $.each(factor.weights, function (weightIdx, weight) {
                rbm[weight.type] = that.getWeight(factor.type, weight.type);
            });
        });
        return rbm;
    }
    /* get weights after risk controls by substracting something for each risk control*/
    getRAMByMath(rbm: IRiskValueMap): IRiskValueMap {
        let that = this;

        let ram = ml.JSON.clone(rbm);

        $.each(that.riskValue.mitigations, function (mitigationIdx, mitigation) {
            if (that.config.mitigationTable) {
                $.each(mitigation.changes, function (ccidx, cc) {
                    ram[cc.changes] += cc.by;
                });
            } else {
                $.each(mitigation.changes, function (changeIdx, change) {
                    // add it up
                    ram[change.changes] += change.by;
                });
            }
            $.each(that.config.factors, function (factorIdx, factor) {
                $.each(factor.weights, function (weightIdx, weight) {
                    // make sure ram is not bigger or smaller than possible range
                    let minVal = 20000;
                    let maxVal = -20000;

                    $.each(weight.values, function (valIdx, val) {
                        minVal = Math.min(minVal, val.factor);
                        maxVal = Math.max(maxVal, val.factor);
                    });

                    ram[weight.type] = Math.max(ram[weight.type], minVal);
                    ram[weight.type] = Math.min(ram[weight.type], maxVal);
                });
            });
        });

        return ram;
    }

    /* get weights set by user */
    getRAMByUser(rbm: IRiskValueMap): IRiskValueMap {
        let that = this;

        let ram = ml.JSON.clone(rbm);

        if (that.riskValue.mitigations.length === 0 && this.config.mitigationTypes.length > 0) {
            // no mitigations, mean the risks before and after are the same
            return ram;
        }

        $.each(this.config.postReduction.weights, function (weightIdx, weight) {
            let setWeight = that.riskValue.postWeights.filter((postWeight) => postWeight.type == weight.type);
            if (setWeight.length) {
                ram[weight.type] = setWeight[0].value;
            }
        });

        return ram;
    }

    getRAM(rbm: IRiskValueMap): IRiskValueMap {
        if (this.config.postReduction) {
            return this.getRAMByUser(rbm);
        } else {
            return this.getRAMByMath(rbm);
        }
    }

    // returns rendering info for ram and rbm
    getRiskSumText(riskValues: IRiskValueMap) {
        let that = this;

        let values: number[] = [];
        $.each(that.config.factors, function (factorIdx, factor) {
            $.each(factor.weights, function (weightIdx, weight) {
                values.push(riskValues[weight.type]);
            });
        });

        if (that.config.method === "lookup") {
            let zone = "";
            let text = "unknown combination of values";
            let foreground = "red";
            let background = "transparent";

            // find same combination of input values
            $.each(that.config.rpns, function (idx, rpn) {
                let hit = true;
                $.each(that.config.factors, function (factorIdx, factor) {
                    $.each(factor.weights, function (weightIdx, weight) {
                        // compare number to string
                        if (riskValues[weight.type] != rpn[weight.type]) {
                            hit = false;
                        }
                    });
                });
                if (hit) {
                    zone = rpn.zone;
                    text = rpn.text;
                    return;
                }
            });
            // get color for display
            if (zone) {
                $.each(that.config.charts, function (idx, chart) {
                    if (chart.zone == zone) {
                        foreground = chart.foreground;
                        background = chart.background;
                    }
                });
            }
            return {
                text: text,
                foregroundColor: foreground,
                backgroundColor: background,
                css: "",
            };
        } else {
            let result = that.config.method === "*" ? 1 : 0;
            $.each(values, function (valIdx, val) {
                if (that.config.method === "*") {
                    result *= val;
                } else {
                    result += val;
                }
            });

            return {
                text: values.join(" " + that.config.method + " ") + " = " + Math.round(result * 100) / 100,
                foregroundColor: "",
                backgroundColor: "",
                css:
                    result <= that.config.maxGreen
                        ? "riskgreen"
                        : result <= that.config.maxYellow
                        ? "riskyellow"
                        : "riskred",
            };
        }
    }

    // returns a span with rendering info
    getRiskSumSpan(riskValues: IRiskValueMap) {
        let riskSum = this.getRiskSumText(riskValues);
        return `<span class="${riskSum.css ? riskSum.css : ""}"  style="color:${
            riskSum.foregroundColor ? riskSum.foregroundColor : ""
        };background-color:${riskSum.backgroundColor ? riskSum.backgroundColor : ""}">${riskSum.text}</span>`;
    }

    // returns the color as text (for styling)
    getColor(riskValues: IRiskValueMap, foreground: boolean) {
        let riskSum = this.getRiskSumText(riskValues);
        if (foreground) {
            if (riskSum.foregroundColor) {
                return riskSum.foregroundColor;
            } else if (riskSum.css == "riskgreen") {
                return "#FFF";
            } else if (riskSum.css == "riskyellow") {
                return "#000";
            } else if (riskSum.css == "riskred") {
                return "#FFF";
            }
            return "";
        } else {
            if (riskSum.backgroundColor) {
                return riskSum.backgroundColor;
            } else if (riskSum.css == "riskgreen") {
                return "#0A0";
            } else if (riskSum.css == "riskyellow") {
                return "#FF0";
            } else if (riskSum.css == "riskred") {
                return "#F00";
            }
            return "";
            return riskSum.backgroundColor ? riskSum.backgroundColor : "";
        }
    }

    static labelDisplay(weightValue: IRiskConfigFactorWeightValue): string {
        return weightValue.factor + (weightValue.shortname ? " - " + weightValue.shortname : "");
    }
}

class RiskControlImpl extends BaseControl<GenericFieldHandler> {
    private settings: IRiskControlOptions;
    private config: IRiskConfig;
    private risk: JQuery;
    private mitbody: JQuery;
    private isPrint: boolean;
    private riskCalculator: RiskCalculator;
    private mitigationsRemoved: boolean;

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IRiskControlOptions) {
        let that = this;

        if (!matrixSession.hasRisks()) {
            this._root.append("risks not licensed");
            return;
        }

        if (options.parameter && options.parameter["reviewMode"] === true) {
            options.controlState = ControlState.Print;
        }

        var defaultOptions: IRiskControlOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dummy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: function () {},
            parameter: {},
        };

        this.settings = <IRiskControlOptions>ml.JSON.mergeOptions(defaultOptions, options);

        if (this.settings && this.settings.parameter.hide_UI) {
            this._root.hide();
        }

        this.config =
            this.settings && this.settings.parameter && this.settings.parameter.riskConfig
                ? this.settings.parameter.riskConfig
                : globalMatrix.ItemConfig.getRiskConfig();
        if (!this.config) {
            this._root.append("<p style='color:red'>Risk configuration settings is missing</p>");
            return;
        }

        this.isPrint = this.settings.controlState == ControlState.Print;

        // *********************************************************************************
        //  prepare the data
        //
        //  get it from JSON parameter and clean it up if necessary,
        //  e.g. if some referred mitigations do not exist anymore
        //
        // **********************************************************************************

        this.riskCalculator = new RiskCalculator(this.config);

        this.riskCalculator.parse(this.settings.fieldValue);

        this.mitigationsRemoved = this.riskCalculator.updateMitigations(this.settings.links);

        this.riskCalculator.updateMitigationTitles(this.settings.links);

        // *********************************************************************************
        // save the original data
        // *********************************************************************************

        this._root.data("original", JSON.stringify(this.riskCalculator.getValue()));

        // *********************************************************************************
        // In case a downlink exists but without meta info, add it to the UI (will trigger save)
        // *********************************************************************************

        // Note: this can happen if someone adds a reference but does not save after)
        if (
            this.settings.isForm &&
            matrixSession.isEditor() &&
            this.settings.controlState != ControlState.HistoryView
        ) {
            for (var refs = 0; refs < this.settings.links.length; refs++) {
                if (this.canBeMitigation(this.settings.links[refs].to)) {
                    var mitExists = false;
                    for (var idx = 0; idx < this.riskCalculator.getValue().mitigations.length; idx++) {
                        if (this.settings.links[refs].to === this.riskCalculator.getValue().mitigations[idx].to) {
                            mitExists = true;
                        }
                    }
                    if (!mitExists) {
                        ml.Logger.log("info", "found downlink, which is not a mitigation, adding it as mitigation");
                        this.riskCalculator.getValue().mitigations.push({
                            to: this.settings.links[refs].to,
                            title: this.settings.links[refs].title,
                            changes: [],
                        });
                    }
                }
            }
        }

        // *********************************************************************************
        // render the UI
        // *********************************************************************************

        if (this.settings.controlState === ControlState.Tooltip || this.isPrint) {
            this.settings.canEdit = false;
        }
        let riskBody = this._root; // in case it is rendered in table form this is a <tr>
        if (!(<IRiskTableParams>this.settings.parameter).tableOptions) {
            // create the heading and a container for the risk
            this._root.append(super.createHelp(this.settings));
            $(".baseControlHelp", this._root).css("color", "red");

            riskBody = $(
                `<div class='container riskTable ${
                    this.settings.controlState === ControlState.Tooltip || this.settings.isHistory ? "riskCompact" : ""
                }'>`,
            );
            if (this.isPrint) {
                riskBody.addClass("printNoBox");
            }
            this._root.append(riskBody);
        }

        // *********************************************************************************
        // PRE MITIGATION
        // *********************************************************************************

        if ((<IRiskTableParams>this.settings.parameter).tableOptions) {
            // *********************************************************************************
            // render as table row
            // *********************************************************************************

            this.renderTableBodyRow(this.settings.id);
        } else {
            // *********************************************************************************
            // render as multi line control
            // *********************************************************************************
            let tableClass = this.isPrint ? "risk2" : "table riskTable";

            this.risk = $(`<table class="${tableClass}">`);
            this.risk.append("<tbody>");

            $.each(this.config.factors, function (factorIdx, factor) {
                // create something like Cause: [Enter Cause] input field as first two columns in table
                that.renderFactorWithWeightsLine(factor);
            });

            var rbmLine = $("<tr>").appendTo(this.risk);
            if (this.config.rbm && this.config.rbm.hidden) {
                rbmLine.hide();
            }
            rbmLine.append($("<td>").attr("colspan", 2));
            rbmLine.append(
                $('<td class="RBM riskReductionLabel">').html(
                    this.config.rbm && this.config.rbm.short ? this.config.rbm.short : "RBM",
                ),
            );
            rbmLine.append($('<td class="RBM riskReductionLabel totalRBM risksum riskSelect ">'));
            riskBody.append(this.risk);
        }
        // *********************************************************************************
        // hook up changes to inputs
        // *********************************************************************************

        $("input", this._root).change(function () {
            that.riskChange();
        });
        $("textarea", this._root).change(function () {
            that.riskChange();
        });
        $(".riskrichtext", this._root).change(function () {
            that.riskChange();
        });
        $("select", this._root).change(function () {
            that.riskChange();
        });

        // *********************************************************************************
        // set values pre mitigation
        // *********************************************************************************
        $.each(this.riskCalculator.getValue().factors, function (factorIdx, factorVal) {
            var factor = <IRiskValueFactor>factorVal;
            // figure out if the input should be rendered differently
            $.each(that.config.factors, function (idx, factorType) {
                if (factorType.type === factorVal.type && factorType.inputType) {
                    factor.inputType = factorType.inputType;
                }
            });
            // this line might update risk's printed values if configuration changes
            factor.label = that.getLabelFactor(factor.type);

            // fill the input
            if (factor.inputType && factor.inputType === "select") {
                $("select[name=" + factorVal.type + "]", that._root).val(factorVal.value);
            } else if (factor.inputType && factor.inputType === "textarea") {
                let ta = $("textarea[name=" + factorVal.type + "]", that._root);
                if (ta.length) {
                    // if not some old value from previous config might exist...
                    ta.val(ml.UI.lt.forUI(factorVal.value, that.settings.fieldId));
                    if (!that.isPrint) {
                        ta.height(1);
                        ta.height(Math.max(40, ta[0].scrollHeight));
                    }
                }
            } else if (factor.inputType && factor.inputType === "richtext") {
                let tr = $(".riskrichtext[name=" + factorVal.type + "]", that._root);
                if (tr.length) {
                    // if not some old value from previous config might exist...
                    that.setFactorRichValue(tr, factorVal.value);
                }
            } else {
                $("input[name=" + factorVal.type + "]", that._root).val(
                    ml.UI.lt.forUI(factorVal.value, that.settings.fieldId),
                );
            }

            $.each(factorVal.weights, function (weightIdx, weight) {
                // these two lines might update risk's printed values if configuration changes
                weight.label = that.getLabelWeight(weight.type);
                weight.description = weight.value + " - " + that.getLabelWeightFactor(weight.type, weight.value);

                that.setSelectValues($('select[name="' + weight.type + '"]:not(.riskAfter)', that._root), weight.value);
            });
        });

        // *********************************************************************************
        // MITIGATION AREA
        // *********************************************************************************

        if ((<IRiskTableParams>this.settings.parameter).tableOptions) {
            // has been added in table already
        } else {
            // create control container
            this.mitbody = $("<div>");
            this.risk.append($("<tr>").append($('<td colspan=4 style="border:none">').append(this.mitbody)));
            // add details
            this.renderMitigationSelect(false);
        }

        // *********************************************************************************
        // POST MITIGATION
        // *********************************************************************************

        if ((<IRiskTableParams>this.settings.parameter).tableOptions) {
            // *********************************************************************************
            // init post mitigation factors (if displayed as table)
            // *********************************************************************************

            if (!this.riskCalculator.getValue().postWeights) {
                this.riskCalculator.getValue().postWeights = [];
            }

            $.each(this.config.factors, function (factorIdx, factor) {
                $.each(factor.weights, function (weightIdx, weight) {
                    let select = $("select[name='" + weight.type + "'].riskAfter", that._root);

                    select.change(function () {
                        // store the new value
                        let exists = false;
                        let selectedOption = $(":selected", select);
                        let description = selectedOption.data("description");
                        let value = <number>selectedOption.data("value");
                        $.each(that.riskCalculator.getValue().postWeights, function (pwidx, pwi) {
                            if (pwi.type === weight.type) {
                                exists = true;
                                pwi.description = description;
                                pwi.value = value;
                            }
                        });
                        if (!exists) {
                            that.riskCalculator.getValue().postWeights.push({
                                type: weight.type,
                                value: value,
                                description: description,
                                label: weight.label,
                            });
                        }
                        // update the display
                        that.riskChange();
                    });

                    // default value for post mitigation is the before mitigation
                    let sameBefore = $('select[name="' + weight.type + '"].riskBefore', that._root);
                    let selectValue = <number>$(":selected", sameBefore).data("value");

                    // check if actual value has been saved before and overwrite the default
                    $.each(that.riskCalculator.getValue().postWeights, function (pwidx, pwi) {
                        if (pwi.type === weight.type) {
                            selectValue = pwi.value;
                        }
                    });

                    $("option", select).each(function (optIdx, opt) {
                        if ($(opt).data("value") == selectValue) {
                            $(opt).prop("selected", true);
                        }
                    });

                    // update the description text to match the dropdown
                    $.each(that.riskCalculator.getValue().postWeights, function (pwidx, pwi) {
                        if (pwi.type === weight.type) {
                            let selectedOption = $(":selected", select);
                            let description = selectedOption.data("description");
                            pwi.description = description;
                        }
                    });
                });
            });
        } else if (this.config.postReduction || (this.isPrint && this.settings.parameter.forceAfterWeightsInPrint)) {
            // in case (this.isPrint && this.settings.parameter.forceAfterWeightsInPrint) make sure all weights are shown
            if (this.isPrint && this.settings.parameter.forceAfterWeightsInPrint) {
                if (!this.config.postReduction) {
                    this.config.postReduction = { weights: [] };
                }
                if (!this.config.postReduction.weights) {
                    this.config.postReduction.weights = [];
                }
                for (let factor of this.config.factors) {
                    for (let weight of factor.weights) {
                        if (this.config.postReduction.weights.filter((w) => w.type == weight.type).length == 0) {
                            this.config.postReduction.weights.push(weight);
                        }
                    }
                }
            }

            // *********************************************************************************
            // show post mitigation factors as normal UI (if wanted)
            // *********************************************************************************

            // initialize 'place to store the selected post reduction values if necessary
            if (!this.riskCalculator.getValue().postWeights) {
                this.riskCalculator.getValue().postWeights = [];
            }

            let help = this.config.postReduction.help ? this.config.postReduction.help : "Risk After Risk Controls";
            this.risk.append(
                $("<tr>").append($("<td colspan='4'>").html("<span class='baseControlHelp'>" + help + "</span>")),
            );

            $.each(this.config.postReduction.weights, function (weightIdx, weight) {
                var riskPostRow = $(
                    '<tr class="riskPostRow" rowspan="' + that.config.postReduction.weights.length + '">',
                ).appendTo(that.risk);
                riskPostRow.append($("<td colspan='2'>"));
                that.renderWeight("After Risk Reduction", riskPostRow, false, weight);
                var select = $("select", riskPostRow);
                select.change(function () {
                    // store the new value
                    let exists = false;
                    let selectedOption = $(":selected", select);
                    let description = select.val();
                    let value = <number>selectedOption.data("value");
                    $.each(that.riskCalculator.getValue().postWeights, function (pwidx, pwi) {
                        if (pwi.type === weight.type) {
                            exists = true;
                            pwi.description = description;
                            pwi.value = value;
                        }
                    });
                    if (!exists) {
                        that.riskCalculator.getValue().postWeights.push({
                            type: weight.type,
                            value: value,
                            description: description,
                            label: weight.label,
                        });
                    }
                    // update the display
                    that.riskChange();
                });

                // default value for post mitigation is the before mitigation
                let sameBefore = $('select[name="' + weight.type + '"]', that._root);
                let selectValue = <number>$(":selected", sameBefore).data("value");

                // check if actual value has been saved before and overwrite the default
                $.each(that.riskCalculator.getValue().postWeights, function (pwidx, pwi) {
                    if (pwi.type === weight.type) {
                        selectValue = pwi.value;
                    }
                });

                $("option", select).each(function (optIdx, opt) {
                    if ($(opt).data("value") == selectValue) {
                        $(opt).prop("selected", true);
                    }
                });

                // update the description text to match the dropdown
                $.each(that.riskCalculator.getValue().postWeights, function (pwidx, pwi) {
                    if (pwi.type === weight.type) {
                        pwi.description = select.val();
                    }
                });
            });
        }

        // *********************************************************************************
        // update all the maths once -> can trigger save, e.g. if configuration changed
        // *********************************************************************************

        this.riskChange();

        // try to cope with ui which converts simple dashes into unicode dashes....
        if (this.hasChangedAsync()) {
            var oo = this._root.data("original");
            var no = JSON.stringify(this.riskCalculator.getValue());

            var same = "";
            for (var idx = 0; idx < Math.min(oo.length, no.length); idx++) {
                if (oo[idx] === no[idx]) {
                    same += oo[idx];
                } else {
                    //console.log(same);
                    //console.log("Difference at " + idx + " got: '" + oo[idx] + "' should have been '" + no[idx] + "'");
                    same = "";
                }
            }
            if (oo.length !== no.length) {
                //console.log("also object have not same length");
            }
        }

        if (!this.settings.canEdit) {
            $("input", this._root).attr("disabled", "disabled");
            $("select", this._root).attr("disabled", "disabled").css("background", "transparent");
        }

        if (this.settings.isForm || this.isPrint || this.settings.controlState === ControlState.Tooltip) {
            if (
                !(<IRiskTableParams>this.settings.parameter).tableOptions &&
                !(this.config.ram && this.config.ram.hidden)
            ) {
                this.risk.append(
                    $("<tr>")
                        .append($('<td colspan="2">'))
                        .append(
                            $('<td class="RAM riskReductionLabel" >').html(
                                this.config.ram && this.config.ram.short ? this.config.ram.short : "RAM",
                            ),
                        )
                        .append($('<td class="risksum RAM RAMValue riskSelect totalRAM">')),
                );

                this.riskChange();
            }
        }

        if (this.settings.isForm || this.settings.controlState === ControlState.DialogCreate) {
            var rmHelpKeys: string[] = [];
            $.each(this.config.factors, function (factorIdx, factor) {
                $.each(factor.weights, function (weightIdx, factor) {
                    rmHelpKeys.push(factor.label);
                });
            });

            $(".RBM", this._root).popover({
                container: "body",
                html: true,
                placement: "auto",
                title: this.config.rbm && this.config.rbm.long ? this.config.rbm.long : "RBM - Risk Before Mitigation",
                trigger: "hover",
                content: "<p>Formula: <b>" + rmHelpKeys.join(" " + this.config.method + " ") + "</b></p>",
            });
            $(".RAM", this._root).popover({
                container: "body",
                html: true,
                placement: "auto",
                title: this.config.ram && this.config.ram.long ? this.config.ram.long : "RAM - Risk After Mitigation",
                trigger: "hover",
                content: "<p>Formula: <b>" + rmHelpKeys.join(" " + this.config.method + " ") + "</b></p>",
            });
        }

        /***********************************************************************************************
         * add special code for new printing
         ******************************************************************************************/
        if (this.isPrint) {
            // replace the input fields with spans for printing
            $.each($("input", this._root), function (idx, y) {
                let el = $(y);
                let classes: string[] = [el.attr("name"), "risk_factor"];
                let val = el.val() ? el.val() : "";
                let pre = that.settings.parameter.showAttributeNames
                    ? `<div class="debug">${el.attr("name")}</div>` + val
                    : "";

                el.replaceWith($(pre + `<span class="${classes.join(" ")}">${ml.UI.lt.forDB(val, null)}</span>`));
            });
            $.each($("textarea", this._root), function (idx, y) {
                let el = $(y);
                let classes: string[] = [el.attr("name"), "risk_factor"];
                let val = el.val() ? el.val() : "";
                let pre = that.settings.parameter.showAttributeNames
                    ? `<div class="debug">${el.attr("name")}</div>`
                    : "";

                el.replaceWith(
                    $(
                        pre +
                            `<div style="white-space: pre-wrap;" class="${classes.join(" ")}">${ml.UI.lt.forDB(
                                val,
                                null,
                            )}</div>`,
                    ),
                );
            });
            $.each($(".riskrichtext", this._root), function (idx, y) {
                $(y).css("border", "none").css("height", "100%"); // no border around the right text
                if (that.settings.parameter.showAttributeNames) {
                    $(y).prepend(`<div class="debug">${$(y).attr("name")}</div>`);
                } else {
                    $(y).addClass($(y).attr("name"));
                }
            });

            $.each($("select", this._root), function (idx, y) {
                let el = $(y);
                let val = $("option:selected", el).text();
                if (!val) val = "";
                let classes: string[] = [el.attr("name")];
                if (el.hasClass("risk-control")) classes.push("risk_control");
                if (el.hasClass("riskTextSelect")) {
                    // drop down for a risk factor
                    classes.push("risk_factor");
                    if (that.settings.parameter.showAttributeNames) {
                        val = `<div class="debug">${el.attr("name")}</div>` + val;
                    }
                }
                if (el.hasClass("riskSelect")) {
                    // drop down for a risk weight
                    classes.push("risk_weight");
                    let isbeforeControls = el.closest(".riskPostRow").length == 0;
                    classes.push(isbeforeControls ? "before" : "after");

                    if (that.settings.parameter.showAttributeNames) {
                        val =
                            `<div class="debug">${isbeforeControls ? "before." : "after."}${el.attr("name")}</div>` +
                            val;
                    }
                }

                el.replaceWith($(`<span class="${classes.join(" ")}">${val}</span>`));
            });
            $.each($(".refId", this._root), function (idx, y) {
                let el = $(y);
                // create a fake element to create a hyper link with standard print settings
                el.replaceWith($(`<span class="_createHyper">${el.text()}</span>`));
            });
            $(".baseControl", this._root).addClass("riskControls");
            if (that.settings.parameter.showAttributeNames) {
                $(".simpleMitigationTable", this._root).prepend(`<div class="debug">riskControls</div>`);
                $(".baseControl", this._root).prepend(`<div class="debug">riskControls</div>`);
                $(".totalRBM", this._root).prepend(`<div class="debug">totalRBM</div>`);
                $(".totalRAM", this._root).prepend(`<div class="debug">totalRAM</div>`);
            }
            $(".refTitle", this._root).remove();
        }
    }

    // public interface
    async hasChangedAsync() {
        return (
            this.mitigationsRemoved || this._root.data("original") !== JSON.stringify(this.riskCalculator.getValue())
        );
    }
    async getValueAsync() {
        let that = this;

        return JSON.stringify(that.riskCalculator.getValue());
    }
    destroy() {
        $(".popover").remove();
    }
    resizeItem() {}

    private syncTheLinks(actual: IRiskValueMitigation[]) {
        let that = this;

        let res = $.Deferred();

        // only need to do this if this is a mitigation table, otherwise links are created on the fly
        if (!this.config.mitigationTable) {
            res.resolve();
            return res;
        }

        let neededLinks = actual
            ? actual.map(function (mit) {
                  return mit.to;
              })
            : [];
        let existingLinks = this.settings.links
            ? this.settings.links.map(function (mit) {
                  return mit.to;
              })
            : [];
        let needToAdd = neededLinks.filter(function (link) {
            return existingLinks.indexOf(link) == -1;
        });
        let needToRemove = existingLinks.filter(function (link) {
            return neededLinks.indexOf(link) == -1;
        });
        if (needToAdd.length == 0 && needToRemove.length == 0) {
            res.resolve();
        } else {
            let cadd: IReferenceChange[] = needToAdd.map(function (toId) {
                return { action: "addLink", fromId: that.settings.id, toId: toId };
            });
            let crem: IReferenceChange[] = needToRemove.map(function (toId) {
                return { action: "removeLink", fromId: that.settings.id, toId: toId };
            });
            let changes = cadd.concat(crem);
            app.commitChangeListAsync(changes).always(function (error, stepsDone) {
                if (error) {
                    ml.UI.showError(error, "cancelled operation");
                    res.reject();
                } else {
                    // update the links which are stored with the item
                    $.each(needToAdd, function (cidx, c) {
                        that.settings.links.push(<any>{ to: c });
                    });
                    $.each(needToRemove, function (cidx, c) {
                        that.settings.links = that.settings.links.filter(function (l) {
                            return l.to != c;
                        });
                    });

                    res.resolve();
                }
            });
        }
        return res;
    }
    private controlsFromTable(riskControlsAsTable: string): IRiskValueMitigation[] {
        let that = this;

        if (!riskControlsAsTable) {
            return [];
        }
        let riskControls: IRiskValueMitigation[] = [];
        let table = JSON.parse(riskControlsAsTable);
        $.each(table, function (rcIdx, rcRow) {
            let exists = riskControls.filter(function (rc) {
                return rc.to == rcRow.to;
            });
            let changes: IRiskValueMitigationChange[] = [];
            if (exists.length == 1) {
                changes = exists[0].changes;
            }

            let added = false;
            if (rcRow.reduction) {
                $.each(that.config.reductions, function (reductionIdx, reduction) {
                    $.each(reduction.options, function (optionIdx, option) {
                        if (rcRow.reduction == option.changes + "|" + option.by) {
                            changes.push({
                                by: option.by,
                                changes: option.changes,
                                description: option.shortname,
                                name: "00",
                            });
                            added = true;
                        }
                    });
                });
            }
            if (!added) {
                // maybe not selected, maybe not defined
                changes.push(<any>{});
            }
            // add additional columns
            $.each(rcRow, function (key: string, val: string) {
                if (key != "to" && key != "reduction") {
                    (<any>changes)[changes.length - 1][key] = val;
                }
            });
            if (exists.length != 1) {
                let newMit: IRiskValueMitigation = {
                    to: rcRow.to,
                    title: app.getItemTitle(rcRow.to),
                    changes: changes,
                };
                riskControls.push(newMit);
            }
        });
        return riskControls;
    }

    private controlsToTable(riskControls: IRiskValueMitigation[]): string {
        let table: any = [];
        $.each(riskControls, function (rcIdx, rc) {
            if (rc.changes && rc.changes.length > 0) {
                $.each(rc.changes, function (idx, cg) {
                    let rcLine: any = {};
                    rcLine.to = rc.to;
                    rcLine.reduction = cg.changes + "|" + cg.by;
                    $.each(cg, function (key, val) {
                        if (key != "to" && key != "title" && key != "by" && key != "changes") {
                            rcLine[key] = val;
                        }
                    });
                    table.push(rcLine);
                });
            } else {
                let rcLine: any = {};
                rcLine.to = rc.to;
                table.push(rcLine);
            }
        });
        return JSON.stringify(table);
    }

    private renderFactorWithWeightsLine(factor: IRiskConfigFactor) {
        let that = this;
        // render one line in table
        var riskInputLine = $("<tr>").appendTo(this.risk);

        // rowspan is the number of rows for label and input of the weight text (depends on number of associated factors)
        var rowspan = Math.max(1, factor.weights.length);

        // first cell is the label and the input
        riskInputLine.append(
            $('<td class="risktd riskInputLabel">')
                .attr("rowspan", rowspan)
                .html(factor.label + ":"),
        );

        // if there are no factors, the weight input can be larger
        let colspan = (!factor.weights || factor.weights.length == 0) && factor.spancols ? 3 : 1;

        // second cell is the input (a text field, dropdown, ...)
        let ti = this.renderFactorInput(factor);
        riskInputLine.append(
            $('<td class="risktd" colspan=' + colspan + ">")
                .attr("rowspan", rowspan)
                .append(ti),
        );

        if (colspan == 1) {
            // add additional columns for the risk factors. each factor after the second will add a new line
            if (factor.weights.length == 0) {
                // add two empty columns
                riskInputLine.append($("<td colspan='2'>"));
            } else {
                $.each(factor.weights, function (weightIdx, weight) {
                    riskInputLine = that.renderWeight(factor.label, riskInputLine, weightIdx !== 0, weight);
                });
            }
        }
    }

    private editRichText(field: JQuery) {
        let that = this;

        var dlg = $("#editFieldDlg");
        dlg.html("");
        dlg.addClass("dlg-no-scroll");
        dlg.removeClass("dlg-v-scroll");

        var rte = $("<div>");
        dlg.append($("<div>").append(rte));

        var padding = 28;

        ml.UI.showDialog(
            $("#editFieldDlg"),
            "Edit Factor",
            rte,
            730,
            550,
            [
                {
                    text: "OK",
                    class: "btnDoIt",
                    click: async function () {
                        that.setFactorRichValue(field, await rte.getController().getValueAsync());
                        that.riskChange();
                        dlg.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.None,
            false,
            false,
            null,
            () => {
                rte.richText({
                    controlState: ControlState.DialogEdit,
                    fieldValue: field.data("realValue"),
                    canEdit: true,
                    help: " ",
                    parameter: { height: 315, tableMode: false, autoEdit: true, autoFocus: true },
                });

                padding = $("#editFieldDlg").parent().width() - $("#editFieldDlg").width();
                var el = $(".note-editable", rte);
                el.on("keydown", async function (event) {
                    if (globalMatrix.globalShiftDown && event.keyCode === 13) {
                        if (event.preventDefault) event.preventDefault();
                        if (event.stopPropagation) event.stopPropagation();
                        that.setFactorRichValue(field, await rte.getController().getValueAsync());
                        dlg.dialog("close");
                    }
                });
            },
            () => {
                $(".note-editable", rte).height(dlg.height() - 65);
                $("#editFieldDlg").width($("#editFieldDlg").parent().width() - padding);
            },
            true,
        );
    }

    private setFactorRichValue(field: JQuery, html: string) {
        let clean = new HTMLCleaner(html ? html : "", false).getClean(0, true);
        field.data("realValue", clean);
        clean = ml.SmartText.replaceTextFragments(clean, true);
        field.html(clean);
        ml.SmartText.showTooltips(field);
        field.highlightReferences();
    }

    private renderWeight(
        factorLabel: string,
        riskInputLine: JQuery,
        additionalRow: boolean,
        weight: IRiskConfigFactorWeight,
    ): JQuery {
        // add more columns (and new lines if needed with dropdowns like Probability 1 \/
        if (additionalRow) {
            riskInputLine = $("<tr>").appendTo(this.risk);
        }

        // create the label
        var weightLabel = $('<span class ="riskReductionLabel">').html(weight.label + ":");
        riskInputLine.append($("<td>").append(weightLabel));
        if (weight.hidden) {
            weightLabel.hide();
        } else if (weight.help && !this.isPrint) {
            // add help for label
            var help = $("<div>");
            var helpTable = $("<table class='table-bordered table-help'>").appendTo(help);
            var helpBody = $("<tbody>").appendTo(helpTable);
            helpTable.append(
                "<thead><tr><td class='riskhelpleft'>Value</td><td class='riskhelpright'>Description</td></tr></thead>",
            );

            $.each(weight.values, function (valIdx, val) {
                var ftext = RiskCalculator.labelDisplay(val); // val.factor + (val.shortname ? (" - " + val.shortname) : "");
                helpBody.append(
                    $("<tr>")
                        .append("<td class='riskhelpleft'>" + ftext + "</td>")
                        .append("<td class='riskhelpright'>" + val.help + "</td>"),
                );
            });
            weightLabel.popover({
                template:
                    '<div class="popover riskhelppopover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"><div class="data-content"></div></div></div>',
                container: "body",
                html: true,
                placement: "auto",
                title: factorLabel + " - " + weight.label,
                trigger: "hover",
                content: help,
            });
        }

        // create the weight
        let weightSelect = this.renderWeightInput(weight, true, false); // default true: can edit, false: not a table
        riskInputLine.append($("<td>").append(weightSelect));

        return riskInputLine;
    }

    private setWeight(factorType: string, weightType: string, val: number, label: string, description: string) {
        $.each(this.riskCalculator.getValue().factors, function (factorIdx, factor) {
            if (factor.type === factorType) {
                var found = false;
                for (var idx = 0; idx < factor.weights.length; idx++) {
                    if (factor.weights[idx].type === weightType) {
                        factor.weights[idx].value = val;
                        factor.weights[idx].description = description;
                        found = true;
                    }
                }
                if (!found) {
                    factor.weights.push({
                        type: weightType,
                        value: val,
                        label: label,
                        description: description,
                    });
                }
            }
        });
    }

    private setFactor(type: string, label: string, val: string, inputType: string) {
        for (var idx = 0; idx < this.riskCalculator.getValue().factors.length; idx++) {
            if (this.riskCalculator.getValue().factors[idx].type === type) {
                this.riskCalculator.getValue().factors[idx].value = val;
                return;
            }
        }
        let fac: IRiskValueFactor = {
            type: type,
            value: val,
            label: label,
            weights: [],
        };
        if (inputType) {
            fac.inputType = inputType;
        }
        this.riskCalculator.getValue().factors.push(fac);
    }

    // called from once after painting or UI in case any value changes: this updates the math and the save state
    riskChange() {
        let that = this;
        // read and calculate risk values after mitigations. save them in item
        function savePerMitigationEffect() {
            function setChange(
                mitigation: IRiskValueMitigation,
                name: string,
                by: number,
                changes: string,
                description: string,
            ) {
                for (var idx = 0; idx < mitigation.changes.length; idx++) {
                    if (mitigation.changes[idx].name === name) {
                        mitigation.changes[idx].by = by;
                        mitigation.changes[idx].changes = changes ? changes : "";
                        mitigation.changes[idx].description = description;
                        return;
                    }
                }
                mitigation.changes.push({
                    name: name,
                    by: by,
                    changes: changes,
                    description: description,
                });
            }

            $.each(that.riskCalculator.getValue().mitigations, function (mitigationIdx, mitigation) {
                // get the unique id of the mitigation: the target item
                var mitigationTo = mitigation.to;
                if (that.config.mitigationTable) {
                } else {
                    // get all related mitigation controls, by the id of the item they relate to
                    var mitigationControls = $(".mitigation" + mitigationTo, that._root);
                    // sum up all values for the mitigation
                    // in case there are several drop downs sum up all values
                    $.each(mitigationControls, function (mitigationControlIdx, mitigationControl) {
                        var selectedOption = $(":selected", mitigationControl);
                        var mitigationName = $(mitigationControl).attr("name");
                        var changes = selectedOption.data("changes");
                        var by = selectedOption.data("value");

                        $(mitigationControl).css("color", by > 0 ? "red" : "black");

                        // save the mitgation value which is set in the UI
                        setChange(mitigation, mitigationName, by, changes, selectedOption.text());
                    });
                }
            });
        }
        // use whatever user selected after mitigations
        function saveCombinedMitgationValues(rbm: IRiskValueMap) {
            if (that.riskCalculator.getValue().mitigations.length === 0 && that.config.mitigationTypes.length > 0) {
                if ((<IRiskTableParams>that.settings.parameter).tableOptions) {
                    $.each($("select.riskAfter", that._root), function (weightIdx, weight) {
                        let name = $(weight).attr("name");
                        that.setSelectValue($(weight), <any>rbm[name]);
                    });
                    $(".riskAfter.riskSelect.riskInputValue", that._root).attr("disabled", "disabled");
                } else {
                    $(".riskPostRow").hide();
                }
                // no mitigations selected -> nothing can change -> use original values
                return;
            }
            let riskPostRowsWeights: JQuery;
            if ((<IRiskTableParams>that.settings.parameter).tableOptions) {
                $(".riskAfter.riskSelect.riskInputValue", that._root).removeAttr("disabled");
                // update the readonly selects
                let readonlyWeights = $("select.riskAfter.riskSelect.riskInputValuedisabled", that._root);
                $.each(readonlyWeights, function (weightIdx, weight) {
                    let name = $(weight).attr("name");
                    that.setSelectValue($(weight), <any>rbm[name]);
                });
                // get the values
                riskPostRowsWeights = $("select.riskAfter.riskSelect option:selected", that._root);
            } else {
                $(".riskPostRow").show();
                riskPostRowsWeights = $(".riskPostRow select  option:selected", that._root);
            }
        }
        // $ to display a risk level before and after mitigation including the math
        function updateRiskDisplay(ctrl: JQuery, riskValues: IRiskValueMap) {
            let riskText = that.riskCalculator.getRiskSumText(riskValues);

            if (that.config.method === "lookup") {
                ctrl.html(riskText.text);
                ctrl.css("background-color", riskText.backgroundColor);
                ctrl.css("color", riskText.foregroundColor);
            } else {
                ctrl.html(riskText.text);
                ctrl.removeClass("riskgreen").removeClass("riskyellow").removeClass("riskred").addClass(riskText.css);
            }
        }

        // something in ui changed, read all values and update math
        $.each(this.config.factors, function (factorIdx, factor) {
            // get from UI and set in results the input text (e.g. in Cause field
            var inp: string;
            if (factor.inputType && factor.inputType === "select") {
                inp = $("select[name=" + factor.type + "]", that._root).val();
            } else if (factor.inputType && factor.inputType === "textarea") {
                inp = ml.UI.lt.forDB($("textarea[name=" + factor.type + "]", that._root).val(), that.settings.fieldId);
            } else if (factor.inputType && factor.inputType === "richtext") {
                inp = $(".riskrichtext[name=" + factor.type + "]", that._root).data("realValue");
            } else {
                inp = ml.UI.lt.forDB($("input[name=" + factor.type + "]", that._root).val(), that.settings.fieldId);
            }
            that.setFactor(factor.type, factor.label, inp, factor.inputType);
            // get from UI and set in results the weights
            $.each(factor.weights, function (weightIdx, weight) {
                var selectedOption = $("select[name=" + weight.type + "] :selected:first()", that._root);
                that.setWeight(
                    factor.type,
                    weight.type,
                    selectedOption.data("value"),
                    weight.label,
                    ml.UI.lt.forDB(selectedOption.data("description"), that.settings.fieldId),
                );
            });
        });

        let rbm = this.riskCalculator.getRBM();

        updateRiskDisplay($(".totalRBM", this._root), rbm);

        // update the post reduction risk ui and read the values
        if (this.config.postReduction) {
            // use whatever user selected after mitigations
            try {
                // still allow to have drop downs / even if they do not change final math.
                savePerMitigationEffect();
            } catch (ex) {}
            saveCombinedMitgationValues(rbm);
        } else {
            // substract something from values as defined in drop downs after each mitigation
            savePerMitigationEffect();
        }
        // calculate the ram
        let ramCalc = this.riskCalculator.getRAM(rbm);

        // in case we are in a table in a folder, also update the readonly columns
        if ((<IRiskTableParams>this.settings.parameter).tableOptions) {
            $.each(this.config.factors, function (factorIdx, factor) {
                $.each(factor.weights, function (weightIdx, weight) {
                    let dd = $('select[name="' + weight.type + '"].riskInputValuedisabled.riskAfter', that._root);
                    that.setSelectValues(dd, ramCalc[weight.type]);
                });
            });
        }

        // update overall result
        updateRiskDisplay($(".totalRAM", this._root), ramCalc);

        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }

    // called if user added a new mitigation (or removed one)
    private async mitigationChanged() {
        (await this.riskCalculator.getValue()).mitigations = this.config.mitigationTable
            ? this.controlsFromTable(await this.mitbody.getController().getValueAsync())
            : await this.mitbody.getController().getValueAsync();
        if (this.config.mitigationTable) {
            this.syncTheLinks((await this.riskCalculator.getValue()).mitigations).done(function () {});
        }
        // update risk display if anything
        this.riskChange();

        // update main UI about change
        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }

    private getLabelFactor(type: string): string {
        if (this.config.factors) {
            for (var idx = 0; idx < this.config.factors.length; idx++) {
                if (this.config.factors[idx].type == type) {
                    return this.config.factors[idx].label;
                }
            }
        }
        return "";
    }

    private getLabelWeight(type: string): string {
        if (this.config.factors) {
            for (var idx = 0; idx < this.config.factors.length; idx++) {
                for (var jdx = 0; jdx < this.config.factors[idx].weights.length; jdx++) {
                    if (this.config.factors[idx].weights[jdx].type == type) {
                        return this.config.factors[idx].weights[jdx].label;
                    }
                }
            }
        }
        return "";
    }

    private getLabelWeightFactor(type: string, factor: number): string {
        if (this.config.factors) {
            for (const factorIt of this.config.factors) {
                if (factorIt.weights) {
                    for (const weight of factorIt.weights) {
                        if (weight.type == type) {
                            if (weight.values) {
                                for (const value of weight.values) {
                                    if (value.factor == factor) {
                                        return value.shortname;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return "";
    }

    private createMitigationSelect(
        reduction: IRiskConfigReduction,
        mitigationElement: IRiskValueMitigation,
        mitigationTo: string,
    ) {
        let that = this;

        var mit = $("<select class='risk-control multimitctrl riskInputValue' name='" + reduction.name + "'>");

        // mitigation can be changed, so add drop downs and other info
        // make sure mitigation can be found by it's id (in getRiskAfterMitigation)
        mit.addClass("mitigation" + mitigationTo);

        mit.on("change", function () {
            that.riskChange();
        });

        $.each(reduction.options, function (optionIdx, option) {
            var opt = $("<option>" + option.shortname + "</option>");

            opt.data("changes", option.changes);
            opt.data("value", option.by);
            opt.css("color", option.by > 0 ? "#F00" : "#000");
            mit.append(opt);
        });

        // make sure there is an array (for new mitigations)
        if (!mitigationElement.changes) {
            mitigationElement.changes = [];
        }

        // check if there is already a value
        var exists = false;
        $.each(mitigationElement.changes, function (changedIdx, change) {
            if (change.name === reduction.name) {
                exists = true;
                that.setSelectValues(mit, change.by, change.changes);
            }
        });
        if (!exists) {
            var description = reduction.options.length > 0 ? reduction.options[0].shortname : "";
            var by = reduction.options.length > 0 ? reduction.options[0].by : 0;

            mitigationElement.changes.push({ name: reduction.name, changes: "", description: description, by: by });
        }

        return mit;
    }

    private mitigationRenderer(referenceLI: JQuery, mitigationTo: string, mitigationIdx: number) {
        let that = this;

        // get the saved data for the mitigation
        var mitigationElement: IRiskValueMitigation = null;
        $.each(this.riskCalculator.getValue().mitigations, function (mitIdx, mit) {
            if (mit.to === mitigationTo) {
                mitigationElement = mit;
            }
        });

        // now build all the drop downs / the one drop down to mitigate the risk

        $.each(this.config.reductions, function (reductionIdx, reduction) {
            var mit = that.createMitigationSelect(reduction, mitigationElement, mitigationTo);
            referenceLI.append(mit);
        });
    }

    // set values of a select to closest value saved with item
    private setSelectValues(selects: JQuery, val: number, impact?: string) {
        let that = this;

        $.each(selects, function (selectIdx, select) {
            that.setSelectValue(select, val, impact);
        });
    }
    private setSelectValue(select: JQuery, val: number, impact?: string) {
        $($("option", select)[0]).prop("selected", true);
        if (!val) {
            return;
        }
        var difference = 10000000;
        $.each($("option", select), function (optionIdx, option) {
            if (!impact || $(option).data("changes") === impact) {
                var d = $(option).data("value") - val;
                if (d * d < difference) {
                    difference = d * d;
                    $(option).prop("selected", true);
                }
            }
        });
    }

    private canBeMitigation(itemId: string) {
        for (var mt = 0; mt < this.config.mitigationTypes.length; mt++) {
            if (itemId.indexOf(this.config.mitigationTypes[mt].type + "-") === 0) {
                return true;
            }
        }
        return false;
    }

    /********************************
     * render as table control
     ********************************/

    private renderTableBodyRow(riskId: string) {
        let that = this;

        let tr = this._root;

        // add column risk id
        if (riskId) {
            $("<td class='riskIdCell'>")
                .appendTo(tr)
                .html(riskId + ((<IRiskTableParams>this.settings.parameter).tableOptions.showFullRisk ? "!" : ""));
        }

        // add columns for text inputs (weights)
        $.each(this.config.factors, function (factorIdx, factor) {
            let input = that.renderFactorInput(factor);
            input.addClass("riskBefore");
            $("<td>").appendTo(tr).append(input);
        });

        // add columns values inputs (factors)
        $.each(this.config.factors, function (factorIdx, factor) {
            $.each(factor.weights, function (weightIdx, weight) {
                let input = that.renderWeightInput(weight, true, true);
                input.addClass("riskBefore");
                $("<td>").appendTo(tr).append(input); // column for weight (drop down input);
            });
        });
        // add column for RBM // RPN before controls

        $("<td class='riskBefore RBM riskReductionLabel totalRBM risksum riskSelect'>").appendTo(tr);

        // container for selector
        this.mitbody = $("<div>");
        // add column for risk controls
        $("<td class='tinyRisks'>").appendTo(tr).append(this.mitbody);
        // add details
        this.renderMitigationSelect(true);

        // add columns for values (factors) after risk controls
        $.each(this.config.factors, function (factorIdx, factor) {
            $.each(factor.weights, function (weightIdx, weight) {
                let input = that.renderWeightInput(weight, !!that.config.postReduction, true);
                input.addClass("riskAfter");
                let td = $("<td>").appendTo(tr).append(input); // column for weight (drop down input);
                if (
                    that.settings.hideReadonlyColumns &&
                    (ml.JSON.isTrue(weight.readonly) || !that.config.postReduction)
                ) {
                    td.hide();
                }
                if (that.config.postReduction) {
                    let visibleColumns = that.config.postReduction.weights.map(function (weight) {
                        return weight.type;
                    });
                    if (visibleColumns.indexOf(weight.type) == -1) {
                        td.remove();
                    }
                }
            });
        });
        // column for RAM // RPN after  controls
        $("<td class='riskAfter risksum RAM RAMValue riskSelect totalRAM'>").appendTo(tr);

        return tr;
    }

    /********************************
     * user inputs
     ********************************/

    // this renders the actual input field for the risk weight text
    private renderFactorInput(factor: IRiskConfigFactor) {
        let that = this;
        let ti: JQuery;

        var fd = "";
        if (ml.JSON.isTrue(factor.readonly)) {
            fd = "disabled";
        }
        if (factor.inputType && factor.inputType === "select") {
            ti = $(
                '<select class="form-control riskInputValue riskTextSelect' + fd + '" style="width:100%" ' + fd + ">",
            )
                .attr("name", factor.type)
                .attr("placeholder", "Enter " + factor.label);

            $.each(factor.options, function (idx, option) {
                ti.append(
                    $("<option value='" + option.value + "'>")
                        .html(option.label)
                        .data("changes", option.changes),
                );
            });

            ti.change(function (event: JQueryEventObject) {
                var option = $("option:selected", $(event.delegateTarget));
                var changes = option.data("changes");
                if (changes) {
                    $.each(changes, function (cidx: number, change: IRiskConfigSelectChanges) {
                        if (change.changesWeight) {
                            that.setSelectValues(
                                $('select[name="' + change.changesWeight + '"]', that._root),
                                <any>change.value,
                            );
                        }
                        if (change.changesFactor) {
                            let fact = that.config.factors.filter(
                                (f) => f.type == change.changesFactor && f.inputType == "richtext",
                            );
                            if (fact.length == 1) {
                                that.setFactorRichValue(
                                    $('[name="' + change.changesFactor + '"]', that._root),
                                    <string>change.value,
                                );
                            } else {
                                $('[name="' + change.changesFactor + '"]', that._root).val(change.value);
                            }
                        }
                    });
                }
            });
        } else if (factor.inputType && factor.inputType === "textarea") {
            ti = $(
                '<textarea type="text" ' +
                    (that.settings.canEdit ? "" : "readonly ") +
                    'class="form-control riskText' +
                    fd +
                    ' riskInputTextArea riskTextArea" ' +
                    fd +
                    ">",
            )
                .attr("name", factor.type)
                .attr("placeholder", "Enter " + factor.label);
        } else if (factor.inputType && factor.inputType === "richtext") {
            ti = $('<div class="riskrichtext riskTextArea form-control">')
                .attr("name", factor.type)
                .attr("placeholder", "Enter " + factor.label);
            if (that.settings.canEdit && !ml.JSON.isTrue(factor.readonly)) {
                ti.click(function () {
                    that.editRichText(ti);
                });
            }
        } else {
            ti = $(
                '<input autocomplete="off" type="text" ' +
                    (that.settings.canEdit ? "" : "readonly ") +
                    ' class="form-control riskTextSelectStyle riskText' +
                    fd +
                    '" ' +
                    fd +
                    ">",
            )
                .attr("name", factor.type)
                .attr("placeholder", "Enter " + factor.label);
        }
        if (factor.hideTextInput) {
            ti.hide();
        }
        return ti;
    }

    // this enters the input for a weight dropdown
    private renderWeightInput(weight: IRiskConfigFactorWeight, canEdit: boolean, forTable: boolean) {
        var disabled = "";
        var riskInputValue = "riskInputValue";
        if (!canEdit || ml.JSON.isTrue(weight.readonly)) {
            disabled = "disabled";
            riskInputValue = "riskInputValuedisabled";
        }
        var weightSelect = $('<select class="form-control riskSelect ' + riskInputValue + '" ' + disabled + ">").attr(
            "name",
            weight.type,
        );

        // add options for dropdown
        $.each(weight.values, function (valIdx, val) {
            var description = RiskCalculator.labelDisplay(val); // val.factor + (val.shortname? (" - " + val.shortname) : "");
            var text = forTable ? val.factor : description;
            weightSelect.append(
                $("<option>" + text + "</option>")
                    .data("value", val.factor)
                    .data("description", description),
            );
        });

        // hide it
        if (weight.hidden) {
            weightSelect.hide();
        }
        return weightSelect;
    }
    // Replace highlight ref in richtext when reloading.
    highlightReferences() {
        $(this._root).find(".riskrichtext").highlightReferences();
    }

    // render area to select mitigations
    private renderMitigationSelect(forTable: boolean) {
        let that = this;

        if (this.config.mitigationTypes.length > 0) {
            let theMitigations = this.riskCalculator.getValue().mitigations;
            if (theMitigations) {
                // fix the labels if they need fixing
                $.each(theMitigations, function (theIdx, theMitigation) {
                    $.each(theMitigation.changes, function (theJdx, theChange) {
                        $.each(that.config.reductions, function (idx, reduction) {
                            $.each(reduction.options, function (jdx, option) {
                                if (option.by == theChange.by && option.changes == theChange.changes) {
                                    theChange.description = option.shortname;
                                }
                            });
                        });
                    });
                });
            }

            if (this.config.mitigationTable) {
                let columns: ITableControlOptionsColumn[] = [];
                $.each(this.config.mitigationTable.columns, function (colIdx, colDef) {
                    switch (colDef.editor) {
                        case "control":
                            columns.push({
                                editor: ColumnEditor.category,
                                name: colDef.name,
                                field: "to",
                                options: <any>{
                                    singleSelect: true,
                                    showTitle: true,
                                    categories: that.config.mitigationTypes.map(function (mt: any) {
                                        return mt.type;
                                    }),
                                },
                            });
                            break;
                        case "reduction":
                            let options: any = {};
                            $.each(that.config.reductions, function (reductionIdx, reduction) {
                                $.each(reduction.options, function (optionIdx, option) {
                                    options[option.changes + "|" + option.by] = option.shortname;
                                });
                            });
                            columns.push({
                                editor: ColumnEditor.select,
                                name: colDef.name,
                                field: "reduction",
                                options: options,
                            });
                            break;
                        default:
                            // take it as it is
                            columns.push({
                                editor: colDef.editor,
                                name: colDef.name,
                                field: colDef.field,
                                options: colDef.options,
                            });
                            break;
                    }
                });
                this.mitbody.width(forTable ? "" : this.risk.width());
                this.mitbody.tableCtrl({
                    canEdit: that.settings.canEdit,
                    help: that.config.controls ? that.config.controls : "Risk Controls",
                    parameter: {
                        canBeModified: that.settings.canEdit,
                        create: that.settings.canEdit,
                        showLineNumbers: that.settings.canEdit,
                        columns: columns,
                    },
                    fieldValue: that.controlsToTable(theMitigations),
                    controlState: this.settings.controlState,
                    id: this.settings.id,
                    valueChanged: async () => await that.mitigationChanged(),
                    item: this.settings.item,
                });
                if (this.settings.controlState != ControlState.HistoryView) {
                    this.mitbody.highlightReferences();
                }
                this.mitbody.getController().redraw();
                // handle the special case of printing -> convert the mitigation table into a simple table
                if (this.isPrint) {
                    let simpleTable = $("<table class='simpleMitigationTable table table-bordered'>");
                    let head = $("<thead>").appendTo(simpleTable);
                    let body = $("<tbody>").appendTo(simpleTable);

                    $(".slick-header-column", this.mitbody).each(function (idx, col) {
                        if (idx) head.append("<th>" + $(col).text() + "</th>");
                    });
                    $(".slick-row", this.mitbody).each(function (rowIdx, row) {
                        let tr = $("<tr>").appendTo(body);
                        $(".slick-cell", $(row)).each(function (colIdx, col) {
                            if (colIdx) tr.append("<td>" + $(col).text() + "</td>");
                        });
                    });
                    this.mitbody.replaceWith(simpleTable);
                }
            } else {
                this.mitbody.linkCollection({
                    canEdit: that.settings.canEdit,
                    help: that.config.controls ? that.config.controls : "Risk Controls",
                    parameter: { linkTypes: this.config.mitigationTypes },
                    fieldValue: theMitigations,
                    mitigationRenderer: (referenceLI: JQuery, mitigationTo: string, mitigationIdx: number) =>
                        that.mitigationRenderer(referenceLI, mitigationTo, mitigationIdx),
                    controlState: this.settings.controlState,
                    id: this.settings.id,
                    valueChanged: async () => await that.mitigationChanged(),
                    item: this.settings.item,
                    tiny: forTable,
                });
            }
        }
    }
}
