import { ItemSelectionFromToAbstractDocFieldHandler } from "./ItemSelectionFromToAbstractDocFieldHandler";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { IFromToSelection } from "../../../UI/Controls/itemSelectionFromTo";
import { IGenericMap } from "../../../../globals";

export interface ITraceMatrixOptions extends IDHFSectionOptions {
    includeInToc: boolean;
    numericalOrder: boolean;
    showIdOnly: boolean;
    searchFrom?: string;
    searchTo?: string;
    columnDef?: string;
}

export class TraceMatrixDocFieldHandler extends ItemSelectionFromToAbstractDocFieldHandler {
    getDefaultConfig(): ITraceMatrixOptions {
        return {
            includeInToc: false,
            numericalOrder: false,
            showIdOnly: false,
            columnDef: "",
        };
    }

    getXmlValue(): string {
        let controllerConfig = this.dhfFieldConfig;
        // get lists
        let reflist = <IFromToSelection>this.getSelectedItems();

        let td: IGenericMap[] = [];
        let r0: IGenericMap = {};
        for (let idx = 0; idx < reflist.from.length; idx++) {
            r0["from" + idx] = reflist.from[idx].to;
        }
        td.push(r0);

        let r1: IGenericMap = {};
        for (let idx = 0; idx < reflist.to.length; idx++) {
            r1["to" + idx] = reflist.to[idx].to;
        }
        td.push(r1);

        // copy parameters

        let r2: IGenericMap = { ...this.dhfFieldConfig };

        td.push(r2);

        // update the xml dump
        return JSON.stringify(td);
    }
}
