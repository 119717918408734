import { IDocFieldHandler } from "./IDocFieldHandler";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { IAnyMap } from "../../../../globals";
import { ITableOfContentOptions } from "./TableOfContentDocFieldHandler";
import { GenericDocAbstractFieldHandler } from "./GenericDocAbstractFieldHandler";

export interface IItemIndexOptions extends IDHFSectionOptions {
    format: string;
}

export const ITEM_INDEX_FORMAT = '\\e "\\t" \\c "1"';

export class ItemIndexDocFieldHander extends GenericDocAbstractFieldHandler implements IDocFieldHandler {
    dhfFieldConfig: IAnyMap;

    getXmlValue(): string {
        let controllerConfig = this.dhfFieldConfig;
        if (this.dhfFieldConfig == undefined) {
            controllerConfig = this.getDefaultConfig();
        }
        let options: ITableOfContentOptions = { format: controllerConfig.format };
        return JSON.stringify([options]);
    }

    getDefaultConfig(): IItemIndexOptions {
        return { format: ITEM_INDEX_FORMAT };
    }
}
