export enum LineType {
    textline = "textline",
    id = "id",
    uppercase = "uppercase",
    number = "number",
    select = "select",
    table = "table",
    json = "json",
    color = "color",
    id_ = "id_",
    richtext = "richtext",
    readonly = "readonly",
    boolean = "boolean",
    multiselect = "multiselect",
    folderselect = "folderselect",
    userAndGroupSelect = "userAndGroupSelect",
}
