import { GenericDocAbstractFieldHandler } from "./GenericDocAbstractFieldHandler";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";

export interface IRichTextOptions extends IDHFSectionOptions {}

export class RichTextDocFieldHandler extends GenericDocAbstractFieldHandler {
    getDefaultConfig(): IRichTextOptions {
        return {};
    }

    getXmlValue(): string {
        return JSON.stringify([]);
    }
}
