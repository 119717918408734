import { InitializeCodeMirrorPlugins } from "./CodeMirrorPlugins";
import "./jqueryFunctions";
import "./RefLink";
import "./SummernotePlugins";

export { InitializeParts };

// Function exists to ensure initializations in the imported modules above
// are made.
function InitializeParts() {
    InitializeCodeMirrorPlugins();
}
