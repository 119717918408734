import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { DropdownAbstractDocFieldHandler } from "./DropdownAbstractDocFieldHandler";

export interface IRemarkOptions extends IDHFSectionOptions {}

export class RemarksDocFieldHandler extends DropdownAbstractDocFieldHandler {
    getXmlValue(): string {
        let tt: { lines: string }[] = [];
        tt.push({ lines: this.getData() });
        return JSON.stringify(tt);
    }

    getDefaultConfig(): IRemarkOptions {
        return {};
    }
}
