import { app, ControlState, globalMatrix, IReference } from "../../../globals";
import { ILinkType } from "../Components/ItemForm";
import { SelectMode } from "../Components/ProjectViewDefines";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { ItemSelectionTools } from "../Tools/ItemSelectionView";
import { DateFieldHandler, ItemSelectionFieldHandler, ItemSelectionFieldHandlerFromTo } from "../../businesslogic";
import { DropdownFieldHandler } from "../../businesslogic/FieldHandlers/DropdownFieldHandler";

export type { IItemSelectionFromToOptions, IFromToSelection };
export { ItemSelectionFromToImpl };

interface IItemSelectionFromToOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: {
        /** Do not include these CATs in the FROM selection list */
        showNotFrom?: string[];
        /** Only include these CATs in the TO selection list */
        showOnlyTo?: string[];
        /** Initially select all possible items as to items */
        allTo?: boolean;
        buttonNameFrom?: string;
        buttonNameTo?: string;
        prefixFrom?: string;
        prefixTo?: string;
    };
}

$.fn.itemSelectionFromTo = function (this: JQuery, options: IItemSelectionFromToOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = new ItemSelectionFieldHandlerFromTo(options.parameter);
    }
    let baseControl = new ItemSelectionFromToImpl(this, options.fieldHandler as ItemSelectionFieldHandlerFromTo);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

interface IFromToSelection {
    from: IReference[];
    to: IReference[];
}

class ItemSelectionFromToImpl extends BaseControl<ItemSelectionFieldHandlerFromTo> {
    private settings: IItemSelectionFromToOptions;

    constructor(control: JQuery, fieldHandler: ItemSelectionFieldHandlerFromTo) {
        super(control, fieldHandler);
    }
    init(options: IItemSelectionFromToOptions) {
        let that = this;

        let defaultOptions: IItemSelectionFromToOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dummy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: () => {}, // callback to call if value changes
            parameter: {
                prefixFrom: "Selected items:",
                buttonNameFrom: "Select Items From",
                showNotFrom: [],
                prefixTo: "Selected items:",
                buttonNameTo: "Select Items To",
                showOnlyTo: [],
            },
        };
        this.settings = <IItemSelectionFromToOptions>ml.JSON.mergeOptions(defaultOptions, options);

        this.getFieldHandler().setConfig(this.settings);

        // get to types
        let allLinkTypes = globalMatrix.ItemConfig.getCategories();
        let fromTypes = [];
        let toTypes = [];
        for (let idx = 0; idx < allLinkTypes.length; idx++) {
            if (
                this.settings.parameter.showNotFrom.length === 0 ||
                this.settings.parameter.showNotFrom.indexOf(allLinkTypes[idx]) === -1
            ) {
                fromTypes.push({ type: allLinkTypes[idx] });
            }
            if (
                this.settings.parameter.showOnlyTo.length === 0 ||
                this.settings.parameter.showOnlyTo.indexOf(allLinkTypes[idx]) !== -1
            ) {
                toTypes.push({ type: allLinkTypes[idx] });
            }
        }

        this.getFieldHandler().setDefaultSelection(
            toTypes
                .filter((type) => ["SIGN", "DOC", "REPORT"].indexOf(type.type) == -1)
                .map((type) => {
                    return { to: "F-" + type.type + "-1", title: "" };
                }),
        );

        this.getFieldHandler().initData(this.settings.fieldValue);

        this._root.append(super.createHelp(this.settings));

        if (
            !this.settings.canEdit ||
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.Tooltip
        ) {
            this._root.append($("<div class='itemSelectionList'>").append(this.getSelectionString()));
            this._root.data("original", ml.JSON.clone(this.getFieldHandler().getSelectedItems()));
            this._root.data("new", this.getFieldHandler().getSelectedItems());
            return;
        }
        var ctrlContainer = $("<div>").addClass("baseControl").addClass("rowFlex");
        this._root.append(ctrlContainer);

        var selButtonFrom = $("<span>");
        var selectToolsFrom = new ItemSelectionTools();
        selectToolsFrom.renderButtons({
            selectMode: this.settings.preciseSelection ? SelectMode.autoPrecise : SelectMode.auto,
            control: selButtonFrom,
            linkTypes: fromTypes,
            smallbutton: true,
            selectionChange: (newSelection: IReference[]) => {
                this.getFieldHandler().setFromSelectiont(newSelection);
                that._root.data("new", this.getFieldHandler().getSelectedItems());
                selStr.html(that.getSelectionString());
                if (that.settings.valueChanged) {
                    that.settings.valueChanged.apply(null);
                }
            },
            getSelectedItems: async () => {
                return this.getFieldHandler().getSelectedItems().from;
            },
            buttonName: this.settings.parameter.buttonNameFrom,
        });

        let selButtonTo = $("<span>");
        let selectToolsTo = new ItemSelectionTools();
        selectToolsTo.renderButtons({
            selectMode: this.settings.preciseSelection ? SelectMode.autoPrecise : SelectMode.auto,
            control: selButtonTo,
            linkTypes: toTypes,
            smallbutton: true,
            selectionChange: (newSelection: IReference[]) => {
                this.getFieldHandler().setToSelectiont(newSelection);
                that._root.data("new", this.getFieldHandler().getSelectedItems());
                selStr.html(that.getSelectionString());
                if (that.settings.valueChanged) {
                    that.settings.valueChanged.apply(null);
                }
            },
            getSelectedItems: async () => {
                return this.getFieldHandler().getSelectedItems().to;
            },
            buttonName: this.settings.parameter.buttonNameTo,
        });

        let selStr = $("<span class='itemSelectionList'></span>");
        ctrlContainer
            .append($('<div style="float:left;margin-right:20px">').append(selStr))
            .append(selButtonFrom)
            .append(selButtonTo)
            .append($("<div style='clear:both'>"));
        selButtonFrom.css("margin-top:5px");
        selButtonTo.css("margin-top:5px");

        selStr.html(this.getSelectionString());
        this._root.data("original", ml.JSON.clone(this.getFieldHandler().getSelectedItems()));
        this._root.data("new", this.getFieldHandler().getSelectedItems());
    }
    // implement interface
    async hasChangedAsync() {
        return JSON.stringify(this._root.data("original")) !== JSON.stringify(this._root.data("new"));
    }
    async getValueAsync() {
        return JSON.stringify(this._root.data("new"));
    }
    setValue(newValue: string) {
        this._root.data("new", JSON.parse(newValue));
    }

    setValueFrom(itemIds: string[]) {
        this.getFieldHandler().setSelectedItems(<IFromToSelection>this._root.data("new"));
        if (!this.getFieldHandler().getSelectedItems()) {
            this.getFieldHandler().setSelectedItems({ from: [], to: [] });
        }
        this.getFieldHandler().setFromSelectiont(
            itemIds.map((itemId) => {
                return { to: itemId, title: app.getItemTitle(itemId) };
            }),
        );
        this._root.data("new", this.getFieldHandler().getSelectedItems());

        $(".itemSelectionList", this._root).html(this.getSelectionString());
        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }

    setValueTo(itemIds: string[]) {
        this.getFieldHandler().setSelectedItems(<IFromToSelection>this._root.data("new"));
        if (!this.getFieldHandler().getSelectedItems()) {
            this.getFieldHandler().setSelectedItems({ from: [], to: [] });
        }
        this.getFieldHandler().setToSelectiont(
            itemIds.map((itemId) => {
                return { to: itemId, title: app.getItemTitle(itemId) };
            }),
        );
        this._root.data("new", this.getFieldHandler().getSelectedItems());

        $(".itemSelectionList", this._root).html(this.getSelectionString());
        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }
    destroy() {}
    resizeItem() {}
    // private functions

    private getSelectionString() {
        let fromStr = "";
        if (
            !this.getFieldHandler().getSelectedItems().from ||
            this.getFieldHandler().getSelectedItems().from.length === 0
        ) {
            fromStr = this.settings.parameter.prefixFrom + " (none)";
        } else {
            fromStr = ml.Item.refListToDisplayString(
                this.getFieldHandler().getSelectedItems().from,
                this.settings.parameter.prefixFrom,
                100,
            );
        }

        let toStr = "";
        if (
            !this.getFieldHandler().getSelectedItems().to ||
            this.getFieldHandler().getSelectedItems().to.length === 0
        ) {
            toStr = this.settings.parameter.prefixTo + " (none)";
        } else if (this.isDefaultSelection()) {
            toStr = this.settings.parameter.prefixTo + " Default";
        } else {
            toStr = ml.Item.refListToDisplayString(
                this.getFieldHandler().getSelectedItems().to,
                this.settings.parameter.prefixTo,
                100,
            );
        }

        return fromStr + "<br/>" + toStr;
    }

    private isDefaultSelection() {
        return this.getFieldHandler().isDefaultSelection();
    }
}
