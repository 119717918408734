import { IDHFSectionOptions, IDHFControlDefinition, DocumentSectionType, mDHF } from "../../../../common/businesslogic";
import { ml } from "../../../../common/matrixlib";
import { IBaseControlOptions } from "../../../../common/UI/Controls/BaseControl";
import { IReference } from "../../../../globals";
import { ISingleDefaultControllerConfig, SingleSelectBase } from "../../../../SingleSelectBase";
import {
    IItemListOptions,
    ItemListDocFieldHandler,
} from "../../../../common/businesslogic/FieldHandlers/Document/ItemListDocFieldHandler";
import { ItemRefDocFieldHandler } from "../../../../common/businesslogic/FieldHandlers/Document/ItemRefDocFieldHandler";

export { ItemList };

class ItemList extends SingleSelectBase<IItemListOptions> {
    protected isSIGNPackage = false;
    constructor(isPackage: boolean) {
        super();

        this.isSIGNPackage = isPackage;
    }

    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        ctrl.control.itemSelection(
            ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    prefix: this.isSIGNPackage ? "Package the newest SIGNs of" : "Include a list of references to",
                    buttonName: "Select Items",
                    showOnly: this.isSIGNPackage ? ["DOC", "SIGN"] : [],
                    showNot: this.isSIGNPackage ? [] : ["REPORT"],
                },
            }),
        );
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        var controllerConfig = this.getConfig(ctrl);

        var listInfo = $(
            '<select class="p4 docOptionSelect form-control" style="margin:10px;width:80%">' +
                '<option value="p1">Show only items (as flat list, no folders)</option>' +
                '<option value="SORTNUMERICAL">Show only items (as sorted flat list, no folders)</option>' +
                (this.isSIGNPackage
                    ? ""
                    : '<option value="">Show selected input only (no children inside folders)</option>') +
                (this.isSIGNPackage ? "" : '<option value="p2">Show folders and items (as a tree)</option>') +
                (this.isSIGNPackage ? "" : '<option value="p2b">Show folders and items (as a complete tree)</option>') +
                (this.isSIGNPackage ? "" : '<option value="p3">Show folders and items (hide empty folders)</option>') +
                "</select>",
        );

        custom.append(listInfo);

        let options =
            '<div class="checkbox" ><label><input type="checkbox" class="includeInToc" ' +
            (controllerConfig.includeInToc ? "checked" : "") +
            "> Add a reference to included items to table of content</label></div>";
        custom.append($(options));
        // decode dropdown values
        var sel_opt = "";
        if (controllerConfig.tree) {
            sel_opt = controllerConfig.hideEmptyFolders ? "p3" : controllerConfig.showParentFolders ? "p2b" : "p2";
        } else if (controllerConfig.recursive) {
            sel_opt = "p1";
        } else if (controllerConfig.sortedList) {
            sel_opt = "SORTNUMERICAL";
        }
        $(custom.find(".p4")[0]).val(sel_opt);

        this.addSpecificSettings(controllerConfig, custom);
    }
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        var controllerConfig = this.getConfig(ctrl);
        // read values from UI
        var sel_opt = $(custom.find(".p4")[0]).val();
        var recursive = sel_opt === "p1";
        var tree = sel_opt === "p2" || sel_opt === "p3" || sel_opt === "p2b";

        var showParentFolders = sel_opt === "p2b";
        var hideEmptyFolders = sel_opt === "p3";
        var sortedList = sel_opt === "SORTNUMERICAL";
        // check if one of the values changes
        var changed =
            recursive !== controllerConfig.recursive ||
            tree !== controllerConfig.tree ||
            showParentFolders !== controllerConfig.showParentFolders ||
            hideEmptyFolders !== controllerConfig.hideEmptyFolders ||
            controllerConfig.sortedList !== sortedList;
        // set value in config
        controllerConfig.recursive = recursive;
        controllerConfig.tree = tree;
        controllerConfig.hideEmptyFolders = hideEmptyFolders;
        controllerConfig.showParentFolders = showParentFolders;
        controllerConfig.sortedList = sortedList;

        changed = changed || controllerConfig.includeInToc !== $(custom.find(".includeInToc")[0]).prop("checked");
        controllerConfig.includeInToc = $(custom.find(".includeInToc")[0]).prop("checked");

        let specificChanged = this.setSpecificSettings(controllerConfig, custom);

        this.fieldHandler.setDHFConfig(controllerConfig);

        return changed || specificChanged;
    }

    protected config: ISingleDefaultControllerConfig;
}
