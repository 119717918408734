/// <reference types="bootstrap" />
import { GridStack } from "./../../../js/vendor/gridstack_3.2.0/dist/gridstack";
import { ml } from "../common/matrixlib";
import { LineEditorExt, ILineEditorLine } from "../common/UI/ILineEditor";
import { LineType } from "../common/UI/LineType";
import { globalMatrix, matrixSession } from "../globals";
import { IDropdownOption } from "../ProjectSettings";
import { widgetPluginManager, IWidgetScope, IDisplayedWidget, renderMode } from "./WidgetPluginManager";
import { WidgetSettingsManager } from "./WidgetSettingsManager";

export type { IDashboard, IDashboardConfig, IWidgetPluginsContainer };
export { WidgetPluginsContainer };
export { WidgetPluginsConstants };

interface IDashboard {
    displayString: string;
    icon?: string;
}
interface IDashboardConfig {
    dashboards: { [key: string]: IDashboard };
}

class WidgetPluginsConstants {
    static defaultDashboardId: string = "default";
    static defaultDashboard: IDashboard = { displayString: "home" };
}

interface IWidgetPluginsContainer {
    visible: boolean;
    previousUrl: string;
    toggle();
    addNewWidget();
    render(dashboardId: string);
    loadServerSettingWidgets(loadAllUser: boolean);
    exit(destination: string);
    hideWidget(id: string);
    unhide(id: string);
    deleteWidget(displayedWidget: IDisplayedWidget);
    showUpdateShowHiddenButton();
}

class WidgetPluginsContainer implements IWidgetPluginsContainer {
    public visible: boolean;
    private currentDashboard = WidgetPluginsConstants.defaultDashboardId;
    public previousUrl: string;

    toggle() {
        if (!this.visible) {
            this.render(this.currentDashboard);
        } else {
            this.exit(this.previousUrl);
        }
    }
    private _root: JQuery;
    constructor() {}
    addNewWidget() {
        let that = this;
        let editor = new LineEditorExt();

        let lst: IDropdownOption[] = [];
        for (let key in widgetPluginManager.pluginList) {
            let widget = widgetPluginManager.pluginList[key];
            if (
                widget.defaultParameters().canBeAddedOrDeletedBy == IWidgetScope.superAdmin &&
                !matrixSession.isSuperAdmin()
            ) {
                continue;
            }
            if (widget.defaultParameters().canBeAddedOrDeletedBy == IWidgetScope.admin && !matrixSession.isAdmin()) {
                continue;
            }

            let display = (widget.help ?? "") + "   [" + key + "]";
            lst.push({ id: key, label: display });
        }

        let defaultVal = lst.length > 0 ? lst[0].id : "";

        let lines: ILineEditorLine[] = [
            { help: "Type", type: LineType.select, required: true, value: defaultVal, options: lst },
        ];
        let editorTitle = "Add a widget";
        if (matrixSession.isAdmin() && globalMatrix.globalShiftDown) {
            editorTitle = "Add a widget for specific users";
            lines.push({ help: "Add for other user?", type: LineType.boolean, required: false, value: "false" });
        }

        let selectedPlugin: any;
        let newWidget: IDisplayedWidget;
        editor
            .showDialog(editorTitle, 400, lines, (updated) => {
                selectedPlugin = Object.create(window[updated[0].value].prototype);
                selectedPlugin.constructor.apply();

                let params = selectedPlugin.defaultParameters();
                let selectedPluginIndex = updated[0].options.findIndex((i) => {
                    return i.id == updated[0].value;
                });
                params.options.title = updated[0].options[selectedPluginIndex].label;
                params.position.w = 2;
                params.position.h = 2;
                params.position.dashboard = this.currentDashboard;

                newWidget = {
                    id: `widget_${updated[0].value}_${new Date().getTime().toString()}`,
                    pluginName: updated[0].value,
                    parameters: params,
                    createdBy: matrixSession.getUser(),
                };

                if (updated.length == 2 && updated[1].value != undefined && updated[1].value != "") {
                    newWidget.parameters.users = ["*"];
                    newWidget.parameters.options.canBeHidden = updated[1].value.toString() == "true";
                }
                WidgetSettingsManager.setSettings(newWidget);

                let widget = that.grid.addWidget({
                    w: Math.max(params.position.w, 1),
                    h: Math.max(params.position.h, 1),
                });

                selectedPlugin.render($(".grid-stack-item-content", widget), newWidget, renderMode.placeholder);

                widgetPluginManager.addLoadedWidget(newWidget.id, selectedPlugin);

                return true;
            })
            .done(() => {
                selectedPlugin.showWidgetSettingEditor(newWidget);
            });
    }
    private grid: any;
    render(dashboardId = WidgetPluginsConstants.defaultDashboardId) {
        let conf = matrixSession.getDashboardConfig();
        if (conf.dashboards[dashboardId] == null) {
            ml.UI.showError("Dashboard not found", "");
        }
        let dashboard = conf.dashboards[dashboardId];

        widgetPluginManager.unLoadAllWidgets();
        $("#widgetDashboard").remove();
        $("#addWidget").remove();
        $(".dashboardTitle").remove();
        $("#showHiddenWidgetButton").remove();
        this.previousUrl = window.location.href;

        history.pushState(null, null, globalMatrix.matrixBaseUrl + "/?dashboard=" + dashboardId);

        this.currentDashboard = dashboardId;
        this.visible = true;
        let that = this;
        $("#highlightContext").hide();
        $("#contextframe").hide();
        $("#contextframesizer").hide();

        $("body").append(`<div id='addWidget'><span class="fa-stack ">
                            <i class="fal fa-sticky-note fa-stack-2x"></i>
                            <i class="fa fa-plus fa-stack" style=""></i>
                        </span> </div> `);

        this._root = $("<div id='widgetDashboard'> <div class='grid-stack'> </div></div>");
        this._root.insertAfter("header");
        $("#widgetDashboard").prepend(ml.UI.getPageTitle(dashboard.displayString));

        var bClose = $(
            "<div  class='btn-group btn-dashboard-close' ><button title data-original-title='Close' style='margin-right: -12px;' class='btn btn-item btn-fullscreen'> <span class='fal fa-times-circle'></span></button></div>",
        ).click(function () {
            that.exit(that.previousUrl);
        });
        $("#widgetDashboard .toolbarButtons").append(bClose);

        widgetPluginManager.unLoadAllWidgets();

        this.grid = GridStack.init();

        let layout = "none";

        let width = document.body.clientWidth;

        if (width < 800) {
            that.grid.column(1, layout);
        } else {
            that.grid.column(12, layout);
        }

        let i = 0;

        matrixSession.serverConfig.currentUserSettings.forEach((userSetting) => {
            if (userSetting.key.indexOf("widget_") == 0) {
                let w: IDisplayedWidget = JSON.parse(userSetting.value);

                let plugin = widgetPluginManager.pluginList[w.pluginName];

                if (plugin && w.parameters.position.dashboard == this.currentDashboard) {
                    let clonedPlugin = Object.create(window[w.pluginName].prototype);
                    clonedPlugin.constructor.apply();
                    w.parameters = plugin.mergeOptions(w.parameters);

                    widgetPluginManager.addLoadedWidget(userSetting.key, clonedPlugin);
                    let gridWidget = this.grid.addWidget({
                        w: w.parameters.position.w,
                        h: w.parameters.position.h,
                        x: w.parameters.position.x,
                        y: w.parameters.position.y,
                    });
                    clonedPlugin.render($(".grid-stack-item-content", gridWidget), w, renderMode.placeholder);
                } else {
                    console.log("Unknown plugin");
                }
            }
        });

        that.loadServerSettingWidgets();

        let hiddenWidget = WidgetSettingsManager.getHiddenWidgets();
        hiddenWidget.forEach((w) => {
            let p = widgetPluginManager.loadedWidgets[w];
            if (p != undefined) {
                p.hide(false);
            }
        });

        $("#addWidget").click(() => {
            that.addNewWidget();
        });
        $("#addWidget").hover(() => {
            $("#addWidget").tooltip(<TooltipOptions>{
                title: matrixSession.isAdmin()
                    ? "Add a widget (use shift to add it for somebody else)"
                    : "Add a widget",
                placement: "auto",
                container: "body",
            });
        });

        this.showUpdateShowHiddenButton();

        this.grid.on("change", function (event: Event, items: any[]) {
            setTimeout(() => {
                if (window.innerWidth > 700) {
                    items.forEach(function (item) {
                        let id = $(".mWidget", item.el).prop("id");

                        let widget = widgetPluginManager.loadedWidgets[id];
                        if (widget != undefined) {
                            widget.updatePosition(item.w, item.h, item.x, item.y);
                        }
                    });
                }
            }, 100);
        });

        this._root.prop("style", "");
    }
    loadServerSettingWidgets(loadAllUser = false) {
        matrixSession.serverConfig.customerSettings.forEach((setting) => {
            if (setting.key.indexOf("widget_") == 0 && widgetPluginManager.loadedWidgets[setting.key] == undefined) {
                let w: IDisplayedWidget = JSON.parse(setting.value);
                let plugin = widgetPluginManager.pluginList[w.pluginName];

                if (
                    plugin &&
                    w.parameters.users != undefined &&
                    (w.parameters.users.indexOf("*") != -1 ||
                        w.parameters.users.indexOf(matrixSession.getUser()) != -1 ||
                        (loadAllUser && matrixSession.isAdmin()))
                ) {
                    // Check for position override
                    let pos = localStorage.getItem(w.id);
                    if (pos != undefined && pos != "") {
                        let position = JSON.parse(pos);
                        w.parameters.position = position;
                    }
                    let clonedPlugin = Object.create(window[w.pluginName].prototype);
                    clonedPlugin.constructor.apply();
                    w.parameters = plugin.mergeOptions(w.parameters);
                    if (w.parameters.position.dashboard == this.currentDashboard) {
                        widgetPluginManager.addLoadedWidget(setting.key, clonedPlugin);
                        let gridWidget = this.grid.addWidget({
                            w: w.parameters.position.w,
                            h: w.parameters.position.h,
                            x: w.parameters.position.x,
                            y: w.parameters.position.y,
                            noMove: !matrixSession.isAdmin(),
                            noResize: !matrixSession.isAdmin(),
                        });
                        clonedPlugin.render($(".grid-stack-item-content", gridWidget), w, renderMode.placeholder);

                        if (
                            matrixSession.isAdmin() &&
                            w.parameters.users &&
                            w.parameters.users.indexOf("*") == -1 &&
                            w.parameters.users.indexOf(matrixSession.getUser()) == -1
                        ) {
                            $(".grid-stack-item-content", gridWidget).css("opacity", 0.4);
                        }
                        $(".grid-stack-item-content", gridWidget).addClass("widgetAddedByAdmin");
                    }
                } else {
                    console.log("Unknown plugin");
                }
            }
        });
    }
    exit(destination: string) {
        this.visible = false;
        if (destination == null || destination.indexOf(globalMatrix.matrixBaseUrl + "/?dashboard=") == 0) {
            if (localStorage.getItem("SessionLastProject") != null)
                window.location.href = globalMatrix.matrixBaseUrl + localStorage.getItem("SessionLastProject");
            else window.location.href = globalMatrix.matrixBaseUrl;
        } else {
            history.pushState(null, null, destination);

            $("#highlightContext").show();
            $("#contextframe").show();
            $("#contextframesizer").show();
            widgetPluginManager.unLoadAllWidgets();
            $("#widgetDashboard").remove();
            $("#addWidget").remove();
            $("#showHiddenWidgetButton").remove();
            $(".dashboardTitle").remove();
        }
    }
    hideWidget(id: string) {
        $(".grid-stack-item:has(#" + id + ")").hide();
        this.showUpdateShowHiddenButton();
    }

    unhide(id: string) {
        $(".grid-stack-item:has(#" + id + ")").show();
        this.showUpdateShowHiddenButton();
    }
    deleteWidget(displayedWidget: IDisplayedWidget) {
        WidgetSettingsManager.unhide(displayedWidget);
        $(".grid-stack-item:has(#" + displayedWidget.id + ")").remove();
        this.showUpdateShowHiddenButton();
    }
    showUpdateShowHiddenButton() {
        let that = this;
        if (WidgetSettingsManager.getHiddenWidgets().length > 0) {
            if ($("#showHiddenWidgetButton").length == 0) {
                $("body").append(`<div id='showHiddenWidgetButton'><span class="fa-stack ">
                <i class="fal fa-eye-slash fa-stack-2x"></i>
                <i class="fal fa-toggle-on" style=""></i>
                    </span> </div> `);
                $("#showHiddenWidgetButton").hover(() => {
                    $("#showHiddenWidgetButton").tooltip(<TooltipOptions>{
                        title: "Toggle hidden widget",
                        placement: "top",
                        container: "body",
                    });
                });
                $("#showHiddenWidgetButton").click(() => {
                    if (globalMatrix.globalShiftDown) {
                        that.loadServerSettingWidgets(true);
                    }
                    WidgetSettingsManager.getHiddenWidgets().forEach((widget) => {
                        $(".grid-stack-item:has(#" + widget + ")").toggle();
                    });
                });
            }
        } else {
            $("#showHiddenWidgetButton").remove();
        }
    }
}
