import { app, globalMatrix, matrixSession, restConnection } from "../../../globals";
import { XRGetProject_Tags_TagList } from "../../../RestResult";
import { ml } from "../../matrixlib";
import { IPanel } from "../Application";

export { TagPanel };

class TagPanel implements IPanel {
    private control: JQuery;
    title = "Project Tags";
    constructor() {
        this.control = app.itemForm;
        document.title = this.title + " - " + matrixSession.getProject();
        this.control.prepend(ml.UI.getPageTitle(this.title));

        this.render();
    }

    destroy() {}

    render() {
        let container = $("<div class='controlContainer'>");
        let table = $("<table class='table table-bordered' style='border:1px solid #ddd;'>");
        let thead = $("<thead>").appendTo(table);
        let tbody = $("<tbody>").appendTo(table);

        var outerbody = $("<div class='panel-body-v-scroll fillHeight'>");
        var body = $("<div class='panel-body'>");

        this.control.append(outerbody);
        if (ml.UI.DateTime.requiresTimeZoneWarning()) {
            $(".toolbarButtons").append(ml.UI.DateTime.getTimeZoneCTA());
        }
        outerbody.append(body);
        body.append(container);
        container.append(table);

        this.control.append(outerbody).css("border", "none");
        $("<tr>")
            .appendTo(thead)
            .append($("<th>").html("type"))
            .append($("<th>").html("label"))
            .append($("<th>").html("comments"))
            .append($("<th>").html("tag time"))
            .append($("<th>").html("tag creation time"))
            .append($("<th>").html("user"));

        restConnection
            .getProject("tag")
            .done(function (result) {
                $.each(result as XRGetProject_Tags_TagList, function (idx, tag) {
                    var row = $("<tr>");
                    row.append($("<td>").html(tag.tagType));
                    row.append($("<td>").html(tag.label));
                    row.append($("<td>").html(tag.comments));
                    row.append($("<td>").html(ml.UI.DateTime.renderHumanDate(new Date(tag.auditTime))));
                    row.append($("<td>").html(ml.UI.DateTime.renderHumanDate(new Date(tag.tagCreation))));
                    row.append($("<td>").html(tag.userLogin));
                    tbody.append(row);
                });
                table.highlightReferences();
                table.tablesorter({ sortList: [[3, 0]] });
            })
            .fail(function () {});
    }
}
