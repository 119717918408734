import {
    ColumnEditor,
    FieldHandlerFactory,
    IDHFPasteBuffer,
    IExternalItem,
    IPlugin,
    ISearchResult,
    mDHF,
    plugins,
    Tasks,
} from "../../common/businesslogic/index";
import { ml } from "../../common/matrixlib";
import { ILabelTools, UIToolsConstants } from "../../common/matrixlib/MatrixLibInterfaces";
import { ItemControl, SelectMode } from "../../common/UI/Components/index";
import { BaseControl, IBaseControlOptions } from "../../common/UI/Controls/BaseControl";
import { HTMLAnnotator, IReviewData } from "../../common/UI/Controls/docReview";
import { IDropdownParams } from "../../common/UI/Controls/dropdown";
import { ITableControlOptions } from "../../common/UI/Controls/tableCtrl";
import { HistoryTools } from "../../common/UI/Tools/ItemHistoryView";
import { ItemSelectionTools } from "../../common/UI/Tools/ItemSelectionView";

import { ReviewContextFrame } from "./ReviewContextFrame";
import {
    app,
    ControlState,
    globalMatrix,
    IGenericMap,
    IItem,
    IItemGet,
    IItemGetMap,
    IItemPut,
    IReference,
    IStringMap,
    IStringNumberMap,
    IStringStringArrayMap,
    matrixApplicationUI,
    matrixSession,
} from "../../globals";
import { FieldDescriptions } from "../../common/businesslogic/FieldDescriptions";
import { GenericFieldHandler } from "../../common/businesslogic/FieldHandlers/GenericFieldHandler";
import {
    IItemReviews,
    IReviewConfig,
    IReviewControlOptions,
    ITableReviewData,
    ReviewControlColumns,
} from "./ScheduleReviewDefines";
import { IDropdownOption, IFieldDescription, ILabelLockConfig } from "../../ProjectSettings";

export function initialize() {
    //No thing to do really...
    plugins.register(new ReviewControlPlugin());
}

const reviewOptionsSetting = "dd_reviewOptions";

export class ReviewControlPlugin implements IPlugin {
    static fieldType = FieldDescriptions.Field_reviewControl;
    public isDefault: boolean = true;
    supportsControl(fieldType: string): boolean {
        return fieldType === ReviewControlPlugin.fieldType;
    }

    createControl(ctrl: JQuery, options: IBaseControlOptions) {
        ctrl.reviewControl(options);
    }

    getFieldConfigOptions(): IFieldDescription[] {
        return [
            {
                id: ReviewControlPlugin.fieldType,
                label: "Design Review Table [" + ReviewControlPlugin.fieldType + "]",
                capabilities: {
                    canImportedFromExcel: true,
                    onlyOne: true,
                    canBeXtcPreset: false,
                    canBePublished: false,
                    canBeReadonly: true,
                    canHideInDoc: false,
                    needsConfiguration: true,
                    canBeUnsafe: true,
                },
                class: "all",
                help: "table control to simplify design reviews",
            },
        ];
    }

    addFieldSettings(
        configApp: any,
        project: string,
        pageId: string,
        fieldType: string,
        fieldParams: IReviewConfig,
        ui: JQuery,
        paramChanged: () => void,
    ) {
        let that = this;

        if (fieldType != ReviewControlPlugin.fieldType) {
            return;
        }

        if (!matrixSession.isReview()) {
            ui.html('<p style="color:red">Review module not licensed</p>');
            return;
        }

        // check if there's a review dropdown, if not create it
        let dd: IDropdownParams = globalMatrix.ItemConfig.getSettingJSON(reviewOptionsSetting);
        if (!dd || !dd.options) {
            // create the setting with default values!
            ml.Logger.log("warn", "No configuration of dropdown with review results exists, creating it now....");
            dd = {
                options: [
                    {
                        id: "inprogress",
                        label: "in progress",
                        class: "inprogress",
                    },
                    {
                        id: "fail",
                        label: "Failed",
                        class: "failed",
                    },
                    {
                        id: "pass",
                        label: "Passed",
                        class: "passed",
                    },
                ],
                groups: [
                    {
                        value: "passed",
                        label: "passed",
                    },
                    {
                        value: "failed",
                        label: "failed",
                    },
                    {
                        value: "inprogress",
                        label: "in progress",
                    },
                ],
            };
            configApp.setJSONProjectSettingAsync(
                project,
                {
                    id: reviewOptionsSetting,
                    value: dd,
                },
                pageId,
            );
            globalMatrix.ItemConfig.setSettingJSON(reviewOptionsSetting, dd);
        }

        if (typeof fieldParams.showAnnotations == "undefined") fieldParams.showAnnotations = true;
        if (typeof fieldParams.showHistory == "undefined") fieldParams.showHistory = true;
        if (typeof fieldParams.showInline == "undefined") fieldParams.showInline = false;
        if (typeof fieldParams.showVersions == "undefined") fieldParams.showVersions = true;
        if (typeof fieldParams.showComments == "undefined") fieldParams.showComments = true;

        ml.UI.SelectUserOrGroup.getAllUsersAndGroups().done((userDropdown: IDropdownOption[]) => {
            let grouping = [
                { value: "groups", label: "groups" },
                { value: "users", label: "users" },
            ];

            // label options
            let lls = <ILabelLockConfig>globalMatrix.ItemConfig.getSettingJSON("lockingLabels");
            let lockingLabels =
                lls && lls.locks
                    ? lls.locks.map(function (lock) {
                          return { id: lock.label, label: lock.label };
                      })
                    : [];
            let lt = ml.CreateNewLabelTools();
            let allLabels = lt.getLabelDefinitions(null).map(function (ld) {
                return { id: ld.label, label: ld.label };
            });
            // drop down options
            let dds = globalMatrix.ItemConfig.getDropDowns();
            let allDropdowns = dds.map(function (dd) {
                return { id: dd.id, label: dd.label };
            });

            if (!fieldParams.doneLabel) fieldParams.doneLabel = <any>{ buttonName: "set progress labels" };
            if (!fieldParams.lockLabel) fieldParams.lockLabel = <any>{ buttonName: "set lock labels" };
            if (!fieldParams.mailTo) fieldParams.mailTo = <any>{ buttonName: "send mail" };
            if (!fieldParams.tasks) fieldParams.tasks = <any>{ buttonName: "create tasks" };
            if (!fieldParams.statusDropdown) fieldParams.statusDropdown = reviewOptionsSetting;

            ml.UI.addCheckbox(
                ui,
                "allow selecting user groups as reviewer",
                fieldParams,
                "allowSelectUserGroups",
                paramChanged,
            );
            ml.UI.addCheckbox(ui, "show version number of reviewed items", fieldParams, "showVersions", paramChanged);
            ml.UI.addCheckbox(ui, "allow text annotations", fieldParams, "showAnnotations", paramChanged);

            ml.UI.addCheckbox(
                ui,
                "automatically lock items when review is saved for the first time",
                fieldParams,
                "autoLock",
                paramChanged,
            );

            ml.UI.addCheckbox(
                ui,
                "include review id in change comment when setting labels",
                fieldParams,
                "autoComment",
                paramChanged,
            );

            let dummy = { method: "0" };
            if (fieldParams.showHistory) {
                dummy.method = "1";
                if (fieldParams.showHistoryOutOfDate) dummy.method = "2";
                if (fieldParams.showHistoryOutOfDateBeforeDone) dummy.method = "3";
            }
            ml.UI.addDropdownToValue(
                ui,
                "show history button",
                dummy,
                "method",
                [
                    { id: "0", label: "don't show button" },
                    { id: "1", label: "show button" },
                    { id: "2", label: "show button - indicate if items have changed" },
                    {
                        id: "3",
                        label: "show button - indicate if items have changed, unless review is done",
                    },
                ],
                false,
                false,
                () => {
                    switch (dummy.method) {
                        case "0":
                            fieldParams.showHistory = false;
                            fieldParams.showHistoryOutOfDate = false;
                            fieldParams.showHistoryOutOfDateBeforeDone = false;
                            break;
                        case "1":
                            fieldParams.showHistory = true;
                            fieldParams.showHistoryOutOfDate = false;
                            fieldParams.showHistoryOutOfDateBeforeDone = false;
                            break;
                        case "2":
                            fieldParams.showHistory = true;
                            fieldParams.showHistoryOutOfDate = true;
                            fieldParams.showHistoryOutOfDateBeforeDone = false;
                            break;
                        case "3":
                            fieldParams.showHistory = true;
                            fieldParams.showHistoryOutOfDate = false;
                            fieldParams.showHistoryOutOfDateBeforeDone = true;
                            break;
                    }
                    paramChanged();
                },
            );
            ml.UI.addCheckbox(ui, "allow showing items in review table", fieldParams, "showInline", paramChanged);
            ml.UI.addCheckbox(
                ui,
                "auto enable context view if item cell is selected",
                fieldParams,
                "autoshowContext",
                paramChanged,
            );
            let showC = ml.UI.addCheckbox(ui, "show comment column", fieldParams, "showComments", paramChanged);
            let confC = ml.UI.addCheckbox(
                ui,
                "append new comments at end of comment list",
                fieldParams,
                "appendComments",
                paramChanged,
            );
            ml.UI.enableIf(showC, true, [confC]);

            ml.UI.addDropdownToValue(
                ui,
                "review result dropdown",
                fieldParams,
                "statusDropdown",
                allDropdowns,
                false,
                true,
                paramChanged,
                "select dropdown definition",
            );

            let cb = ml.UI.addCheckbox(
                ui,
                "allow to modify review content",
                fieldParams,
                "canBeModified",
                paramChanged,
            );
            let mb = ml.UI.addDropdownToArray(
                ui,
                "who can modify (empty for all)",
                fieldParams,
                "canBeModifiedBy",
                userDropdown,
                grouping,
                1000,
                false,
                true,
                paramChanged,
                "select user",
            );
            ml.UI.enableIf(cb, true, [mb]);
            mb.css("margin", "0 0 0 24px"); // indent dropdown under checkbox
            $("<h1>Connection to task management tool</h1>").appendTo(ui);
            ml.UI.addTextInput(ui, "button name", fieldParams.tasks, "buttonName", paramChanged);

            ml.UI.addDropdownToArray(
                ui,
                "users (empty for all)",
                fieldParams.tasks,
                "users",
                userDropdown,
                grouping,
                1000,
                false,
                true,
                paramChanged,
                "select user",
            );
            ml.UI.addTextInput(ui, "task plugin id", fieldParams.tasks, "taskPluginId", paramChanged);
            ml.UI.addTextInput(ui, "project id", fieldParams.tasks, "taskProject", paramChanged);
            ml.UI.addTextInput(ui, "ticket type", fieldParams.tasks, "taskIssueType", paramChanged);
            ml.UI.addTextInput(ui, "task description", fieldParams.tasks, "taskDescription", paramChanged);
            $("<h1>Actions to lock</h1>").appendTo(ui);
            ml.UI.addTextInput(ui, "button name", fieldParams.lockLabel, "buttonName", paramChanged);
            ml.UI.addDropdownToValue(
                ui,
                "lock label",
                fieldParams.lockLabel,
                "label",
                lockingLabels,
                false,
                true,
                paramChanged,
                lockingLabels.length ? "select lock - or keep empty to disable" : "no locks defined!",
            );
            ml.UI.addDropdownToArray(
                ui,
                "users (empty for all)",
                fieldParams.lockLabel,
                "users",
                userDropdown,
                grouping,
                1000,
                false,
                true,
                paramChanged,
                "select user",
            );
            $("<h1>Action to set progress labels</h1>").appendTo(ui);
            ml.UI.addTextInput(ui, "button name", fieldParams.doneLabel, "buttonName", paramChanged);
            ml.UI.addDropdownToArray(
                ui,
                "users (empty for all)",
                fieldParams.doneLabel,
                "users",
                userDropdown,
                grouping,
                1000,
                false,
                true,
                paramChanged,
                "select user",
            );
            ml.UI.addDropdownToValue(
                ui,
                "passed items",
                fieldParams.doneLabel,
                "passedLabel",
                allLabels,
                false,
                true,
                paramChanged,
                "select labels - or keep empty to disable",
            );
            ml.UI.addDropdownToValue(
                ui,
                "failed items",
                fieldParams.doneLabel,
                "failedLabel",
                allLabels,
                false,
                true,
                paramChanged,
                "select labels - or keep empty to disable",
            );
            ml.UI.addDropdownToValue(
                ui,
                "to do items",
                fieldParams.doneLabel,
                "todoLabel",
                allLabels,
                false,
                true,
                paramChanged,
                "select labels - or keep empty to disable",
            );
            $("<h1>Send Mail</h1>").appendTo(ui);
            ml.UI.addTextInput(ui, "button name", fieldParams.mailTo, "buttonName", paramChanged);
            ml.UI.addTextInput(ui, "subject", fieldParams.mailTo, "mailSubject", paramChanged);
            ml.UI.addDropdownToArray(
                ui,
                "users (empty for all)",
                fieldParams.mailTo,
                "users",
                userDropdown,
                grouping,
                1000,
                false,
                true,
                paramChanged,
                "select user",
            );

            // enable the save button (at least first time the control is configured)
            paramChanged();
        });
    }
}

$.fn.reviewControl = function (this: JQuery, options: IReviewControlOptions) {
    if (!options.fieldHandler) {
        //No need for a field handler here, so let's create a dummy one.
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_reviewControl,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new ReviewControlImpl(this, options.fieldHandler as GenericFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

export class ReviewControlImpl extends BaseControl<GenericFieldHandler> {
    static reviewOptionsSetting = "dd_reviewOptions";

    private settings: IReviewControlOptions;
    private reviewItems: string[];
    private reviewUsers: string[];
    private forceNewTable: boolean;
    private currentVersions: ISearchResult[];
    private outOfDateInfo: IStringNumberMap;
    private reviewTable: JQuery;
    private expanded: boolean[] = [];
    private expandDetails: string[] = [];
    private expandSaveTimeout: number;
    private readonly: boolean = false;
    private texts: IItemGetMap = {};
    private isCommenting: boolean;

    private callback: () => void;

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    static defaultOptions: IReviewControlOptions = {
        controlState: ControlState.FormView, // read only rendering
        canEdit: false, // whether data can be edited
        fieldValue: "", //
        parameter: {
            readonly: false, // can be set to overwrite the default readonly status
        },
    };

    init(options: IReviewControlOptions) {
        this.texts = {};

        this.settings = <IReviewControlOptions>ml.JSON.mergeOptions(ReviewControlImpl.defaultOptions, options);

        if (this.settings.parameter.hide_UI) {
            this._root.hide();
        }
        if (typeof this.settings.parameter.showComments == "undefined") {
            this.settings.parameter.showComments = true;
        }
        this.init2();
        this.needsLatest = true;

        // Create an callback function that can be saved for unsubscribe
        this.callback = () => {
            if (this.settings.parameter.autoLock) {
                this.reviewPostSaveFct();
            }
        };
        // @ts-ignore
        MR1.onAfterSave().subscribe(this, this.callback);
    }

    destroy() {
        // Unsubscribe from the afterSave callback
        // @ts-ignore
        MR1.onAfterSave().unsubscribe(this.callback);
    }

    private reviewPostSaveFct = () => {
        let versions = this.currentVersions?.map((v) => v.itemId);
        if (versions == null) {
            return;
            // If currentVersions == null then this is not a create but a save
            // The code below would work on save but that is not the intention
            // const data = this.fieldHandler.getData();
            // if (data == null || data == "") {
            //     console.log("DEBUG EMPTY DATA");
            //     return;
            // }
            // const items = JSON.parse(data)
            // console.log("DEBUG POST ITEMS", items)
            // if (items == null || items.reviewtable == null) {
            //     console.log("DEBUG EMPTY JSON", items)
            //     return;
            // }
            // versions = items.reviewtable.map(f => {
            //     const display:string = f.reviewitem;
            //     return display.substring(0, display.indexOf("!"))
            // })
        }
        this.lockItems(versions);
    };

    updateControl() {
        this.destroy();
        this._root.html("");
        this.init2();
    }

    highlightReferences() {
        // Only in cell, not in header
        $(this._root).find(".grid-canvas").highlightReferences();
    }

    init2() {
        let that = this;

        if (!matrixSession.isReview()) {
            this._root.html('<p style="color:red">Review module not licensed</p>');
            return;
        }
        let dd = ReviewControlImpl.getDropdownParams(this.settings.fieldId);
        if (!dd || !dd.options) {
            $('<span class="baseControlHelp">').appendTo(this._root).html(this.settings.help);
            $('<div><span class="warnNoEdit" style="">no valid configuration</span></div>').appendTo(this._root);
            return;
        }

        this.readonly =
            this.forceNewTable ||
            (this.settings.parameter && this.settings.parameter.readonly) ||
            !this.settings.canEdit ||
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.DialogEdit ||
            this.settings.controlState === ControlState.Tooltip ||
            this.settings.controlState === ControlState.HistoryView ||
            this.settings.controlState === ControlState.DialogCreate;

        this._root.addClass("reviewControl");

        let actions = $("<div class='controlContainer'>").appendTo(this._root);

        if (!this.settings.fieldValue || this.settings.fieldValue == "{}") {
            if (!this.readonly) {
                that.renderReviewInput(this._root, false);
            }
        } else {
            let tableData = (<ITableReviewData>JSON.parse(this.settings.fieldValue)).reviewtable;

            if (!this.readonly) {
                let hasTools = false;
                $('<div class="baseControlHelp">' + this.settings.help + " - Review Tools</div>").appendTo(actions);
                let buttons = $("<div class='baseControl rowFlex'>").appendTo(actions);

                // create button to set a locking label
                if (this.settings.parameter.canBeModified) {
                    hasTools = true;
                    let enabled =
                        !this.settings.parameter.canBeModifiedBy ||
                        this.settings.parameter.canBeModifiedBy.length == 0 ||
                        matrixSession.amIAllowedUser(this.settings.parameter.canBeModifiedBy);
                    let changeReviewContainer = $("<span>").appendTo(buttons);
                    $(
                        '<button id="changeReview" class="buttonCreateSelect btn btn-success" ' +
                            (enabled ? "" : "disabled") +
                            ">Edit users / items</button>",
                    )
                        .click(function () {
                            that.editReview();
                        })
                        .appendTo(changeReviewContainer);
                }

                // create button to set a locking label
                if (this.settings.parameter.lockLabel && this.settings.parameter.lockLabel.label) {
                    hasTools = true;
                    let enabled =
                        !this.settings.parameter.lockLabel.users ||
                        this.settings.parameter.lockLabel.users.length == 0 ||
                        matrixSession.amIAllowedUser(this.settings.parameter.lockLabel.users);

                    $(
                        '<button class="buttonCreateSelect btn btn-success" ' +
                            (enabled ? "" : "disabled") +
                            ">" +
                            that.settings.parameter.lockLabel.buttonName +
                            "</button>",
                    )
                        .click(function () {
                            that.lockItems(that.getItems());
                        })
                        .appendTo(buttons);
                }
                // create button to set a review done label
                if (
                    this.settings.parameter.doneLabel &&
                    (this.settings.parameter.doneLabel.failedLabel ||
                        this.settings.parameter.doneLabel.todoLabel ||
                        this.settings.parameter.doneLabel.passedLabel)
                ) {
                    hasTools = true;
                    let enabled =
                        !this.settings.parameter.doneLabel.users ||
                        this.settings.parameter.doneLabel.users.length == 0 ||
                        matrixSession.amIAllowedUser(this.settings.parameter.doneLabel.users);

                    $(
                        '<button class="buttonCreateSelect btn btn-default" ' +
                            (enabled ? "" : "disabled") +
                            ">" +
                            that.settings.parameter.doneLabel.buttonName +
                            "</button>",
                    )
                        .click(function () {
                            that.setItemReviewStatusLabel(that.getItems());
                        })
                        .appendTo(buttons);
                }

                // create button to make new task using ticketing plugin
                if (this.settings.parameter.tasks && this.settings.parameter.tasks.taskPluginId) {
                    hasTools = true;
                    let enabled =
                        !this.settings.parameter.tasks.users ||
                        this.settings.parameter.tasks.users.length == 0 ||
                        matrixSession.amIAllowedUser(this.settings.parameter.tasks.users);

                    $(
                        '<button class="buttonCreateSelect btn btn-default" ' +
                            (enabled ? "" : "disabled") +
                            ">" +
                            that.settings.parameter.tasks.buttonName +
                            "</button>",
                    )
                        .click(function () {
                            $.each(
                                $("li", matrixApplicationUI.lastMainItemForm.getControls("tasksControl")[0]),
                                function (lidx, li) {
                                    if ($(li).text() == "no tasks linked") $(li).remove();
                                },
                            );

                            that.createTasks(ReviewControlImpl.getReviewers(tableData), [that.settings.item.id]).done(
                                function () {
                                    var ctrls = matrixApplicationUI.lastMainItemForm.getControls("tasksControl");
                                    $.each(ctrls, function (idx, ctrl) {
                                        Tasks.showTasks(app.getCurrentItemId(), ctrl, !that.readonly);
                                    });
                                },
                            );
                        })
                        .appendTo(buttons);
                }

                // create button to send reminder email
                if (this.settings.parameter.mailTo) {
                    hasTools = true;
                    let enabled =
                        !this.settings.parameter.mailTo.users ||
                        this.settings.parameter.mailTo.users.length == 0 ||
                        matrixSession.amIAllowedUser(this.settings.parameter.mailTo.users);
                    $(
                        '<button class="buttonCreateSelect btn btn-default" ' +
                            (enabled ? "" : "disabled") +
                            ">" +
                            that.settings.parameter.mailTo.buttonName +
                            "</button>",
                    )
                        .click(function () {
                            that.sendReminder();
                        })
                        .appendTo(buttons);
                }

                if (!hasTools) {
                    actions.hide();
                }
            }

            let container = $("<div>").appendTo(this._root);
            this.showTable(container);

            if (this.settings.parameter.createDoc && this.settings.parameter.createDoc.template) {
                $(
                    '<button class="buttonCreateSelect btn btn-default" style="margin-bottom:12px;">Create Document</button>',
                )
                    .click(function () {
                        that.copyDetails();
                    })
                    .appendTo(this._root);
            }
        }
    }

    private copyDetails() {
        let that = this;

        let tableData = (<ITableReviewData>JSON.parse(this.settings.fieldValue)).reviewtable;
        let details = $("<div style='position:absolute;top:0;left:-10000px;width:1000px;height:100%'>").appendTo(
            $("body"),
        );
        let render = $("<div>").appendTo(details);

        ml.UI.BlockingProgress.Init([{ name: "Generating document" }]);

        this.copyDetail(render, tableData, 0).done(function () {
            // in case there's something sections to hide / hide them (including  possible comments)
            if (that.settings.parameter.createDoc.hide) {
                for (let sec of that.settings.parameter.createDoc.hide) {
                    $(".ft_" + sec, details).remove();
                }
            }
            // remove bad headings
            $("div.itemTitle", details).each((idx, itemTitle) => {
                let id = $(".refIdHyper", $(itemTitle)).text();
                let newId = $("<h2>" + id + "!</h2>");
                that.copyAnnotations($(itemTitle), newId);
                $(itemTitle).replaceWith(newId);
            });
            // remove other bad links
            $(".refIdHyper", details).each((idx, itemId) => {
                let id = $(itemId).text();
                let newId = $("<span>" + id + "!</span>");
                let parent = $(itemId).closest("a").parent();
                that.copyAnnotations(parent, newId);
                parent.replaceWith(newId);
            });
            $(".baseControlHelp").each((i, bch) => {
                $(bch)
                    .removeClass("baseControlHelp")
                    .attr(
                        "style",
                        "font-size: 12px; font-weight: 700;color: #a9b3b8;text-transform: uppercase;letter-spacing: 1px;left: 0!important;margin:24px 0;",
                    );
            });
            // make annotater headings printable
            $(".annotator-hl", details).css("background-color", "aliceblue");
            // remove annotater tools
            $(".annotator-outer", details).remove();
            $(".annotator-adder", details).remove();
            $(".slickTable", details).each((idx, table) => that.replaceSlickTables($(table)));
            that.fixInputs(details);
            // final cleanup (get rid of lot's of markup)
            let panels = $(".panel-body-v-scroll", details);
            panels.each((i, old) => {
                let item = $("<div>");
                $(".itemTitleBarNoToolsNoEdit > h2", $(old)).appendTo(item);
                $(".dialog-body", $(old))
                    .children()
                    .each((j, field) => {
                        $(field).removeAttr("class"); // remove classes (not useful)
                        $(field).removeAttr("style"); // remove margin-right: 15px; margin-bottom: 15px;
                        // maybe we add a class to render nicer
                        $(".baseControl", $(field)).removeAttr("class"); // not needed
                        $(field).appendTo(item);
                    });
                $(old).parent().parent().replaceWith(item);
            });

            mDHF.preparePasteBuffer(null);
            mDHF.copyTemplate([that.settings.parameter.createDoc.template], 0, true).done(() => {
                that.createNewDoc(
                    that.settings.parameter.createDoc.pasteTo ? that.settings.parameter.createDoc.pasteTo : "F-DOC-1",
                    details.html(),
                ).done(() => {
                    ml.UI.BlockingProgress.SetProgress(0, 1);
                    details.remove();
                    ml.UI.showSuccess("Created Review Document");
                });
            });
        });
    }

    // convert input fields into spans (otherwise they'll be rendered empty)
    private fixInputs(details: JQuery) {
        $("input[type='text']", details).each((i, inp) => {
            $(inp).replaceWith("<span>" + $(inp).val() + "</span>");
        });
        $("select", details).each((i, inp) => {
            $(inp).replaceWith("<span> " + $("option:selected", $(inp)).text() + "</span>");
        });
        $("input[type='checkbox']:checked", details).each((i, inp) => {
            $(inp).replaceWith("<span>X </span>");
        });
        $("input[type='checkbox']", details).each((i, inp) => {
            $(inp).replaceWith("<span>_ </span>");
        });
        $("textarea", details).each((i, inp) => {
            $(inp).replaceWith($("<span style='white-space: pre;'>").html($(inp).val()));
        });
    }

    // replace div based table with a real table
    private replaceSlickTables(old: JQuery) {
        let that = this;

        let table = $("<table class='table table-bordered'>");
        let tr = $("<tr>").appendTo($("<thead>").appendTo(table));
        // copy the headers
        $(".slick-header .slick-header-column", old).each((i, header) => {
            $("<td>")
                .appendTo(tr)
                .html($("span", $(header)).html());
        });
        let body = $("<tbody>").appendTo(table);
        $(".slick-row", old).each((i, row) => {
            let r = $("<tr>").appendTo(body);
            $(".slick-cell", $(row)).each((j, cell) => {
                $("<td>").appendTo(r).html($(cell).html());
            });
        });

        old.replaceWith(table);
    }

    private copyAnnotations(fromNode: JQuery, toNode: JQuery) {
        // find annotations inside and move the to smart link
        $(".annPO", fromNode).each((ai, an) => {
            toNode.append(an);
        });
    }

    private createNewDoc(folderId: string, reviewText: string) {
        let res = $.Deferred();

        let that = this;

        let currentBufferString = localStorage.getItem(mDHF.COPY_PASTE_BUFFER);
        let pasteBuffer = (<IDHFPasteBuffer>JSON.parse(currentBufferString)).items[0];

        let targetCategory = ml.Item.parseRef(folderId).type;

        let sourceref = 0;
        let srfs = globalMatrix.ItemConfig.getFieldsOfType("sourceref", targetCategory);
        if (srfs.length) {
            sourceref = srfs.length ? srfs[0].field.id : 0;
        }
        // prepare the item to be created
        let itemJson: IItemPut = {};

        itemJson.title = pasteBuffer.title + " " + app.getCurrentItemId();
        if (sourceref) {
            (<IGenericMap>itemJson)[sourceref] = pasteBuffer.sourceProject + "/" + pasteBuffer.sourceItem;
        }
        let fields = globalMatrix.ItemConfig.getItemConfiguration(targetCategory).fieldList;

        $.each(fields, function (fidx, f) {
            for (var idx = 0; idx < pasteBuffer.item.length; idx++) {
                if (pasteBuffer.item[idx].def.label === f.label && sourceref != f.id) {
                    (<IGenericMap>itemJson)[f.id] = pasteBuffer.item[idx].val;
                }
            }
        });

        let textField = pasteBuffer.item.filter(
            (f) =>
                f.def.fieldType == FieldDescriptions.Field_dhf &&
                f.val &&
                JSON.parse(f.val) &&
                JSON.parse(f.val).name.toLowerCase() == that.settings.parameter.createDoc.section.toLowerCase(),
        );
        if (textField.length == 1) {
            let val = JSON.parse(textField[0].val);
            val.fieldValue = reviewText;
            itemJson[textField[0].def.id] = JSON.stringify(val);
        }

        app.createItemOfTypeAsync(targetCategory, itemJson, "review document creation", folderId)
            .done(function (result) {
                res.resolve();
                app.treeSelectionChangeAsync(result.item.id);
            })
            .fail(function (error) {});
        return res;
    }

    private copyDetail(details: JQuery, tableData: IStringMap[], rowIdx: number) {
        let res = $.Deferred();
        let that = this;

        if (rowIdx >= tableData.length) {
            res.resolve();
            return res;
        }
        ml.UI.BlockingProgress.SetProgress(0, (100 * (rowIdx + 1)) / tableData.length);
        if (rowIdx) {
            $("<hr style='margin:30px 0'>").appendTo(details);
        }
        let reviewItem = $("<div>").appendTo(details);
        let reviewComments = $("<div style='margin-top:15px;'>").appendTo(details);
        let currentAnnotations = (<any>that.reviewTable.getController()).getHiddenCell(
            rowIdx,
            ReviewControlColumns.COL_ANNOTATIONS,
        );
        let annotations = currentAnnotations ? (<IReviewData>JSON.parse(currentAnnotations)).inlineComments : [];

        reviewComments.append(
            `<span class="baseControlHelp">Inline Comments for ${tableData[rowIdx].reviewitem}</span>`,
        );
        let body = $("<tbody>");
        if (annotations.length) {
            $("<table class='annTa' style='width:100%'>")
                .appendTo(reviewComments)
                .append("<thead style='font-weight: 700;'><tr><th>Pos</th><th>User</th><th>Comment</th></tr></thead>")
                .append(body);
        } else {
            $("<p class='annNC'>no inline annotations for this item</p>").appendTo(reviewComments);
        }
        let row = tableData[rowIdx];
        let itemId = ReviewControlImpl.getItem(row);
        let version = row[ReviewControlColumns.COL_VERSION] ? Number(row[ReviewControlColumns.COL_VERSION]) : undefined;

        app.getItemAsync(itemId, version).done(async function (data) {
            let itemC = new ItemControl(<any>{
                control: reviewItem,
                controlState: ControlState.Review,
                isHistory: version,
                type: ml.Item.parseRef(itemId).type,
                item: data,
                isItem: true,
                parameter: {
                    reviewMode: true,
                },
                changed: function () {},
            });
            await itemC.load();

            window.setTimeout(function () {
                reviewItem.annotator({ readOnly: true });
                reviewItem.data("annotator");
                reviewItem.annotator("loadAnnotations", ml.JSON.clone(annotations));
                $(".annotator-wrapper", reviewItem).removeClass("annotator-wrapper");
                for (let anIdx = 0; anIdx < annotations.length; anIdx++) {
                    let an = annotations[anIdx];
                    let hyper = reviewItem.find(`[data-annotation-id='${an.id}']`);
                    if (hyper.length) {
                        $(
                            `<span class="annPO" style="position: relative;padding: 0 20px 0 0;"><span style="color: red;border: 1px solid red;border-radius: 8px;position: absolute;top: -12px;left: -4px;width: 18px;text-align: center;">${
                                Number(anIdx) + 1
                            }</span></span>`,
                        ).insertAfter($(hyper[hyper.length - 1]));
                        body.append(
                            `<tr><td style='color:red'>${Number(anIdx) + 1}</td><td class="annUs">${
                                an.createdBy
                            }</td><td class="annTe">${an.text}</td></tr>`,
                        );
                    }
                }
                that.copyDetail(details, tableData, rowIdx + 1).done(() => res.resolve());
            }, 300);
        });

        return res;
    }

    private renderReviewInput(ctrl: JQuery, isDialog: boolean) {
        let that = this;

        $('<span class="baseControlHelp">' + this.settings.help + " - Review Items</span>").appendTo(ctrl);
        let items = $("<span>");
        var selStr = $("<span class='itemSelectionList'></span>");
        ctrl.append($("<p>").append(selStr).append(items));
        let selectTools = new ItemSelectionTools();
        selectTools.renderButtons({
            selectMode: SelectMode.auto,
            control: items,
            linkTypes: globalMatrix.ItemConfig.getCategories(true).map(function (cat) {
                return { type: cat };
            }),
            smallbutton: true,
            selectionChange: (newSelection: IReference[]) => {
                that.reviewItems = newSelection.map(function (sel) {
                    return sel.to;
                });
                selStr.html(ml.Item.refListToDisplayString(newSelection, "items:"));

                let ok: JQuery;
                if (isDialog) {
                    ok = $(".btnDoIt", ctrl.closest(".ui-dialog"));
                    ml.UI.setEnabled(ok, false); // disable ok/save button, because we first need to get the latest versions
                } else {
                    $("#btnSave").hide();
                }

                let folders = that.reviewItems
                    .filter((id) => ml.Item.parseRef(id).isFolder)
                    .map((id) => "folderm=" + id);
                let items = that.reviewItems.filter((id) => !ml.Item.parseRef(id).isFolder).map((id) => "id=" + id);

                let itemsAndFolders = items.concat(folders);
                let search = itemsAndFolders.join(" OR ");

                const allowSave = () => {
                    if (isDialog) {
                        ml.UI.setEnabled(ok, true);
                    } else {
                        $("#btnSave").show();
                    }
                };

                //Don't make the rest call if the selection is empty
                if (itemsAndFolders.length > 0) {
                    app.searchAsync("mrql:" + search, undefined, true).done((results) => {
                        allowSave();
                        that.currentVersions = results;
                        that.settings.valueChanged.apply(null);
                    });
                } else {
                    allowSave();
                    that.currentVersions = [];
                    that.settings.valueChanged.apply(null);
                }
            },
            getSelectedItems: async function () {
                if (that.reviewItems) {
                    return that.reviewItems.map(function (sel) {
                        return { to: sel, title: "" };
                    });
                } else {
                    return [];
                }
            },
            buttonName: "Select Review Items",
        });

        if (that.reviewItems && that.reviewItems.length > 0) {
            selStr.html(
                ml.Item.refListToDisplayString(
                    that.reviewItems.map((item) => {
                        return { to: item, title: "" };
                    }),
                    "items:",
                ),
            );
        }

        ml.UI.SelectUserOrGroup.showMultiUserSelect(
            ctrl,
            this.settings.help + " - Reviewers",
            that.reviewUsers,
            "Select Reviewers",
            "",
            "",
            true,
            this.settings.parameter.allowSelectUserGroups,
            function (selection: string[]) {
                that.reviewUsers = selection;
                that.settings.valueChanged.apply(null);
            },
        );
        $("button", items).css("margin-top", "4px");
    }

    private isReviewDone() {
        let tableData = (<ITableReviewData>JSON.parse(this.settings.fieldValue)).reviewtable;
        let passedItems: string[] = [];
        let failedItems: string[] = [];
        let todoItems: string[] = [];
        ReviewControlImpl.analyzeReview(this.settings.fieldId, tableData, passedItems, failedItems, todoItems);
        return todoItems.length == 0;
    }

    private editReview() {
        let that = this;
        this.reviewItems = [];
        this.reviewUsers = [];

        let tableData = (<ITableReviewData>JSON.parse(this.settings.fieldValue)).reviewtable;
        if (tableData && tableData.length) {
            this.reviewUsers = ReviewControlImpl.getReviewers(tableData);
            this.reviewItems = this.getItems();
        }

        let dlg = $("<div>").appendTo($("body"));
        let ui = $("<div style='height:100%;width:100%'>");
        this.renderReviewInput(ui, true);
        ml.UI.showDialog(
            dlg,
            "Change items or reviewers",
            ui,
            $(document).width() * 0.9,
            app.itemForm.height() * 0.9,
            [
                {
                    text: "OK",
                    class: "btnDoIt",
                    click: function () {
                        dlg.dialog("close");
                        dlg.remove();
                        that.updateReview();
                        app.saveAsync(true)
                            .done(function () {})
                            .fail(function () {
                                // or user refused
                                // these are set by controls: reset
                                that.reviewItems = null;
                                that.reviewUsers = null;
                                // update save buttons
                            });
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        dlg.dialog("close");
                        dlg.remove();
                        // these are set by controls: reset
                        that.reviewItems = null;
                        that.reviewUsers = null;
                        // update save buttons
                        that.settings.valueChanged.apply(null);
                    },
                },
            ],
            UIToolsConstants.Scroll.Vertical,
            true,
            true,
            () => {},
            () => {},
            () => {},
        );
    }

    private async updateReview() {
        let that = this;

        let savedData = (<ITableReviewData>JSON.parse(this.settings.fieldValue)).reviewtable;
        let tableData = <IStringMap[]>JSON.parse(await this.reviewTable.getController().getValueAsync());

        let oldReviewUsers = ReviewControlImpl.getReviewers(tableData);
        let oldReviewItems = this.getItems();

        // for users other than myself, keep original data
        $.each(oldReviewUsers, function (uIdx, otherUser) {
            if (otherUser != matrixSession.getUser()) {
                $.each(tableData, function (idx, tableRow) {
                    let originalRow = savedData.filter(function (row) {
                        return row.reviewitem == tableRow.reviewitem;
                    });
                    if (originalRow.length == 1) {
                        tableRow[otherUser] = originalRow[0][otherUser];
                    }
                });
            }
        });

        // add new items
        let items: string[] = [];
        $.each(this.reviewItems, function (sidx, select) {
            items = items.concat(app.getChildrenIdsRec(select));
        });

        $.each(items, function (idx, item) {
            if (oldReviewItems.indexOf(item) == -1) {
                let row: IStringMap = {};
                row[ReviewControlColumns.COL_ITEM] = that.getItemRef(item);
                row[ReviewControlColumns.COL_VERSION] = that.getItemVersion(item);

                $.each(that.reviewUsers, function (uidx, user) {
                    row[user] = "";
                });
                row[ReviewControlColumns.COL_COMMENT_LOG] = "";
                tableData.push(row);
            }
        });
        // remove not needed rows
        tableData = tableData.filter(function (tableRow) {
            let oldItem = tableRow.reviewitem.split(" ")[0].replace("!", "");
            return items.indexOf(oldItem) != -1;
        });
        // add and remove users
        let removeTheseUsers = oldReviewUsers.filter(function (oldUser) {
            return that.reviewUsers.indexOf(oldUser) == -1;
        });
        let addTheseUsers = that.reviewUsers.filter(function (oldUser) {
            return oldReviewUsers.indexOf(oldUser) == -1;
        });
        $.each(tableData, function (idx, tableRow) {
            $.each(removeTheseUsers, function (ridx, removeUser) {
                delete tableRow[removeUser];
            });
            $.each(addTheseUsers, function (ridx, addUser) {
                tableRow[addUser] = "";
            });
        });

        // acknowledge selected users
        this.reviewItems = null;
        this.reviewUsers = null;

        // save and repaint
        let newTable = JSON.stringify({ reviewtable: tableData });
        if (newTable != this.settings.fieldValue) {
            this.settings.fieldValue = newTable;
            // make sure new layout overwrites other changes
            this.forceNewTable = true;
            this.updateControl();
        }
        // update save buttons
        this.settings.valueChanged.apply(null);
    }

    // implement interface
    async hasChangedAsync() {
        if (this.isCommenting) {
            // user has the annotate dialog open
            return true;
        }

        if (this.forceNewTable) {
            return true;
        }

        if (this.reviewUsers && this.reviewUsers.length > 0 && !!this.reviewItems) return true;

        if (!this.reviewTable) return false;

        // https://matrixreq.atlassian.net/browse/MATRIX-6244
        // we have to manually preserve editing state, because all the changes will be committed inside
        // this.reviewTable.getController().getValueAsync() method, see getValueAsync method in oldTableCtrl.ts
        const isEditing = this.reviewTable.getController().grid.getEditorLock().isActive();

        let newTable = JSON.parse(await this.reviewTable.getController().getValueAsync());
        let oldTable = JSON.parse(this.settings.fieldValue).reviewtable;

        // recovering editing state if necessary
        if (isEditing) {
            this.reviewTable.getController().grid.editActiveCell();
        }

        let changeReview = $("#changeReview");

        for (let idx = 0; idx < newTable.length; idx++) {
            let changed = false;
            if (
                newTable[idx][ReviewControlColumns.COL_ANNOTATIONS] !=
                    oldTable[idx][ReviewControlColumns.COL_ANNOTATIONS] ||
                newTable[idx][ReviewControlColumns.COL_COMMENT_LOG] !=
                    oldTable[idx][ReviewControlColumns.COL_COMMENT_LOG]
            ) {
                changed = true;
            } else {
                let myReviewColumns = this.getMyReviewColumns();
                $.each(myReviewColumns, function (mrcIdx, mrc) {
                    if (newTable[idx][mrc] != oldTable[idx][mrc]) changed = true;
                });
            }
            if (changed) {
                ml.UI.setEnabled(changeReview, false);
                changeReview.parent().attr("title", "save to modify the table structure");
                return true;
            }
        }

        if (this.settings.parameter.canBeModified) {
            let enabled =
                !this.settings.parameter.canBeModifiedBy ||
                this.settings.parameter.canBeModifiedBy.length == 0 ||
                matrixSession.amIAllowedUser(this.settings.parameter.canBeModifiedBy);

            ml.UI.setEnabled(changeReview, enabled);
        }

        changeReview.parent().attr("title", "");
        return false;
    }

    async getValueAsync(currentItem?: IItemGet) {
        let that = this;
        if (this.reviewTable) {
            if (this.forceNewTable) {
                return this.settings.fieldValue;
            }

            if (!currentItem) {
                return undefined;
            }
            let myReviewColumns = this.getMyReviewColumns();
            // get the old review status for user
            let oldTable: IStringMap[] = this.settings.fieldValue
                ? JSON.parse(this.settings.fieldValue).reviewtable
                : [];
            let beforeEdit: any = {}; // lookup table item id -> row
            $.each(oldTable, function (oidx, row) {
                beforeEdit[ReviewControlImpl.getItem(row)] = row;
            });

            // get the updated review status for user
            let thisTable = JSON.parse(await this.reviewTable.getController().getValueAsync());
            let afterEdit: any = {}; // lookup table item id -> row
            $.each(thisTable, function (oidx, row) {
                afterEdit[ReviewControlImpl.getItem(row)] = row;
            });

            // update last saved table
            let lastSavedValue: ITableReviewData = <ITableReviewData>(
                JSON.parse((<IStringMap>currentItem)[this.settings.fieldId])
            );
            $.each(lastSavedValue.reviewtable, function (oidx, dbRow) {
                let item = ReviewControlImpl.getItem(dbRow);
                $.each(myReviewColumns, function (mrcIdx, mrc) {
                    dbRow[mrc] = afterEdit[item][mrc];
                    if (mrc != matrixSession.getUser() && afterEdit[item][mrc] != beforeEdit[item][mrc]) {
                        // ACL - remember that the logged on user did the change
                        dbRow["_" + mrc] = matrixSession.getUser();
                    }
                });

                // row: the values saved in the database (might have changed since user started edit)
                // oldValues: values before user started edit
                // newValues: values after used did edit
                // -> changes from old to new need to merged into row...
                that.mergeComments(dbRow, beforeEdit[item], afterEdit[item]);
                that.mergeAnnotations(dbRow, beforeEdit[item], afterEdit[item]);
            });

            return JSON.stringify(lastSavedValue);
        } else if (this.reviewItems && this.reviewUsers && this.reviewUsers.length > 0) {
            // create the initial table
            let items: string[] = [];
            $.each(this.reviewItems, function (sidx, select) {
                items = items.concat(app.getChildrenIdsRec(select));
            });

            let newReview: ITableReviewData = { reviewtable: [] };
            $.each(items, function (idx, item) {
                let row: IStringMap = {};
                row[ReviewControlColumns.COL_ITEM] = that.getItemRef(item);
                row[ReviewControlColumns.COL_VERSION] = that.getItemVersion(item);
                $.each(that.reviewUsers, function (uidx, user) {
                    row[user] = "";
                });
                row[ReviewControlColumns.COL_COMMENT_LOG] = "";
                newReview.reviewtable.push(row);
            });

            return JSON.stringify(newReview);
        } else {
            return JSON.stringify({});
        }
    }

    private mergeComments(db: IStringMap, beforeEdit: IStringMap, afterEdit: IStringMap) {
        let that = this;

        let domHelper = $("<div style='display:none'>").appendTo($("body"));
        // get the users' current comments in this cell
        let ncs: string[] = [];
        domHelper.html(afterEdit[ReviewControlColumns.COL_COMMENT_LOG]);
        $(".commentLine", domHelper).each(function (cidx, nc) {
            if ($(".commentUser", $(nc)).text() == matrixSession.getUser()) {
                ncs.push(nc.outerHTML);
            }
        });
        // get the users previous comments in the this cell
        let ocs: string[] = [];
        domHelper.html(beforeEdit[ReviewControlColumns.COL_COMMENT_LOG]);
        $(".commentLine", domHelper).each(function (cidx, oc) {
            if ($(".commentUser", $(oc)).text() == matrixSession.getUser()) {
                ocs.push(oc.outerHTML);
            }
        });
        // build list of comments to add/remove
        let removeComments: string[] = ocs.filter(function (oc) {
            return ncs.indexOf(oc) == -1;
        });
        let addComments: string[] = ncs.filter(function (nc) {
            return ocs.indexOf(nc) == -1;
        });
        // now update the comments in the current Item
        domHelper.html(db[ReviewControlColumns.COL_COMMENT_LOG]);
        // go through existing comments and remove them if they have been deleted
        $(".commentLine", domHelper).each(function (cidx, cc) {
            if (removeComments.indexOf(cc.outerHTML) == -1) {
                addComments.push(cc.outerHTML);
            }
        });
        // that's an old comment from validation test phase... add a fake comment to keep order
        $(".commentDate", domHelper).each(function (cidx, cc) {
            if (!$(cc).data("cd")) {
                $(cc).data("cd", new Date(2017, 1, 1, 1, 1, that.settings.parameter.appendComments ? cidx : 59 - cidx));
            }
        });

        // sort comments
        let mult = that.settings.parameter.appendComments ? 1 : -1;
        let sortedComments = addComments.sort(function (a, b) {
            let aDate = $(".commentDate", $(a)).data("cd");
            let bDate = $(".commentDate", $(b)).data("cd");
            return mult * (new Date(aDate).getTime() - new Date(bDate).getTime());
        });

        // add new stuff
        domHelper.html("");
        $.each(sortedComments, function (clidx, cl) {
            domHelper.append(cl);
        });
        let mergedValue = domHelper.html();
        domHelper.remove();

        db[ReviewControlColumns.COL_COMMENT_LOG] = mergedValue;
    }

    private mergeAnnotations(db: IStringMap, beforeEdit: IStringMap, afterEdit: IStringMap) {
        db[ReviewControlColumns.COL_ANNOTATIONS] = HTMLAnnotator.mergeAnnotation(
            db[ReviewControlColumns.COL_ANNOTATIONS],
            beforeEdit[ReviewControlColumns.COL_ANNOTATIONS],
            afterEdit[ReviewControlColumns.COL_ANNOTATIONS],
        );
    }

    private getItemRef(item: string) {
        let that = this;
        let ref = item + "!";
        if (that.settings.parameter.showVersions) {
            let version = that.getItemVersion(item);
            ref += " (version " + version + ")";
        }
        return ref;
    }

    private getItemVersion(item: string) {
        let that = this;
        let version = "";
        $.each(that.currentVersions, function (cidx, current) {
            if (current.itemId == item) {
                version = "" + current.version;
                return;
            }
        });
        return version;
    }

    async resizeItem(newWidth?: number, force?: boolean) {
        let that = this;

        if (force && this.reviewTable && this.reviewTable.resizeItem) {
            this.reviewTable.resizeItem(newWidth, force);
            // the redraw, removes the tools (history and such, so they need to be added)
            let currentValue = await this.reviewTable.getController().getValueAsync();
            if (this.settings.controlState == ControlState.FormEdit && currentValue) {
                // add the tools to expand inline / show history

                this.reviewTable.getController().redraw();

                let tableData = ml.JSON.clone(JSON.parse(currentValue));

                $.each($(".r1>span", this.reviewTable), function (rowIdx, row) {
                    that.makeExpandable(tableData, that.reviewTable, rowIdx);
                });
            }

            this.reviewTable.getController().refresh();
        }
    }

    // create JIRA tasks
    private createTasks(reviewers: string[], items: string[]): JQueryDeferred<string[]> {
        let res: JQueryDeferred<string[]> = $.Deferred();

        if (!this.settings.parameter.tasks) {
            res.resolve([]);
            return res;
        }
        let jiraConfig = Tasks.getConfig(this.settings.parameter.tasks.taskPluginId);

        if (!jiraConfig) {
            console.log("plugin " + this.settings.parameter.tasks.taskPluginId + " not configured");
            res.resolve([]);
            return res;
        }

        this.createTask(reviewers, items, 0, [])
            .done(function (tasks) {
                res.resolve(
                    tasks.map(function (ei) {
                        return ei.externalItemId;
                    }),
                );
            })
            .fail(function () {
                ml.UI.showError("Failed to create tasks", "see console log");
                res.resolve([]);
            });

        return res;
    }

    private createTask(
        reviewers: string[],
        items: string[],
        jobId: number,
        taskList: IExternalItem[],
    ): JQueryDeferred<IExternalItem[]> {
        let that = this;

        let res: JQueryDeferred<IExternalItem[]> = $.Deferred();

        if (jobId == reviewers.length * items.length) {
            res.resolve([]);
            return res;
        }

        let itemIdx = jobId % items.length;
        let reviewerIdx = Math.floor(jobId / items.length);
        let next = jobId + 1;

        if (itemIdx == 0) {
            ml.UI.showSuccess("Create new review task for " + reviewers[reviewerIdx]);
            app.getCurrentTitle().then((appTitle) => {
                let title: string = reviewers[reviewerIdx] + ":" + appTitle;
                Tasks.postCreateIssue(
                    that.settings.parameter.tasks.taskPluginId,
                    items[itemIdx],
                    title,
                    reviewers[reviewerIdx] +
                        ",\n" +
                        (that.settings.parameter.tasks.taskDescription
                            ? that.settings.parameter.tasks.taskDescription
                            : "a review has been scheduled for you. See linked review item for details."),
                    that.settings.parameter.tasks.taskProject,
                    that.settings.parameter.tasks.taskIssueType,
                )
                    .done(function (created: IExternalItem[]) {
                        taskList.push(created[0]);
                        that.createTask(reviewers, items, next, taskList)
                            .done(function (tasks) {
                                res.resolve(taskList);
                            })
                            .fail(function () {
                                res.reject();
                            });
                    })
                    .fail(function () {
                        console.log("could not create task in external ticketing");
                        res.reject();
                    });
            });
        } else {
            ml.UI.showSuccess("Added item " + items[itemIdx] + " to task of " + reviewers[reviewerIdx]);

            Tasks.postCreateLinks(items[itemIdx], [taskList[taskList.length - 1]])
                .done(function () {
                    that.createTask(reviewers, items, next, taskList)
                        .done(function (tasks) {
                            res.resolve(taskList);
                        })
                        .fail(function () {
                            res.reject();
                        });
                })
                .fail(function () {
                    console.log("could not link item to external ticket");
                    res.reject();
                });
        }

        return res;
    }

    // invite users to do reviews, by email and jira task
    private async sendReminder() {
        let that = this;

        let tableData = (<ITableReviewData>JSON.parse(this.settings.fieldValue)).reviewtable;

        let todos = this.getItemsToDoByUser(this.settings.fieldId, tableData);
        let actualReviewers: string[] = [];

        let link = ml.Item.parseRef(app.getCurrentItemId()).link;

        let richtexts = matrixApplicationUI.lastMainItemForm.getControls("richtext");

        let body = richtexts.length ? await richtexts[0].getController().getValueAsync() : "";
        body = body ? "See " + link + "<br><br>" + body + "<br><br>" : "Review: " + link + "<br><br>";

        $.each(ReviewControlImpl.getReviewers(tableData), function (idx, user) {
            if (todos[user].length) {
                // add info to mail body
                body += "<b>" + user + "</b> - ";
                // remember that person needs to do some work
                actualReviewers.push(user);

                // add links to review items
                let links = todos[user].map(function (id) {
                    return "<li>" + ml.Item.renderLink(id).html();
                });
                body += "still to do:<br><ul> " + links.join(",") + "</ul>";
            }
        });

        let cannedMessage = ml.Mail.getCannedMessage(
            "review_reminder",
            actualReviewers.join(","),
            app.getCurrentItemId(),
            undefined,
            body,
        );

        let subject = that.settings.parameter.mailTo.mailSubject
            ? that.settings.parameter.mailTo.mailSubject
            : "Review _id_";
        subject = subject.replace("_id_", app.getCurrentItemId());
        ml.Mail.sendMailDlg(actualReviewers.join(","), null, subject, cannedMessage, matrixSession.getUser());
    }

    // set the label for a list of items
    private lockItems(items: string[]) {
        let labelConfig = this.settings.parameter.lockLabel;

        let lt = ml.CreateNewLabelTools();
        let res = $.Deferred();
        this.setLabels(lt, items, labelConfig.label)
            .done(function () {
                ml.UI.showSuccess("All lock labels set!");
                res.resolve();
            })
            .fail(function () {
                ml.UI.showError("Failed to set labels", "see console log");
                res.resolve();
            });
        return res;
    }

    // mark items as done (passed/failed/todo)
    private async setItemReviewStatusLabel(items: string[]) {
        let that = this;
        // get current table
        let tableData = ml.JSON.clone(JSON.parse(await this.reviewTable.getController().getValueAsync()));
        // merge with original data to change UI values to internal values for other reviewers
        let oldData = (<ITableReviewData>JSON.parse(this.settings.fieldValue)).reviewtable;
        let otherReviewers = ReviewControlImpl.getReviewers(tableData).filter(function (reviewer) {
            return reviewer != matrixSession.getUser();
        });
        $.each(tableData, function (ridx, row) {
            $.each(otherReviewers, function (oridx, other) {
                tableData[ridx][other] = oldData[ridx][other];
            });
        });
        // prepare the triaged list of items
        let passedItems: string[] = [];
        let failedItems: string[] = [];
        let todoItems: string[] = [];
        ReviewControlImpl.analyzeReview(this.settings.fieldId, tableData, passedItems, failedItems, todoItems);

        // now set the labels for each group (if configured)
        let res = $.Deferred();
        let lt = ml.CreateNewLabelTools();

        let marks = this.settings.parameter.doneLabel;
        this.setLabels(lt, failedItems, marks.failedLabel)
            .done(function () {
                that.setLabels(lt, passedItems, marks.passedLabel)
                    .done(function () {
                        that.setLabels(lt, todoItems, marks.todoLabel)
                            .done(function () {
                                ml.UI.showSuccess("All review status labels set!");
                                res.resolve();
                            })
                            .fail(function () {
                                ml.UI.showError("Failed to set labels", "see console log");
                                res.resolve();
                            });
                    })
                    .fail(function () {
                        ml.UI.showError("Failed to set labels", "see console log");
                        res.resolve();
                    });
            })
            .fail(function () {
                ml.UI.showError("Failed to set labels", "see console log");
                res.resolve();
            });

        return res;
    }

    // set a label recursively on all items which need it
    private setLabels(lt: ILabelTools, items: string[], label: string) {
        let that = this;
        let res = $.Deferred();
        if (!label) {
            // nothing to do
            res.resolve();
            return res;
        }
        if (items.length == 0) {
            // nothing to do
            res.resolve();
            return res;
        }

        // filter the items to only those which have the label
        let labelDefs = lt.getLabelDefinitions([]).filter(function (ld) {
            return ld.label == label;
        });
        if (labelDefs.length == 0) {
            // nothing to do
            res.resolve();
            return res;
        }
        // get a list with all relevant categories for that labels
        let categories = [];
        for (let labelDef of labelDefs) {
            for (let cat of labelDef.categories) {
                if (categories.indexOf(cat) == -1) categories.push(cat);
            }
        }
        if (categories.length == 0) {
            // nothing to do
            res.resolve();
            return res;
        }

        let catSearch = categories.map((c) => "category=" + c).join(" OR ");
        let idSearch = items.map((id) => "id=" + id).join(" OR ");
        let labelSearch = 'label!="' + label + '"';

        app.searchAsync(`mrql:(${catSearch}) and (${idSearch}) and (${labelSearch})`).done(function (
            results: ISearchResult[],
        ) {
            const itemWithoutLabels = results.map(function (needle) {
                return needle.itemId;
            });

            let originalComment = matrixSession.getComment();
            if (that.settings.parameter.autoComment) {
                matrixSession.setComment(app.getCurrentItemId() + "! - " + originalComment);
            }

            that.setLabel(lt, itemWithoutLabels, label, 0)
                .done(function () {
                    res.resolve();
                })
                .fail(function () {
                    res.reject();
                })
                .always(() => {
                    if (that.settings.parameter.autoComment) {
                        matrixSession.setComment(originalComment);
                    }
                });
        });
        return res;
    }
    // set a label recursively on all items which need it
    private setLabel(lt: ILabelTools, items: string[], label: string, nextIdx: number) {
        let that = this;

        let res = $.Deferred();
        if (!label || nextIdx >= items.length) {
            res.resolve();
            return res;
        }

        // verify if the label is defined for the item / if not skip
        const labelsFromLabelDef = lt.getLabelDefinitions([ml.Item.parseRef(items[nextIdx]).type]).map(function (ld) {
            return ld.label;
        });
        if (!label || labelsFromLabelDef.indexOf(label) == -1) {
            // for this category no labels are defined, so we can skip this item
            let cont = nextIdx + 1;
            that.setLabel(lt, items, label, cont)
                .done(function () {
                    res.resolve();
                })
                .fail(function () {
                    res.reject();
                });
            return res;
        }

        // set the label and proceed
        app.getItemAsync(items[nextIdx])
            .done(function (original: IItemGet) {
                // check if label needs to be set
                let originalLabels = original.labels;
                let labels = lt.setLabel(original.labels, label);
                let same =
                    labels &&
                    originalLabels &&
                    labels.length == originalLabels.length &&
                    labels.every(function (x) {
                        return originalLabels.indexOf(x) != -1;
                    });

                if (same) {
                    // no label is already set
                    ml.UI.showSuccess(
                        "Set label '" + label + "' " + (nextIdx + 1) + " / " + items.length + " : " + items[nextIdx],
                    );
                    let cont = nextIdx + 1;
                    that.setLabel(lt, items, label, cont)
                        .done(function () {
                            res.resolve();
                        })
                        .fail(function () {
                            res.reject();
                        });
                } else {
                    // do set label
                    let changes: IItemPut = { id: items[nextIdx], onlyThoseFields: 1, labels: labels.join(",") };

                    app.updateItemInDBAsync(changes, "Schedule Review")
                        .done(function () {
                            ml.UI.showSuccess(
                                "Set label '" +
                                    label +
                                    "' " +
                                    (nextIdx + 1) +
                                    " / " +
                                    items.length +
                                    " : " +
                                    items[nextIdx],
                            );
                            let cont = nextIdx + 1;
                            that.setLabel(lt, items, label, cont)
                                .done(function () {
                                    res.resolve();
                                })
                                .fail(function () {
                                    res.reject();
                                });
                        })
                        .fail(function () {
                            console.log("failed to set label");
                            res.reject();
                        });
                }
            })
            .fail(function () {
                console.log("failed to get item");
                res.reject();
            });
        return res;
    }

    // get all items from table
    private getItems(): string[] {
        let that = this;

        let tableData = (<ITableReviewData>JSON.parse(this.settings.fieldValue)).reviewtable;

        let items: string[] = [];
        $.each(tableData, function (idx, row) {
            let item = ReviewControlImpl.getItem(row);
            items.push(item);
        });

        return items;
    }

    getItemsToDoByUser(fieldId: number, tableData: IStringMap[]): IStringStringArrayMap {
        let that = this;

        let todos: IStringStringArrayMap = {};

        // get dropdown setting options which are considered to be still todo
        let todoStates = ReviewControlImpl.getReviewOptions(fieldId, false, false, true);

        let columns = ReviewControlImpl.getReviewers(tableData);

        $.each(columns, function (idx, column) {
            if (!todos[column]) {
                // might be first task of user
                todos[column] = [];
            }
            // get items which still need to be reviewed
            $.each(tableData, function (rowIdx, row) {
                if (!row[column] || todoStates.indexOf(row[column]) != -1) {
                    // in table we keep the ID with a ! to render title
                    let item = ReviewControlImpl.getItem(row);
                    todos[column].push(item);
                }
            });
        });

        return todos;
    }

    static analyzeReview(
        fieldId: number,
        tableData: IStringMap[],
        passedItems: string[],
        failedItems: string[],
        todoItems: string[],
    ) {
        let reviewers = ReviewControlImpl.getReviewers(tableData);

        // get the cell values which represent the different states
        let passed = ReviewControlImpl.getReviewOptions(fieldId, true, false, false);
        let failed = ReviewControlImpl.getReviewOptions(fieldId, false, true, false);
        let todo = ReviewControlImpl.getReviewOptions(fieldId, false, false, true);

        // for each row - check items in which bucket they need to go
        $.each(tableData, function (rowIdx, row) {
            let stillTodo = false;
            let alreadyFailed = false;
            $.each(reviewers, function (cidx, column) {
                if (!row[column] || todo.indexOf(row[column]) != -1) {
                    stillTodo = true;
                } else if (failed.indexOf(row[column]) != -1) {
                    alreadyFailed = true;
                } else if (passed.indexOf(row[column]) != -1) {
                } else {
                    // default unknown state
                    stillTodo = true;
                }
            });
            if (alreadyFailed) {
                failedItems.push(ReviewControlImpl.getItem(row));
            } else if (stillTodo) {
                todoItems.push(ReviewControlImpl.getItem(row));
            } else {
                passedItems.push(ReviewControlImpl.getItem(row));
            }
        });
    }

    // get all columns which are user or ACL columns
    static getReviewers(tableData: IStringMap[]): string[] {
        if (tableData.length == 0) return [];

        let reviewColumns = Object.keys(tableData[0]).filter(function (c) {
            return c[0] != "_" && c != ReviewControlColumns.COL_ITEM && c != ReviewControlColumns.COL_COMMENT_LOG;
        });
        return reviewColumns.sort();
    }

    static getItem(row: IStringMap): string {
        return ReviewControlImpl.getItemFromCell(row["reviewitem"]);
    }

    static getItemFromCell(value: string): string {
        return value.split(" ")[0].replace("!", "");
    }

    // returns all drop down options for the wanted principal states
    static getReviewOptions(fieldId: number, passed: boolean, failed: boolean, todo: boolean): string[] {
        let dd: IDropdownParams = ReviewControlImpl.getDropdownParams(fieldId);

        if (!dd || !dd.options) {
            ml.Logger.log("warn", "no setting for dropdowns in review table");
            return [];
        }
        return dd.options
            .filter(function (ddo) {
                return (
                    (todo && (!ddo.class || ddo.class == "todo")) ||
                    (passed && ddo.class == "passed") ||
                    (failed && ddo.class == "failed")
                );
            })
            .map(function (ddo) {
                return ddo.id;
            });
    }

    private static getDropdownParams(fieldId: number): IDropdownParams {
        let field = globalMatrix.ItemConfig.getFieldsOfType("reviewControl").filter(function (rcs) {
            return rcs.field.id == fieldId;
        });
        if (field.length && field[0].field.parameterJson && field[0].field.parameterJson.statusDropdown) {
            return <IDropdownParams>globalMatrix.ItemConfig.getSettingJSON(field[0].field.parameterJson.statusDropdown);
        }
        return <IDropdownParams>globalMatrix.ItemConfig.getSettingJSON(ReviewControlImpl.reviewOptionsSetting);
    }

    // either a column specifically for the user or a acl group containing the user
    private getMyReviewColumns(): string[] {
        let mine: string[] = [matrixSession.getUser()];

        let groups = globalMatrix.ItemConfig.getUserGroups();

        if (groups) {
            $.each(groups, function (aclIdx, group) {
                if (
                    group.membership
                        .map(function (member) {
                            return member.login;
                        })
                        .indexOf(matrixSession.getUser()) != -1
                ) {
                    mine.push(ml.UI.SelectUserOrGroup.getGroupId(group));
                }
            });
        }

        return mine;
    }

    private showTable(container: JQuery) {
        let that = this;

        that.outOfDateInfo = null;
        if (
            (this.settings.controlState == ControlState.FormEdit ||
                this.settings.controlState == ControlState.FormView) && // the out of date icons don't make sense in the history view as they would be wrong / the are not shown in the tooltip either
            (this.settings.parameter.showHistoryOutOfDate ||
                (this.settings.parameter.showHistoryOutOfDateBeforeDone && !this.isReviewDone()))
        ) {
            let items = this.getItems().map((id) => "id=" + id);

            app.searchAsync("mrql:" + items.join(" or "), null, true, "", null, null, null, null).done(
                (currentItems) => {
                    that.outOfDateInfo = {};
                    for (let item of currentItems) {
                        that.outOfDateInfo[item.itemId] = item.version;
                    }
                    that.showTableDetails(container);
                },
            );
        } else {
            that.showTableDetails(container);
        }
    }

    private showTableDetails(container: JQuery) {
        let that = this;

        let dd: IDropdownParams = ReviewControlImpl.getDropdownParams(this.settings.fieldId);
        let ddName = this.settings.parameter.statusDropdown
            ? this.settings.parameter.statusDropdown
            : ReviewControlImpl.reviewOptionsSetting;

        // render actual review table
        (<ITableControlOptions>this.settings).parameter.canBeModified = false;
        (<ITableControlOptions>this.settings).parameter.showLineNumbers = false;
        (<ITableControlOptions>this.settings).parameter.columns = [];
        (<ITableControlOptions>this.settings).parameter.doNotRememberWidth = true;
        (<ITableControlOptions>this.settings).parameter.disableColumnReorder = true;
        (<ITableControlOptions>this.settings).parameter.onColumnsResized = () => {
            that.repaintAfterColumnChange();
        };

        let tableData = (<ITableReviewData>JSON.parse(this.settings.fieldValue)).reviewtable;

        (<ITableControlOptions>this.settings).parameter.readOnlyFields = ["items"];
        if (tableData && tableData.length) {
            let columns = Object.keys(tableData[0]);
            (<ITableControlOptions>this.settings).parameter.columns.push({
                name: "Item",
                field: ReviewControlColumns.COL_ITEM,
                editor: ColumnEditor.none,
                relativeWidth: 300,
            });
            let reviewers = ReviewControlImpl.getReviewers(tableData);
            let myReviewColumns = this.getMyReviewColumns();

            $.each(reviewers, function (cidx, reviewer) {
                let itsMe = myReviewColumns.indexOf(reviewer) != -1;
                (<ITableControlOptions>that.settings).parameter.columns.push({
                    name: ml.UI.SelectUserOrGroup.getGroupDisplayNameFromId(reviewer),
                    field: reviewer,
                    editor: itsMe ? ColumnEditor.select : ColumnEditor.none,
                    options: { setting: ddName },
                    relativeWidth: itsMe ? 100 : 50,
                    cssClass: itsMe ? "reviewMe" : "reviewOther",
                    headerCssClass: itsMe ? "reviewMeHeader" : "reviewOtherHeader",
                });
                if (!itsMe) {
                    (<ITableControlOptions>that.settings).parameter.readOnlyFields.push(reviewer);
                }
            });
            if (
                typeof (<IReviewConfig>that.settings.parameter).showComments == "undefined" ||
                (<IReviewConfig>that.settings.parameter).showComments
            ) {
                (<ITableControlOptions>this.settings).parameter.columns.push({
                    name: "Comments",
                    field: ReviewControlColumns.COL_COMMENT_LOG,
                    editor: ColumnEditor.commentlog,
                    options: this.settings.parameter.appendComments ? { append: "true" } : {},
                    relativeWidth: 300,
                });
            }

            $.each(tableData, function (ridx, row) {
                $.each(columns, function (cidx, column) {
                    // replace the drop down ids of other users with actual values
                    if (reviewers.indexOf(column) != -1 && myReviewColumns.indexOf(column) == -1) {
                        let opts = dd.options.filter(function (ddo) {
                            return ddo.id == row[column];
                        });
                        row[column] = opts.length ? opts[0].label : row[column];
                    }
                });
            });
        }
        let tableInfo = ml.JSON.clone(this.settings);
        tableInfo.fieldHandler = null;
        tableInfo.parameter.onCellChanged = function (row: any) {
            that.makeExpandable(tableData, container, row.row);
        };

        tableInfo.fieldValue = JSON.stringify(tableData);
        tableInfo.onSelectCell = (rowIdx: number, colIdx: number, data: any) => {
            if (colIdx == 1) {
                let reviewItem = data.reviewitem.replace("!", "").replace("(version ", "").replace(")", "");
                let itemId = reviewItem.split(" ")[0];
                let line = tableData[rowIdx];

                let version = line[ReviewControlColumns.COL_VERSION]
                    ? Number(line[ReviewControlColumns.COL_VERSION])
                    : 0;
                ReviewContextFrame.renderItem(itemId, version, that.settings.parameter.autoshowContext);
            }
        };
        container.html("");

        this.reviewTable = $("<div>").appendTo(container);
        this.reviewTable.tableCtrl(tableInfo);
        if (this.settings.controlState != ControlState.HistoryView) {
            this.reviewTable.highlightReferences();
        }

        this.reviewTable.getController().redraw();

        if (this.settings.controlState == ControlState.FormEdit) {
            // add the tools to expand inline / show history
            $.each($(".r1>span", container), function (rowIdx, row) {
                that.makeExpandable(tableData, container, rowIdx);
            });
        }

        this.reviewTable.getController().refresh();
    }

    private makeExpandable(tableData: IStringMap[], container: JQuery, rowIdx: number) {
        let that = this;

        let row = $($(".r1>span", container)[rowIdx]);

        let toggler = $(
            '<button class="btn-plain"><span class="cbimg fal fa-chevron-right reviewToggle"></span></button>',
        );
        let history = $(
            '<button title="view changes" class="btn-plain reviewHistory"><span class="fal fa-history"></span></button>',
        );
        let allowShowHistory = true;
        if (this.outOfDateInfo) {
            let line = tableData[rowIdx];
            let itemId = ReviewControlImpl.getItem(line);
            let version = line[ReviewControlColumns.COL_VERSION] ? Number(line[ReviewControlColumns.COL_VERSION]) : 0;
            if (version) {
                if (!this.outOfDateInfo[itemId]) {
                    history.css("color", "red");
                    history.attr("title", "item was deleted");
                    allowShowHistory = false;
                } else if (this.outOfDateInfo[itemId] > version) {
                    history.css("color", "orange");
                    history.attr("title", "item was changed after it was added to review");
                }
            }
        }
        let annotations = $(
            '<button title="annotate" class="btn-plain reviewAnnotations"><span class="fal fa-pencil"></button>',
        );
        let details = $('<div class="reviewDetails">');

        if (that.settings.parameter.showInline) $(row).prepend(toggler);
        if (that.settings.parameter.showHistory) {
            $(row).append(history);
        } else {
            allowShowHistory = false;
        }
        if (that.settings.parameter.showAnnotations) $(row).append(annotations);
        if (that.settings.parameter.showInline) {
            row.append(details);

            if (that.expanded[rowIdx]) {
                details.html(that.expandDetails[rowIdx]);
            } else {
                that.expanded[rowIdx] = false;
            }
        }

        if (allowShowHistory) {
            history.click(function (event: JQueryMouseEventObject) {
                let line = tableData[rowIdx];

                let version = line[ReviewControlColumns.COL_VERSION]
                    ? Number(line[ReviewControlColumns.COL_VERSION])
                    : 0;
                let itemId = ReviewControlImpl.getItem(line);

                // showHistoryAgainstLastReviewed is an async function, but we do not wait for it to finish here.
                // We are only kicking off the process.
                that.showHistoryAgainstLastReviewed(itemId, version);

                if (event.preventDefault) event.preventDefault();
                if (event.stopPropagation) event.stopPropagation();

                return false;
            });
        }

        annotations.click(function (event: JQueryMouseEventObject) {
            let icon = $(event.delegateTarget);

            let line = tableData[rowIdx];
            let version = line[ReviewControlColumns.COL_VERSION] ? Number(line[ReviewControlColumns.COL_VERSION]) : 0;
            let itemId = ReviewControlImpl.getItem(line);

            that.showAnnotations(itemId, version, rowIdx, icon);

            if (event.preventDefault) event.preventDefault();
            if (event.stopPropagation) event.stopPropagation();

            return false;
        });
        that.colorIcon(annotations, tableData[rowIdx][ReviewControlColumns.COL_ANNOTATIONS]);

        toggler.click(function (event: JQueryMouseEventObject) {
            that.toggleItem(rowIdx, tableData, event, details);
        });
    }

    private tablesReadyTimeout: number;

    private showAnnotations(itemId: string, version: number, rowIdx: number, icon: JQuery) {
        let that = this;

        let currentAnnotations = (<any>that.reviewTable.getController()).getHiddenCell(
            rowIdx,
            ReviewControlColumns.COL_ANNOTATIONS,
        );
        that.retrieveItem(itemId, version).done(function (data) {
            // show dialog
            let hTMLAnnotator = new HTMLAnnotator();
            let isSuperUser =
                that.settings.parameter.annotationMasters &&
                that.settings.parameter.annotationMasters.indexOf(matrixSession.getUser()) != -1;
            that.isCommenting = true;
            that.settings.valueChanged.apply(null);
            hTMLAnnotator.showReviewDialog(
                !that.readonly,
                itemId,
                version,
                data,
                currentAnnotations,
                isSuperUser,
                async function () {
                    that.isCommenting = false;
                    let changed = await hTMLAnnotator.getValueAsync();
                    (<any>that.reviewTable.getController()).setHiddenCell(
                        rowIdx,
                        ReviewControlColumns.COL_ANNOTATIONS,
                        changed,
                    );
                    that.colorIcon(icon, changed);
                },
            );
        });
    }

    private colorIcon(icon: JQuery, annotations: string) {
        icon.css("color", HTMLAnnotator.hasAnnotations(annotations) ? "red" : "");
    }

    private repaintAfterColumnChange() {
        $.each($(".reviewDetails"), function (rowIdx, rwd) {
            if ($(rwd).html() != "" && $(rwd).css("display") != "none") {
                let toogle = $(rwd).parent().find(".reviewToggle");
                // hide it
                toogle.click();
                // reset it
                $(rwd).html("");
                $(rwd).show();
            }
        });
    }

    private toggleItem(rowIdx: number, tableData: IStringMap[], event: JQueryMouseEventObject, details: JQuery) {
        let that = this;

        that.expanded[rowIdx] = !that.expanded[rowIdx];

        let line = tableData[rowIdx];
        let reviewDetails = $(".reviewDetails", $(event.delegateTarget).parent());

        if (that.expanded[rowIdx]) {
            $(".cbimg", $(event.delegateTarget).parent()).removeClass("fa-chevron-right").addClass("fa-chevron-down");
        } else {
            $(".cbimg", $(event.delegateTarget).parent()).removeClass("fa-chevron-down").addClass("fa-chevron-right");
        }

        if (reviewDetails.html()) {
            reviewDetails.toggle();
            let top = that.reviewTable.closest(".panel-body-v-scroll");
            let topPos = top.scrollTop();
            that.reviewTable.getController().refresh();
            top.scrollTop(topPos);
        } else {
            let version = line[ReviewControlColumns.COL_VERSION]
                ? Number(line[ReviewControlColumns.COL_VERSION])
                : undefined;
            let itemId = ReviewControlImpl.getItem(line);
            that.retrieveItem(itemId, version).done(async function (data) {
                let ctrl = new ItemControl(<any>{
                    control: reviewDetails,
                    controlState: ControlState.Tooltip,
                    isHistory: version,
                    type: ml.Item.parseRef(itemId).type,
                    item: data,
                    isItem: true,
                    parameter: {
                        updateParent: function () {
                            window.clearTimeout(that.expandSaveTimeout);
                            // in case there's a table this function is called after the table has finished rendering
                            that.expandDetails[rowIdx] = details.html();
                            let top = that.reviewTable.closest(".panel-body-v-scroll");
                            let topPos = top.scrollTop();
                            that.reviewTable.getController().refresh();
                            top.scrollTop(topPos);
                        },
                    },
                    changed: function () {},
                });
                await ctrl.load();
                window.clearTimeout(that.expandSaveTimeout);
                that.expandSaveTimeout = window.setTimeout(function () {
                    // in case there's no table: html is already ok: save it
                    $(".itemTitle", details).remove(); // it's just above
                    that.expandDetails[rowIdx] = details.html();
                    let top = that.reviewTable.closest(".panel-body-v-scroll");
                    let topPos = top.scrollTop();
                    that.reviewTable.getController().refresh();
                    top.scrollTop(topPos);
                }, 500); // wait a little, e.g. to render images
            });
        }
    }

    private retrieveItem(itemId: string, version: number) {
        let res = $.Deferred();
        let that = this;

        if (this.texts[itemId]) {
            res.resolve(this.texts[itemId]);
            return res;
        }

        app.getItemAsync(itemId, version).done(function (data) {
            that.texts[itemId] = data;
            res.resolve(data);
        });

        return res;
    }

    private async showHistoryAgainstLastReviewed(itemId: string, version: number) {
        let that = this;
        var ht = new HistoryTools();

        if (!version) {
            ht.compareLatest(itemId);
            return;
        }

        if (version == 1 || !that.settings.parameter.doneLabel.passedLabel) {
            ht.compareVersions(itemId, version, 0);
            return;
        }

        const lastRevision: number = await ml.LabelTools.getLastTimeLabelWasSet(
            itemId,
            that.settings.parameter.doneLabel.passedLabel,
            version,
        );
        if (!lastRevision) {
            ht.compareVersions(itemId, version, 0);
        } else {
            app.getItemAsync(itemId, lastRevision).done(function (data: IItemGet) {
                ht.compareVersions(itemId, lastRevision, version);
            });
        }
    }
}
