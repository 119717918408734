import { ControlState, globalMatrix } from "../../../globals";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { UIToolsConstants } from "../../matrixlib/MatrixLibInterfaces";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { HyperlinkFieldHandler } from "../../businesslogic/FieldHandlers/HyperlinkFieldHandler";

export type { IHyperlinkOptions };
export { HyperlinkImpl };

interface IHyperlinkOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: {};
    fieldValueLabel?: string; // displayed name of hyperlink (by default uses last part of url)
    linkPrefix?: string; // if set, is displayed before name of hyperlink
}

$.fn.hyperlink = function (this: JQuery, options: IHyperlinkOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_hyperlink,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new HyperlinkImpl(this, options.fieldHandler as HyperlinkFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class HyperlinkImpl extends BaseControl<HyperlinkFieldHandler> {
    private settings: IHyperlinkOptions;
    private lastValueChanged: number;
    private _editor: JQuery;

    constructor(control: JQuery, fieldHandler: HyperlinkFieldHandler) {
        super(control, fieldHandler);
    }

    private getHandler(): HyperlinkFieldHandler {
        return <HyperlinkFieldHandler>this.settings.fieldHandler;
    }

    init(options: IHyperlinkOptions) {
        let that = this;
        var defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            dummyData: false, // fill control with a dumy text (for form design...)
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                placeholder: "www.google.com", // can be a dummy text, e.g. for creation dialog
                readonly: false, // can be set to overwrite the default readonly status
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        if (typeof this.settings.fieldValue === "undefined" || this.settings.fieldValue === "") {
            this.settings.fieldValue = "";
        }

        if (this.settings.controlState === ControlState.Print) {
            this._root.append(super.createHelp(this.settings));
            this._root.append("<div class='printBox'>" + this.settings.fieldValue + "</div>");
            return;
        }
        if (this.settings.controlState === ControlState.Tooltip) {
            this._root.append("<p>" + this.settings.fieldValue + "</p>");
            return;
        }
        this._root.append(super.createHelp(this.settings));
        var ctrlContainer = $("<div>").addClass("baseControl");
        this._root.append(ctrlContainer);

        let hyper = $("<span>").appendTo(ctrlContainer);

        this._root.data("original", this.settings.fieldValue);
        this._root.data("new", this.settings.fieldValue);

        this.showLink(hyper);

        if (this.settings.canEdit) {
            let edit = this.settings.fieldValue ? "edit" : "add link";
            $("<button class='btn btn-link'>(" + edit + ")</button>")
                .appendTo(ctrlContainer)
                .click(function () {
                    let dlg = $("<div>").appendTo($("body"));

                    let textInput = $("<div>");
                    let textContainer: any = { url: that.settings.fieldValue ? that.settings.fieldValue : "" };
                    ml.UI.addTextInput(textInput, "URL:", textContainer, "url", null);

                    ml.UI.showDialog(
                        dlg,
                        "Edit URL",
                        textInput,
                        -730,
                        -340,
                        [
                            {
                                text: "Apply",
                                class: "btnDoIt",
                                click: function () {
                                    that._root.data("new", textContainer["url"]);
                                    if (that.settings.valueChanged) that.settings.valueChanged();
                                    that.showLink(hyper);
                                    dlg.dialog("close");
                                },
                            },
                            {
                                text: "Cancel",
                                class: "btnCancelIt",
                                click: function () {
                                    dlg.dialog("close");
                                },
                            },
                        ],
                        UIToolsConstants.Scroll.None,
                        false,
                        false,
                        () => {
                            dlg.remove();
                        },
                        () => {},
                        () => {},
                    );
                });
        }
    }

    protected showLink(container: JQuery) {
        let url: string = this._root.data("new") ? this._root.data("new") : "";
        let full = url;
        let title = url;

        let qm = url.indexOf("?");

        if (qm != -1) {
            title = url.substr(qm + 1);
            url = url.substr(0, qm);
        }
        // fix for MATRIX-4354 : URL fields in Matrix: no way to enter them anymore
        // let's undo what the  HTML cleanup is doing for &
        full = full.replace("&lt;", "%3C").replace("&amp;", "&");
        container.html("");
        let hyper = $("<span>").appendTo(container);
        hyper.html(this.settings.linkPrefix ? this.settings.linkPrefix : "").append(
            $('<a title="' + title + '">' + url + "</a>")
                .attr("href", full)
                .attr("target", "_blank"),
        );
    }

    // public interface
    async hasChangedAsync() {
        // make sure no changes are pending
        clearTimeout(this.lastValueChanged);
        // this will take and text from the editor and put it int he variable  _root.data("new")
        // but it will not recursively trigger a change
        this.valueChanged(true);
        // now compare
        return this._root.data("original") !== this._root.data("new");
    }
    async getValueAsync() {
        // make sure no changes are pending
        clearTimeout(this.lastValueChanged);
        this.valueChanged(true);
        return this._root.data("new");
    }
    destroy() {
        if (this._editor) {
            this._editor.off();
        }
    }
    resizeItem() {}
    // private functions
    private valueChanged(noCallback: boolean): void {
        if (this._editor && this.settings.canEdit) {
            this._root.data("new", this._editor.val());
        }
        if (this.settings.valueChanged && !noCallback) {
            this.settings.valueChanged.apply(null);
        }
    }
}
