export { SectionDescriptions };

class SectionDescriptions {
    static section_Checkbox = "Checkbox";
    static section_Custom = "Custom";
    static section_derivedfrom = "derivedfrom";
    static section_dashboard = "dashboard";
    static section_designreviews = "designreviews";
    static section_audittrail = "audittrail";
    static section_signatures = "signatures";
    static section_responsibilities = "responsibilities";
    static section_genericTable = "genericTable";
    static section_dhf_table = "dhf_table";
    static section_document_options = "document_options";
    static section_Date = "Date";
    static section_item_index = "item_index";
    static section_itemlist = "itemlist";
    static section_package = "package";
    static section_items = "items";
    static section_itemtable = "itemtable";
    static section_linklist = "linklist";
    static section_figures_tables = "figures_tables";
    static section_multiselect = "multiselect";
    static section_remarks = "remarks";
    static section_richtext = "richtext";
    static section_riskstats = "riskstats";
    static section_terms_abbreviations = "terms_abbreviations";
    static section_table_of_content = "table_of_content";
    static section_testresults = "testresults";
    static section_textline = "textline";
    static section_tracematrix = "tracematrix";
    static section_downtraces = "downtraces";
    static section_uptraces = "uptraces";
    static section_duedate = "duedate";
    static section_templateapproval = "templateapproval";
    static section_signaturebox = "signaturebox";
    static section_CustomSection = "CustomSection";
    static section_checkbox = "checkbox";
    static section_hidden = "hidden";

    /** get all sections name for document*/
    static getAllSections(): string[] {
        return [
            this.section_Checkbox,
            this.section_Custom,
            this.section_derivedfrom,
            this.section_dashboard,
            this.section_designreviews,
            this.section_audittrail,
            this.section_signatures,
            this.section_responsibilities,
            this.section_genericTable,
            this.section_dhf_table,
            this.section_document_options,
            this.section_Date,
            this.section_item_index,
            this.section_itemlist,
            this.section_package,
            this.section_items,
            this.section_itemtable,
            this.section_linklist,
            this.section_figures_tables,
            this.section_multiselect,
            this.section_remarks,
            this.section_richtext,
            this.section_riskstats,
            this.section_terms_abbreviations,
            this.section_table_of_content,
            this.section_testresults,
            this.section_textline,
            this.section_tracematrix,
            this.section_downtraces,
            this.section_uptraces,
            this.section_duedate,
            this.section_templateapproval,
            this.section_signaturebox,
            this.section_CustomSection,
            this.section_checkbox,
        ];
    }
}
