// -----------------------------------------------------------
// Project tree (on left of default layout)

import { IReference, ControlState, IItem, globalMatrix, app } from "../../../globals";
import { IDB, IDBParent } from "../../businesslogic/index";
import { ml } from "../../matrixlib";
import "../Components/ProjectView";
import { ProjectView } from "../Components/ProjectView";
import { plugins } from "../../businesslogic/index";
import { NavBar } from "../Components";

export type { MatrixTreeOptions, ITreeDropInfo };
export { MainTreeImpl, NavigationPanel };

// -----------------------------------------------------------
interface MatrixTreeOptions {
    noAnimation: boolean;
    highlight: boolean;
    canFilter: boolean;
    serverSearch: boolean;
    expand: number;
    canSelectItems: boolean;
    // TODO: fix typo
    canFilterExclusiv: boolean;
    selectedItems: IReference[];
    isConfigSearch?: boolean;
    tree?: IDB[];
    controlState: ControlState;
    dropCallback: (moveDetails: ITreeDropInfo) => boolean; // callback to be called after drop, returns true if item can be dropped
    selectionChanged: (id: string) => void; // callback to be called when selection in tree changes
    onExpand?: (id: string) => void; // optional callback, called when folder is opened
    isMainTree?: boolean; // can be set to true to indicate it's the main tree on left
}
interface ITreeDropInfo {
    parentId: string;
    itemId: string;
    index: number;
    updateUI?: () => void;
}

class MainTreeImpl {
    private settings: MatrixTreeOptions;
    private _jui: JQuery;
    private triggerSelectionChange: boolean;

    constructor() {
        let that = this;
        // define default settings
        this.settings = {
            noAnimation: false,
            highlight: true,
            canFilter: true,
            serverSearch: true,
            expand: 0,
            canSelectItems: false,
            canFilterExclusiv: true,
            selectedItems: [],
            controlState: ControlState.FormEdit,
            dropCallback: (moveDetails: ITreeDropInfo) => {
                if (moveDetails.itemId === app.getCurrentItemId() && app.getNeedsSave()) {
                    ml.UI.showError("Save First", "Please save the changes before moving the item.");

                    return false;
                }

                app.moveItemsAsync(moveDetails.itemId, moveDetails.parentId, moveDetails.index)
                    .done(function () {
                        if (moveDetails.updateUI) {
                            moveDetails.updateUI();
                        }
                    })
                    .fail(function () {});
                return true;
            },
            selectionChanged: (id: string) => {
                if (that.triggerSelectionChange) {
                    app.treeSelectionChangeAsync(id)
                        .done(function () {})
                        .fail(function () {});
                }
            },
        };
        this.init($("#projectTree"));

        this.triggerSelectionChange = true;
    }
    init(control: JQuery) {
        this._jui = control;
    }

    focusTree() {
        this._jui.getController().tree.find("ul.ui-fancytree").focus();
    }

    render(treeSettings?: MatrixTreeOptions) {
        // Register with search tools.
        let that = this;
        ml.Search.OnCancelSearch.subscribe(that, function (arg) {
            that.clearFilter();
        });
        if (treeSettings) {
            this.settings = treeSettings;
        }
        this.settings["tree"] = app.getTree();
        this.settings.isMainTree = true;

        this._jui.html("");
        this._jui.projectView(this.settings);
        window.setTimeout(function () {
            $(".ui-fancytree").focus();
        }, 200);
        plugins.updateSearchPanel();
    }

    update(item: IItem): void {
        // if the node changed, it might be the title: update in tree
        if (typeof item.title == "undefined") {
            // a partial update (only some fields or labels) will not change label
            return;
        }
        // avoid 'malicious' item titles...
        item.title = item.title.replace(/</g, "&lt;");
        (<ProjectView>this._jui.getController()).setTitle(item.id, item.title);
    }

    remove(itemId: string): void {
        (<ProjectView>this._jui.getController()).removeNode(itemId);
    }

    // open a folder (optionally also parent folders)
    async openFolder(itemId: string, expandToRoot?: boolean): Promise<void> {
        await (<ProjectView>this._jui.getController()).openTree(itemId);
        if (expandToRoot) {
            var parent = app.getParentId(itemId);
            if (parent && parent != "F-PROJECT") {
                await NavigationPanel.openFolder(itemId, true);
            }
        }
    }
    // open a folder (optionally also parent folders)
    closeFolder(itemId: string) {
        (<ProjectView>this._jui.getController()).closeTree(itemId);
    }
    select(itemId: string): void {
        this.triggerSelectionChange = false;
        try {
            NavBar.activateItemsTab(itemId);
            (<ProjectView>this._jui.getController()).select(itemId);
        } catch (ex) {
            // the change of selection can fail if the tree is filtered (so the item is not shown)
        }
        this.triggerSelectionChange = true;
    }

    isSelected(itemId: string): boolean {
        return (<ProjectView>this._jui.getController()).isSelected(itemId);
    }

    insertInTree(newItem: IDBParent, noEscape?: boolean): void {
        // the parameter new item contains information about the new item
        // newitem = {parent: ID of (parent) folder, position: position inside folder, item: json object defining the item
        // the item itself has ("title", "type", "id", and "children" if it is a folder

        if (newItem && newItem.item && !noEscape) newItem.item.title = newItem.item.title.replace(/</g, "&lt;");

        (<ProjectView>this._jui.getController()).insertNode(newItem.parent, newItem.item, { at: newItem.position });
    }

    moveInTree(itemId: string, newParentId: string, newPosition: number) {
        (<ProjectView>this._jui.getController()).moveNode(newParentId, itemId, newPosition);
    }
    insertUpdateTreeRec(target: string, source: IDB) {
        // this function either replaces the target and or a child of the target with the new database stuff, if the source does not exists it's added

        if (target === source.id) {
            (<ProjectView>this._jui.getController()).updateRec(source);
        } else {
            (<ProjectView>this._jui.getController()).insertRec(target, source);
        }
    }

    destroy(): void {
        this._jui.html("");
    }

    clearFilter(): void {
        // called if the user starts searching in a selection dialog to remove highlights from NavigationPanel
        if (this._jui && this._jui.getController) (<ProjectView>this._jui.getController()).clearFilter();
    }

    updateItemIsUnselected(itemId: string, isUnselected: boolean): void {
        if (this._jui && this._jui.getController)
            (<ProjectView>this._jui.getController()).updateItemIsUnselected(itemId, isUnselected);
    }

    updateNotificationCounters(): void {
        if (this._jui && this._jui.getController) (<ProjectView>this._jui.getController()).updateNotificationCounters();
    }
}

var NavigationPanel: MainTreeImpl = new MainTreeImpl();
