import { IDocFieldHandler } from "./IDocFieldHandler";
import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { IReference } from "../../../../globals";
import { extend } from "../../../matrixlib/JQueryExtendReplacement";
import { ItemSelectionAbstractDocFieldHandler } from "./ItemSelectionAbstractDocFieldHandler";

export interface IRiskStatsOptions extends IDHFSectionOptions {
    includeInToc: boolean;
    table: string;
    rbm: string;
    ram: string;
    noTest: string;
    mustReduce: string;
    noBenefits: string;
    riskNoTest: string;
    riskAfterByZone: string;
    riskBeforeByZone: string;
    mitNoTest: string;
    traceMitTest: string;
    search?: string;
}

export class RiskStatsDocFieldHandler extends ItemSelectionAbstractDocFieldHandler implements IDocFieldHandler {
    getDefaultConfig(): IRiskStatsOptions {
        return {
            includeInToc: false,
            table: "Risk table",
            rbm: "Risk levels before controls",
            ram: "Risk levels after controls",
            noTest: "Test coverage overview",
            mustReduce: "Risks which need to be further reduced",
            noBenefits: "Risks which are not justified by benefits",
            riskAfterByZone: "Risks by zone after control",
            riskBeforeByZone: "Risks by zone before control",
            riskNoTest: "Risks without tests",
            mitNoTest: "Risk controls without tests",
            traceMitTest: "Traces from risks to tests",
        };
    }

    getXmlValue(): string {
        let reflist = <IReference[]>this.getItems();
        for (let idx = 0; idx < reflist.length; idx++) {
            extend(true, reflist[idx], this.dhfFieldConfig);
        }
        return JSON.stringify(reflist);
    }
}
