import { IFieldHandler } from "./IFieldHandler";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { IFromToSelection } from "../../UI/Controls/itemSelectionFromTo";
import { IAnyMap, IReference } from "../../../globals";

export class ItemSelectionFieldHandlerFromTo implements IFieldHandler {
    protected config: XRFieldTypeAnnotatedParamJson;
    private fieldType: string;

    private selectedItems: IFromToSelection;
    private defaultSelection: IReference[] = [];

    constructor(configIn: XRFieldTypeAnnotatedParamJson, fieldTypeIn?: string) {
        this.config = configIn;
        this.fieldType = fieldTypeIn;
        // initialize object
        this.selectedItems = { from: [], to: [] };
    }

    getData(): string | undefined {
        return JSON.stringify(this.selectedItems);
    }

    setData(value: string, doValidation?: boolean) {
        this.selectedItems = JSON.parse(value);
    }

    getFieldType(): string {
        return this.fieldType;
    }

    initData(serializedFieldData: string | undefined) {
        this.selectedItems = { from: [], to: [] };
        if (serializedFieldData && serializedFieldData !== "undefined" && serializedFieldData !== "") {
            this.selectedItems = JSON.parse(serializedFieldData);
        } else if (this.config && this.config.parameter && this.config.parameter.allTo) {
            this.selectedItems.to = this.defaultSelection;
        }
    }

    getSelectedItems() {
        return this.selectedItems;
    }

    setSelectedItems(data: IFromToSelection) {
        this.selectedItems = data;
    }

    setFromSelectiont(newSelection: IReference[]) {
        this.selectedItems.from = newSelection;
    }

    setToSelectiont(newSelection: IReference[]) {
        this.selectedItems.to = newSelection;
    }

    isDefaultSelection() {
        if (this.defaultSelection.length !== this.selectedItems.to.length) {
            return false;
        }
        const notInDefault = this.selectedItems.to.filter((d: IReference) => {
            return this.defaultSelection.reduce((prev, item) => {
                if (item.to === d.to) {
                    return false;
                } else {
                    return prev;
                }
            }, true);
        });
        return notInDefault.length === 0;
    }

    setDefaultSelection(map: IReference[]) {
        this.defaultSelection = map;
    }

    setConfig(config: IAnyMap) {
        this.config = config;
    }
}
