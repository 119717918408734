import { InitializeDocumentSections } from "./DocumentSections/initialize";

import { initialize as InitializeReviewContextFrame } from "./ReviewContextFrame";
import { initialize as InitializeMerge } from "./Merge";
import { initialize as InitializeMassImport } from "./MassImport";
import { initialize as InitializeNotifications } from "./Notifications";
import { initialize as InitializeRedlining } from "./Redlining";
import { initialize as InitializeReview } from "./ReviewControl";
import { initialize as InitializeReIndex } from "./ReIndex";

import { initialize as InitializeRiskControlFolder } from "./RiskControlFolder";

import { initialize as InitializeCleanup } from "./beta/Cleanup";
import { initialize as InitializeCompare } from "./beta/Compare";
import { initialize as InitializeCopyPaste } from "./beta/CopyPaste";
import { initialize as InitializeHazards } from "./beta/Hazards";
import { initialize as InitializeMultiMove } from "./beta/MultiMove";
import { initialize as InitializeMultiSetLabel } from "./beta/MultiSetLabel";
import { initialize as InitializeTouchdown } from "./beta/Touchdown";
import { initialize as InitializeComponentImportDashboard } from "../Dashboards/ComponentImportDashboard";

export { InitializePlugins };

function InitializePlugins() {
    InitializeDocumentSections();

    InitializeMassImport();
    InitializeMerge();
    InitializeReviewContextFrame();
    InitializeNotifications();
    InitializeRedlining();
    InitializeReview();
    InitializeReIndex();
    InitializeRiskControlFolder();

    InitializeCleanup();
    InitializeCompare();
    InitializeCopyPaste();
    InitializeHazards();
    InitializeMultiMove();
    InitializeMultiSetLabel();
    InitializeTouchdown();
    InitializeComponentImportDashboard();
}
