import { ItemSelectionFieldHandlerFromTo } from "../ItemSelectionFieldHandlerFromTo";
import { IDocFieldHandler } from "./IDocFieldHandler";
import { IAnyMap } from "../../../../globals";
import { IDHFControlDefinition } from "../../DHFInterfaces";

export abstract class ItemSelectionFromToAbstractDocFieldHandler
    extends ItemSelectionFieldHandlerFromTo
    implements IDocFieldHandler
{
    dhfFieldConfig: IAnyMap;

    constructor(fieldType: string, config: IAnyMap, fieldValue: string | undefined) {
        super(config, fieldType);

        if (!config) {
            config = this.getDefaultConfig();
        }
        this.dhfFieldConfig = config;

        this.initData(fieldValue);
    }

    setDHFConfig(config: IAnyMap): void {
        this.dhfFieldConfig = config;
    }
    addSignatures(signatures: string[], includeAll: boolean) {
        //Nothing to do here
    }
    abstract getDefaultConfig(): any;

    abstract getXmlValue(): string;

    protected name = "";
    getFieldName(): string {
        return this.name;
    }

    setFieldName(value: string): void {
        this.name = value;
    }
}
