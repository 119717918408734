import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { IDropdownOption, IDropdownGroup, IDropDownConfig } from "../../../ProjectSettings";
import { globalPrintConfig } from "../../../printinterface/PrintProcessorInterfaces";
import { ControlState, IGenericMap, globalMatrix, app } from "../../../globals";
import { DropdownFieldHandler, IBaseDropdownFieldParams } from "../../businesslogic/FieldHandlers/DropdownFieldHandler";
import { UserFieldHandler } from "../../businesslogic";

export interface IDropdownParams extends IBaseDropdownFieldParams {
    readonly?: boolean; // can be set to overwrite the default readonly status
    placeholder?: string; // can be a dummy text, e.g. for creation dialog
    groups?: IDropdownGroup[]; // whether to group options
    sort?: boolean; // true sort options,

    splitHuman?: boolean; // if human values differ from real values (e.g. for XTCs which are passed|ok|p (only the fist part is interesting).
    inlineHelp?: string; // optional help under title
    requiresContent?: boolean;
    maxHeight?: string; // if set allow to have bigger drop downs (default is 200px max)
    // very special:)
    printProcessor?: {
        dropdownOptions?: string; // name of a function... these are hardcoded: see below
        parameterField?: string; //
    };
    width?: string; // allow to make something else than 18cm width
}

export interface IDropDownControlOptions extends IBaseControlOptions {
    parameter?: IDropdownParams;
    fieldHandler?: UserFieldHandler | DropdownFieldHandler;
    noMarkup?: boolean; // to create a control without help line and default width
}

export interface IUserSelect extends IDropDownControlOptions {
    parameter?: {
        readonly?: boolean; // can be set to overwrite the default readonly status
        placeholder?: string;
        maxItems?: number;
        // sort?:boolean,-> users are always sorted....
        showGroups?: boolean; // set to true to show users groups
        showUsers?: boolean; // set to false to not show users (default or undefined == true)
    };
    options?: IDropdownOption[]; // this is generated by matrix
    create?: boolean;
}

$.fn.mxDropdown = function (this: JQuery, options: IDropDownControlOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = new DropdownFieldHandler(options.parameter, options.fieldValue);
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new DropdownImpl(this, options.fieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

export class DropdownImpl extends BaseControl<DropdownFieldHandler | UserFieldHandler> {
    private settings: IDropDownControlOptions;
    private ctrl: JQuery;
    private duringInit: boolean;
    private beforeDisplay: string;
    private doesRequireContent = false;

    constructor(control: JQuery, fieldHandler: DropdownFieldHandler | UserFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IDropDownControlOptions) {
        let that = this;

        let defaultOptions: IDropDownControlOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            valueChanged: function () {
                // callback to call if value changes
            },
            parameter: {
                placeholder: "select item", // can be a dummy text, e.g. for creation dialog
                readonly: false, // can be set to overwrite the default readonly status
                maxItems: 1, // maxItems which can be selected
                options: [], // options in dropdown  [{id:..., label: ...}]
                groups: [], // whether to group options
                create: false, // true if values can be added
                sort: true, // true sort options,
                optionSetting: null, // can be name of project setting with options and groups
                splitHuman: true, // if human values differ from real values (e.g. for XTCs which are passed|ok|p (only the fist part is interesting).
                // however sometimes, e.g. for traces REQ|SPEC is the value not (just REQ), so the should not be splitted
            },
        };

        this.settings = <IDropDownControlOptions>ml.JSON.mergeOptions(defaultOptions, options); // initialize options

        // If we are working with an Item FieldHandler, then the options field is already initialized with valid values.
        // Otherwise, it is an empty array as specified in the defaultOptions above.
        if (this.settings.parameter.optionSetting && this.settings.parameter.options.length === 0) {
            let config = <IDropDownConfig>globalMatrix.ItemConfig.getSettingJSON(this.settings.parameter.optionSetting);
            if (config) {
                this.settings.parameter.options = config.options;
                this.settings.parameter.groups = config.groups;
            }
        }

        // special handling for print processor options
        if (this.settings.parameter.printProcessor) {
            if (this.settings.parameter.printProcessor.dropdownOptions == "getFieldAndLabelsIteratorsDropdown") {
                this.settings.parameter.options = globalPrintConfig.getFieldAndLabelsIteratorsDropdown();
            } else if (this.settings.parameter.printProcessor.dropdownOptions == "getTracesIteratorsDropdownOptional") {
                this.settings.parameter.options = globalPrintConfig.getItemIteratorsDropdown(true, false, true);
            } else if (this.settings.parameter.printProcessor.dropdownOptions == "getTracesIteratorsDropdown") {
                this.settings.parameter.options = globalPrintConfig.getItemIteratorsDropdown(true, false, false);
            } else if (
                this.settings.parameter.printProcessor.dropdownOptions == "getTracesAndTreeIteratorsDropdownOptional"
            ) {
                this.settings.parameter.options = globalPrintConfig.getItemIteratorsDropdown(true, true, true);
            } else if (this.settings.parameter.printProcessor.dropdownOptions == "getTracesAndTreeIteratorsDropdown") {
                this.settings.parameter.options = globalPrintConfig.getItemIteratorsDropdown(true, true, false);
            } else if (this.settings.parameter.printProcessor.dropdownOptions == "getTreeIteratorsDropdown") {
                this.settings.parameter.options = globalPrintConfig.getItemIteratorsDropdown(false, true, false);
            } else if (this.settings.parameter.printProcessor.dropdownOptions == "getTreeIteratorsDropdownOptional") {
                this.settings.parameter.options = globalPrintConfig.getItemIteratorsDropdown(false, true, true);
            } else if (this.settings.parameter.printProcessor.dropdownOptions == "getItemConditionDropdown") {
                this.settings.parameter.options = globalPrintConfig.getItemConditionDropdown();
            }
        }
        // have default values
        if (!this.settings.fieldValue && this.settings.parameter.initialContent && !this.settings.item) {
            this.settings.fieldValue = this.settings.parameter.initialContent;
        }

        this.fieldHandler = <DropdownFieldHandler>this.settings.fieldHandler;
        this.addCustomValuesToOptions();

        // create help line
        if (!this.settings.noMarkup) {
            this._root.append(super.createHelp(this.settings));
        }

        // draw control: in case it is readonly
        if (!this.settings.canEdit) {
            // MATRIX-2481
            this._root.data("original", this.fieldHandler.getData());
            this._root.data("new", this.fieldHandler.getData());
            // get values for UI
            let hvs = this.getFieldHandler().getHuman() ? this.getFieldHandler().getHuman().split(",") : [];
            let labels: string[] = [];

            for (let hv of hvs) {
                let label = hv;
                for (let option of this.settings.parameter.options) {
                    if (option.id == hv) {
                        label = decodeURI(option.label);
                    }
                }
                labels.push(label);
            }

            if (
                this.settings.controlState === ControlState.Print ||
                this.settings.controlState === ControlState.Tooltip
            ) {
                let css = this.settings.controlState === ControlState.Print ? "class='printBox'" : "";

                const labelsDiv = $("<div class='" + css + "'>");
                // using "text" method in order to avoid interpreting label's content as html
                labelsDiv.text(labels.join(","));

                this._root.append(labelsDiv);
            }
            // MATRIX-6909 :in review state, the value should be in a div
            else if (this.settings.controlState == ControlState.Review) {
                let ctrlContainer = $("<div class='baseControl'>").appendTo(this._root);
                let ctrlEdit = $("<div>").appendTo(ctrlContainer);
                ctrlEdit.text(labels.join(","));
            } else {
                // ui is locked by label or gate
                let ctrlContainer = $("<div class='baseControl'>").appendTo(this._root);
                let ctrlEdit = $(
                    '<input autocomplete="off" type="text" class="lineInput form-control" readonly>',
                ).appendTo(ctrlContainer);
                ctrlEdit.val(labels.join(","));
            }
            return;
        }

        if (options.parameter && options.parameter.requiresContent) {
            this.doesRequireContent = options.parameter.requiresContent;
        }

        // draw editable control
        let ctrlContainer = $("<div class='baseControl'>").appendTo(this._root);

        // If we can add new options let's add them to the list
        if (this.settings.parameter.create) {
            for (let value of this.fieldHandler.getValues(false)) {
                const uiValue = ml.UI.lt.forUI(value, null);
                if (!this.settings.parameter.options.some((option) => option.id === uiValue)) {
                    this.settings.parameter.options.push({ id: uiValue, label: uiValue });
                }
            }
        }

        let sIdx = 1;
        for (let option of this.settings.parameter.options) {
            (<IGenericMap>option)["sId"] = sIdx;
            sIdx++;
        }

        this.ctrl = $("<input>");
        ctrlContainer.append(this.ctrl);

        // This is to avoid escaped strings showing in the UI

        this.settings.parameter.options.forEach((option) => (option.label = ml.UI.lt.forUI(option.label, null)));

        this.ctrl.selectize({
            plugins: ["remove_button"],
            maxItems: <any>this.settings.parameter.maxItems == "1" ? 1 : this.settings.parameter.maxItems,
            valueField: "id",
            labelField: "label",
            searchField: "label",
            create: ml.JSON.isTrue(this.settings.parameter.create),
            createOnBlur: ml.JSON.isTrue(this.settings.parameter.create),
            sortField: ml.JSON.isTrue(this.settings.parameter.sort) ? "label" : "sId",
            placeholder: this.settings.parameter.placeholder,
            options: this.settings.parameter.options,
            optgroups: this.settings.parameter.groups,
            optgroupField: "class",
            onChange: function () {
                that.valueChanged();
            },
            render: {
                option: function (item, escape) {
                    // This is to avoid escaped strings showing in the UI
                    let labelText = ml.UI.lt.forDB(item.label, null);
                    if (item.strikethrough) {
                        labelText = `<s>${labelText}</s>`;
                    }
                    return `<div class='option'>${labelText}</div>`;
                },
                item: function (item, escape) {
                    let labelText = ml.UI.lt.forDB(item.label, null);
                    if (item.strikethrough) {
                        labelText = `<s>${labelText}</s>`;
                    }
                    return `<div class='item'>${labelText}</div>`;
                },
            },
        });
        if (this.settings.parameter.width) {
            $(".selectize-input", this.ctrl.parent()).css("width", this.settings.parameter.width);
        }
        if (this.settings.parameter.maxHeight) {
            $(".selectize-dropdown-content", $(this.ctrl).parent()).css(
                "max-height",
                this.settings.parameter.maxHeight,
            );
        }

        if (
            this.settings.isForm &&
            this.settings.parameter.printProcessor &&
            this.settings.parameter.printProcessor.parameterField
        ) {
            let editor = $("<div class='printFunctionOption link'>edit parameters</div>");
            ctrlContainer.append(editor);
            editor.click(async function () {
                let editFieldId = globalMatrix.ItemConfig.getFieldId(
                    that.settings.type,
                    that.settings.parameter.printProcessor.parameterField,
                );
                globalPrintConfig.showOptionsEditor(
                    await that.getValueAsync(),
                    await app.getFieldValueAsync(editFieldId),
                    (newValue: string) => {
                        app.setFieldValue(editFieldId, newValue);
                    },
                );
            });
        }

        this.duringInit = true;
        this.beforeDisplay = this.fieldHandler.getData();

        const vals = this.getFieldHandler()
            .getValues(false)
            .map((val) => ml.UI.lt.forUI(val, that.settings.fieldId));
        (<any>this.ctrl[0]).selectize.setValue(vals);
        this.duringInit = false;

        let rt = ml.UI.lt.forDB(this.ctrl.val(), that.settings.fieldId);

        this._root.data("new", rt);
        this._root.data("original", rt);
    }

    getValueRaw(): string {
        return this.fieldHandler.getData();
    }

    async hasChangedAsync() {
        if (!this.duringInit) {
            return this._root.data("original") !== this._root.data("new");
        }
        // value get value in control after it was displayed
        let afterDisplay = ml.UI.lt.forDB(this.ctrl.val(), this.settings.fieldId);
        if (afterDisplay == this.beforeDisplay) {
            // nothing changed!
            return false;
        }
        if (!afterDisplay && !this.beforeDisplay) {
            // one could be undefined and the other "" -> nothing changed
            return false;
        }
        if (!afterDisplay || !this.beforeDisplay) {
            // one has a value, but not the other -> something changed
            return true;
        }

        let aParts = afterDisplay.split(",");
        let bParts = this.beforeDisplay.split(",");

        if (aParts.length != bParts.length) {
            // they have different number of values -> something changed
            return true;
        }

        for (let idx = 0; idx < aParts.length; idx++) {
            if (bParts.indexOf(aParts[idx]) == -1) {
                // a value in one does not exist in other -> something changed
                return true;
            }
        }

        // nothing changed!
        return false;
    }

    async getValueAsync() {
        return this._root.data("new");
    }

    // return not selected index, but typed (visible) text
    getText(): string {
        return $(".item", this._root).html();
    }

    destroy() {
        // do nothing
    }

    resizeItem() {
        // do nothing
    }

    requiresContent() {
        return this.doesRequireContent;
    }

    setValue(newValueDirty: string, force?: boolean, triggerChange?: boolean) {
        let that = this;

        // be sure there's no <
        let newValue = ml.UI.lt.forDB(newValueDirty, that.settings.fieldId);

        this._root.data("new", newValue);
        if (force) {
            // do not care whether the value actually exists
            return;
        }
        this.fieldHandler.setData(newValue);
        this.settings.fieldValue = newValue;

        this.addCustomValuesToOptions();

        if (this.ctrl) {
            const newValues = this.getFieldHandler()
                .getValues(false)
                .map((val) => ml.UI.lt.forUI(val, that.settings.fieldId));
            (<any>this.ctrl[0]).selectize.setValue(newValues);
        }
        if (triggerChange && this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }

    // private functions
    private valueChanged() {
        let val = ml.UI.lt.forDB(this.ctrl.val(), this.settings.fieldId);

        if (val) {
            $(".printFunctionOption", this._root).show();
        } else {
            $(".printFunctionOption", this._root).hide();
        }

        if (this.duringInit || this._root.data("new") == val) {
            // only trigger change if something really changes
            return;
        }

        this._root.data("new", val);
        this.fieldHandler.setData(val);
        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }

    private addCustomValuesToOptions() {
        // add custom value to selectable values if it is create mode
        if (this.settings.parameter.create && this.fieldHandler && this.getValueRaw() !== "") {
            for (let rv of this.getFieldHandler().getValues(false)) {
                const uiRv = ml.UI.lt.forUI(rv, this.settings.fieldId);
                if (!this.settings.parameter.options.some((value) => value.id === uiRv)) {
                    this.settings.parameter.options.push({
                        id: uiRv,
                        label: uiRv,
                    });
                }
            }
        }
    }
}
