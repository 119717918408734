export { EImportMode, ItemParentMode };

enum EImportMode {
    Include = "include",
    Copy = "copy",
    IncludeRoot = "recipient_folder_include",
}

enum ItemParentMode {
    Orphan = "orphan",
    Preserve = "preserve",
}
